import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import { AuthService } from "services";
import { StorageUtil, getPrefixKey, toCamel, toSnake, uuid } from "utils";
import { LocalDbManagement, ElectronService, getInteractor } from "services/local.service";
import store, { AuthActions, BranchActions, PubSubActions } from "redux-store";
import { remoteApiFactory } from "services";
import { synchronizeData } from "./synchronize.saga";
import { getLoginBranchInfo, setLoginData } from "utils/auth.utils";

export function* requestLogin(action) {
  try {
    yield put(
      AuthActions.authSet({
        authLoginStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
      }),
    );

    const { data } = action;
    const params = toSnake(data);

    const response = yield call(AuthService.login, params);
    const dataLogin = toCamel(response.data);
    if (response.status === ApiConstant.STT_OK && dataLogin) {
      const currentLoginInfo = StorageUtil.getCommonKey(KeyConstant.KEY_TMP_BRANCH);
      StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, { ...currentLoginInfo, phone: data.phone });
      yield put(
        AuthActions.authSuccess({
          isSuccess: true,
          phoneNumber: data.phone,
          accountUuid: data.accountUuid,
          deviceUuid: data.deviceUuid,
          token: data.token,
          isNew: response.data.isNew,
          masterExisted: dataLogin.masterExisted,
          masterName: response.data.master_name,
          authLoginStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.success,
          authVerifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
        }),
      );
    } else {
      yield handleErrorResponse(response, STATE_CONTROL_NAME.login);
    }
  } catch (error) {
    yield put(AuthActions.authFailure(error));
    console.warn(error);
  }
}

export function* requestVerify(action) {
  try {
    yield put(
      AuthActions.authSet({
        authVerifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
      }),
    );

    const {
      authRedux: { token, accountUuid, deviceUuid, phoneNumber },
    } = yield select();
    const { data } = action;
    const params = {
      ...data,
      token: data.token || token,
      account_uuid: data.account_uuid || accountUuid,
      device_uuid: data.device_uuid || deviceUuid,
    };

    const response = yield call(AuthService.verify, params);
    if (response.status === ApiConstant.STT_OK) {
      const camelResponse = toCamel(response.data);
      const isResult = yield handleLogin(camelResponse, data.phone || phoneNumber);
      if (false === isResult) throw new Error("CAN NOT LOGIN");

      if (false === Boolean(data.verify_token)) {
        // Case: Login page
        yield put(
          AuthActions.authSuccess({
            isLogin: true,
            authVerifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.success,
          }),
        );
      }

      yield synchronizeData();
    } else {
      yield handleErrorResponse(response, STATE_CONTROL_NAME.authVerify);
    }
  } catch (error) {
    yield put(AuthActions.authFailure(error));
    console.warn(error);
  }
}

const handleLogin = async (camelResponse, phoneNumber) => {
  try {
    const prefixKey = getPrefixKey(camelResponse.accountId, camelResponse.branchId);
    const isValid =
      Object.keys(camelResponse).length > 0 && phoneNumber && prefixKey && false === prefixKey.includes("undefined");
    if (!isValid) return false;

    setLoginData(camelResponse, phoneNumber, prefixKey);
    // Set token to api's current branch
    remoteApiFactory.getBranchApi(prefixKey).setToken(camelResponse.accessToken);

    // create db for new user in this device
    await getInteractor(prefixKey).LocalInitDataService.initDataForUser();
    const currentDbRecord = await LocalDbManagement.findOne({
      branch_id: camelResponse.branchId,
    });

    const timestamp = new Date().getTime();
    const loginBranchInfo = getLoginBranchInfo();
    const dbManagementItem = Boolean(currentDbRecord && currentDbRecord.id)
      ? {
          ...currentDbRecord,
          owner_name: phoneNumber,
          account_id: camelResponse.accountId,
          token: camelResponse.accessToken,
          options: JSON.stringify(camelResponse),
          state: SystemConstant.STATE.active,
          modified: timestamp,
        }
      : {
          id: uuid(),
          account_id: camelResponse.accountId,
          branch_id: camelResponse.branchId,
          owner_name: phoneNumber,
          state: SystemConstant.STATE.active,
          options: JSON.stringify(camelResponse),
          token: camelResponse.accessToken,
          branch_name: null,
          branch_domain: loginBranchInfo.domain,
          logo_app: null,
          created: timestamp,
          modified: timestamp,
        };
    await LocalDbManagement.save([dbManagementItem]);

    // Update new device id in message.table
    const newDeviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    await getInteractor(prefixKey).LocalMessageService.updateNewDevice(newDeviceId);

    BranchActions.updateSelectedBranch(StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER));

    // Set app logo
    ElectronService.setLogoApp(camelResponse.branchId);

    // Start interval checking news
    store.dispatch(PubSubActions.startCheckingNews(prefixKey, loginBranchInfo.domain));

    return true;
  } catch (error) {
    console.warn(error);
  }

  return false;
};

const STATE_CONTROL_NAME = {
  login: "authLoginStatus",
  authVerify: "authVerifyStatus",
};

function* handleErrorResponse(response, key) {
  const errorResponse = {};
  switch (response.status) {
    case ApiConstant.STT_BAD_REQUEST:
      errorResponse[key] = AppConstant.VERIFY_OTP_ERROR_TYPE.wrongOtp;
      yield put(AuthActions.authSuccess(errorResponse));
      break;

    case ApiConstant.STT_OTP_EXPIRED:
      errorResponse[key] = AppConstant.VERIFY_OTP_ERROR_TYPE.expiredOtp;
      yield put(AuthActions.authSuccess(errorResponse));
      break;

    case ApiConstant.STT_FORBIDDEN:
      errorResponse[key] = AppConstant.VERIFY_OTP_ERROR_TYPE.limitResend;
      yield put(AuthActions.authSuccess(errorResponse));
      break;

    case ApiConstant.STT_NOT_FOUND:
      errorResponse[key] = AppConstant.VERIFY_OTP_ERROR_TYPE.notFoundPhone;
      yield put(AuthActions.authSuccess(errorResponse));
      break;

    case ApiConstant.STT_BLOCK:
      errorResponse[key] = AppConstant.VERIFY_OTP_ERROR_TYPE.block;
      yield put(AuthActions.authSuccess(errorResponse));
      break;

    case ApiConstant.STT_LIMIT_DEVICE:
      errorResponse[key] = AppConstant.VERIFY_OTP_ERROR_TYPE.limitDevice;
      yield put(AuthActions.authSuccess(errorResponse));
      break;

    default:
      errorResponse[key] = AppConstant.VERIFY_OTP_ERROR_TYPE.systemError;
      yield put(AuthActions.authSuccess({ ...errorResponse, ...response.data }));
      break;
  }
}
