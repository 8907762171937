import { useEffect, useState } from "react";
import { SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { getAllDeviceFromRemote } from "pubsub/services/device.service";
import { StorageUtil } from "utils";

const useOnlineGroup = groupId => {
  const [isOnline, setIsOnline] = useState(false);

  const checkOnline = async prefixKey => {
    const groupDetail = await getInteractor(prefixKey).LocalGroupService.get(groupId);
    const isChatWithPersonal =
      Array.isArray(groupDetail.groupMembers) &&
      groupDetail.groupMembers.length > 0 &&
      groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    const personalAccountId = isChatWithPersonal ? groupDetail.groupMembers[0].id : null;

    if (personalAccountId) {
      await getAllDeviceFromRemote(prefixKey, personalAccountId);
      const isOnline = await getInteractor(prefixKey).LocalDeviceService.isOnlineAccount(personalAccountId);
      setIsOnline(isOnline);
    } else {
      setIsOnline(false);
    }
  };

  useEffect(() => {
    if (groupId) {
      const prefixKey = StorageUtil.getCurrentPrefixKey();
      checkOnline(prefixKey);
    }
  }, [groupId]);

  return { isOnline };
};

export default useOnlineGroup;
