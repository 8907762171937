import { ErrorMessageRetry, ErrorMessageSendNull } from "app-model";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { LocalAppNotificationService, getInteractor } from "services/local.service";
import { StorageUtil, isJSONString, toSnake, uuid } from "utils";
import { isGroupOrChannelType } from "utils/view.utils";
import store, { SystemActions } from "redux-store";

const RETRY_AFTER_5_MINUTE = 5 * 60000;

export const handlingErrorMessage = async (newMessage, group, prefixKey) => {
  console.log("handle message error");
  if (
    newMessage.sendType === SystemConstant.SEND_TYPE.senderKeyDeliveryError ||
    newMessage.sendType === SystemConstant.SEND_TYPE.keyError
  ) {
    await getInteractor(prefixKey).LocalCryptoService.deleteSession(
      newMessage.senderId,
      newMessage.senderDeviceId,
      newMessage.groupId,
    );

    if (newMessage.sendType === SystemConstant.SEND_TYPE.keyError) {
      try {
        const decryptE2EMsg = await getInteractor(prefixKey).LocalCryptoService.decryptE2EMessage(
          newMessage.senderId,
          newMessage.senderDeviceId,
          newMessage.groupId,
          newMessage.content,
        );

        if (decryptE2EMsg != null && group) {
          if (isGroupOrChannelType(group.groupType)) {
            await sendOneMessageSenderKeyDistributionMessage(prefixKey, group, newMessage);
          }
          await catchResend(
            prefixKey,
            newMessage.senderId,
            newMessage.senderDeviceId,
            newMessage.sourceId,
            newMessage.branchId,
            group.groupType,
            newMessage.groupId,
          );
        }
      } catch (e) {
        console.log(e);
        console.log(
          `handle message keyError fail 2 group_id: ${newMessage.groupId}, send_type: ${newMessage.sendType}, sender: ${newMessage.senderDeviceId}, deviceId: ${newMessage.deviceId}`,
        );
      }
    }
    if (newMessage.sendType === SystemConstant.SEND_TYPE.senderKeyDeliveryError && group) {
      if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        await catchSendErrorMessage(
          prefixKey,
          newMessage.senderId,
          newMessage.senderDeviceId,
          newMessage.sourceId,
          newMessage.branchId,
          group,
          SystemConstant.SEND_TYPE.keyError,
        );
      } else {
        await sendOneMessageSenderKeyDistributionMessage(prefixKey, group, newMessage);
      }
    }
  } else if (newMessage.sendType === SystemConstant.SEND_TYPE.senderKey) {
    const isStored = await getInteractor(prefixKey).LocalCryptoService.storeDistributionKey(
      newMessage.senderId,
      newMessage.senderDeviceId,
      newMessage.groupId,
      newMessage.content,
    );
    if (!isStored) {
      await handleSendErrorMsg(newMessage, SystemConstant.SEND_TYPE.senderKeyDeliveryError, group, prefixKey);
    }
  }

  console.log("Save error-message");

  return await getInteractor(prefixKey).LocalMessageService.saveFromRemote([toSnake(newMessage)]);
};

export const handleSendErrorMsg = async (message, sendType, group, prefixKey) => {
  const checkError = await getInteractor(prefixKey).LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
    message.senderDeviceId,
    message.groupId,
    0,
  );
  const timeRetry = new Date().getTime() - RETRY_AFTER_5_MINUTE;
  const isError =
    checkError == null ||
    (checkError.created < timeRetry && !checkError.modified) ||
    (checkError.modified != null && checkError.modified !== 0 && checkError.modified < timeRetry);

  const sourceMessage = await getInteractor(prefixKey).LocalMessageService.findOne({ source_id: message.sourceId });
  if (isError && (!sourceMessage || sourceMessage.send_type !== message.sendType)) {
    await getInteractor(prefixKey).LocalCryptoService.deleteSession(
      message.senderId,
      message.senderDeviceId,
      message.groupId,
    );
    if (isGroupOrChannelType(group.groupType)) {
      await sendOneMessageSenderKeyDistributionMessage(prefixKey, group, message);
    }
    await catchSendErrorMessage(
      prefixKey,
      message.senderId,
      message.senderDeviceId,
      message.sourceId,
      message.branchId,
      group,
      sendType,
    );
  }
};

export const catchResend = async (prefixKey, accountId, deviceId, sourceId, branchId, groupType, groupId) => {
  const message = (await getInteractor(prefixKey).LocalMessageService.findOne({ source_id: sourceId })) || {};
  let retryRow = await getInteractor(prefixKey).LocalMsgErrorResendService.findByDeviceIdAndGroupIdAndSourceId(
    deviceId,
    groupId,
    sourceId,
  );

  try {
    if (message && Object.keys(message).length > 0) {
      if (retryRow == null) {
        const test = {
          id: uuid(),
          source_id: sourceId,
          device_id: deviceId,
          group_id: groupId,
          retry: 0,
          state: SystemConstant.STATE.active,
          options: null,
          created: Date.now(),
          modified: Date.now(),
          branch_id: message.branch_id,
        };
        const tmpRetry = new ErrorMessageRetry(test);
        retryRow = tmpRetry.toJson();
        await getInteractor(prefixKey).LocalMsgErrorResendService.save([retryRow]);
        let count = 0;
        let offset = 0;
        while (count % 999 === 0) {
          let reSendList = await getInteractor(prefixKey).LocalMessageService.findNextMyMessage(
            message.device_id,
            message.group_id,
            message.created,
            999,
            false,
          );
          count += reSendList.length;
          if (offset === 0) reSendList = [message].concat(reSendList);
          offset += 999;
          for (let i = 0; i < reSendList.length; i++) {
            const it = reSendList[i];
            if (groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
              await resendE2EMessage(prefixKey, accountId, deviceId, sourceId, branchId, it);
            } else {
              await resendE2EEMessage(prefixKey, accountId, deviceId, sourceId, branchId, it, groupType);
            }
          }
          if (reSendList.length === 0 || (reSendList.length === 1 && count === 0)) break;
        }
      }
    } else if (retryRow && retryRow.retry < 3) {
      let count = 0;
      let offset = 0;
      while (count % 999 === 0) {
        let reSendList = await getInteractor(prefixKey).LocalMessageService.findNextMyMessage(
          message.device_id,
          message.group_id,
          message.created,
          999,
          false,
        );
        count += reSendList.length;
        if (offset === 0) reSendList = [message].concat(reSendList);
        offset += 999;
        for (let msgIndex = 0; msgIndex < reSendList.length; msgIndex++) {
          const it = reSendList[msgIndex];
          if (groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
            await resendE2EMessage(prefixKey, accountId, deviceId, sourceId, branchId, it);
          } else {
            await resendE2EEMessage(prefixKey, accountId, deviceId, sourceId, branchId, it, groupType);
          }
        }
        if (reSendList.length === 0 || (reSendList.length === 1 && count === 0)) break;
      }
    }
    return null;
  } finally {
    if (retryRow != null) {
      if (retryRow.retry >= 3) {
        retryRow.state = SystemConstant.STATE.inactive;
        //
      } else {
        retryRow.modified = new Date().getTime();
        retryRow.retry = retryRow.retry + 1;
      }
      await getInteractor(prefixKey).LocalMsgErrorResendService.save([retryRow]);
    }
  }
};

export const sendOneMessageSenderKeyDistributionMessage = async (prefixKey, group, message) => {
  message = toSnake(message);
  group = toSnake(group);
  const { sender_id, sender_device_id } = message || {};
  const groupId = group?.id || uuid();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
  const senderKeyMessage = await getInteractor(prefixKey).LocalCryptoService.generateDistributionKey(
    accountId,
    deviceId,
    groupId,
  );
  const contentEncryption = await getInteractor(prefixKey).LocalCryptoService.encryptE2EMessage(
    sender_id,
    sender_device_id,
    groupId,
    senderKeyMessage,
  );
  if (contentEncryption) {
    const id = uuid();
    const jsonOption = {};
    jsonOption.encryption_f = 1;
    const sendOneDistribution = {
      messageId: id,
      sendToDeviceId: sender_device_id,
      sendToAccountId: sender_id,
      content: contentEncryption,
      options: null,
      status: SystemConstant.MESSAGE_STATUS.send,
      sendType: SystemConstant.SEND_TYPE.senderKey,
      mentions: null,
      encryption_type: SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION,
    };
    const newMessage = {
      groupId: group.id,
      sourceId: uuid(),
      groupType: group.group_type,
      sendType: SystemConstant.SEND_TYPE.senderKey,
      created: Date.now(),
      messages: [sendOneDistribution],
      branchId: group.branch_id,
      parentId: null,
      threadId: null,
    };
    const marks = [
      {
        group_id: group.id,
        account_id: sendOneDistribution.sendToAccountId,
        device_id: sendOneDistribution.sendToDeviceId,
      },
    ];
    await getInteractor(prefixKey).LocalSenderKeySharedService.save(marks);

    await getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: newMessage.sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: newMessage.branchId,
        group_id: newMessage.groupId,
      },
    ]);
    store.dispatch(SystemActions.executeQueue());
  }
};

export const catchSendErrorMessage = async (prefixKey, accountId, deviceId, sourceId, branchId, group, sendType) => {
  group = toSnake(group);
  // Kiểm tra xem đã từng gửi báo error tới tin nhắn này với thiết bị này chưa
  let sendNullRow = await getInteractor(prefixKey).LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
    deviceId,
    group?.id,
    0,
  );
  // Nếu đã gửi và vẫn chưa xử lý được thành công + sau 10 phút thì xóa và làm lại từ đầu
  if (sendNullRow && sendNullRow.retry >= 3 && sendNullRow.created <= Date.now() - 2 * RETRY_AFTER_5_MINUTE) {
    await getInteractor(prefixKey).LocalMsgErrorSendNullService.deleteByGroupIdAndDeviceIdAndType(
      group.id,
      deviceId,
      0,
    );
    sendNullRow = null;
  }

  const timeRetry = new Date().getTime() - RETRY_AFTER_5_MINUTE;
  try {
    if (sendNullRow == null) {
      // Nếu chưa từng tạo record
      const tmpSendNull = new ErrorMessageSendNull({
        id: uuid(),
        type: 0,
        device_id: deviceId,
        group_id: group?.id,
        retry: 0,
        state: SystemConstant.STATE.active,
        options: null,
        created: new Date().getTime(),
        modified: null,
        branch_id: branchId,
      });
      sendNullRow = tmpSendNull.toJson();
      await getInteractor(prefixKey).LocalMsgErrorSendNullService.save([sendNullRow]);

      await sendNullMessage(prefixKey, accountId, deviceId, sendType, sourceId, group);
    } else if (
      sendNullRow.retry < 3 &&
      (sendNullRow.created < timeRetry ||
        (sendNullRow.modified != null && sendNullRow.modified !== 0 && sendNullRow.modified < timeRetry))
    ) {
      // Cho phép gửi sendNull báo lỗi tới 3 lần với 1 tin nhắn
      sendNullRow.retry = sendNullRow.retry + 1;
      sendNullRow.modified = new Date().getTime();
      await sendNullMessage(prefixKey, accountId, deviceId, sendType, sourceId, group);
    }
    return null;
  } finally {
    if (sendNullRow != null) {
      if (sendNullRow.retry >= 3) {
        // Nếu sau 3 lần không hồi phục được thông báo với người dùng là thiết bị hỏng khoá
        sendNullRow.state = SystemConstant.STATE.inactive;
        if (StorageUtil.getItem(KeyConstant.KEY_ERROR_TIME_WARNING, prefixKey) === null) {
          LocalAppNotificationService.showNotification("Trios", {
            content: "Thiết bị lỗi khoá vui lòng đăng xuất và đăng nhập lại.",
            groupId: group?.id,
          });
        }
        await getInteractor(prefixKey).LocalMsgErrorSendNullService.deleteByGroupIdAndDeviceIdAndType(
          group.id,
          deviceId,
          0,
        );
      } else {
        await getInteractor(prefixKey).LocalMsgErrorSendNullService.save([sendNullRow]);
      }
    }
  }
};

export const sendNullMessage = async (prefixKey, accountId, deviceId, sendType, sourceId, group) => {
  const nullContent = Math.random().toString();
  const contentEncryption = await getInteractor(prefixKey).LocalCryptoService.encryptE2EMessage(
    accountId,
    deviceId,
    group.id,
    nullContent,
  );
  const id = uuid();
  // const text = content;
  if (contentEncryption === null) return null;
  const jsonOption = {};
  jsonOption.encryption_f = 1;
  const resendMessage = {
    messageId: id,
    sendToDeviceId: deviceId,
    sendToAccountId: accountId,
    content: contentEncryption,
    options: JSON.stringify(jsonOption),
    status: SystemConstant.MESSAGE_STATUS.send,
    sendType: sendType,
    mentions: null,
  };
  const newMessage = {
    isSendingKey: false,
    groupId: group.id,
    sourceId: sourceId,
    groupType: group.group_type,
    sendType: sendType,
    created: Date.now(),
    messages: [resendMessage],
    branchId: group.branch_id,
    parentId: null,
    threadId: null,
    deviceList: [deviceId],
    messageId: id,
  };
  await getInteractor(prefixKey).LocalApiCallService.save([
    {
      id: uuid(),
      task: `${AppConstant.TASK_MESSAGE_SEND}`,
      original_uid: sourceId,
      query: "",
      content: JSON.stringify({ data: newMessage }),
      original_content: JSON.stringify({ data: newMessage }),
      created: new Date().getTime(),
      retry: 0,
      branch_id: newMessage.branchId,
      group_id: newMessage.groupId,
    },
  ]);
  store.dispatch(SystemActions.executeQueue());
  return null;
};

export const resendE2EMessage = async (prefixKey, accountId, deviceId, sourceId, branchId, message) => {
  if (message) {
    const jsonOption = {};
    jsonOption.encryption_f = 1;
    const id = uuid();

    const text = await getInteractor(prefixKey).LocalCryptoService.encryptE2EMessage(
      accountId,
      deviceId,
      message.group_id,
      message.content,
    );
    // const text = content;
    if (text === null) return null;
    let callInfo = null;
    try {
      if (SystemConstant.ARR_CALLING_TYPES.includes(message.send_type)) callInfo = JSON.parse(message.content);
    } catch (e) {
      console.log(e);
    }

    const resendMessage = {
      messageId: id,
      sendToDeviceId: deviceId,
      sendToAccountId: accountId,
      content: text,
      options: JSON.stringify(jsonOption),
      status: SystemConstant.MESSAGE_STATUS.send,
      sendType: message.send_type,
      mentions: message.mentions,
      encryption_type: SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION,
    };
    const memberIdArr = await getInteractor(prefixKey).LocalAccountGroupService.find({
      group_id: message.group_id,
      state: 1,
    });
    let deviceList = null;
    if (memberIdArr && memberIdArr.length > 0) {
      deviceList = await getInteractor(prefixKey).LocalDeviceService.find({
        account_id: memberIdArr.map(member => member.account_id),
        state: SystemConstant.STATE.active,
      });
    }

    let deviceListIds = [deviceId];
    if (deviceList && deviceList.length > 0) {
      deviceListIds = deviceList.map(d => d.id);
    }
    const newMessage = {
      isSendingKey: false,
      groupId: message.group_id,
      sourceId: message.source_id,
      groupType: message.group_type,
      sendType: message.send_type,
      created: Date.now(),
      messages: [resendMessage],
      branchId: branchId,
      parentId: message.parent_id,
      threadId: message.thread_id,
      deviceList: deviceListIds,
      roomId: callInfo?.room_id,
      messageId: id,
    };
    message.id = id;
    message.send_type = SystemConstant.SEND_TYPE.keyError;
    message.options = JSON.stringify(jsonOption);
    await getInteractor(prefixKey).LocalMessageService.save([message]);
    await getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: newMessage.sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: newMessage.branchId,
        group_id: newMessage.groupId,
      },
    ]);
    store.dispatch(SystemActions.executeQueue());
  }
  return null;
};

export const resendE2EEMessage = async (prefixKey, accountId, deviceId, sourceId, branchId, message, groupType) => {
  if (message) {
    const text = await getInteractor(prefixKey).LocalCryptoService.encryptE2EEMessage(
      message.account_id,
      message.device_id,
      message.group_id,
      message.content,
    );
    const jsonOption = {};
    jsonOption.encryption_f = 1;
    const id = uuid();

    // const text = content;
    if (text === null) return null;
    let callInfo = null;
    if (SystemConstant.ARR_CALLING_TYPES.includes(message.send_type) && isJSONString(message.content)) {
      callInfo = JSON.parse(message.content);
    }
    const resendMessage = {
      messageId: id,
      sendToDeviceId: deviceId,
      sendToAccountId: accountId,
      content: text,
      options: message.options,
      status: SystemConstant.MESSAGE_STATUS.send,
      sendType: message.send_type,
      mentions: message.mentions,
      callStatus: message.call_status,
    };

    const newMessage = {
      isSendingKey: false,
      groupId: message.group_id,
      sourceId: message.source_id,
      groupType: groupType,
      sendType: message.send_type,
      created: Date.now(),
      messages: [resendMessage],
      branchId: branchId,
      parentId: message.parent_id,
      threadId: message.thread_id,
      deviceList: [deviceId],
      roomId: callInfo?.room_id,
      messageId: id,
    };
    message.id = id;
    message.options = JSON.stringify(jsonOption);
    message.send_type = SystemConstant.SEND_TYPE.keyError;
    await getInteractor(prefixKey).LocalMessageService.save([message]);
    await getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: AppConstant.TASK_MESSAGE_SEND,
        original_uid: newMessage.sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: newMessage.branchId,
        group_id: newMessage.groupId,
      },
    ]);
    console.log("resendE2EEMessage");
    store.dispatch(SystemActions.executeQueue());
  }
  return null;
};
