import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Backdrop, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import KeywordsPopup from "./KeywordsPopup";
import ContactSearchPopup from "./ContactSearchPopup";
import MessageSearchPopup from "./MessageSearchPopup";
import GroupSearchPopup from "./GroupSearchPopup";
import { EmptyResult } from "components";

const SearchPopup = ({ anchorEL, onClose, keyword, data, onKeywordClick, ...otherProps }) => {
  const classes = useStyles();
  const { contacts, channels, groups, messages } = data;
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  useEffect(() => {
    if (anchorEL) {
      let popupEl = document.getElementById(SEARCH_POPUP_ID);
      popupEl.style.left = anchorEL.offsetLeft + "px";
      popupEl.style.top = anchorEL.offsetHeight + anchorEL.offsetTop + "px";
      popupEl.style.width = anchorEL.offsetWidth + "px";
    }
  }, [anchorEL?.offsetWidth]);

  return (
    <Backdrop open={Boolean(anchorEL)} onClick={onClose} className={classes.modal} {...otherProps}>
      <Box className={classes.popupContainer} onClick={e => e.stopPropagation()} id={SEARCH_POPUP_ID}>
        {keyword.length === 0 ? (
          <KeywordsPopup onKeywordClick={onKeywordClick} />
        ) : contacts.length > 0 || messages.length > 0 || groups.length > 0 || channels.length > 0 ? (
          <Box className={classes.resultWrapper}>
            {contacts.length > 0 && <ContactSearchPopup data={contacts} keyword={keyword} onClose={onClose} />}
            {channels.length > 0 && (
              <GroupSearchPopup
                data={channels}
                keyword={keyword}
                onClose={onClose}
                title={getLabel(LangConstant.TXT_CHANNEL_FOUND)}
              />
            )}
            {groups.length > 0 && (
              <GroupSearchPopup
                data={groups}
                keyword={keyword}
                onClose={onClose}
                title={getLabel(LangConstant.TXT_GROUP_FOUND)}
              />
            )}
            {messages.length > 0 && <MessageSearchPopup data={messages} keyword={keyword} onClose={onClose} />}
          </Box>
        ) : (
          <EmptyResult
            classes={{
              root: classes.emptyDataWrapper,
              emptyImage: classes.emptySearchResultImage,
              emptyText: classes.notFoundText,
            }}
          />
        )}
      </Box>
    </Backdrop>
  );
};

export default SearchPopup;

const SEARCH_POPUP_ID = "search-popup-id";

SearchPopup.propTypes = {
  onClose: PropTypes.func,

  keyword: PropTypes.string,

  data: PropTypes.shape({
    contacts: PropTypes.array,
    groups: PropTypes.array,
    messages: PropTypes.array,
  }),
};

SearchPopup.defaultProps = {
  onClose: () => {},

  keyword: "",

  data: {
    contacts: [],
    groups: [],
    messages: [],
  },
};

const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    zIndex: theme.zIndex.modal,
  },

  popupContainer: {
    position: "absolute",
    marginTop: 8,
    maxHeight: 370,
    borderRadius: 10,
    backgroundColor: theme.palette.white,
    overflowY: "hidden",
    boxShadow: "none",
  },

  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    height: 40,
  },

  title: {
    color: "#7F7F80",
    lineHeight: "16px",
  },

  emptyDataWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 330,
    padding: 64,
  },

  emptySearchResultImage: {
    width: "unset",
    height: "unset",
    marginBottom: 18,
  },

  notFoundText: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.black,
  },

  resultWrapper: {
    overflowY: "scroll",
    maxHeight: 350,
    margin: "10px 0",
  },
}));
