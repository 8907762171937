import { Box, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFileUrl } from "hooks";

const BackgroundImage = ({ backgroundId, ...otherProps }) => {
  const classes = useStyles();
  const { fileUrl } = useFileUrl(backgroundId);

  return fileUrl ? (
    <CardMedia
      component="img"
      alt=""
      className={fileUrl ? classes.cover : classes.coverDefault}
      src={fileUrl}
      {...otherProps}
    />
  ) : (
    <Box className={classes.coverDefault} />
  );
};

export default BackgroundImage;

const useStyles = makeStyles({
  cover: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  coverDefault: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },
});
