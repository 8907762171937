import { ElectronService, getInteractor } from "services/local.service";
import { useAlertContext } from "components/context/AlertContext";
import { getCommonLang } from "utils/lang.utils";
import { AttachmentUtil, StorageUtil } from "utils";
import { getFileLocalDetails } from "services/attachment.service";
import { SystemConstant } from "const";

const useDownloadFile = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { showAlert } = useAlertContext();

  // Open downloading folder
  const openFolder = async mediaData => {
    const fileRecord = await getInteractor(prefixKey).LocalFileService.get(mediaData.attachmentId);

    if (fileRecord) {
      ElectronService.openFolder({
        prefixKey: prefixKey,
        attachmentId: mediaData.attachmentId,
        fileName: mediaData.metaData.fileName,
      });
    } else {
      console.log("can get path: ", fileRecord);
      showAlert({ content: getCommonLang("TXT_SERVER_ERROR"), alertProps: { severity: "error" } });
    }
  };

  const downloadFile = async (mediaDataList, groupId) => {
    const isValid = Array.isArray(mediaDataList) && mediaDataList.length > 0;
    if (!isValid) return false;
    try {
      const notInLocal = mediaDataList.filter(
        item => !AttachmentUtil.exitsLocalFile(prefixKey, item.attachmentId, item.metaData?.fileName),
      );

      const getRemoteFile = [];
      for (let index = 0; index < notInLocal.length; index++) {
        const item = notInLocal[index];
        const fileRecord = await getInteractor(prefixKey).LocalFileService.get(item.attachmentId);
        if (fileRecord && fileRecord.status === SystemConstant.UPLOAD_STATUS.success) {
          getRemoteFile.push(getFileLocalDetails(prefixKey, item, { groupId: groupId }));
        }
      }

      const fileResult = await Promise.all(getRemoteFile);
      const fileSuccessIds = fileResult.filter(item => item && item.mediaPath).map(item => item.attachmentId);
      const fileFailIds = notInLocal
        .filter(item => false === Boolean(fileSuccessIds.includes(item.attachmentId)))
        .map(item => item.attachmentId);

      const downloadIds = mediaDataList
        .filter(item => false === Boolean(fileFailIds.includes(item.attachmentId)))
        .map(item => item.attachmentId);

      if (downloadIds.length > 0) ElectronService.download(StorageUtil.getCurrentPrefixKey(), downloadIds);
      return true;
    } catch (error) {
      console.log("download file error: ", error);
    }

    return false;
  };

  return { downloadFile, openFolder };
};

export default useDownloadFile;
