import { useState, createContext, useContext } from "react";
import AppAlert from "../AppAlert";

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  showAlert: props => {}, // Alert props
  closeAlert: () => {},
};

const AlertContext = createContext(INITIAL_STATE);
export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alertProps, setAlertProps] = useState({ open: false });

  const showAlert = props => {
    setAlertProps({ ...props, open: true });
  };

  const closeAlert = () => {
    if (alertProps?.onClose) alertProps.onClose();
    setAlertProps(preState => ({ ...preState, open: false }));
  };

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      {children}

      <AppAlert
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={closeAlert}
        {...alertProps}
      />
    </AlertContext.Provider>
  );
};
