import { KeyConstant } from "const";
import { getPrefixKey } from "utils";
import RemoteApi from "./RemoteApi";

export class RemoteApiFactory {
  constructor() {
    /**
     * Ex: {
     *  <accountId_GLOBAL_ID>: <API config for global server> - global branch
     *  <accountId_branchId>: <API config for branch server>
     */
    this.instances = {};
  }

  getBranchApi = (prefixKey, domain) => {
    if (!prefixKey) {
      // Return api's current branch if prefix is not exist
      const accountId = window.electronUtils.storage.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const branchId = window.electronUtils.storage.getItem(KeyConstant.KEY_BRANCH_ID);
      prefixKey = getPrefixKey(accountId, branchId);
    }

    if (!prefixKey || prefixKey.includes("undefined"))
      throw new Error(`CAN NOT INIT API - Invalid prefixKey - ${prefixKey}`);

    const storageData = window.electronUtils.storage.getCommonKey(prefixKey) || {};
    const branchInfo = storageData[KeyConstant.KEY_BRANCH_INFO] || {};
    const branchDomain = domain || branchInfo.domain || branchInfo.branch_domain;

    if (!this.instances[prefixKey]) {
      this.instances[prefixKey] = new RemoteApi(prefixKey, branchDomain, storageData[KeyConstant.KEY_TOKEN]);
    }
    return this.instances[prefixKey];
  };
}

export const remoteApiFactory = new RemoteApiFactory();
