import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ListItem,
  ListItemButton,
  Typography,
  ListItemText,
  Paper,
  ClickAwayListener,
  Popper,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/styles";

const SelectList = ({ PopperProps, selectedText, children, disableClickItemEvent }) => {
  const [isShow, setIsShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowDropdown = event => {
    setIsShow(preIsShow => !preIsShow);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleHidden = () => {
    setIsShow(false);
    setAnchorEl(null);
  };

  const handleClickItem = () => {
    if (!disableClickItemEvent) handleHidden();
  };

  const popperId = isShow ? "popper-list" : undefined;
  return (
    <ClickAwayListener onClickAway={handleHidden}>
      <Box>
        <ListItem
          disablePadding
          secondaryAction={<IconRoot>{isShow ? <ExpandMore /> : <ExpandLess />}</IconRoot>}
          onClick={handleShowDropdown}
        >
          <ListItemButton aria-describedby={popperId}>
            <ListItemText
              primary={
                <Typography fontWeight={600} minWidth={56}>
                  {selectedText}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>

        <Popper
          id={popperId}
          open={isShow}
          anchorEl={anchorEl}
          placement="bottom-start"
          sx={{ zIndex: 1 }}
          {...PopperProps}
        >
          <Paper elevation={1} onClick={handleClickItem}>
            {children}
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

SelectList.propTypes = {
  PopperProps: PropTypes.object,
  selectedText: PropTypes.string,
  disableClickItemEvent: PropTypes.bool,
};

export default SelectList;

const IconRoot = styled(Box)(props => ({
  color: props.theme.palette.grey[300],
}));
