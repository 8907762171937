import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const SendInputIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.01 18L21 9L0.01 0L0 7L15 9L0 11L0.01 18Z" fill="#008FE8" />
    </SvgIcon>
  );
};

SendInputIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
SendInputIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(SendInputIcon);
