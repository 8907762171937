import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const RestoreIcon = ({ width, height, className }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g clipPath="url(#clip0_1_2899)">
        <path
          d="M41.6288 89.0002C33.4095 89.0002 25.9513 85.6709 20.5479 80.2578C15.1699 74.87 11.8213 67.3982 11.8213 59.1641C11.8213 56.8514 12.075 54.5896 12.5823 52.4294H14.6118H14.6371H20.9538C20.2689 54.5387 19.8883 56.8006 19.8883 59.1641C19.8883 65.1872 22.3237 70.6004 26.2557 74.5396C30.1878 78.4788 35.6166 80.9185 41.6034 80.9185H119.002C123.365 80.9185 127.348 79.1395 130.265 76.2423C133.132 73.396 134.907 69.4314 134.907 65.0093C134.907 61.8072 133.969 58.8337 132.371 56.3431C130.696 53.7763 128.312 51.6924 125.47 50.4471L122.604 49.1764L123.137 46.1013C123.695 42.8229 123.238 39.7223 122.046 37.0285C120.955 34.5633 119.281 32.4539 117.2 30.8274C115.12 29.2009 112.66 28.0827 110.047 27.6507C107.18 27.1678 104.085 27.4728 101.041 28.8197L98.2251 30.065L96.2971 27.6507C93.2275 23.8131 89.3208 20.6872 84.8814 18.5524C80.6196 16.4939 75.825 15.3503 70.726 15.3503C64.0288 15.3503 57.8137 17.358 52.6639 20.7889L52.3341 21.0176V12.9105V12.8343V11.6144C57.8644 8.81886 64.1049 7.21777 70.726 7.21777C77.0173 7.21777 83.0042 8.66637 88.3569 11.2586C93.0246 13.495 97.185 16.5956 100.66 20.3568C104.288 19.264 107.941 19.0861 111.391 19.6707C115.349 20.3568 119.078 22.0342 122.173 24.4739C125.293 26.9137 127.83 30.0904 129.453 33.7755C130.899 37.003 131.609 40.6372 131.432 44.4748C134.527 46.3554 137.165 48.9222 139.144 51.9465C141.579 55.7078 143 60.2061 143 65.0093C143 71.6932 140.311 77.6401 135.998 81.9859C131.635 86.3571 125.597 89.0256 119.027 89.0256H41.6288V89.0002Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.659 23.2031H14.6623C12.988 23.2031 11.6182 24.5755 11.6182 26.2528V43.2802C11.6182 44.9576 12.988 46.3299 14.6623 46.3299H31.659C33.3333 46.3299 34.7031 44.9576 34.7031 43.2802V26.2528C34.7031 24.5755 33.3333 23.2031 31.659 23.2031Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.2019 9.83545H26.1799C24.5056 9.83545 23.1357 11.2078 23.1357 12.8851V29.9126C23.1357 31.5899 24.5056 32.9622 26.1799 32.9622H43.2019C44.8762 32.9622 46.2461 31.5899 46.2461 29.9126V12.8851C46.2715 11.2078 44.9016 9.83545 43.2019 9.83545Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.4292 37.7397H41.8826C40.5381 37.7397 39.4473 38.8326 39.4473 40.1795V53.7506C39.4473 55.0975 40.5381 56.1903 41.8826 56.1903H55.4292C56.7737 56.1903 57.8645 55.0975 57.8645 53.7506V40.1795C57.8645 38.8326 56.7737 37.7397 55.4292 37.7397Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9819 0H2.43534C1.09083 0 0 1.0928 0 2.43975V16.0109C0 17.3578 1.09083 18.4506 2.43534 18.4506H15.9819C17.3264 18.4506 18.4172 17.3578 18.4172 16.0109V2.43975C18.4172 1.0928 17.3264 0 15.9819 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2899">
          <rect width="143" height="89" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

RestoreIcon.propTypes = {
  className: PropTypes.string,

  width: PropTypes.number,
  height: PropTypes.number,
};

RestoreIcon.defaultProps = {
  className: "",

  width: 143,
  height: 89,
};

export default memo(RestoreIcon);
