import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import AddingChannelLayout from "./AddingChannelLayout";

const AddingChannelButton = props => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  return (
    <AddingChannelLayout className={classes.root} {...props}>
      <Button variant="outlined" fullWidth className={classes.btnCreate} size="large">
        {chatObjContent.createChannel}
      </Button>
    </AddingChannelLayout>
  );
};

AddingChannelButton.propTypes = AddingChannelLayout.propTypes;

AddingChannelButton.defaultProps = {};

export default memo(AddingChannelButton);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  btnCreate: {
    borderStyle: "dashed",
  },
}));
