import { useEffect, useState } from "react";
import { AppConstant, KeyConstant } from "const";
import { isNotEqual, toCamel } from "utils";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors, SystemSelectors } from "redux-store";
import { getSavedServer } from "utils/branch.utils";
import { createSelector } from "reselect";

const memoizedReduxState = createSelector(
  [BranchSelectors.getSelectedBranch, SystemSelectors.isSystemSynchronizing],
  (selectedBranch, isSynchronizing) => {
    return {
      selectedBranch,
      isSynchronizing,
    };
  },
);

const useAccount = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const { selectedBranch, isSynchronizing } = useSelector(memoizedReduxState);

  const [currentAccount, setCurrentAccount] = useState({});

  const getAccount = async () => {
    const selectAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const selectBranchId = getSavedServer().id;
    if (!selectAccountId) return;

    const account = await getInteractor(prefixKey).LocalAccountService.get(selectAccountId, branchId);

    const branchAccount = await getInteractor(prefixKey).LocalBranchAccountService.getBranchAccount(
      selectAccountId,
      selectBranchId,
    );
    const newAccount = account ? toCamel({ ...account, branchAccountState: branchAccount?.state }) : {};

    // Only update if account obj is changed
    if (newAccount.id && isNotEqual(newAccount, currentAccount)) {
      setCurrentAccount(newAccount);
    }
    return newAccount;
  };

  useEffect(() => {
    const accountTimeout = setTimeout(() => {
      const selectAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

      if (selectAccountId) {
        getAccount();
      }
    }, AppConstant.DEBOUNCE_TIME);

    return () => {
      if (accountTimeout) clearTimeout(accountTimeout);
    };
  }, [selectedBranch, isSynchronizing]);

  return { currentAccount, getAccount };
};

export default useAccount;
