import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import AccountItem from "./AccountItem";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";

const ManageFileLink = ({ onClose }) => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });

  const handleOpenMnFile = () => {
    dispatch(
      SystemActions.systemSet({
        isShowMnFile: true,
      }),
    );
    onClose();
  };

  return <AccountItem text={accountObjContent.manageFile} onClick={handleOpenMnFile} />;
};

export default ManageFileLink;
