import { AttachmentUtil, CryptoUtil, FileUtil, StorageUtil } from "utils";
import { tusGet } from "./tus-get";
import { SystemConstant } from "const";
import JSZip from "jszip";
import { tusUpload } from "./tus-upload";

export const getFileFromTus = async (prefixKey, fileInfo, groupId) => {
  try {
    const remoteResponse = await tusGet(prefixKey, fileInfo.attachmentId, groupId);

    if (false === Boolean(remoteResponse)) return false;
    const remoteData = remoteResponse.data;
    const remoteFileRecord = remoteResponse.fileInfo;
    const fileType = remoteFileRecord.type;
    const isPublicFile = fileType === SystemConstant.UPLOAD_TYPE.account;

    if (remoteData) {
      const fileMetaData = fileInfo.metaData;
      const fileAesInfo = fileInfo.aesKeyInfo;

      const fileName = fileMetaData.fileName;
      const decryptFilePath = AttachmentUtil.getPath(`${remoteFileRecord.url}/${fileName}`);
      const decryptFolderPath = AttachmentUtil.getPath(remoteFileRecord.url);

      if (remoteFileRecord.version === SystemConstant.UPLOAD_FILE_VERSION.oldVersion) {
        if (isPublicFile) {
          await FileUtil.makeFolder(decryptFolderPath);
          FileUtil.writeFile(decryptFilePath, new Uint8Array(remoteData));
        } else {
          await CryptoUtil.decryptAES(
            new Uint8Array(remoteData),
            fileAesInfo.key,
            fileAesInfo.iv,
            fileAesInfo.authtag,
            decryptFilePath,
          );
        }
      } else {
        if (isPublicFile) {
          await FileUtil.makeFolder(decryptFolderPath);
          FileUtil.writeFile(decryptFilePath, new Uint8Array(remoteData));
        } else {
          const zip = new JSZip();
          const zipContent = await zip.loadAsync(remoteData);
          const fileEntries = Object.keys(zipContent.files);
          for (let index = 0; index < fileEntries.length; index++) {
            const fileIndex = index + 1;
            const fileName = `${fileIndex}.tmp`;
            const file = zipContent.files[fileName];
            if (false === Boolean(file)) continue;

            const content = await file.async("arraybuffer");
            const fileOptions = {
              encoding: "utf8",
              flag: "a",
              mode: 0o666,
            };
            await CryptoUtil.decryptAES(
              new Uint8Array(content),
              fileAesInfo.key,
              fileAesInfo.iv,
              fileAesInfo.authtags[index],
              decryptFilePath,
              fileOptions,
            );
          }
        }
      }

      return remoteFileRecord;
    }
  } catch (error) {
    console.log("get file from tus error: ", error);
  }
  return null;
};

export const uploadFile = async (prefixKey, attachmentId, uploadType, groupId, callbackFuncs) => {
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
  if (false === Boolean(attachmentId)) return false;
  const filePath = AttachmentUtil.getEncryptionZipPath(attachmentId);
  return await tusUpload(prefixKey, attachmentId, filePath, uploadType, groupId, callbackFuncs);
};
