// Defined static path to image

const LogoImage = "/assets/images/img-logo.svg";
const WelcomeImage = "/assets/images/img-welcome.svg";
const DownloadImage = "/assets/images/img-download.png";
const BtnAppleStoreImage = "/assets/images/img-btn-apple-store.png";
const BtnPlayStoreImage = "/assets/images/img-btn-play-store.png";
const BGlobalServerImage = "/assets/images/img-global-server.png";
const BGlobalImage = "/assets/images/img-bglobal.png";
const YourDeviceImage = "/assets/images/img-your-device.png";
const DeviceLinuxImage = "/assets/images/img-device-linux.svg";
const DeviceWindowImage = "/assets/images/img-device-window.svg";
const LoginWelcomeImage = "/assets/images/img-login-welcome.png";
const EmptyMessageList = "/assets/images/img-no-message.png";
const EmptyContactImage = "/assets/images/img-empty-contact.svg";
const ChannelImage = "/assets/images/img-channel.svg";
const NoLinkImage = "/assets/images/img-no-file.png";
const NoMediaImage = "/assets/images/img-no-media.png";
const NoFileImage = "/assets/images/img-no-link.png";
const EmptyNotification = "/assets/images/img-welcome.svg";
const AddChat = "/assets/images/img-btn-add-chat.png";
const AddContact = "/assets/images/img-btn-add-contact.png";
const KeyImage = "/assets/images/img-key.png";
const SynchronizeImage = "/assets/images/img-synchronize.png";
const ImageLogin = "/assets/images/img-login.svg";
const ImageOTPKey = "/assets/images/img-smart-key.svg";
const ImageEmptyCat = "/assets/images/img-empty-cat.svg";

export {
  LogoImage,
  WelcomeImage,
  DownloadImage,
  BtnAppleStoreImage,
  BtnPlayStoreImage,
  BGlobalServerImage,
  LoginWelcomeImage,
  BGlobalImage,
  YourDeviceImage,
  DeviceLinuxImage,
  DeviceWindowImage,
  EmptyMessageList,
  EmptyContactImage,
  ChannelImage,
  NoLinkImage,
  NoMediaImage,
  NoFileImage,
  EmptyNotification,
  AddChat,
  AddContact,
  KeyImage,
  SynchronizeImage,
  ImageLogin,
  ImageOTPKey,
  ImageEmptyCat,
};
