import { createReducer, createActions } from "reduxsauce";
import { requestReducerFunc } from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  initPubsub: [],
  startCheckingNews: ["prefixKey", "domain"],
  destroyLogoutBranchThread: [],
  destroyParallelThread: ["prefixKey"],
});

export const PubSubTypes = Types;
export const PubSubActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {};

/* ------------- Selector ------------- */
export const PubSubSelectors = {};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => {
  console.log(state);

  return requestReducerFunc(state);
};

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.INIT_PUBSUB]: request,
  [Types.START_CHECKING_NEWS]: request,
  [Types.DESTROY_PARALLEL_THREAD]: request,
  [Types.DESTROY_LOGOUT_BRANCH_THREAD]: request,
};

/* ------------- Hookup Reducers To Types ------------- */
export const PubSubReducer = createReducer(INITIAL_STATE, HANDLERS);
