import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const GlobalIcon = ({ width, height, className }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM2.5 10C2.5 9.39 2.58 8.79 2.71 8.22L7.49 13V14C7.49 15.1 8.39 16 9.49 16V17.93C5.56 17.43 2.5 14.07 2.5 10ZM16.39 15.4C16.13 14.59 15.39 14 14.49 14H13.49V11C13.49 10.45 13.04 10 12.49 10L6.49 10L6.49 8H8.49C9.04 8 9.49 7.55 9.49 7V5L11.49 5C12.59 5 13.49 4.1 13.49 3V2.59C16.42 3.77 18.5 6.65 18.5 10C18.5 12.08 17.69 13.98 16.39 15.4Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

GlobalIcon.propTypes = {
  className: PropTypes.string,

  width: PropTypes.number,
  height: PropTypes.number,
};

GlobalIcon.defaultProps = {
  className: "",

  width: 24,
  height: 24,
};

export default memo(GlobalIcon);
