import { useEffect, useState } from "react";
import { KeyConstant } from "const";
import { isJSONString, toCamel } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { CallingActions } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";

const useCallInfo = () => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const callingGroupDetail = useSelector(state => state.callingRedux.callingGroupDetail);
  const groupCallOptions = useSelector(state => state.callingRedux.groupCallOptions);
  const isReceiver = useSelector(state => state.callingRedux.isReceiver);

  const [callInfo, setCallInfo] = useState({});

  // Get callingGroupDetails
  // Get avatar group call
  // When accept a group call or start a call
  const getCallInfoByGroupDetail = async (callingGroupDetail, groupCallOptions, prefixKey) => {
    if (callInfo.id && callInfo.roomId) {
      console.log("Skip new calling", callingGroupDetail);
      return;
    }

    const callingOpt = getCallInfoByOptions(groupCallOptions);
    if (callingGroupDetail?.adminId && callingGroupDetail?.adminId !== accountId) {
      const callHistory = await getInteractor(prefixKey).LocalCallHistoryService.getLastItem({
        group_id: callingGroupDetail?.id,
        room_id: callingGroupDetail?.roomId,
      });

      const callHistoryOptions = isJSONString(callHistory?.options) ? toCamel(JSON.parse(callHistory.options)) : {};

      if (callHistoryOptions.avatarUrl) {
        dispatch(CallingActions.getImageIncomingCall({ avatarId: callHistoryOptions?.avatarUrl }));
      }

      const newCallInfo = {
        ...callingGroupDetail,
        callName: callHistoryOptions?.callName,
        avatarId: callHistoryOptions?.avatarUrl || callingGroupDetail.avatarId,
        roomId: toCamel(callHistory).roomId,
      };

      setCallInfo({ ...newCallInfo, ...callingOpt });
    } else if (callingGroupDetail?.id) {
      setCallInfo({ ...callingGroupDetail, ...callingOpt });
    } else {
      setCallInfo({});
    }
  };

  // Receive call's info change ????
  const getCallInfoByOptions = groupCallOptions => {
    if (Boolean(groupCallOptions)) {
      let options = {};
      if (groupCallOptions && groupCallOptions.avatar_url) {
        options = { ...options, avatarId: groupCallOptions.avatar_url };
      }
      if (groupCallOptions && groupCallOptions.call_name) {
        options = { ...options, callName: groupCallOptions.call_name };
      }
      return options;
    }
    return {};
  };

  useEffect(() => {
    if (callingGroupDetail && callingGroupDetail.roomId) {
      getCallInfoByGroupDetail(callingGroupDetail, groupCallOptions, prefixKey);
    } else {
      setCallInfo({});
    }
  }, [callingGroupDetail, groupCallOptions, isReceiver]);

  return { callInfo, getCallInfoByGroupDetail, getCallInfoByOptions };
};

export default useCallInfo;
