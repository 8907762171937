import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GroupInfoSelectors } from "redux-store";
import { AvatarInfo } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import SearchPopup from "../SearchPopup";
import { isGroupOrChannelType } from "pages/Call";
import { useOnlineGroup } from "hooks";
import { getInteractor } from "services/local.service";
import { deepCloneJsonObject, StorageUtil } from "utils";
import { useConversationContext } from "../ConversationContext";
import TitleChatAction from "./TitleChatAction";
import { isChangeGroupData } from "utils/view.utils";
import { Error } from "@mui/icons-material";
import { withDrawerLayout } from "components/hoc";
import GroupInfoDrawer from "../../GroupInfoDrawer";

const GroupInfoWithIcon = withDrawerLayout(
  GroupInfoDrawer,
  <IconButton>
    <Error color="action" />
  </IconButton>,
);

const TitleChat = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const classes = useStyles();
  const searchAnchorRef = useRef(null);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const {
    groupDetail: contextGroupDetail,
    store: { isInactive },
  } = useConversationContext();
  const isGroupOrChannel = isGroupOrChannelType(contextGroupDetail.groupType);
  const { isOnline } = useOnlineGroup(contextGroupDetail.id);
  const updatingGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);

  const [isAdminGroup, setIsAdminGroup] = useState(false);
  const [groupDetail, setGroupDetail] = useState({
    groupMembers: [],
  });

  const handleChangeGroupDetail = async groupDetail => {
    const isValidData = groupDetail && groupDetail.id;
    if (false === isValidData) return;

    const accountGroupRecords = await getInteractor().LocalAccountGroupService.find({
      group_id: groupDetail.id,
      account_id: accountId,
      type: SystemConstant.ROLE_OBJ.admin,
    });
    setIsAdminGroup(Boolean(isGroupOrChannel && accountGroupRecords.length > 0));
  };

  useEffect(() => {
    if (
      groupDetail.id &&
      groupDetail.id === updatingGroupData.id &&
      isChangeGroupData(groupDetail, updatingGroupData)
    ) {
      const newGroupDetail = { ...groupDetail, ...updatingGroupData };
      setGroupDetail(newGroupDetail);
      handleChangeGroupDetail(newGroupDetail);
    }
  }, [updatingGroupData]);

  useEffect(() => {
    setGroupDetail(deepCloneJsonObject(contextGroupDetail));
    handleChangeGroupDetail(contextGroupDetail);
  }, [contextGroupDetail]);

  return (
    <Stack className={classes.wapFlex} ref={searchAnchorRef}>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Box className={classes.avatarAndStatus}>
            <AvatarInfo
              avatar={{
                avatarId: groupDetail.avatarId,
                type: groupDetail.groupType,
              }}
              privateF={groupDetail.privateF}
              title={groupDetail.groupName}
              sendToMe={groupDetail.sendToMe}
              secondTitle={
                isGroupOrChannel
                  ? getLabel(
                      groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group
                        ? LangConstant.TXT_GROUP
                        : LangConstant.TXT_CHANNEL,
                    )
                  : ""
              }
              sx={{ p: 0 }}
            />

            <Box
              className={clsx(
                classes.status,
                isOnline ? classes.onlineStatus : classes.offline,
                isGroupOrChannel && "hidden",
              )}
            />
          </Box>
        </Stack>

        {!isInactive && <TitleChatAction groupDetail={groupDetail} />}
        <GroupInfoWithIcon data={groupDetail} isInactive={isInactive} isAdminGroup={isAdminGroup} />
      </Stack>

      {searchAnchorRef.current && <SearchPopup anchor={searchAnchorRef.current} />}
    </Stack>
  );
};

export default memo(TitleChat);

const useStyles = makeStyles(theme => ({
  wapFlex: {
    position: "relative",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 22,
  },

  avatarAndStatus: {
    display: "flex",
    position: "relative",
    height: 50,
    minWidth: 50,
  },

  titleChat: {
    color: theme.palette.black,
    position: "relative",
  },

  status: {
    position: "absolute",
    width: 10,
    height: 10,
    borderRadius: "50%",
    bottom: 7,
    left: 40,
  },

  onlineStatus: {
    backgroundColor: "#2CC84A",
    border: "1px solid white",
  },

  offline: {
    backgroundColor: theme.palette.divider,
    border: `1px solid ${theme.palette.grey[300]}`,
  },

  groupTypeText: {
    color: theme.palette.grey[500],
  },
}));
