import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const RemoveCircle = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <circle cx="15" cy="15" r="15" fill="#C4C4C4" />
      <path
        d="M15 5C9.48 5 5 9.48 5 15C5 20.52 9.48 25 15 25C20.52 25 25 20.52 25 15C25 9.48 20.52 5 15 5ZM20 16H10V14H20V16Z"
        fill="#010101" />
    </SvgIcon>
  );
};
RemoveCircle.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
RemoveCircle.defaultProps = {
  width: 30,
  height: 30,
};
export default memo(RemoveCircle);
