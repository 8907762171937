import { Sidebar, Welcome } from "./components";
import MessengerChat from "./components/MessengerChat";
import { InactiveDialog, MainContent, MainLayout, MainSidebar } from "components";
import HandlingParallel from "./HandlingParallel";
import ManageFilePage from "pages/ManageFilePage";
import { useSelector } from "react-redux";
import { SystemSelectors } from "redux-store";
import { Box, styled } from "@mui/material";

const Home = () => {
  const isShowMnFile = useSelector(SystemSelectors.isShowMnFile);

  return (
    <>
      <HandlingParallel />

      <MainLayout className={isShowMnFile ? "hidden" : ""}>
        <MainSidebar>
          <Sidebar />
        </MainSidebar>

        <MainContent>
          <MessengerChat />
          <Welcome />
        </MainContent>
      </MainLayout>

      <InactiveDialog />
      {isShowMnFile && (
        <ManageFileContainer>
          <ManageFilePage />
        </ManageFileContainer>
      )}
    </>
  );
};

export default Home;

const ManageFileContainer = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
});
