import React, { useState } from "react";
import { IconButton, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatLimitNumber } from "utils";
import { useUnreadMessage } from "hooks";
import { NotificationsOutlined } from "@mui/icons-material";
import NotificationDrawer from "./NotificationDrawer";

const NotificationBadge = () => {
  const classes = useStyles();
  const { unreadNotification } = useUnreadMessage();

  const [isOpenNotification, setIsOpenNotification] = useState(false);

  const handleOpenNotificationTab = () => {
    setIsOpenNotification(true);
  };

  return (
    <>
      <Badge
        badgeContent={formatLimitNumber(unreadNotification)}
        color="primary"
        classes={{ root: classes.numNotify, badge: "badge" }}
      >
        <IconButton onClick={handleOpenNotificationTab}>
          <NotificationsOutlined />
        </IconButton>
      </Badge>
      {isOpenNotification && <NotificationDrawer open onClose={() => setIsOpenNotification(false)} />}
    </>
  );
};

NotificationBadge.propTypes = {};

export default NotificationBadge;

const useStyles = makeStyles({
  numNotify: {
    "& .badge": {
      top: 4,
      right: -1,
      backgroundColor: "#EE4F49",
      border: "1px solid white",
    },
  },
});
