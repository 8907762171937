import { ApiConstant, FormatConstant, SystemConstant } from "const";
import { createApiWithDomain } from "./remote-api/api.config";
import StringFormat from "string-format";
import { toCamel } from "utils";
import { LocalDbManagement } from "./local.service";

export const getBranchWithoutToken = async (domain, branchId) => {
  try {
    if (domain && branchId) {
      const path = StringFormat(ApiConstant.GET_BRANCH_INFO, branchId);
      const response = await createApiWithDomain(domain).get(path);
      if (response.status === ApiConstant.STT_OK) {
        const remoteBranch = toCamel(response.data);

        // Update branch name/ domain in db
        const localBranch = await LocalDbManagement.find({
          branch_id: branchId,
        })[0];
        if (localBranch) {
          await LocalDbManagement.update(
            {
              branch_name: remoteBranch.name,
              branch_domain: remoteBranch.domain,
              modified: Date.now(),
            },
            { id: localBranch.id },
          );
        }

        return remoteBranch;
      }
    }
  } catch (error) {
    console.log("Get branch info failed: ", error);
  }

  return {};
};

export const getBranchAvatarUrl = (domain, branchId) => {
  let url = StringFormat(FormatConstant.FM_BASE_URL, domain);
  if (false === Boolean(domain) && false === Boolean(branchId)) {
    url = ApiConstant.BASE_URL;
    branchId = SystemConstant.GLOBAL_BRANCH_ID;
  }

  return StringFormat(ApiConstant.GET_BRANCH_LOGO, url, branchId);
};
