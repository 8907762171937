import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import { RestoreActions } from "redux-store/restore.redux";
import { getInteractor } from "services/local.service";
import { AttachmentUtil, CryptoUtil, MultipleFileUtil, toCamel, toSnake, uuid } from "utils";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services";
import { IGNORE_MESSAGE } from "./saga.helper";
import { getAllDeviceFromRemote } from "pubsub/services/device.service";
import store, { ConversationActions } from "redux-store";
import { uploadFile } from "services/multiple-file";
import { getFileList, updateFileStatus } from "pubsub/services/file.service";
import { getFileLocalPath } from "services/attachment.service";

export function* changeDeviceRole(action) {
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateDevice, { change_master_f: 1 });
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        RestoreActions.restoreSet({
          changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.success,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.fail,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    RestoreActions.restoreSet({
      changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.fail,
    });
  }
}

export function* verifyChangeDeviceRole(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).requestVerifyChangeDeviceRole, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.success,
        }),
      );

      yield getAllDeviceFromRemote(prefixKey);

      StorageUtil.setItem(KeyConstant.KEY_VERIFY_RETRIES, 0, prefixKey);
    } else if (response.status === ApiConstant.STT_FORBIDDEN) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.limitResend,
        }),
      );
    } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.wrongOtp,
        }),
      );
    } else if (response.status === ApiConstant.STT_OTP_EXPIRED) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.expiredOtp,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.systemError,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      RestoreActions.restoreSet({
        verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.systemError,
      }),
    );
  }
}

// Get file info
export function* restore(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).requestGetBackup, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      const backupInfo = toCamel(response.data);
      if (backupInfo && Object.keys(backupInfo).length > 0) {
        yield put(
          RestoreActions.restoreSet({
            backupInfo: backupInfo,
            restoreStatus: SystemConstant.RESTORE_STATUS.getFileInfo,
          }),
        );
      } else {
        yield put(
          RestoreActions.restoreSet({
            backupInfo: backupInfo,
            restoreStatus: SystemConstant.RESTORE_STATUS.noBackupFile,
          }),
        );
      }
    } else {
      throw new Error("An error occurred");
    }
  } catch (error) {
    console.log(error);
    yield put(
      RestoreActions.restoreSet({
        backupInfo: null,
        restoreStatus: SystemConstant.RESTORE_STATUS.error,
      }),
    );
  }
}

/**
 *
 * Check passCode
 * Get file
 * Decrypt data and migrate data
 */
export function* restoreToLocal(action) {
  try {
    const { passCode } = action.data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);

    const {
      restoreRedux: { backupInfo },
    } = yield select();

    if (Boolean(backupInfo)) {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.inProgress,
        }),
      );

      const key0 = "" + passCode + accountId + backupInfo.createdTime;
      const key0Encrypt = yield CryptoUtil.encryptHmac256(key0);
      const keyEncryptFileInfo = yield CryptoUtil.decryptCBC(backupInfo.ckx, key0Encrypt);
      const aesKeyInfo = JSON.parse(keyEncryptFileInfo);
      console.log({ aesKeyInfo });

      const fileName = `${backupInfo.attachmentId}.txt`;
      const backupFile = {
        attachmentId: backupInfo.attachmentId,
        metaData: { fileName },
        aesKeyInfo: aesKeyInfo,
      };

      const filePath = yield getFileLocalPath(prefixKey, backupFile);

      if (filePath) {
        const fileLocal = yield getInteractor(prefixKey).LocalFileService.get(backupInfo.attachmentId);
        const fileUrl = AttachmentUtil.getLocalPath(fileLocal.url, fileName);
        yield getInteractor(prefixKey).LocalRestoreService.restoreFromFile(fileUrl, deviceId);

        yield restoreThread(prefixKey, accountId);

        // Get file record
        const attachmentIds = (yield getInteractor(prefixKey).LocalFileGroupService.getAllFileIds()).map(
          item => item.file_id,
        );

        if (Array.isArray(attachmentIds) && attachmentIds.length > 0) {
          let reqAttachment = [];
          let offset = 0;
          let lastIndex = 100;
          do {
            reqAttachment = attachmentIds.slice(offset, lastIndex);
            yield getFileList(prefixKey, reqAttachment);
            offset = lastIndex;
            lastIndex += 100;
          } while (reqAttachment.length === 100);
        }

        yield put(
          RestoreActions.restoreSet({
            restoreStatus: SystemConstant.RESTORE_STATUS.success,
          }),
        );

        // Trigger update UI
        yield put(
          ConversationActions.conversationSet({
            isUpdateViewMode: Date.now(),
          }),
        );
      } else {
        throw new Error("RESTORE - FILE NOT FOUND");
      }
    }
  } catch (error) {
    console.log(error);
    if (error.message === AppConstant.PASSCODE_ERROR) {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.wrongPasscode,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.error,
        }),
      );
    }
  }
}

// Upload file backup to server
export function* backup(action) {
  try {
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const { passCode, createdTime } = action.data;

    yield put(
      RestoreActions.backupSet({
        backupStatus: SystemConstant.RESTORE_STATUS.inProgress,
      }),
    );
    const file = yield getInteractor(prefixKey).LocalMessageService.getMessagesAndCallHistories(deviceId);

    if (file) {
      const attachmentId = uuid();
      const iv = CryptoUtil.randomBytes(16);
      const keyEncryptFile = CryptoUtil.randomBytes(32);

      const fileEncrypt = yield MultipleFileUtil.encryptFile(attachmentId, file, keyEncryptFile, iv);

      const aesKeyInfo = fileEncrypt.aes_key_info;
      const key = "" + passCode + accountId + createdTime;
      const keyEncrypt = yield CryptoUtil.encryptHmac256(key);

      const CKx = yield CryptoUtil.encryptCBC(JSON.stringify(aesKeyInfo), keyEncrypt);

      const backup2Trios = {
        attachment_id: attachmentId,
        ckx: CKx,
        created_time: createdTime,
      };

      // upload tus
      yield MultipleFileUtil.zipFile(attachmentId);
      uploadFile(prefixKey, attachmentId, SystemConstant.UPLOAD_TYPE.backup, null, {
        onError: () => handleBackupError(prefixKey, attachmentId),
        onSuccess: () => handleBackupSuccess(prefixKey, backup2Trios, file.size),
        onProgress: handleBackupProgress,
      });
    }
  } catch (error) {
    console.log("backup fail: ", error);
    yield put(
      RestoreActions.backupSet({
        backupStatus: SystemConstant.RESTORE_STATUS.error,
      }),
    );
  }
}

const restoreThread = async (prefixKey, accountId) => {
  const threadIds = await getInteractor(prefixKey).LocalMessageService.getThreadInfoFromMsg();

  let restoreThreads = [];
  for (let index = 0; index < threadIds.length; index++) {
    const threadId = threadIds[index].thread_id;

    const parentMessage =
      (await getInteractor(prefixKey).LocalMessageService.findOne({
        source_id: threadId,
      })) || {};
    if (false === Boolean(parentMessage.id)) continue;
    const messagesInThread = await getInteractor(prefixKey).LocalMessageService.getThreadMessage(threadId);
    const changeParentMsg = await getInteractor(prefixKey).LocalMessageService.getMessageWithParentId(threadId);
    const deleteMsgInThread = messagesInThread.filter(
      item => item.send_type === SystemConstant.SEND_TYPE.deleteMessage,
    );
    const normalMessagesInThread = messagesInThread.filter(
      item => item.parent_id === null && !IGNORE_MESSAGE.includes(item.send_type),
    );
    const lastMessage = messagesInThread[0] || {};
    const isMeReply =
      parentMessage.sender_id === accountId || messagesInThread.filter(item => item.sender_id === accountId).length > 0;

    const mentionArr = [parentMessage, ...messagesInThread].reduce((resultArr, item) => {
      if (Boolean(item.mentions)) {
        const mention = JSON.parse(item.mentions);
        return [...resultArr, ...mention];
      } else {
        return resultArr;
      }
    }, []);

    const isInvolved = isMeReply || mentionArr.includes(accountId);
    const totalReply = normalMessagesInThread.length - deleteMsgInThread.length;
    const isDeleteParent =
      changeParentMsg.filter(item => item.send_type === SystemConstant.SEND_TYPE.deleteMessage).length > 0;
    const isDelete = isDeleteParent || totalReply <= 0;
    const totalUnread = normalMessagesInThread.filter(
      item => item.status !== SystemConstant.MESSAGE_STATUS.read && item.sender_id !== accountId,
    ).length;

    const newThreadInfo = {
      id: uuid(),
      thread_id: threadId,
      total_reply: totalReply,
      total_unread: totalUnread,
      involved_f: isInvolved ? SystemConstant.STATE.active : SystemConstant.STATE.inactive,
      thread_account_id: parentMessage.sender_id,
      thread_created: parentMessage.created,
      group_id: parentMessage.group_id,
      group_type: null,
      setting_type: null,
      thread_content: parentMessage.content, // TODO: original content of parentMessage
      thread_mentions: JSON.stringify(mentionArr),
      branch_id: parentMessage.branch_id,
      state: isDelete ? AppConstant.THREAD_STATE.inactive : AppConstant.THREAD_STATE.active,
      created: parentMessage.created,
      modified: lastMessage.created || 0,
    };

    restoreThreads.push(newThreadInfo);
  }

  await getInteractor(prefixKey).LocalThreadService.save(restoreThreads);
};

const handleBackupError = async (prefixKey, attachmentId) => {
  store.dispatch(
    RestoreActions.backupSet({
      backupStatus: SystemConstant.RESTORE_STATUS.error,
    }),
  );

  await updateFileStatus(prefixKey, attachmentId, SystemConstant.UPLOAD_STATUS.failed);
};

const handleBackupSuccess = async (prefixKey, backup2Trios, fileSize) => {
  const responseBackup = await remoteApiFactory.getBranchApi(prefixKey).requestBackup(backup2Trios);
  if (responseBackup.status === ApiConstant.STT_OK) {
    console.log("backup success");
    store.dispatch(
      RestoreActions.backupSet({
        fileSize: fileSize,
        backupStatus: SystemConstant.RESTORE_STATUS.success,
      }),
    );
  } else if (responseBackup.status !== ApiConstant.STT_FORBIDDEN) {
    store.dispatch(
      RestoreActions.backupSet({
        backupStatus: SystemConstant.RESTORE_STATUS.losePermission,
      }),
    );
  } else {
    store.dispatch(
      RestoreActions.backupSet({
        backupStatus: SystemConstant.RESTORE_STATUS.error,
      }),
    );
  }
};

const handleBackupProgress = () => {
  console.log("back up in progress");
};
