import { useEffect, useState } from "react";
import { getFileLocalPath } from "services/attachment.service";
import { StorageUtil } from "utils";
import { AppAudio } from "components";

const AudioBox = ({ mediaData, isCurrentDevice }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const [src, setSrc] = useState("");

  useEffect(() => {
    getFileLocalPath(prefixKey, mediaData, { groupId: mediaData.groupId, isCurrentDevice }).then(src => setSrc(src));
  }, [mediaData]);

  return <AppAudio style={{ width: "100%" }} controls preload="metadata" src={src} />;
};

export default AudioBox;
