import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const NotFoundPage = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_NOT_FOUND);

  return (
    <Container className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Box className={classes.content}>
          <Typography variant="h1">{getLabel(LangConstant.TXT_PAGE_NOT_FOUND)}</Typography>
          <Typography variant="subtitle2">{getLabel(LangConstant.TXT_PAGE_NOT_FOUND_DESCRIPTION)}</Typography>
        </Box>
      </Grid>
    </Container>
  );
};

export default NotFoundPage;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: "center",
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));
