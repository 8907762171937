import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography } from "@mui/material";
import clsx from "clsx";
import { formatSentTime } from "utils/date.utils";
import { ImageConstant, LangConstant } from "const";
import { useStyles } from "./NotificationInviteItem";

const NotificationOTPItem = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  return (
    <Box className={classes.mentionNotificationItemRoot} {...otherProps}>
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <Avatar
            className={classes.iconImage}
            classes={{ img: classes.smartKeyIcon }}
            src={ImageConstant.ImageOTPKey}
          ></Avatar>
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography
            className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
            dangerouslySetInnerHTML={{
              __html: getLabel(LangConstant.FM_OTP_NOTIFICATION, {
                message: data.content,
              }),
            }}
          />
          <Typography className={classes.createdTimeNotification}>{formatSentTime(data.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationOTPItem;
