import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const UnpinIcon = ({ className, width, height }) => {
  const classes = useStyles({ width, height });

  return (
    <SvgIcon className={clsx(className, classes.root)} width={width} height={height} viewBox="0 0 24 24">
      <path d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
    </SvgIcon>
  );
};
UnpinIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
UnpinIcon.defaultProps = {
  width: 24,
  height: 24,
};
export default memo(UnpinIcon);

const useStyles = makeStyles(() => ({
  root: {
    width: props => props.width,
    height: props => props.height,
  },
}));
