import React, { useCallback, useState } from "react";
import AccountItem from "./AccountItem";
import { useDispatch } from "react-redux";
import { ProfileActions } from "redux-store";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";

const ChangingStatus = ({ accountDetail, onChange }) => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });

  const [isActive, setIsActive] = useState(accountDetail.status === SystemConstant.STATE.active);

  const onSetAccountStatus = useCallback(() => {
    const newAccountStatus = {
      status: isActive ? 0 : 1,
    };
    setIsActive(preIsActive => !preIsActive);
    dispatch(ProfileActions.updateProfileStatus(newAccountStatus));
    onChange(newAccountStatus);
  });

  const accountStatusContent = isActive ? accountObjContent.setAway : accountObjContent.setActive;

  return <AccountItem text={accountStatusContent} onClick={onSetAccountStatus} />;
};

export default ChangingStatus;
