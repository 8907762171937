import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const PinIcon = ({ className, width, height, color }) => {
  const classes = useStyles({ width, height });

  return (
    <SvgIcon className={clsx(className, classes.root)} width={width} height={height} viewBox="0 0 24 24" color={color}>
      <path d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" fill={color} />
    </SvgIcon>
  );
};
PinIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,

  color: PropTypes.string,
};
PinIcon.defaultProps = {
  width: 24,
  height: 24,

  color: "black",
};

export default memo(PinIcon);

const useStyles = makeStyles(() => ({
  root: {
    width: props => props.width,
    height: props => props.height,
  },
}));
