import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { CardMedia, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageConstant, LangConstant } from "const";
import NoData from "./NoData";
import MediaBox from "../MessengerChat/ChatItem/MediaPreview/MediaBox";
import { InfiniteScroll } from "components";

const Media = ({ mediaList, onLoadMore }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const handleLoadMore = () => {
    if (false === Boolean(mediaList.isEnd) && onLoadMore) onLoadMore();
  };

  return (
    <InfiniteScroll className={classes.root} onScrollToBottom={handleLoadMore} hasMore={!mediaList.isEnd}>
      {mediaList.displayData && Object.keys(mediaList.displayData).length > 0 ? (
        Object.keys(mediaList.displayData).map(key => (
          <Grid key={key} className={classes.mediaContainer}>
            <Typography className={classes.titleMedia} variant="subtitle2">
              {key}
            </Typography>
            <ImageList cols={3} gap={0} rowHeight={124} className={classes.imageList}>
              {mediaList.displayData[key].map((item, index) => (
                <ImageListItem key={`${item.attachmentId}-${index}`} sx={{ width: "100%" }}>
                  <MediaBox
                    fileInfo={item}
                    customStyles={classes.imageItem}
                    groupId={item.groupId}
                    isCurrentDevice={item.isCurrentDevice}
                    isThumbnail={true}
                    width={150}
                    height={150}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        ))
      ) : (
        <NoData
          media={<CardMedia component="img" alt="cover" className={classes.cover} src={ImageConstant.NoMediaImage} />}
          textOne={getLabel(LangConstant.TXT_CONTENT_NO_MEDIA)}
          textTwo={getLabel(LangConstant.TXT_NO_MEDIA)}
        />
      )}
    </InfiniteScroll>
  );
};

export default memo(Media);

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: "calc(100vh - 135px)",
    marginTop: 14,
    overflowX: "hidden",
    overflowY: "auto",
  },

  cover: {
    position: "relative",
    marginBottom: 12,
    height: "auto",
    maxWidth: "100%",
    width: "unset",
  },

  titleMedia: {
    marginBottom: 30,
    paddingLeft: 14,
  },

  mediaContainer: {
    marginBottom: 14,
  },

  imageList: {
    width: "100%",
    overflowX: "hidden",
  },

  imageItem: {
    border: "solid 1px",
    borderColor: theme.palette.grey[100],
    borderRadius: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",

    "& > .custom-loading, .custom-error": {
      minWidth: "unset",
      minHeight: "unset",
    },
  },
}));
