import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const MissedCallIcon = props => {
  return (
    <SvgIcon {...props} viewBox={`0 0 16 16`}>
      <g clipPath="url(#clip0_1285_2649)">
        <path
          d="M2.75992 0.00239339C3.21832 0.00239339 3.67622 0.00239339 4.13412 0.00239339C4.93994 0.00739324 5.47333 0.513877 5.49932 1.31335C5.52982 2.24182 5.64929 3.15429 5.93223 4.04227C6.09919 4.56475 6.00621 5.02773 5.64629 5.46222C5.22138 5.9747 4.83397 6.51819 4.44355 7.05817C4.41742 7.1032 4.40165 7.1535 4.39742 7.2054C4.39318 7.2573 4.40057 7.30948 4.41906 7.35816C5.38335 9.2081 6.79954 10.6116 8.64414 11.5795C8.69327 11.5973 8.74577 11.6036 8.79772 11.5982C8.84966 11.5927 8.8997 11.5756 8.94407 11.548C9.51062 11.136 10.0692 10.7151 10.6197 10.2851C10.9751 10.0086 11.3695 9.91258 11.7939 10.0376C12.76 10.3252 13.7597 10.4846 14.7673 10.5116C15.4671 10.5306 15.982 11.0815 15.99 11.7815C16.002 12.7562 16.002 13.7308 15.99 14.7054C15.982 15.4589 15.4197 16.0054 14.6583 15.9984C11.9219 15.9809 9.37198 15.2779 7.04598 13.836C3.93133 11.9047 1.80779 9.1901 0.675374 5.69221C0.221105 4.27894 -0.00748627 2.80286 -0.00197798 1.31835C-0.00197798 0.562876 0.547901 0.0148928 1.30873 0.00339319C1.79413 -0.00360659 2.27703 0.00239339 2.75992 0.00239339Z"
          fill="currentColor"
        />
        <path
          d="M13.6496 6.58736L12.5189 5.4564C12.4579 5.39546 12.3752 5.36123 12.2889 5.36123C12.2027 5.36123 12.12 5.39546 12.059 5.4564L10.9652 6.55186C10.9041 6.61283 10.8213 6.64707 10.735 6.64707C10.6487 6.64707 10.5659 6.61283 10.5048 6.55186L9.78597 5.83188C9.72504 5.77086 9.69082 5.68814 9.69082 5.60189C9.69082 5.51565 9.72504 5.43293 9.78597 5.3719L10.8802 4.27743C10.9412 4.21641 10.9754 4.13369 10.9754 4.04744C10.9754 3.9612 10.9412 3.87848 10.8802 3.81745L9.75848 2.69549C9.69755 2.63446 9.66333 2.55174 9.66333 2.46549C9.66333 2.37925 9.69755 2.29653 9.75848 2.2355L10.4133 1.58052C10.4744 1.51958 10.5571 1.48535 10.6433 1.48535C10.7295 1.48535 10.8122 1.51958 10.8732 1.58052L11.9955 2.70199C12.0565 2.76293 12.1392 2.79716 12.2254 2.79716C12.3117 2.79716 12.3944 2.76293 12.4554 2.70199L13.5496 1.60802C13.6107 1.54708 13.6934 1.51285 13.7796 1.51285C13.8658 1.51285 13.9485 1.54708 14.0096 1.60802L14.7284 2.327C14.7893 2.38803 14.8235 2.47075 14.8235 2.55699C14.8235 2.64324 14.7893 2.72595 14.7284 2.78698L13.6346 3.88145C13.5737 3.94248 13.5395 4.0252 13.5395 4.11144C13.5395 4.19769 13.5737 4.2804 13.6346 4.34143L14.7649 5.4724C14.8258 5.53342 14.86 5.61614 14.86 5.70239C14.86 5.78863 14.8258 5.87135 14.7649 5.93238L14.11 6.58736C14.0798 6.61761 14.0439 6.64161 14.0044 6.65798C13.9649 6.67435 13.9226 6.68278 13.8798 6.68278C13.8371 6.68278 13.7947 6.67435 13.7552 6.65798C13.7157 6.64161 13.6798 6.61761 13.6496 6.58736Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1285_2649">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

MissedCallIcon.propTypes = {
  style: PropTypes.object,

  width: PropTypes.number,
  height: PropTypes.number,
};

MissedCallIcon.defaultProps = {
  style: {},

  width: 16,
  height: 16,
};

export default memo(MissedCallIcon);
