import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";

// Listener changing and update when user focus application
const HandlingFocusApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateFocusStatus = isFocused => () => {
      dispatch(
        SystemActions.systemSet({
          isFocused,
        }),
      );
    };

    window.addEventListener("focus", updateFocusStatus(true));
    window.addEventListener("blur", updateFocusStatus(false));

    return () => {
      window.removeEventListener("focus", updateFocusStatus(true));
      window.removeEventListener("blur", updateFocusStatus(false));
    };
  }, []);

  return null; // This component doesn't render anything
};

export default HandlingFocusApp;
