import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const MarkReadMsgIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox="0 -960 960 960" fill="#e8eaed">
      <path d="M694-160 553-302l56-56 85 85 170-170 56 57-226 226ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v280h-80v-280H160v525l46-45h274v80H240L80-80Zm80-240v-480 480Z" />
    </SvgIcon>
  );
};

MarkReadMsgIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
MarkReadMsgIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(MarkReadMsgIcon);
