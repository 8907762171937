import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { LangConstant } from "const";
import { ImageEmptyCat } from "const/image.const";
import { useTranslation } from "react-i18next";

const EmptyResult = ({ classes = {} }) => {
  const { t: getLabel } = useTranslation();

  return (
    <EmptyResultRoot className={classes.root}>
      <Image src={ImageEmptyCat} aria-label="Not found" lazy="true" className={classes.emptyImage} />
      <EmptyText variant="subtitle2" className={classes.emptyText}>
        {getLabel(LangConstant.TXT_NOT_FOUND)}
        <Typography variant="body2">{getLabel("TXT_TRY_OTHER_KEYWORD")}</Typography>
      </EmptyText>
    </EmptyResultRoot>
  );
};

EmptyResult.prototype = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    emptyImage: PropTypes.string,
    emptyText: PropTypes.string,
  }),
};
export default EmptyResult;

const Image = styled("img")({
  width: "40%",
  height: "auto",
  margin: "auto",
});

const EmptyResultRoot = styled(Stack)({
  justifyContent: "center",
  textAlign: "center",
  padding: 2,
});

const EmptyText = styled(Typography)(props => ({
  textAlign: "center",
  padding: 2,

  "& > p": {
    color: props.theme.palette.grey[300],
    padding: 4,
  },
}));
