import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import { Box, IconButton } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const PrimaryCarousel = props => {
  const { children, classes, nextSlide, ...otherProps } = props;
  const defaultClasses = useStyles();

  const [carouseRef, setCarouseRef] = useState();

  useEffect(() => {
    if (carouseRef) carouseRef.goToSlide(nextSlide);
  }, [nextSlide, carouseRef]);

  return (
    <Carousel
      responsive={responsive}
      showDots={true}
      autoPlay={false}
      shouldResetAutoplay={false}
      dotListClass={classes?.dotListClass || defaultClasses.dotListClass}
      itemClass={classes?.itemClass || ""}
      containerClass={classes?.containerClass || defaultClasses.containerClass}
      ref={el => setCarouseRef(el)}
      customLeftArrow={
        <CustomArrow className={clsx(defaultClasses.btnSlider, defaultClasses.btnPrevious, classes?.btnPrevious)}>
          <KeyboardArrowLeft />
        </CustomArrow>
      }
      customRightArrow={
        <CustomArrow className={clsx(defaultClasses.btnSlider, defaultClasses.btnNext, classes?.btnNext)}>
          <KeyboardArrowRight />
        </CustomArrow>
      }
      customDot={<CustomDot />}
      {...otherProps}
    >
      {children}
    </Carousel>
  );
};

PrimaryCarousel.propTypes = {
  classes: PropTypes.shape({
    dotListClass: PropTypes.string,
    itemClass: PropTypes.string,
    containerClass: PropTypes.string,
    btnPrevious: PropTypes.string,
    btnNext: PropTypes.string,
  }),
  responsive: PropTypes.object,
  showDots: PropTypes.bool,
  autoPlay: PropTypes.bool,
  nextSlide: PropTypes.number,
};
PrimaryCarousel.defaultProps = {};

export default memo(PrimaryCarousel);

const CustomDot = ({ onClick, active, classes }) => {
  const defaultClass = useStyles();

  return (
    <Box
      className={clsx(
        defaultClass.dotItem,
        active && defaultClass.dotItemActive,
        classes?.dotItem,
        classes?.dotItemActive,
      )}
      onClick={onClick}
    />
  );
};
CustomDot.propTypes = {
  classes: PropTypes.shape({
    dotItem: PropTypes.string,
    dotItemActive: PropTypes.string,
  }),
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
CustomDot.defaultProps = { classes: {} };

const CustomArrow = props => {
  const classes = useStyles();
  const { children, ...restArrowProps } = props;
  delete restArrowProps["carouselState"];
  delete restArrowProps["rtl"];
  return (
    <IconButton className={clsx(classes?.btnSlider, classes?.btnNext)} {...restArrowProps}>
      {children}
    </IconButton>
  );
};

const useStyles = makeStyles(theme => ({
  containerClass: {
    overflowX: "clip",
    overflow: "initial",
    height: "100%",
  },
  btnSlider: {
    width: 48,
    height: 48,
    position: "absolute",
    top: "calc(50% - 24px)",
    backgroundColor: "#AAAEB1",
    color: "white",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",

    "& svg": {
      fontSize: 36,
    },
  },
  btnPrevious: {
    left: 2,
  },
  btnNext: {
    right: 2,
  },
  dotListClass: {
    maxWidth: "50%",
    margin: "0 auto",
    flexWrap: "wrap",
  },
  dotItem: {
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 8,
    background: theme.palette.grey[200],
    opacity: 0.8,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  dotItemActive: {
    background: theme.palette.primary.main,
  },
}));

const responsive = {
  default: {
    breakpoint: { max: 6000, min: 0 },
    items: 1,
  },
};
