import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ConversationSelectors, GroupInfoSelectors } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { SystemConstant } from "const";
import { checkBlockedContact } from "pubsub/services/conversation.service";
import { getSavedServer } from "utils/branch.utils";

// Check current user is blocked by other user or not
// Blocking account feature is active on global server
const HandlingBlockAccount = () => {
  const updatingGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const handleBlockAccount = async () => {
    const isGlobalServer = getSavedServer().type === SystemConstant.SERVER_TYPE.server;
    if (isGlobalServer && selectedGroupId && selectedGroupId === updatingGroupData.id) {
      const selectedGroup = await getInteractor(prefixKey).LocalGroupService.get(selectedGroupId);
      const isChatWithPersonal =
        Array.isArray(selectedGroup.groupMembers) &&
        selectedGroup.groupMembers.length > 0 &&
        selectedGroup.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

      if (isChatWithPersonal) {
        const groupMembersId = selectedGroup.groupMembers.map(item => item.id);
        await checkBlockedContact(prefixKey, updatingGroupData.id, groupMembersId);
        return true;
      }
    }
  };

  useEffect(() => {
    handleBlockAccount();
  }, [updatingGroupData, selectedGroupId]);

  return null;
};

export default HandlingBlockAccount;
