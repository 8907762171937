import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isJSONString, toCamel } from "utils";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import {
  BranchSelectors,
  ConversationActions,
  ConversationSelectors,
  GroupInfoSelectors,
  getReduxState,
} from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { ARR_NOTICE_NORMAL } from "sagas/saga.helper";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import { convertContentFileMsg } from "utils/message.utils";
import useLazyEffect from "./useLazyEffect";
import { createSelector } from "reselect";
import useCleanUpEffect from "./useCleanUpEffect";

const MEDIA_SEND_TYPE = [
  SystemConstant.SEND_TYPE.image,
  SystemConstant.SEND_TYPE.file,
  SystemConstant.SEND_TYPE.audio,
  SystemConstant.SEND_TYPE.video,
];

const memoizedReduxState = createSelector(
  [
    state => state.conversationRedux.isUpdateViewMode,
    BranchSelectors.getSelectedBranch,
    GroupInfoSelectors.getDeleteGroup,
    GroupInfoSelectors.getGroupHasNewMember,
    state => state.conversationRedux.updateThreadStatus,
  ],
  (isUpdateViewMode, selectedBranch, deleteGroup, groupHasNewMember, updateThreadStatus) => {
    return {
      isUpdateViewMode,
      selectedBranch,
      deleteGroup,
      groupHasNewMember,
      updateThreadStatus,
    };
  },
);

export const useUnreadThread = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const effectReduxData = useSelector(memoizedReduxState);

  const [unreadThreadNum, setUnreadThreadNum] = useState(0);

  const countUnreadThread = async () => {
    const unreadNum = await getInteractor(prefixKey).LocalThreadService.countTotalUnreadMessage();
    if (unreadNum !== unreadThreadNum) setUnreadThreadNum(unreadNum);
  };

  useLazyEffect(() => {
    countUnreadThread();
  }, [effectReduxData]);

  return { unreadThreadNum };
};

const getDefaultViewPaging = () => ({
  isEnd: false,
  threadList: [],
});
const PAGING_SIZE = 10;

export const useThreadInfo = () => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const { isUpdateViewMode, selectedBranch, deleteGroup, groupHasNewMember, updateThreadStatus } =
    useSelector(memoizedReduxState);
  const { isMounted } = useCleanUpEffect();

  const [pagination, setPagination] = useState(getDefaultViewPaging);
  const [viewMode, setViewMode] = useState(AppConstant.VIEW_MODE.fetching);

  const updateThreadAndMessage = useCallback(async (displayThreads, isReadAll = false) => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    const unreadThread = displayThreads.filter(item => item.totalUnread > 0);

    // Get unread messageIds in thread
    let unreadMessageIdArr = [];
    for (let index = 0; index < unreadThread.length; index++) {
      const item = unreadThread[index];
      const unreadMessagesInThread = await getInteractor(prefixKey).LocalMessageService.getUnreadInThread(
        item.threadId,
      );
      const unreadId = unreadMessagesInThread.map(item => item.id);
      unreadMessageIdArr = [...unreadMessageIdArr, ...unreadId];
    }

    // Dispatch to server
    if (unreadMessageIdArr.length > 0) {
      dispatch(
        ConversationActions.updateMessageStatus({
          messageIds: unreadMessageIdArr,
          status: SystemConstant.MESSAGE_STATUS.read,
          isReadAllThread: isReadAll,
        }),
      );
    }

    const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
    const isNeedUpdateViewMode = displayThreads.find(item => item.groupId === selectedGroupId);
    if (isNeedUpdateViewMode) {
      const parentMessageList = await getInteractor(prefixKey).LocalMessageService.find({
        source_id: displayThreads.map(item => item.threadId),
      });
      dispatch(
        ConversationActions.conversationSet({
          updateMessageStatus: {
            updateMessageIds: parentMessageList.map(item => item.id),
            modified: Date.now(),
          },
        }),
      );
    }
  }, []);

  const getThreadWithPagination = useCallback(async (totalThreadList, limit = PAGING_SIZE) => {
    setViewMode(AppConstant.VIEW_MODE.fetching);

    const lastThread = totalThreadList[totalThreadList.length - 1] || {};
    const records = await getInteractor(StorageUtil.getCurrentPrefixKey()).LocalThreadService.getThreads({
      limit,
      compareTime: lastThread.modified || Date.now(),
    });
    const threadList = await refactorThread(toCamel(records));
    const uniqThreadList = uniqBy(totalThreadList.concat(threadList), "id");

    if (isMounted()) {
      setPagination({
        isEnd: records.length < limit,
        threadList: uniqBy(totalThreadList.concat(threadList), "id"),
      });
      setViewMode(uniqThreadList.length > 0 ? AppConstant.VIEW_MODE.list : AppConstant.VIEW_MODE.empty);
    }
  }, []);

  const loadMore = useCallback(
    debounce(totalThreadList => getThreadWithPagination(totalThreadList, PAGING_SIZE), AppConstant.DEBOUNCE_TIME),
    [],
  );

  useLazyEffect(
    () => {
      getThreadWithPagination(pagination.threadList, PAGING_SIZE);
    },
    [isUpdateViewMode, selectedBranch, updateThreadStatus],
    100,
  );

  useEffect(() => {
    if (groupHasNewMember && groupHasNewMember.id) {
      getInteractor(prefixKey)
        .LocalThreadService.findOne({ group_id: groupHasNewMember.id })
        .then(groupThread => {
          if (
            false === Boolean(pagination.threadList.find(item => item.groupId === groupHasNewMember.id)) &&
            groupThread?.id
          ) {
            getThreadWithPagination([], pagination.threadList.length);
          }
        });
    }
  }, [groupHasNewMember]);

  useEffect(() => {
    if (deleteGroup && deleteGroup.groupId) {
      if (deleteGroup.groupId) {
        const displayThreads = pagination.threadList;
        const currentTotalThread = displayThreads.filter(item => item.groupId !== deleteGroup.groupId);

        if (!isEqual(currentTotalThread, displayThreads))
          setPagination(preState => ({ ...preState, threadList: currentTotalThread }));
      }
    }
  }, [deleteGroup]);

  return { pagination, viewMode, loadMore, updateThreadAndMessage };
};

// let threadList = await getInteractor(prefixKey).LocalThreadService.getAllByBranchId(branchId);
const refactorThread = async threadList => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  if (threadList && threadList.length > 0) {
    threadList = await Promise.all(
      threadList.map(async threadInfo => {
        const accountGroup =
          (await getInteractor(prefixKey).LocalAccountGroupService.findOne({
            group_id: threadInfo.groupId,
            account_id: accountId,
          })) || {};
        if (accountGroup.state === SystemConstant.STATE.inactive) return null;

        const senderParentMsg = await getInteractor(prefixKey).LocalAccountService.get(
          threadInfo.threadAccountId,
          branchId,
        );
        const newestMsgInThread =
          (await getInteractor(prefixKey).LocalMessageService.getThreadMessage(threadInfo.threadId, { limit: 2 })) ||
          [];
        // Convert parent message content
        let parentMessage = await getInteractor(prefixKey).LocalMessageService.findOne({
          source_id: threadInfo.threadId,
          send_type: ARR_NOTICE_NORMAL.concat(MEDIA_SEND_TYPE),
        });
        parentMessage = await convertMessageContent(parentMessage);

        const replyMessageList = await Promise.all(
          newestMsgInThread.reverse().map(async item => await convertMessageContent(item)),
        );

        return {
          ...threadInfo,
          replyMessageList: replyMessageList,
          parentMessage: toCamel(parentMessage),
          senderParentMsg: toCamel(senderParentMsg),
        };
      }),
    );
    threadList = threadList.filter(item => Boolean(item));

    return threadList;
  }

  return [];
};

const convertMessageContent = async message => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  if (!message) return message;
  let convertMessage = { ...message };

  try {
    if (MEDIA_SEND_TYPE.includes(message.send_type) && isJSONString(message.content)) {
      convertMessage = { ...message, content: convertContentFileMsg(message) };
    } else {
      const lastEditMsg = await getInteractor(prefixKey).LocalMessageService.getNewestEditMsg(message.source_id);
      if (lastEditMsg && Object.keys(lastEditMsg).length > 0) {
        convertMessage = { ...message, content: lastEditMsg.content };
      }
    }
  } catch (error) {
    console.error(error);
  }

  return toCamel(convertMessage);
};
