/**
 * Export an instance of api client
 */

import { ApiConstant, FormatConstant, KeyConstant } from "const";
import apisauce from "apisauce";
import StringFormat from "string-format";
const StorageUtil = window.electronUtils.storage;

export const API_CONFIG = {
  baseURL: ApiConstant.BASE_URL,
  headers: {
    ...ApiConstant.HEADER_DEFAULT,
    Authorization: "Bearer " + StorageUtil.getItem(KeyConstant.KEY_TOKEN),
  },
  timeout: ApiConstant.TIMEOUT,
};
const GlobalApi = apisauce.create(API_CONFIG);
export default GlobalApi;

export const createApiWithDomain = domain => {
  if (!domain) {
    console.error({ domain });
    throw new Error("[ERROR] Domain is invalid");
  }

  const baseUrl = StringFormat(FormatConstant.FM_BASE_URL, domain);
  const branchApi = apisauce.create({ ...API_CONFIG, baseURL: baseUrl });
  return branchApi;
};

export const createApiWithBaseUrl = baseUrl => {
  if (!baseUrl) throw new Error("[ERROR] BaseUrl is invalid", { baseUrl });

  const branchApi = apisauce.create({ ...API_CONFIG, baseURL: baseUrl });
  return branchApi;
};
