import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";
import { convertHex2rgba } from "utils";
import { makeStyles } from "@mui/styles";

const FilePreviewIcon = ({ width, height, color }) => {
  const classes = useStyles({ width, height });

  return (
    <SvgIcon viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" className={classes.iconWrap}>
      <path
        d="M17.3335 0.00321209C17.3335 2.07509 17.3335 4.07202 17.3335 6.06896C17.3232 6.35512 17.337 6.64164 17.3747 6.92555C17.4912 7.68375 17.8796 8.37522 18.4692 8.87358C19.0587 9.37195 19.8098 9.64394 20.5854 9.63987C22.174 9.65594 23.7626 9.63987 25.3501 9.63987H25.9999V10.2588C25.9999 15.6689 25.9999 21.0789 25.9999 26.489C25.9999 28.6712 24.6636 29.9957 22.4642 29.9968C16.146 29.9968 9.82917 29.9968 3.5138 29.9968C1.35454 29.9968 0.00743054 28.6584 0.00634766 26.5147C0.00634766 18.8375 0.00634766 11.16 0.00634766 3.48205C0.00634766 1.34057 1.35345 0.00107074 3.51272 0C7.91644 0 12.3202 0 16.7239 0L17.3335 0.00321209Z"
        fill={color}
      />
      <path
        d="M25.4151 7.49836C24.264 7.49836 23.2613 7.49836 22.2585 7.49836C21.6987 7.49836 21.1388 7.51335 20.5801 7.49836C19.9541 7.47481 19.5123 7.06043 19.5069 6.4469C19.4896 4.62664 19.5004 2.80638 19.5069 0.986121C19.5239 0.912272 19.549 0.840467 19.5816 0.771973C21.5178 3.00125 23.4194 5.19948 25.4151 7.49836Z"
        fill={convertHex2rgba(color, 0.8)}
      />
    </SvgIcon>
  );
};

FilePreviewIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,

  color: PropTypes.string,
};
FilePreviewIcon.defaultProps = {
  width: 26,
  height: 30,

  color: "#799098",
};

export default memo(FilePreviewIcon);

const useStyles = makeStyles(() => ({
  iconWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: props => props.width,
    height: props => props.height,
  },
}));
