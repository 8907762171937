import { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Button, Skeleton, Stack } from "@mui/material";
import { getLabel } from "language";
import { ConfirmDialog, InfiniteScroll } from "components";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { useCallHistory } from "hooks";
import { StorageUtil } from "utils";
import { useDispatch } from "react-redux";
import { CallingActions } from "redux-store";
import { getNSLang } from "utils/lang.utils";
import CallHistoryItem from "./CallHistoryItem";
import EmptyContact from "pages/HomePage/components/AddingChat/EmptyContact";

const CallHistoryTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { pagination, viewMode, handleSetCallHistory } = useCallHistory();

  const [selectedList, setSelectedList] = useState([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isDeleteAll, setIsDeleteAll] = useState(false);

  const handleSelect = callHistory => () => {
    let newSelectedList = [];
    const isExisted = selectedList.findIndex(item => item.id === callHistory.id) >= 0;
    if (isExisted) {
      newSelectedList = selectedList.filter(item => item.id !== callHistory.id);
    } else {
      newSelectedList = [...selectedList, callHistory];
    }

    setSelectedList(newSelectedList);
  };

  const handleConfirmDelete = () => {
    setIsOpenConfirm(true);
  };

  const handleCancelDelete = () => {
    setIsOpenConfirm(false);
    setSelectedList([]);
    setIsDeleteAll(false);
  };

  const handleDeleteCallHistory = async () => {
    const deleteRoomIds = selectedList.map(item => item.roomId);
    // update to server
    const data2Req = {
      state: SystemConstant.STATE.inactive,
      room_ids: deleteRoomIds,
    };
    if (isDeleteAll) data2Req.select_all = 1;
    dispatch(CallingActions.updateCallHistory(data2Req, prefixKey));

    setSelectedList([]);
    setIsOpenConfirm(false);
  };

  const handleDeleteAll = () => {
    setIsDeleteAll(true);
    setIsOpenConfirm(true);
  };

  const handleLoadingMore = () => {
    if (!pagination.isEnd) handleSetCallHistory(prefixKey, pagination.callHistoryList, {});
  };

  const handleCheckSelected = useCallback(
    selectedItem => selectedList.findIndex(item => item.id === selectedItem.id) >= 0,
    [selectedList],
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p="0px 16px">
        <Button
          variant="text"
          className={classes.textBtn}
          disabled={selectedList.length === 0}
          onClick={handleConfirmDelete}
        >
          {getLabel("FM_DELETE", { number: selectedList.length })}
        </Button>
        <Button
          variant="text"
          className={classes.textBtn}
          disabled={pagination.callHistoryList.length === 0}
          onClick={handleDeleteAll}
        >
          {getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_ALL")}
        </Button>
      </Stack>

      <InfiniteScroll className={classes.scrollBox} onScrollToBottom={handleLoadingMore} hasMore={!pagination.isEnd}>
        {pagination.callHistoryList.map(item => (
          <CallHistoryItem
            key={item.id}
            callHistory={item}
            onSelect={handleSelect(item)}
            checked={handleCheckSelected(item)}
          />
        ))}
        {viewMode === AppConstant.VIEW_MODE.fetching && <CallHistoryLoading />}
      </InfiniteScroll>

      {viewMode === AppConstant.VIEW_MODE.empty && (
        <EmptyContact displayText={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_EMPTY_CALL_HISTORY")} />
      )}

      {isOpenConfirm && (
        <ConfirmDialog
          open
          title={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_CALL_HISTORY_TITLE")}
          content={getNSLang(
            LangConstant.NS_HOME_CONVERSATION,
            isDeleteAll ? "TXT_CONFIRM_DELETE_ALL" : "TXT_DELETE_CALL_HISTORY_CONTENT",
          )}
          submitProps={{ submitText: getLabel("TXT_DELETE"), onClick: handleDeleteCallHistory }}
          cancelProps={{ onClick: handleCancelDelete }}
        />
      )}
    </>
  );
};

export default CallHistoryTab;

const CallHistoryLoading = () => {
  return [...Array(3).keys()].map(chattingKey => (
    <Stack key={chattingKey} flexDirection="row" alignItems="center" gap={1} m={2}>
      <Skeleton variant="circular">
        <Avatar />
      </Skeleton>

      <Box width="100%">
        <Skeleton width="100%" />
        <Skeleton width="60%" />
      </Box>
    </Stack>
  ));
};

const useStyles = makeStyles({
  scrollBox: {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "auto",
  },

  textBtn: {
    textTransform: "unset",
  },
});
