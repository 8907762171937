import PropTypes from "prop-types";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";
import { Close } from "@mui/icons-material";

import { ConversationSelectors } from "redux-store";
import FileBox from "../ChatItem/FileBox";
import { withCloseBadge } from "components/hoc";
import { useEffect, useState } from "react";

const FileBoxWithBadge = withCloseBadge(FileBox);

const UploadDocument = ({ multipleFile, onChangeFile, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const { processStatus } = useSelector(ConversationSelectors.getUploadingAttachment);
  const isFailedUpload = AppConstant.PROCESS_STATUS.error === processStatus;

  const [metaDataList, setMetaDataList] = useState([]);

  const handleRemoveOneFile = removedItem => {
    onChangeFile(removedItem);
    const newMetaList = metaDataList.filter(item => item.attachmentId !== removedItem.attachmentId);
    setMetaDataList(newMetaList);
  };

  const setMetadata = multipleFile => {
    const metaDataList = multipleFile.map(item => ({
      attachmentId: item.attachmentId,
      metaData: {
        fileName: item.file.name,
        size: item.file.size,
        mimeType: item.file.type,
      },
      filePath: item.file.path,
    }));

    setMetaDataList(metaDataList);
  };

  useEffect(() => {
    setMetadata(multipleFile);
  }, []);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={2} pt={2}>
        <Typography className={"semiBold-lg-txt"}>{getLabel(LangConstant.TXT_SEND_FILE_CONFIRM_TEXT)}</Typography>
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </Stack>

      <Box className={classes.contentBox} p={2}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {metaDataList.map(item => (
            <Grid item xs={6} key={item.attachmentId}>
              <FileBoxWithBadge
                ComponentProps={{ mediaData: item }}
                IconProps={{ onClick: () => handleRemoveOneFile(item) }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {isFailedUpload && (
        <Typography className={clsx(classes.uploadFailed, "small-md-txt")}>
          {getLabel(LangConstant.TXT_UPLOAD_FAILED)}
        </Typography>
      )}
    </Box>
  );
};

UploadDocument.propTypes = { multipleFile: PropTypes.any.isRequired };

export default UploadDocument;

const useStyles = makeStyles({
  uploadFailed: {
    color: "red",
    marginTop: 10,
  },

  contentBox: {
    maxHeight: 315,
    overflowY: "auto",
    overflowX: "hidden",
  },
});
