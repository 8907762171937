import React, { memo } from "react";
import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Groups } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { makeStyles } from "@mui/styles";
import AddingGroupLayout from "./AddingGroup/AddingGroupLayout";
import AddingGroupDrawer from "./AddingGroup/AddingGroupDrawer";

const AddingGroupButton = props => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  return (
    <AddingGroupLayout Drawer={AddingGroupDrawer} {...props}>
      <ListItemButton className={classes.btnCreate}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <Groups />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle1" className={classes.btnText}>
              {chatObjContent.createGroup}
            </Typography>
          }
        />
      </ListItemButton>
    </AddingGroupLayout>
  );
};

AddingGroupButton.propTypes = AddingGroupLayout.propTypes;

AddingGroupButton.defaultProps = {};

export default memo(AddingGroupButton);

const useStyles = makeStyles({
  avatar: {
    width: 48,
    height: 48,
    background: "#BEBEBE",
    color: "white",
  },
  btnCreate: {
    marginTop: 8,
    marginBottom: 8,
  },
  btnText: {
    fontSize: 14,
    color: "#1E272E",
  },
});
