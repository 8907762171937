import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const NoData = ({ media, textOne, textTwo }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {media}
      <Typography className={clsx(classes.text, classes.title)} variant="subtitle2">
        {textTwo}
      </Typography>
      <Typography className={clsx(classes.text, classes.description)} variant="body2">
        {textOne}
      </Typography>
    </Box>
  );
};

NoData.propTypes = {
  media: PropTypes.node,
  textOne: PropTypes.string,
  textTwo: PropTypes.string,
};

NoData.defaultProps = {
  textOne: "",
  textTwo: "",
};

export default NoData;

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    marginTop: "40%",
    padding: "0 67px",
  },

  text: {
    paddingTop: 20,
  },

  description: {
    color: theme.palette.grey[500],
  },

  title: {
    color: theme.palette.black,
  },
}));
