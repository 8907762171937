import { KeyConstant, SystemConstant } from "const";
import { useEffect } from "react";
import { useServerMode } from "hooks";
import { LocalDbManagement } from "services/local.service";
import { StorageUtil, getPrefixKey } from "utils";

const HandlingBranchInvite = () => {
  useHandleBranchInvite();

  return null; // This component doesn't render anything
};

export default HandlingBranchInvite;

export const useHandleBranchInvite = () => {
  const { switchServer } = useServerMode();

  // TODO: check trường hợp deep link thiếu thông tin
  const getBranchInviteInfo = async (_, payload) => {
    const invitationInfo = Object.fromEntries(new URLSearchParams(payload));
    const inviteBranchId = invitationInfo.branch_id;
    const inviteBranchExist = await LocalDbManagement.findOne({
      state: SystemConstant.STATE.active,
      branch_id: inviteBranchId,
    });

    let convertInvitationInfo = {
      id: invitationInfo.branch_id,
      domain: invitationInfo.branch_domain,
      loginState: SystemConstant.STATE.inactive,
      phone: invitationInfo.owner_name?.trim(),
      isDeepLink: invitationInfo.branch_id !== SystemConstant.GLOBAL_BRANCH_ID,
    };

    if (inviteBranchExist) {
      const prefixKey = getPrefixKey(inviteBranchExist.account_id, inviteBranchExist.branch_id);
      const existInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey);
      convertInvitationInfo = existInfo;
    }

    switchServer(convertInvitationInfo);
  };

  useEffect(() => {
    if (window.electronEvent) window.electronEvent.addListener(KeyConstant.EVENT_KEY.inviteBranch, getBranchInviteInfo);

    return () => {
      window.removeEventListener(KeyConstant.EVENT_KEY.inviteBranch, getBranchInviteInfo);
    };
  }, []);

  return null;
};
