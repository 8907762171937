import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { PrimaryTab, TabPanel } from "components";
import { LangConstant, SystemConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import { BranchSelectors, CallingActions, CallingSelectors } from "redux-store";
import { getNSLang } from "utils/lang.utils";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CallHistoryTab from "./CallHistoryTab";
import ConferenceTab from "./ConferenceTab";
import { getInteractor } from "services/local.service";
import { uniq } from "lodash";
import { StorageUtil } from "utils";

const CallingTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);
  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);
  const isFetchHistoryTimestamp = useSelector(CallingSelectors.getFetchTimestamp);
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  let tabList = [
    {
      label: getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_HISTORY"),
      index: CHAT_TAB_INDEX.history,
    },
    {
      label: getNSLang(LangConstant.NS_HOME, "TXT_CONFERENCE"),
      index: CHAT_TAB_INDEX.conference,
    },
  ];

  if (!isBranchServer) {
    tabList = tabList.filter(item => item.index !== CHAT_TAB_INDEX.conference);
  }

  const [selectedTab, setSelectedTab] = useState(CHAT_TAB_INDEX.history);

  const handleUnreadCallHistory = async () => {
    const unreadCallHistory = await getInteractor(prefixKey).LocalCallHistoryService.find({
      action_status: SystemConstant.CALL_HISTORY_ACTION_STATUS.unread,
    });
    if (unreadCallHistory && unreadCallHistory.length > 0) {
      const unreadRoomIds = uniq(unreadCallHistory.map(item => item.room_id));
      dispatch(
        CallingActions.updateCallHistory(
          {
            room_ids: unreadRoomIds,
            action_status: SystemConstant.CALL_HISTORY_ACTION_STATUS.seen,
          },
          prefixKey,
        ),
      );
    }
  };

  useEffect(() => {
    handleUnreadCallHistory();
    if (selectedServerRedux && selectedServerRedux.id === SystemConstant.GLOBAL_BRANCH_ID) {
      setSelectedTab(CHAT_TAB_INDEX.history);
    }
  }, [selectedServerRedux]);

  useEffect(() => {
    handleUnreadCallHistory();
  }, [isFetchHistoryTimestamp]);

  return (
    <Stack sx={{ height: "100%" }}>
      <PrimaryTab
        tabArray={tabList}
        onChange={setSelectedTab}
        TabIndicatorProps={{
          className: "hidden",
        }}
        selectedTabIndex={selectedTab}
        classes={{
          flexContainer: classes.flexContainer,
          tabButton: classes.tabButton,
          tabLabel: clsx(classes.tabLabel, "bold-sm-txt"),
          tabLabelSelected: classes.tabLabelSelected,
          tabButtonSelected: classes.tabButtonSelected,
          root: classes.rootSpace,
        }}
      />

      <TabPanel
        className={selectedTab !== CHAT_TAB_INDEX.history && "hidden"}
        value={selectedTab}
        index={CHAT_TAB_INDEX.history}
      >
        <CallHistoryTab />
      </TabPanel>

      {isBranchServer && (
        <TabPanel
          className={selectedTab !== CHAT_TAB_INDEX.conference && "hidden"}
          value={selectedTab}
          index={CHAT_TAB_INDEX.conference}
        >
          <ConferenceTab />
        </TabPanel>
      )}
    </Stack>
  );
};

export default CallingTab;

const CHAT_TAB_INDEX = {
  history: 0,
  conference: 1,
};

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  flexContainer: {
    padding: "12px 0px",
  },

  tabButton: {
    height: 30,
    minHeight: "unset",
    maxWidth: "unset",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    borderRadius: 15.5,
  },

  tabLabel: {
    marginTop: 0,
    color: "#7F7F7F",
  },

  tabLabelSelected: {
    color: "#1E272E",
  },

  tabButtonSelected: {
    backgroundColor: theme.palette.grey[100],
  },
}));
