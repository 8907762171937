import store, { ConversationActions } from "redux-store";
import { useEffect } from "react";
import { AppConstant } from "const";
import { useAlertContext } from "components/context/AlertContext";
import { getCommonLang } from "utils/lang.utils";

const HandleAppEvents = () => {
  const { showAlert } = useAlertContext();

  useEffect(() => {
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.urlMetaData, (_, payload) =>
      previewLinkMetaData(payload),
    );

    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.downloadResult, (_, payload) =>
      downloadResult(payload, showAlert),
    );
  }, []);

  return null;
};

export default HandleAppEvents;

const previewLinkMetaData = payload => {
  store.dispatch(
    ConversationActions.conversationSetLinkData({
      [payload.url]: {
        messageId: payload.messageId,
        metaData: payload.metaData,
      },
    }),
  );
};

const downloadResult = (payload, showAlert) => {
  const { count, total } = payload;

  showAlert({
    content: getCommonLang("FM_DOWNLOAD_FILES", { count, total }),
  });
};
