import React from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogTitle, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { getSavedServer } from "utils/branch.utils";

const DialogGroupMember = ({ open, onClose, onClickSetAdmin, subTitle, confirmButtonText, groupDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onLeave = () => {
    dispatch(
      ConversationActions.sendMessage({
        groupId: groupDetail.id,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID),
        parentId: null,
        branchId: getSavedServer().id,
        mentionIdsArr: [],
        threadId: null,
        removingId: StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID),
      }),
    );

    onClose();
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.root }}
      className={classes.wrapDialogGroup}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        {getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CHOOSE_NEW_ADMIN")}
      </DialogTitle>
      <Typography className={classes.descGroupMember}>{subTitle}</Typography>
      <Box className={classes.wrapButton}>
        <Button onClick={onClickSetAdmin} className={classes.buttonChooseAdmin} variant="contained">
          {getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_BUTTON_CHOOSE_ADMIN")}
        </Button>
        <Button onClick={onLeave} className={classes.buttonLeave} variant="contained">
          {confirmButtonText}
        </Button>
      </Box>
      <Button onClick={onClose} className={classes.buttonGroup} color="inherit" variant="contained">
        {getCommonLang("TXT_CANCEL")}
      </Button>
    </Dialog>
  );
};

export default DialogGroupMember;

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    padding: 20,
  },
  wrapDialogGroup: {
    textAlign: "center",
  },
  descGroupMember: {
    margin: "0 22px 16px 22px",
    color: "#7F7F80",
  },
  wrapButton: {
    width: "100%",
    display: "flex",
    marginBottom: 20,
    padding: "0px 24px",
  },
  buttonLeave: {
    marginLeft: 24,
    marginRight: 24,
    width: "100%",
    backgroundColor: theme.palette.white,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: "none",
      boxShadow: "none",
      color: theme.palette.secondary.dark,
    },
  },
  buttonGroup: {
    backgroundColor: theme.palette.white,
    color: "#CBCACA",
    margin: "auto",
    "&:hover": {
      color: theme.palette.grey[500],
      boxShadow: "none",
      background: "none",
    },
  },
  dialogTitle: {
    fontSize: 20,
    fontWeight: 700,
  },
  buttonChooseAdmin: {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));
