import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const LineChat = ({ data, className }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.lineWrap, className)}>
      <Box className={classes.line}></Box>
      <Typography
        className={clsx(classes.lineTitle, "medium-sm-txt")}
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      />
    </Box>
  );
};

export default memo(LineChat);

const useStyles = makeStyles(theme => ({
  lineWrap: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 8,
    paddingTop: 8,
    zIndex: 1,
  },

  line: {
    border: "1px solid #F5F5F5",
    width: "60%",
    position: "absolute",
  },

  lineTitle: {
    maxWidth: "40%",
    width: "fit-content",
    backgroundColor: theme.palette.grey[100],
    borderRadius: "20px",
    padding: "7px 12px",
    textAlign: "center",
    zIndex: 10,
  },
}));
