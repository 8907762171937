import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDrawer } from "components";
import { getNSLang } from "utils/lang.utils";
import { LangConstant, SystemConstant } from "const";
import PropTypes from "prop-types";
import SelectingListContact from "../AddingChat/SelectingListContact";
import { useAccountList, useContactList } from "hooks";
import { GroupInfoActions } from "redux-store";
import { getInteractor } from "services/local.service";
import { getSavedServer } from "utils/branch.utils";

const AddingGroupMembers = ({ open, onClose, groupId }) => {
  const dispatch = useDispatch();
  const { contactList } = useContactList();
  const { accountList } = useAccountList();

  const [listAddGroupMember, setListAddGroupMember] = useState([]);

  const currentBranchId = getSavedServer().id;
  const isGlobal = currentBranchId === SystemConstant.GLOBAL_BRANCH_ID;

  const handleAddingMember = selectedContact => {
    const memberIds = selectedContact.map(item => {
      if (isGlobal) return item.contactId; // Global server: addMemberList is contactList => get contactId
      else return item.id; // Branch server: addMemberList is accountList => get id
    });
    dispatch(
      GroupInfoActions.addMemberGroup({
        groupId: groupId,
        memberIds: memberIds,
        memberInviteType: SystemConstant.ROLE_OBJ.member,
      }),
    );
    onClose();
  };

  useEffect(() => {
    getInteractor()
      .LocalGroupService.get(groupId)
      .then(groupDetail => {
        if (groupDetail && Array.isArray(groupDetail.groupMembers)) {
          const groupMembers = groupDetail.groupMembers;
          const memberGroupIds = groupMembers.map(item => item.id);

          let listAdd = [];
          if (isGlobal) {
            listAdd = contactList.filter(item => !memberGroupIds.includes(item.contactId));
          } else {
            listAdd = accountList.filter(item => !memberGroupIds.includes(item.id));
          }

          setListAddGroupMember(listAdd);
        }
      });
  }, [contactList, accountList, isGlobal]);

  return (
    <AppDrawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      hideBackdrop={true}
      title={getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_ADD_NEW_MEMBER)}
    >
      <SelectingListContact data={listAddGroupMember} onComplete={handleAddingMember} />
    </AppDrawer>
  );
};

AddingGroupMembers.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func,
};

AddingGroupMembers.defaultProps = {
  open: false,
  data: {},

  onClose: () => {},
};

export default AddingGroupMembers;
