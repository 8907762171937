import { Typography } from "@mui/material";
import { KeyConstant, LangConstant } from "const";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConversationSelectors } from "redux-store";
import { createSelector } from "reselect";
import { StorageUtil } from "utils";
import { getNSLang } from "utils/lang.utils";
import orderBy from "lodash/orderBy";

const memoizedIncomingState = createSelector(
  [ConversationSelectors.getSelectedGroupId, state => state.conversationRedux.incomingMessages],

  (selectedGroupId, incomingMessages) => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const messageInGroup = incomingMessages[selectedGroupId];
    const messageList = messageInGroup ? Object.values(messageInGroup) : null;

    if (Array.isArray(messageList) && messageList.length > 0) {
      const receivedMessages = messageList.filter(item => item.senderId !== accountId);
      const orderMessage = orderBy(receivedMessages, ["created"], ["desc"]);
      return orderMessage[0];
    }

    return null;
  },
);

const ScrollTooltip = ({ isShowButton }) => {
  const newMessage = useSelector(memoizedIncomingState);

  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (newMessage) {
      setIsShow(Boolean(isShowButton));
    } else {
      setIsShow(false);
    }
  }, [newMessage]);

  useEffect(() => {
    if (!isShowButton) setIsShow(false);
  }, [isShowButton]);

  return isShow ? (
    <Typography className="custom-notice" color="primary">
      {getNSLang(LangConstant.NS_HOME, "TXT_HAS_NEW_MESSAGE")}
    </Typography>
  ) : (
    <></>
  );
};

export default memo(ScrollTooltip);
