import { AttachmentUtil, FileUtil, isImage, StorageUtil } from "utils";
import { getFileFromTus } from "./multiple-file";
import { AppConstant, SystemConstant } from "const";
import { getInteractor } from "./local.service";
import { getFileList } from "pubsub/services/file.service";

const OPTION_ARGUMENT = { groupId: null, isCurrentDevice: false };
export const AttachmentQueue = {};
export const ThumbnailUrlCache = {};
export const getFileLocalDetails = (prefixKey, fileInfo, option = OPTION_ARGUMENT) => {
  const attachmentId = fileInfo.attachmentId || fileInfo.fileId || fileInfo.id;

  if (AttachmentQueue[attachmentId]) {
    const checkingPromise = AttachmentQueue[attachmentId];

    if (checkingPromise.isFulfilled() && false === Boolean(AttachmentQueue[attachmentId].mediaPath)) {
      AttachmentQueue[attachmentId] = makeQueryablePromise(getAttachment(prefixKey, fileInfo, option));
    }
  } else {
    AttachmentQueue[attachmentId] = makeQueryablePromise(getAttachment(prefixKey, fileInfo, option));
  }

  return AttachmentQueue[attachmentId];
};

export const getAttachment = async (prefixKey, fileInfo, option = OPTION_ARGUMENT) => {
  const metaData = fileInfo.metaData;
  const fileName = metaData.fileName;
  const attachmentId = fileInfo.attachmentId;
  const mediaPath = await getFilePath(prefixKey, fileInfo, option);

  return {
    attachmentId: attachmentId,
    mediaPath: mediaPath,
    fileName: fileName,
    size: metaData.size,
    mimeType: metaData.mimeType,
    status: Boolean(mediaPath) ? SystemConstant.UPLOAD_STATUS.success : SystemConstant.UPLOAD_STATUS.failed,
  };
};

const handleGetFileDetails = async (prefixKey, attachmentId, groupId) => {
  let fileRecord = await getInteractor(prefixKey).LocalFileService.get(attachmentId);

  // Get file info if not existed in local db
  if (!Boolean(fileRecord && fileRecord.id)) {
    const remoteList = (await getFileList(prefixKey, [attachmentId], groupId)) || [];
    fileRecord = remoteList[0];
  }
  if (false === Boolean(fileRecord && fileRecord.id)) return {};

  // If file.status === inProgress for more than 15 minutes => update status = fail in localDB
  const uploadTime = Date.now() - fileRecord.created;
  if (fileRecord.status === SystemConstant.UPLOAD_STATUS.inProgress && uploadTime >= AppConstant.CHECK_UPLOAD_TIMEOUT) {
    await getInteractor(prefixKey).LocalFileService.update(
      { status: SystemConstant.UPLOAD_STATUS.failed, modified: Date.now() },
      { id: attachmentId },
    );

    fileRecord.status = SystemConstant.UPLOAD_STATUS.failed;
  }

  return fileRecord;
};

// Create url with protocol electron
// Synch file from tus if not exist
export const getFilePath = async (prefixKey, fileInfo, option = OPTION_ARGUMENT) => {
  const metaData = fileInfo.metaData;
  const fileName = metaData.fileName;
  const attachmentId = fileInfo.attachmentId;
  const { groupId, isCurrentDevice } = option || {};

  let mediaPath = "";
  let isGetFileSuccess = false;
  let fileRecord = await handleGetFileDetails(prefixKey, attachmentId, groupId);

  try {
    const isFileUploadSuccess = fileRecord.status === SystemConstant.UPLOAD_STATUS.success;
    if (isFileUploadSuccess) {
      const isGetFileLocalSuccess = AttachmentUtil.exitsLocalFile(prefixKey, attachmentId, fileName);

      const isExistedFile = fileRecord && isGetFileLocalSuccess;
      if (isExistedFile) {
        isGetFileSuccess = true;
      } else {
        const fileRecordRemote = await getFileFromTus(prefixKey, fileInfo, groupId);
        fileRecord = fileRecordRemote || fileRecord;
        if (fileRecordRemote) {
          isGetFileSuccess = true;
        } else {
          isGetFileSuccess = false;
        }
      }
    }
    if (isGetFileSuccess || isCurrentDevice) {
      mediaPath = AttachmentUtil.getWebSrc(fileRecord?.url, fileName);
    }
  } catch (error) {
    console.log("get file local path error: ", error);
  }

  return mediaPath;
};

export const getFileLocalPath = async (prefixKey, fileInfo, options) => {
  const fileDetails = await getFileLocalDetails(prefixKey, fileInfo, options);
  if (fileDetails) return fileDetails.mediaPath;

  return "";
};

function makeQueryablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isFulfilled) return promise;

  // Set initial state
  let isPending = true;
  let isRejected = false;
  let isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  let result = promise.then(
    function (v) {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    function (e) {
      isRejected = true;
      isPending = false;
      throw e;
    },
  );

  result.isFulfilled = function () {
    return isFulfilled;
  };
  result.isPending = function () {
    return isPending;
  };
  result.isRejected = function () {
    return isRejected;
  };
  return result;
}

/**
 *
 * @param {*} fileUrl: File local path
 * @param {*} file : { attachmentId, metaData, groupId }
 * @returns
 */
export const getThumbnailUrlCache = (file, fileUrl) => {
  const { attachmentId } = file;

  if (ThumbnailUrlCache[attachmentId]) {
    const checkingPromise = ThumbnailUrlCache[attachmentId];

    if (checkingPromise.isFulfilled() && false === Boolean(ThumbnailUrlCache[attachmentId].mediaPath)) {
      ThumbnailUrlCache[attachmentId] = makeQueryablePromise(getSmallMediaUrl(fileUrl, file));
    }
  } else {
    ThumbnailUrlCache[attachmentId] = makeQueryablePromise(getSmallMediaUrl(fileUrl, file));
  }

  return ThumbnailUrlCache[attachmentId];
};

const getSmallMediaUrl = async (fileUrl, file, compressOptions) => {
  const { attachmentId, metaData, groupId } = file;

  let mediaPath = "";

  if (false === Boolean(fileUrl)) {
    const fileRecord = await handleGetFileDetails(StorageUtil.getCurrentPrefixKey(), attachmentId, groupId);
    if (false === Boolean(fileRecord && fileRecord.id)) return mediaPath;
    fileUrl = AttachmentUtil.getLocalPath(fileRecord.url, metaData.fileName);
  }

  const isImageUrl = isImage(metaData?.mimeType);

  if (isImageUrl) {
    mediaPath = await FileUtil.getUrlFileByPath(fileUrl, metaData.mimeType, { width: 150 });
  } else {
    try {
      const blobUrl = await FileUtil.getUrlFileByPath(fileUrl, metaData.mimeType, { width: 150 });
      const responseBlob = await FileUtil.getVideoThumbnailBlob(blobUrl);

      if (responseBlob && responseBlob.size > 0) {
        const urlTemporary = await FileUtil.getUrlTemporary(responseBlob, compressOptions);
        if (urlTemporary) {
          mediaPath = urlTemporary;
        }
      }
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log("get video url error: ", error);
    }
  }

  return { mediaPath };
};
