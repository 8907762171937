import { KeyConstant } from "const";
import parsePhoneNumber from "libphonenumber-js";
import { isEqual } from "lodash";
import { camelizeKeys, decamelizeKeys } from "humps";

// Electron util
const ElectronCommonUtil = window.electronUtils.common;
export const StorageUtil = {
  ...window.electronUtils.storage,
  setCommonKey: window.electronUtils.storage.setCommonKey,
  getCommonKey: window.electronUtils.storage.getCommonKey,
  removeCommonKey: window.electronUtils.storage.removeCommonKey,
  setItem: window.electronUtils.storage.setItem,
  getItem: window.electronUtils.storage.getItem,
  removeItem: window.electronUtils.storage.removeItem,
  getAllStoreKeys: window.electronUtils.storage.getAllStoreKeys,
  getCurrentPrefixKey: window.electronUtils.storage.getCurrentPrefixKey,
};
export const CryptoUtil = window.electronUtils.crypto;
export const FileUtil = window.electronUtils.file;
export const AttachmentUtil = window.electronUtils.attachment;
export const MultipleFileUtil = window.electronUtils.multipleFile;

export const uuid = ElectronCommonUtil.uuid;
export const highlightString = ElectronCommonUtil.highlightString;
export const removeVietnameseTones = str => ElectronCommonUtil.removeVietnameseTones(str);
export const isJSONString = str => ElectronCommonUtil.isJSONString(str);
export const convertString2JSON = (str, defaultValue) => ElectronCommonUtil.convertString2JSON(str, defaultValue);
export const convertJSONObject = (str, defaultValue) => ElectronCommonUtil.convertString2JSON(str, defaultValue, true);
export const getAllIndexOfKeyword = (keyword, string) => ElectronCommonUtil.getAllIndexOfKeyword(keyword, string);
export const getPrefixKey = (accountId, branchId) => ElectronCommonUtil.getPrefixKey(accountId, branchId);
export const getDataFromPrefixKey = prefixKey => ElectronCommonUtil.getDataFromPrefixKey(prefixKey);
export const textNormalize = str => ElectronCommonUtil.removeVietnameseTones(str).toLowerCase();

export const clearAllStorage = () => {
  // Not clear this keys because they only be created once time
  const exceptKeys = [
    KeyConstant.KEY_IDENTITY_PUBLIC_KEY,
    KeyConstant.KEY_IDENTITY_PRIVATE_KEY,
    KeyConstant.KEY_ACTIVE_SIGNED_PREKEY_ID,
    KeyConstant.KEY_NEXT_SIGNED_PREKEY_ID,
    KeyConstant.KEY_NEXT_PREKEY_ID,
    KeyConstant.KEY_IS_VALID_KEYS,
    KeyConstant.KEY_DEFAULT_BRANCH,
    KeyConstant.KEY_MAC,
    KeyConstant.KEY_DEVICE_TYPE,
  ];

  const storageAllKey = StorageUtil.getAllStoreKeys();
  storageAllKey.forEach(item => {
    if (false === exceptKeys.includes(item)) {
      StorageUtil.removeCommonKey(item);
    }
  });

  window.location.reload();
};

export const toCamel = obj => (obj ? camelizeKeys(obj) : {});

export const toSnake = obj => decamelizeKeys(obj) || {};

export const isArray = array => array && Array.isArray(array);

export const isString = str => typeof str === "string";

export const formatPagination = data => {
  return {
    paging: data?.paging || 1,
    page: data?.page || 1,
    size: data?.size || 10,
    asc: data?.asc || 1,
  };
};

export const createParams = data => {
  let defaultParam = formatPagination();
  return {
    ...defaultParam,
    ...data,
  };
};

export const validatePhone = content => {
  const phoneNumber = parsePhoneNumber(content, "VN");
  return (
    phoneNumber &&
    phoneNumber.isValid() &&
    phoneNumber.nationalNumber.length >= 9 &&
    phoneNumber.nationalNumber.length <= 10
  );
};

export const validateEmail = content => {
  /* eslint-disable no-useless-escape */
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return content && regex.test(String(content).replace(/\s+/g, "").toLowerCase());
};

export const objectToJSON = data => JSON.stringify(data);

export const convertHex2rgba = (hex, alpha = 1) => {
  try {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  } catch (error) {
    return "";
  }
};

export const formatLimitNumber = number => {
  if (!number) return 0;

  let result = "";
  if (0 <= number && number < 10) result = "0" + number;
  if (10 <= number && number < 100) result = `${number}`;
  if (number > 99) result = "99+";

  return result;
};

export const isVideo = type => type && type.includes("video");

export const isAudio = type => type && type.includes("audio");

export const isImage = type => type && type.includes("image");

export const removeDuplicateInArray = arr => {
  const tmpArray = new Set(arr);
  return [...tmpArray];
};

export const isArrayNotEquals = (firstArray, secondArray) =>
  !firstArray ||
  !secondArray ||
  (Array.isArray(firstArray) && JSON.stringify(firstArray) !== JSON.stringify(secondArray));

export const isNotEqual = (firstObject, secondObject) => !isEqual(firstObject, secondObject);

export const addUrlParam = (basePath, key, val) => {
  let newParam = key + "=" + val;
  let queryParams = "?" + newParam;

  // If the "search" string exists, then build params from it
  if (basePath && basePath.includes("?")) {
    // Try to replace an exist instance
    queryParams = queryParams.replaceAll("?", "&");
    // If nothing was replaced, then add the new param to the end
    if (queryParams === basePath) {
      queryParams += "&" + newParam;
    }
  }

  return basePath + queryParams;
};

export const deepCloneJsonObject = json => {
  if (!json) return null;
  return JSON.parse(JSON.stringify(json));
};

export const formatStringWithKeyword = (keyword, string) => {
  let result = "";
  let tmpContent = string.toLowerCase();
  let tmpKeyword = keyword.toLowerCase();
  let keywordIndexArr = getAllIndexOfKeyword(tmpKeyword, tmpContent);
  if (string.length > 100 && keywordIndexArr.length > 1) {
    result = `${string.substr(0, 32)} ... ${string.substr(-32)}`;
  } else if (string.length > 100 && keywordIndexArr.length === 1) {
    let index = tmpContent.indexOf(tmpKeyword) > 32 ? 32 : tmpContent.indexOf(tmpKeyword);
    let lastIndex =
      string.length - tmpContent.indexOf(tmpKeyword) - keyword.length > 32
        ? 32
        : string.length - tmpContent.indexOf(tmpKeyword) - keyword.length - 12;
    result = `${string.substr(0, index)} ... ${keyword} ... ${string.substr(0 - lastIndex)}`;
  } else {
    result = string;
  }

  return result;
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(84|)?(\d{9})$/);
  if (match) {
    return "(+" + match[1] + ")" + match[2];
  }
  return null;
};

export const formatEmoij = hex => {
  let hexArray = hex.split(/[ \-]/gm);

  return hexArray.join("");
};

export const convertHexToEmoji = hex => {
  let rawHexArray = [];
  try {
    const hexArray = hex.split(";").filter(value => Boolean(value?.trim()));
    rawHexArray = hexArray.map(h => h.replace(/(&#x)+/gm, "0x"));

    return rawHexArray.length === 2
      ? String.fromCodePoint(rawHexArray[0], rawHexArray[1])
      : String.fromCodePoint(rawHexArray[0]);
  } catch (error) {
    console.log(rawHexArray, error);
  }
  return "";
};

export const swapArrayElements = (array, firstElementIndex, secondElementIndex) => {
  let tmpArray = [...array];
  let temp = tmpArray[firstElementIndex];
  tmpArray[firstElementIndex] = tmpArray[secondElementIndex];
  tmpArray[secondElementIndex] = temp;

  return tmpArray;
};

let newReg = `(http|https){1}://([-a-zA-Z0-9@:%_\+.~#?&//=]*)`;
export const isExternalLink = data => {
  const regex = new RegExp(newReg);
  return regex.test(data);
};

export const getExternalLinkFromString = data => {
  if (data) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urlArray = data.match(urlRegex);

    return urlArray || [];
  }

  return [];
};

export const isOnlyLink = content => {
  const arrayLink = getExternalLinkFromString(content);
  if (arrayLink.length === 1) {
    const linkContent = arrayLink[0];
    const contentWithoutLink = content.replace(linkContent, "").trim();

    return !Boolean(contentWithoutLink);
  }

  return false;
};

export const getAllLetterIndex = (string, letter) => {
  if (string.length === 0 || !letter) return [];

  let result = [];
  for (let index = 0; index < string.length; index++) {
    if (string[index] === letter) result.push(index);
  }

  return result;
};

export const getFirstLetter = text => (text || "").trim().charAt(0);

export const groupByByKey = (key, array) => {
  let list = array.filter(obj => {
    return obj[key];
  });
  let map = {};
  list.forEach(obj => {
    if (!map[obj[key]]) {
      map[obj[key]] = [];
    }
    map[obj[key]].push(obj);
  });
  return map;
};
