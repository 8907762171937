import { Box } from "@mui/material";
import { useState } from "react";

const withDrawerLayout = (DrawerComponent, TriggerComponent) => {
  return props => {
    const [isShowDrawer, setIsShowDrawer] = useState(false);

    const onCloseDrawer = () => setIsShowDrawer(false);
    const onShowDrawer = () => setIsShowDrawer(true);

    return (
      <>
        <Box onClick={onShowDrawer}>{TriggerComponent}</Box>

        {isShowDrawer && <DrawerComponent open onClose={onCloseDrawer} {...props} />}
      </>
    );
  };
};

export default withDrawerLayout;
