import MigrateVersion48 from "./MigrateVersion48";
import MigrateVersion51 from "./MigrateVersion51";

const HandlingMigrateVersion = () => {
  return (
    <>
      <MigrateVersion48 />
      <MigrateVersion51 />
    </>
  );
};
export default HandlingMigrateVersion;
