import { EventState, ExecuteEvent } from "./PubSub.const";
import { EventDto } from "./dto";

const LIMIT_TO_INCREASE_RETRY = 5000;
export const updateRetryEvent = (eventItem: EventDto, limitRetry: number): EventDto | null => {
  if (navigator.onLine && Date.now() - (eventItem.modified || eventItem.created) > LIMIT_TO_INCREASE_RETRY) {
    eventItem.retry = (eventItem.retry || 0) + 1;
    if (eventItem.retry >= limitRetry) eventItem.state = EventState.FAIL;
    eventItem.modified = Date.now();
    return eventItem;
  }

  return null;
};

export const refactorSavingEvent = (
  eventItem: EventDto,
  executeEventValue: ExecuteEvent,
  limitRetry: number,
): EventDto | null => {
  switch (executeEventValue) {
    case ExecuteEvent.SUCCESSES:
      eventItem.state = EventState.SUCCESSES;
      break;

    case ExecuteEvent.FAIL:
      eventItem.state = EventState.FAIL;
      break;

    case ExecuteEvent.RETRY:
      const retryEvent = updateRetryEvent(eventItem, limitRetry);
      if (retryEvent) {
        eventItem = retryEvent;
      } else return null;
      break;

    default:
      break;
  }

  eventItem.modified = Date.now();
  return eventItem;
};
