import React, { useState, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Box, Input, FormLabel, FormControlLabel, Button, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { validateEmail } from "utils";
import { useTranslation } from "react-i18next";
import { FormatConstant, LangConstant } from "const";
import { format, isValid } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns";
import { ProfileActions } from "redux-store";
import { isEqual } from "lodash";
import { formatBirthday } from "utils/date.utils";

const EditProfile = ({ profile, onChange }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const profileObjEditProfile = getLabel(LangConstant.OBJ_EDIT_PROFILE, { returnObjects: true });

  const isUpdateUserInfoSuccess = useSelector(state => state.profileRedux.isUpdateUserInfoSuccess);

  const [form, setForm] = useState(profile || INIT_FORM);
  const [birthday, setBirthday] = useState(profile?.birthday ? new Date(formatBirthday(profile.birthday)) : null);
  const [errorStatus, setErrorStatus] = useState(INIT_ERROR_STATUS);

  const onChangeInput = e => {
    let name = e.target.id;
    setForm(currentForm => ({
      ...currentForm,
      [name]: e.target.value,
    }));

    setErrorStatus(currentState => ({
      ...currentState,
      [name]: false,
    }));
  };

  const onChangeBirthDay = value => {
    const isValidDate = value && (isValid(parseISO(value)) || isValid(value));
    const strBirthday = isValidDate
      ? format(isValid(value) ? value : parseISO(value), FormatConstant.FM_DD_MM_YYYY).toString()
      : "";

    setBirthday(value);
    setForm(currentForm => ({
      ...currentForm,
      birthday: strBirthday,
    }));
  };

  const onFocusInput = e => {
    setErrorStatus(currentState => ({ ...currentState, [e.target.id]: false }));
  };

  const onSubmitForm = e => {
    e.preventDefault();
    if (isInputValidated()) {
      dispatch(ProfileActions.updateProfileInfo(form));
    }
  };

  useEffect(() => {
    if (isUpdateUserInfoSuccess) {
      onChange(form);
    }
  }, [isUpdateUserInfoSuccess]);

  const isInputValidated = () => {
    let isValidated = true;

    if (!form.name) {
      isValidated = false;
      setErrorStatus(currentState => ({ ...currentState, name: true }));
    }
    if (form.mail && !validateEmail(form.mail)) {
      isValidated = false;
      setErrorStatus(currentState => ({ ...currentState, mail: true }));
    }

    return isValidated;
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={onSubmitForm}>
        <FormControlLabel
          labelPlacement="top"
          className={classes.formControlLabel}
          control={
            <Input
              fullWidth
              disableUnderline
              id="name"
              onChange={onChangeInput}
              onFocus={onFocusInput}
              placeholder={profileObjEditProfile.enterFullName}
              value={form.name ? form.name : ""}
              classes={{
                root: clsx(classes.inputWrapper, {
                  [classes.errorInputWrapper]: errorStatus.name,
                }),
                input: clsx(classes.input, {
                  [classes.errorInput]: errorStatus.name,
                }),
              }}
            />
          }
          label={
            <FormLabel classes={{ root: classes.formLabel, asterisk: classes.asterisk }}>
              {profileObjEditProfile.fullName}
            </FormLabel>
          }
        />
        <FormControlLabel
          labelPlacement="top"
          className={classes.formControlLabel}
          control={
            <Input
              fullWidth
              disableUnderline
              id="mail"
              onChange={onChangeInput}
              onFocus={onFocusInput}
              placeholder={profileObjEditProfile.enterEmail}
              value={form.mail ? form.mail : ""}
              classes={{
                root: clsx(classes.inputWrapper, {
                  [classes.errorInputWrapper]: errorStatus.mail,
                }),
                input: clsx(classes.input, {
                  [classes.errorInput]: errorStatus.mail,
                }),
              }}
            />
          }
          label={
            <FormLabel classes={{ root: classes.formLabel, asterisk: classes.asterisk }}>
              {profileObjEditProfile.email}
            </FormLabel>
          }
        />
        <FormControlLabel
          labelPlacement="top"
          className={classes.formControlLabel}
          control={
            <Input
              fullWidth
              disableUnderline
              id="address"
              name="address"
              onChange={onChangeInput}
              onFocus={onFocusInput}
              placeholder={profileObjEditProfile.enterAddress}
              value={form.address ? form.address : ""}
              classes={{
                root: classes.inputWrapper,
                input: classes.input,
              }}
            />
          }
          label={
            <FormLabel classes={{ root: classes.formLabel, asterisk: classes.asterisk }}>
              {profileObjEditProfile.address}
            </FormLabel>
          }
        />
        <FormControlLabel
          labelPlacement="top"
          className={classes.formControlLabel}
          control={
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={classes.birthdayPicker}
                id="birthday"
                openTo="year"
                format={FormatConstant.FM_DD_MM_YYYY}
                onChange={onChangeBirthDay}
                onError={() => {
                  setErrorStatus(state => ({
                    ...state,
                    birthday: true,
                  }));
                }}
                value={birthday}
              />
            </LocalizationProvider>
          }
          label={
            <FormLabel
              classes={{
                root: classes.formLabel,
                asterisk: classes.asterisk,
              }}
            >
              {profileObjEditProfile.birthday}
            </FormLabel>
          }
        />

        <FormControlLabel
          labelPlacement="top"
          className={classes.formControlLabel}
          control={
            <Select
              className={classes.genderInput}
              onChange={event => {
                let value = event.target.value;
                setForm(state => ({
                  ...state,
                  gender: value,
                }));
              }}
              value={form.gender}
            >
              <MenuItem value={1}>{profileObjEditProfile.male}</MenuItem>
              <MenuItem value={2}>{profileObjEditProfile.female}</MenuItem>
              <MenuItem value={3}>{profileObjEditProfile.other}</MenuItem>
            </Select>
          }
          label={
            <FormLabel classes={{ root: classes.formLabel, asterisk: classes.asterisk }}>
              {profileObjEditProfile.gender}
            </FormLabel>
          }
        />

        <Button
          className={classes.buttonSave}
          disabled={isEqual(form, profile)}
          fullWidth
          variant="contained"
          type="submit"
        >
          {profileObjEditProfile.save}
        </Button>
      </form>
    </Box>
  );
};

export default memo(EditProfile);

const INIT_FORM = {
  name: "",
  status: "",
  state: "",
  fullName: "",
  phone: "",
  mail: "",
  address: "",
  birthday: "",
  avatarId: "",
  gender: "",
};

const INIT_ERROR_STATUS = {
  name: false,
  mail: false,
  birthday: false,
  avatar_id: false,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: 14,
  },

  formControlLabel: {
    width: "100%",
    alignItems: "flex-start",
    margin: 0,
  },

  inputWrapper: {
    padding: 12,
    borderRadius: 8,
    border: "1px solid #D2D6DA",
    marginBottom: 16,
  },

  input: {
    fontSize: 15,
    fontWeight: 400,
    padding: 0,
    color: theme.palette.black,
  },

  errorInputWrapper: {
    border: "1px solid red",
  },

  errorInput: {
    color: "#EA403F",
    "&::placeholder": {
      color: "#495057",
    },
  },

  formLabel: {
    display: "inline-block",
    fontWeight: 700,
    fontSize: 15,
    color: theme.palette.black,
    paddingBottom: 8,
  },

  asterisk: {
    color: "#EA403F",
  },

  boxSave: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  buttonSave: {
    backgroundColor: "#008FE8",
    height: "48px",
    margin: "auto",
    color: "white",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    transition: "1s ease-in-out",
    "&:hover": {
      backgroundColor: "#008FE8",
    },
  },

  buttonSaveSmall: {
    backgroundColor: "#008FE8",
    height: "48px",
    margin: "auto",
    color: "white",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    transition: "1s ease-in-out",
    padding: "6px 10px",
    "&:hover": {
      backgroundColor: "#008FE8",
    },
  },

  birthdayPicker: {
    width: "100%",
    borderRadius: 8,
    border: "1px solid #D2D6DA",
    marginBottom: 20,
    "&>*": {
      border: "none",
      "&>input": {
        padding: 12,
      },
      "&>*": {
        border: "none",
      },
    },
  },

  successIcon: {
    color: "#19e31c",
    fontSize: 40,
    transition: "opacity 1s ease-in-out",
    opacity: 1,
    width: 50,
  },

  loadingIcon: {
    transition: "opacity 1.5s ease-in-out",
    opacity: 1,
    width: 50,
    fontSize: 40,
  },

  loadingIconHidden: {
    opacity: 0,
    width: "0px !important",
  },

  successIconHidden: {
    opacity: 0,
    width: "0px !important",
  },

  failedIcon: {
    transition: "opacity 1.5s ease-in-out",
    opacity: 1,
    width: 50,
    fontSize: 40,
    color: "red",
  },

  failedIconHidden: {
    opacity: 0,
    width: "0px !important",
  },

  genderInput: {
    width: "100%",
    borderRadius: 8,
    marginBottom: 20,
    "&>div": {
      padding: 12,
    },
  },
}));
