import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const DeleteIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 14 16`}>
      <path
        d="M2.83325 15.5C2.37492 15.5 1.98256 15.3368 1.65617 15.0104C1.32978 14.684 1.16659 14.2917 1.16659 13.8333V3H0.333252V1.33333H4.49992V0.5H9.49992V1.33333H13.6666V3H12.8333V13.8333C12.8333 14.2917 12.6701 14.684 12.3437 15.0104C12.0173 15.3368 11.6249 15.5 11.1666 15.5H2.83325ZM11.1666 3H2.83325V13.8333H11.1666V3ZM4.49992 12.1667H6.16658V4.66667H4.49992V12.1667ZM7.83325 12.1667H9.49992V4.66667H7.83325V12.1667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
DeleteIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
DeleteIcon.defaultProps = {
  width: 36,
  height: 36,
};
export default memo(DeleteIcon);
