import { forwardRef, useRef } from "react";
import { Avatar, Box, CardMedia, ClickAwayListener, Stack, styled, Typography } from "@mui/material";
import { useMediaUrl } from "hooks";
import PreviewImageWithAction from "./PreviewImageWithAction";
import { isVideo } from "utils";
import { Error, PlayArrow } from "@mui/icons-material";
import { useAlertContext } from "./context/AlertContext";
import { getCommonLang } from "utils/lang.utils";
import { ImageIcon } from "components/icons";
import clsx from "clsx";

const withControlMedia = WrapComponent => {
  return props => {
    const mediaRef = useRef(null);

    const handleClickAway = () => {
      mediaRef.current.pause();
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <WrapComponent ref={mediaRef} {...props} />
      </ClickAwayListener>
    );
  };
};

export const AppAudio = withControlMedia(
  forwardRef((props, ref) => {
    return <audio ref={ref} {...props} />;
  }),
);

export const AppVideo = withControlMedia(
  forwardRef((props, ref) => {
    return <video ref={ref} {...props} />;
  }),
);

export const ImageMedia = ({ file, isThumbnail, isImageAction, isCurrentDevice, ...otherProps }) => {
  const { width, height } = otherProps;
  const { mediaUrl, isFailedLoading, isLoading } = useMediaUrl(file, { isThumbnail, width, height, isCurrentDevice });

  if (isLoading) return <LoadingMedia />;
  if (isFailedLoading) return <ErrorLoadMedia backgroundSrc={mediaUrl} {...otherProps} />;
  return isImageAction ? (
    <PreviewImageWithAction src={mediaUrl} alt={file?.metaData?.fileName} />
  ) : (
    <CardMedia component="img" src={mediaUrl} alt={file?.metaData?.fileName} {...otherProps} />
  );
};

export const VideoMedia = ({ file, isThumbnail, isCurrentDevice, ...otherProps }) => {
  const isVideoUrl = isVideo(file?.metaData?.mimeType);
  const { width, height } = otherProps;
  const { mediaUrl, isFailedLoading, isLoading } = useMediaUrl(file, { isThumbnail, width, height, isCurrentDevice });

  if (isLoading) return <LoadingMedia />;
  if (isFailedLoading) return <ErrorLoadMedia backgroundSrc={mediaUrl} {...otherProps} />;
  return (
    <VideoMediaWrap {...otherProps}>
      {isThumbnail ? (
        <>
          <CardMedia component="img" src={mediaUrl} alt={file?.metaData?.fileName} />
          {isVideoUrl && (
            <PlayIcon>
              <PlayArrow />
            </PlayIcon>
          )}
        </>
      ) : (
        <AppVideo src={mediaUrl} controls />
      )}
    </VideoMediaWrap>
  );
};

const ErrorLoadMedia = ({ backgroundSrc, ...otherProps }) => {
  const { showAlert } = useAlertContext();

  const handleClickError = () => {
    showAlert({ content: getCommonLang("ERR_IMAGE_FAILED_LOADING"), alertProps: { severity: "error" } });
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      backgroundColor="#CBCBCB"
      sx={{ minHeight: 150, minWidth: 150 }}
      {...otherProps}
      className="custom-error"
      onClick={handleClickError}
    >
      {backgroundSrc ? (
        <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
          <CardMedia
            component="img"
            src={backgroundSrc}
            sx={{ opacity: 0.5, width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Error color="error" sx={{ position: "absolute" }} />
        </Stack>
      ) : (
        <>
          <ImageIcon />
          <Typography color="text.secondary">{getCommonLang("TXT_IMAGE_ERROR")}</Typography>
        </>
      )}
    </Stack>
  );
};

export const LoadingMedia = ({ backgroundSrc, classes }) => {
  return (
    <Box
      sx={{ width: "100%", height: "100%", minWidth: 150, minHeight: 150, backgroundColor: "#BEBEBE" }}
      className={clsx("flex-center", "custom-loading", classes?.root)}
    >
      <Box className={clsx("loader", classes?.loading)} sx={{ position: "absolute" }} />
      {backgroundSrc && (
        <CardMedia component="img" src={backgroundSrc} sx={{ opacity: 0.5 }} className={classes?.background} />
      )}
    </Box>
  );
};

const VideoMediaWrap = styled(Box)({
  position: "relative",
  width: "100%",
  height: "100%",
  textAlign: "center",

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  "& video": {
    width: "100%",
    height: "100%",
  },
});

const PlayIcon = styled(Avatar)({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)",
  width: 50,
  height: 50,
  background: "rgba(0, 0, 0, 0.4)",
  backdropFilter: "blur(10px)",
});
