import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const ContactIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78471 19.5623H19.2163C20.7515 19.5623 22.0005 18.4057 22.0005 16.9841V3.57788C22.0005 2.1563 20.7515 0.999756 19.2163 0.999756H4.78471C3.24949 0.999756 2.00049 2.1563 2.00049 3.57788V20.4216C2.00049 21.8432 3.24949 22.9998 4.78471 22.9998H18.2882H19.2163C20.7515 22.9998 22.0005 21.8432 22.0005 20.4216V20.4195C21.2244 20.9602 20.2601 21.281 19.2163 21.281H18.2882H4.78471C4.27297 21.281 3.85664 20.8955 3.85664 20.4216C3.85664 19.9478 4.27297 19.5623 4.78471 19.5623ZM3.85693 3.57788C3.85693 3.10402 4.27327 2.71851 4.78501 2.71851H19.2166C19.7283 2.71851 20.1446 3.10402 20.1446 3.57788V16.9841C20.1446 17.458 19.7283 17.8435 19.2166 17.8435H4.78501C4.45976 17.8435 4.14733 17.8954 3.85693 17.9908V3.57788ZM11.9313 11.656C14.0038 11.656 15.69 13.2173 15.69 15.1365H17.5461C17.5461 13.1031 16.2788 11.3389 14.4373 10.4848C14.8989 9.95969 15.1762 9.29049 15.1762 8.56226C15.1762 6.88007 13.6982 5.51147 11.8816 5.51147C10.0649 5.51147 8.58689 6.88007 8.58689 8.56226C8.58689 9.30583 8.87599 9.98783 9.35515 10.5177C7.55163 11.3837 6.31641 13.129 6.31641 15.1365H8.17255C8.17255 13.2173 9.85873 11.656 11.9313 11.656ZM11.8814 7.23022C12.6746 7.23022 13.3199 7.82779 13.3199 8.56226C13.3199 9.29672 12.6746 9.89429 11.8814 9.89429C11.0882 9.89429 10.4429 9.29672 10.4429 8.56226C10.4429 7.82779 11.0882 7.23022 11.8814 7.23022Z"
      />
    </SvgIcon>
  );
};

ContactIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
ContactIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(ContactIcon);
