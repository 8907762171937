import React from "react";
import PropTypes from "prop-types";
import { ImageConstant, SystemConstant } from "const";
import { Android, Apple, DeviceUnknown } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Device = ({ type, ...otherProps }) => {
  const classes = useStyles();

  const DeviceComponent = () => {
    switch (type) {
      case SystemConstant.DEVICE_TYPE.android:
        return <Android sx={{ fontSize: "32px" }} />;
      case SystemConstant.DEVICE_TYPE.mac:
      case SystemConstant.DEVICE_TYPE.ios:
        return <Apple sx={{ fontSize: "34px" }} />;
      case SystemConstant.DEVICE_TYPE.window:
        return <img src={ImageConstant.DeviceWindowImage} alt="window logo" loading="lazy" />;
      case SystemConstant.DEVICE_TYPE.linux:
        return <img src={ImageConstant.DeviceLinuxImage} alt="linux logo" loading="lazy" />;
      default:
        return <DeviceUnknown sx={{ fontSize: "28px" }} />;
    }
  };

  return (
    <Avatar className={classes.root} {...otherProps}>
      <DeviceComponent />
    </Avatar>
  );
};

Device.propTypes = {
  type: PropTypes.number,
};

export default Device;

const useStyles = makeStyles({
  root: {
    width: 50,
    height: 50,
    objectFit: "contain",
    background: "#508DE3",
    borderRadius: "50%",
  },
});
