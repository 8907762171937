import { useEffect } from "react";

const useScrollEvent = ({ ref, onScrollRightEnd, onScrollLeftEnd }) => {
  const handleScroll = () => {
    const isAtEnd = ref.scrollLeft + ref.offsetWidth >= ref.scrollWidth;
    if (isAtEnd && onScrollRightEnd) {
      onScrollRightEnd();
    }

    if (ref.scrollLeft === 0 && onScrollLeftEnd) {
      onScrollLeftEnd();
    }
  };

  useEffect(() => {
    if (!ref) {
      return;
    }

    if (onScrollRightEnd || onScrollLeftEnd) {
      ref.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (onScrollRightEnd || onScrollLeftEnd) {
        ref.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, onScrollRightEnd, onScrollLeftEnd]);
};

export default useScrollEvent;
