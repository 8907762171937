import { useEffect, useState } from "react";
import { ApiConstant, KeyConstant } from "const";
import NoticeDialog from "./dialog/NoticeDialog";
import { useTranslation } from "react-i18next";
import { GlobalApi } from "services";
import { StorageUtil } from "utils";
import { Typography } from "@mui/material";

let serverTimeout;
const HandleTimezoneWarning = () => {
  const { t: getLabel } = useTranslation();

  const [differentTime, setDifferentTime] = useState(0);

  const handleGetServerTime = async () => {
    const response = await GlobalApi.get(ApiConstant.GET_SERVER_TIME);
    if (response.status === ApiConstant.STT_OK && response.data.data?.timestamp) {
      const serverTime = response.data.data.timestamp;
      const clientTime = Date.now();
      const milliseconds = Math.abs(serverTime - clientTime);

      setDifferentTime(milliseconds);
    } else if (!serverTimeout) {
      serverTimeout = setTimeout(() => handleGetServerTime(), 60000); // Re-execute after 1 minute
    }
  };

  useEffect(() => {
    if (window.env?.CHECK_SERVER_TIME === "true") {
      handleGetServerTime();

      return () => {
        if (serverTimeout) clearTimeout(serverTimeout);
      };
    }
  }, []);

  const waringContent = getLabel("FM_DIFFERENT_TIME_SERVER", {
    deviceName: StorageUtil.getItem(KeyConstant.KEY_DEVICE_NAME) || "",
    second: Math.round(differentTime / 1000),
  });

  return (
    <NoticeDialog
      open={differentTime >= window.env?.DIFFERENT_TIME_WARNING}
      title={getLabel("WARNING_TITLE")}
      content={<Typography color="inherit" dangerouslySetInnerHTML={{ __html: waringContent }} />}
      submitProps={{ onClick: () => setDifferentTime(0) }}
      maxWidth="xs"
    />
  );
};

HandleTimezoneWarning.propTypes = {};

HandleTimezoneWarning.defaultProps = {};

export default HandleTimezoneWarning;
