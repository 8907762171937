import Entity from "./Entity";

interface IErrorMessageSendNull {
  id: string;
  type: number;
  device_id: string;
  group_id: string;
  retry: number;
  state: number;
  options: number;
  created: number;
  modified: number;
  branch_id: string;
}

export default class ErrorMessageSendNull extends Entity implements IErrorMessageSendNull {
  public constructor({
    id,
    type,
    device_id,
    group_id,
    retry,
    state,
    options,
    created,
    modified,
    branch_id,
  }: IErrorMessageSendNull) {
    super();
    this.id = id;
    this.type = type;
    this.device_id = device_id;
    this.group_id = group_id;
    this.retry = retry;
    this.state = state;
    this.options = options;
    this.created = created;
    this.modified = modified;
    this.branch_id = branch_id;
  }

  branch_id: string;
  created: number;
  device_id: string;
  group_id: string;
  id: string;
  modified: number;
  options: number;
  retry: number;
  type: number;
  state: number;
}
