import React, { memo } from "react";
import PropTypes from "prop-types";

const ForwardingIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0432 4.77576L15.7841 7.99146C17.2614 9.26132 18 9.89626 18 10.7142C18 11.5322 17.2614 12.1671 15.7841 13.4369L12.0432 16.6527C11.3689 17.2323 11.0318 17.5221 10.7538 17.4013C10.4758 17.2806 10.4758 16.8445 10.4758 15.9719V13.8367C7.08546 13.8367 3.41263 15.3979 2 18C2 9.67334 7.02268 7.59172 10.4758 7.59172V5.45644C10.4758 4.58397 10.4758 4.14774 10.7538 4.02702C11.0318 3.9063 11.3689 4.19612 12.0432 4.77576Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ForwardingIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ForwardingIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(ForwardingIcon);
