import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const AddingConferenceIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width="30" height="30" viewBox={`0 0 30 30`}>
      <path
        d="M27.9292 10.0601H30.8589V11.3638H27.9292V14.3228H26.6255V11.3638H23.7031V10.0601H26.6255V7.07178H27.9292V10.0601Z"
        fill="#008FE8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 18.1667C5.95 18.1667 7 17.1167 7 15.8333C7 14.55 5.95 13.5 4.66667 13.5C3.38333 13.5 2.33333 14.55 2.33333 15.8333C2.33333 17.1167 3.38333 18.1667 4.66667 18.1667ZM5.985 19.45C5.55333 19.38 5.12167 19.3333 4.66667 19.3333C3.51167 19.3333 2.415 19.5783 1.42333 20.01C0.56 20.3833 0 21.2233 0 22.1683V24H5.25V22.1217C5.25 21.1533 5.51833 20.2433 5.985 19.45ZM23.3333 18.1667C24.6167 18.1667 25.6667 17.1167 25.6667 15.8333C25.6667 14.55 24.6167 13.5 23.3333 13.5C22.05 13.5 21 14.55 21 15.8333C21 17.1167 22.05 18.1667 23.3333 18.1667ZM28 22.1683C28 21.2233 27.44 20.3833 26.5767 20.01C25.585 19.5783 24.4883 19.3333 23.3333 19.3333C22.8783 19.3333 22.4467 19.38 22.015 19.45C22.4817 20.2433 22.75 21.1533 22.75 22.1217V24H28V22.1683ZM18.9467 18.925C17.5817 18.3183 15.9017 17.875 14 17.875C12.0983 17.875 10.4183 18.33 9.05333 18.925C7.79333 19.485 7 20.745 7 22.1217V24H21V22.1217C21 20.745 20.2067 19.485 18.9467 18.925ZM9.415 21.6667C9.52 21.3983 9.56667 21.2117 10.4767 20.8617C11.6083 20.4183 12.7983 20.2083 14 20.2083C15.2017 20.2083 16.3917 20.4183 17.5233 20.8617C18.4217 21.2117 18.4683 21.3983 18.585 21.6667H9.415ZM14 12.3333C14.6417 12.3333 15.1667 12.8583 15.1667 13.5C15.1667 14.1417 14.6417 14.6667 14 14.6667C13.3583 14.6667 12.8333 14.1417 12.8333 13.5C12.8333 12.8583 13.3583 12.3333 14 12.3333ZM14 10C12.0633 10 10.5 11.5633 10.5 13.5C10.5 15.4367 12.0633 17 14 17C15.9367 17 17.5 15.4367 17.5 13.5C17.5 11.5633 15.9367 10 14 10Z"
        fill="#008FE8"
      />
    </SvgIcon>
  );
};

AddingConferenceIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
AddingConferenceIcon.defaultProps = {
  width: 36,
  height: 36,
};

export default memo(AddingConferenceIcon);
