import { JsonObject } from "pubsub/PubSub.types";

export interface ICheckingDto {
  currentEvent: number;
  lastCheck: number;
  lastDeviceEvent: number;
  lastDeviceMessage: number;
  lastNotificationEvent: number;
  isNewEvent: number;
  isNewMessage: number;
  isNewNotification: number;
  currentMessage: number;
  currentNotification: number;
  deviceId: string;
  status: number;
  timestamp: number;
}

export default class CheckingDto implements ICheckingDto {
  constructor(data: JsonObject) {
    this.currentEvent = Number(data.currentEvent) || 0;
    this.lastCheck = Number(data.lastCheck) || 0;
    this.lastDeviceEvent = Number(data.lastDeviceEvent) || 0;
    this.lastDeviceMessage = Number(data.lastDeviceMessage) || 0;
    this.lastNotificationEvent = Number(data.lastNotificationEvent) || 0;
    this.isNewEvent = Number(data.isNewEvent) || 0;
    this.isNewMessage = Number(data.isNewMessage) || 0;
    this.isNewNotification = Number(data.isNewNotification) || 0;
    this.currentMessage = Number(data.currentMessage) || 0;
    this.currentNotification = Number(data.currentNotification) || 0;
    this.deviceId = data.deviceId;
    this.status = Number(data.status) || 0;
    this.timestamp = Number(data.timestamp) || 0;
  }

  currentEvent: number;
  lastCheck: number;
  lastDeviceEvent: number;
  lastDeviceMessage: number;
  lastNotificationEvent: number;
  isNewEvent: number;
  isNewMessage: number;
  isNewNotification: number;
  currentMessage: number;
  currentNotification: number;
  deviceId: string;
  status: number;
  timestamp: number;
}
