import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const ShareWindow = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.3661 19.2793C92.0229 19.2793 93.3661 20.6225 93.3661 22.2793V54.7314H93.3683V55.4971H109.174C110.831 55.4971 112.174 54.154 112.174 52.4971V8.87598C112.174 7.21912 110.831 5.87598 109.174 5.87598H33.5605C31.9037 5.87598 30.5605 7.21913 30.5605 8.87598V19.2793H24.6836V3C24.6836 1.34315 26.0267 0 27.6836 0H115.05C116.707 0 118.05 1.34315 118.05 3V57.7206C118.05 59.3775 116.707 60.7206 115.05 60.7206H93.3683V64.39H93.3661V76.9999C93.3661 78.6568 92.0229 79.9999 90.3661 79.9999H3C1.34314 79.9999 0 78.6568 0 76.9999V22.2793C0 20.6225 1.34315 19.2793 3 19.2793H24.6836V19.3174H30.5605V19.2793H90.3661ZM87.4907 64.39V71.7764C87.4907 73.4333 86.1475 74.7764 84.4907 74.7764H8.87695C7.2201 74.7764 5.87695 73.4333 5.87695 71.7764V28.1553C5.87695 26.4985 7.2201 25.1553 8.87695 25.1553H23.6123V25.1554H24.6836V25.2197H24.6846V25.1554H33.2709V25.1553H84.4907C86.1475 25.1553 87.4907 26.4984 87.4907 28.1553V54.7314H87.4658V64.39H87.4907Z"
        fill="white"
      />
    </SvgIcon>
  );
};
ShareWindow.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
ShareWindow.defaultProps = {
  width: 30,
  height: 30,
};
export default memo(ShareWindow);
