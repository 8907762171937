import { AppConstant, FormatConstant, KeyConstant, SystemConstant } from "const";
import { updateFileStatus } from "pubsub/services/file.service";
import store, { ConversationActions, ConversationSelectors, getReduxState, SystemActions } from "redux-store";
import { getInteractor } from "services/local.service";
import { uploadFile } from "services/multiple-file";
import { AttachmentUtil, convertString2JSON, FileUtil, StorageUtil, toSnake } from "utils";
import { getCommonLang } from "utils/lang.utils";
import { DISPLAY_MSG_TYPES } from "./saga.helper";
import StringFormat from "string-format";

export const sendFileMessage = async action => {
  try {
    const { data, prefixKey } = action;

    // Save message in local
    const currentMessage = data.currentMessage;
    await getInteractor(prefixKey).LocalMessageService.save([
      { ...currentMessage, status: SystemConstant.MESSAGE_STATUS.read },
    ]);

    // Upload to tus server
    const multipleFile = convertString2JSON(data.content, {}).multiple_file || [];

    const domain = StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER).domain;
    window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.saveUploadingFiles, {
      attachmentIds: multipleFile.map(item => item.attachment_id),
      baseUrl: StringFormat(FormatConstant.FM_BASE_URL, domain),
      accessToken: StorageUtil.getItem(KeyConstant.KEY_TOKEN, prefixKey),
      prefixKey: prefixKey,
    });

    let isUploadSuccess = false;
    for (let index = 0; index < multipleFile.length; index++) {
      const file = multipleFile[index];
      const uploadResult = await uploadFile(
        prefixKey,
        file.attachment_id,
        SystemConstant.UPLOAD_TYPE.group,
        data.groupId,
        {
          onError: handleUploadFileMsgError,
          onProgress: handleUploadFileMsgProgress,
          onSuccess: handleUploadFileMsgSuccess,
        },
      );

      if (uploadResult === SystemConstant.UPLOAD_STATUS.success) {
        isUploadSuccess = true;
      }

      window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.saveUploadingFiles, {
        attachmentIds: multipleFile.map(item => item.attachment_id).filter(item => item !== file.attachment_id),
        baseUrl: StringFormat(FormatConstant.FM_BASE_URL, domain),
        accessToken: StorageUtil.getItem(KeyConstant.KEY_TOKEN, prefixKey),
        prefixKey: prefixKey,
      });
    }

    // At least one file upload success => Send message
    if (isUploadSuccess) {
      store.dispatch(ConversationActions.sendMessage(data, prefixKey));
    } else {
      // Update local message
      const updateLocalMessage = toSnake({
        ...currentMessage,
        modified: Date.now(),
      });
      await getInteractor(prefixKey).LocalMessageService.update(
        {
          modified: updateLocalMessage.modified,
        },
        { id: currentMessage.id },
      );

      // Trigger to UI
      const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
      if (updateLocalMessage.group_id === selectedGroupId && DISPLAY_MSG_TYPES.includes(updateLocalMessage.send_type)) {
        store.dispatch(ConversationActions.receivedRemoteMessage(updateLocalMessage));
      }
      throw new Error("Upload file failed");
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      SystemActions.systemSet({
        toast: {
          content: getCommonLang("TXT_SERVER_ERROR"),
          severity: "error",
          modified: Date.now(),
        },
      }),
    );
  }
};

const clearFile = attachmentId => {
  const encryptFolder = AttachmentUtil.getEncryptionFolder(attachmentId);
  const encryptZipFile = AttachmentUtil.getEncryptionZipPath(attachmentId);

  if (FileUtil.isExistFile(encryptFolder)) FileUtil.removeFolder(encryptFolder);
  if (FileUtil.isExistFile(encryptZipFile)) FileUtil.removeFile(encryptZipFile);
};

const handleUploadFileMsgError = async (prefixKey, attachmentId, groupId, error) => {
  console.log("upload error: ", error);
  await getInteractor(prefixKey).LocalFileService.update(
    { status: SystemConstant.UPLOAD_STATUS.failed, modified: Date.now() },
    { id: attachmentId },
  );
  clearFile(attachmentId);

  // Update file status to server
  await updateFileStatus(prefixKey, attachmentId, SystemConstant.UPLOAD_STATUS.failed, groupId);
  return SystemConstant.UPLOAD_STATUS.failed;
};

const handleUploadFileMsgProgress = (bytesUploaded, bytesTotal) => {
  const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
  console.log({ percentage });
};

const handleUploadFileMsgSuccess = async (prefixKey, attachmentId) => {
  console.log("upload success: ", attachmentId);

  await getInteractor(prefixKey).LocalFileService.update(
    { status: SystemConstant.UPLOAD_STATUS.success, modified: Date.now() },
    { id: attachmentId },
  );
  clearFile(attachmentId);
  return SystemConstant.UPLOAD_STATUS.success;
};
