import { EventState, EventSubType, EventType, ExecuteEvent } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { getInteractor } from "services/local.service";
import { EventDto, IEventDto } from "pubsub/dto";
import { refactorSavingEvent } from "pubsub/PubSub.helper";
import { branchDeleteService, branchUpdateService } from "pubsub/services/branch.service";

export default class BranchEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.BRANCH);
  }

  handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];
    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      const eventContent = eventItem.contentObj;
      const branchId = eventContent.branch_id;
      let executeEventValue: ExecuteEvent = ExecuteEvent.FAIL;

      if (branchId) {
        switch (eventItem.subtype) {
          case EventSubType.UPDATE:
            executeEventValue = (await branchUpdateService(this.prefixKey, branchId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.DELETE:
            executeEventValue = branchDeleteService(this.prefixKey, branchId)
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          default:
            break;
        }
      }

      // Update event before saving in local db
      const refactorEvent = refactorSavingEvent(eventItem, executeEventValue, limitRetry);
      if (refactorEvent) savingEvents.push(refactorEvent);
    }

    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}
