import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Stack } from "@mui/material";
import { Attachment, ImageOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { HandleUploadingInput } from "components";
import UploadFileDialog from "../UploadFileDialog";
import { isImage, isVideo, uuid } from "utils";

const ACCEPTABLE_MEDIA_TYPES = [...AppConstant.SUPPORT_VIDEO_TYPES, AppConstant.ACCEPTABLE_IMAGE_TYPES].join(", ");
const SendMessageFile = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isUploadImage, setIsUploadImage] = useState(false);

  const handleChangeUploadType = isUploadMedia => () => setIsUploadImage(isUploadMedia);

  const handleFileChange = fileList => {
    const multipleFile = [];
    for (let index = 0; index < fileList?.length; index++) {
      multipleFile.push({ file: fileList[index], attachmentId: uuid() });
    }

    setUploadFiles(multipleFile);
  };

  useEffect(() => {
    // Reset data
    setUploadFiles(null);
    setIsUploadImage(false);

    // Catch event paste image
    const handlePaste = event => {
      const items = event.clipboardData || event.originalEvent.clipboardData;
      const pastedFiles = items?.files;
      const isValidFile = pastedFiles && pastedFiles.length > 0;
      if (!isValidFile) return;

      // Only image/ video file
      const isOnlyMediaFiles =
        Array.from(pastedFiles).filter(item => isImage(item.type) || isVideo(item.type)).length === pastedFiles.length;
      setIsUploadImage(isOnlyMediaFiles);
      handleFileChange(pastedFiles);
    };
    window.addEventListener("paste", handlePaste);

    return () => window.removeEventListener("paste", handlePaste);
  }, []);

  const isShowUploadDialog = Array.isArray(uploadFiles) && uploadFiles.length > 0;
  return (
    <>
      <Stack direction="row" spacing={0.5}>
        <Box onClick={handleChangeUploadType(false)}>
          <HandleUploadingInput
            accept="*"
            className="hidden"
            id="icon-button-document"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="icon-button-document" title={getLabel(LangConstant.TXT_POPUP_DOCUMENT)}>
            <IconButton className={clsx(classes.rotateIcon)} component="span" edge="end">
              <Attachment variant="contained" color="action" />
            </IconButton>
          </label>
        </Box>
        <Box onClick={handleChangeUploadType(true)}>
          <HandleUploadingInput
            accept={ACCEPTABLE_MEDIA_TYPES}
            className="hidden"
            id="icon-button-gallery"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="icon-button-gallery" title={getLabel(LangConstant.TXT_POPUP_GALLERY)}>
            <IconButton component="span" edge="end">
              <ImageOutlined variant="contained" color="action" />
            </IconButton>
          </label>
        </Box>
      </Stack>

      {isShowUploadDialog && (
        <UploadFileDialog
          isMediaType={isUploadImage}
          multipleFile={uploadFiles || []}
          onClose={() => setUploadFiles(null)}
        />
      )}
    </>
  );
};

export default memo(SendMessageFile);

const useStyles = makeStyles({
  rotateIcon: {
    transform: "rotate(-45deg)",
  },
});
