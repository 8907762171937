import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const CopyrightCompany = ({ classes }) => {
  const defaultClasses = useStyles();
  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Typography variant="body1" classes={{ body1: "regular-sm-txt" }} color="inherit">
        Copyright © bGlobal Jsc. All Rights Reserved
      </Typography>
    </Box>
  );
};

CopyrightCompany.propTypes = {
  classes: PropTypes.object,
};

CopyrightCompany.defaultProps = {};

export default memo(CopyrightCompany);

const useStyles = makeStyles({
  root: {
    width: "100%",
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
