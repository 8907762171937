import { memo } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { ContentPaste } from "@mui/icons-material";

const PastePopper = ({ anchorEl, onPaste, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const handlePasteContent = async () => {
    const clipboardVal = await navigator.clipboard.readText();
    onPaste(clipboardVal);
    onClose();
  };

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      classes={{
        root: classes.chatMenuRoot,
        paper: classes.chatMenuPaper,
        list: classes.chatMenuList,
      }}
    >
      <MenuItem className={classes.menuItem} onClick={handlePasteContent}>
        <ContentPaste /> {getLabel(LangConstant.TXT_PASTE)}
      </MenuItem>
    </Menu>
  );
};

PastePopper.propTypes = {
  onPaste: PropTypes.func,
  onClose: PropTypes.func,
};

PastePopper.defaultProps = {
  onPaste: () => {},
  onClose: () => {},
};

export default memo(PastePopper);

const useStyles = makeStyles(theme => ({
  chatMenuPaper: {
    borderRadius: 20,
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: -6,
      left: "50%",
      width: 20,
      height: 20,
      backgroundColor: theme.palette.white,
      transform: "translateX(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },

  chatMenuList: {
    padding: 0,
    borderRadius: 20,
  },

  menuItem: {
    borderRadius: 20,
    padding: "10px 14px",
    "&::hover": {
      backgroundColor: "#F2F2F2",
    },

    "&>svg": {
      marginRight: 10,
    },

    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    color: "#1E272E",
  },
}));
