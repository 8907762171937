import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  deviceLogout: ["data", "prefixKey"],

  deviceSuccess: ["data"],
  deviceFailure: ["error"],
  deviceSet: ["data"],
});

export const DeviceTypes = Types;
export const DeviceActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  ...REDUX_STATE,
  fetchStatus: 0,
  loggedOutDevice: null,
};

/* ------------- Selector ------------- */
export const DeviceSelectors = {
  getLoggedOutDevice: state => state.deviceRedux.loggedOutDevice,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
  });

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.DEVICE_LOGOUT]: request,

  [Types.DEVICE_FAILURE]: failure,
  [Types.DEVICE_SUCCESS]: success,
  [Types.DEVICE_SET]: set,
};

/* ------------- Hookup Reducers To Types ------------- */
export const DeviceReducer = createReducer(INITIAL_STATE, HANDLERS);
