import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { OutlinedInput } from "@mui/material";
import clsx from "clsx";

const OTPInput = ({ isError, isReset, initialValue, ...otherProps }) => {
  const classes = useStyles();

  const [otpDigit, setOtpDigit] = useState(initialValue);

  useEffect(() => {
    if (isReset) {
      setOtpDigit("");
    }
  }, [isReset]);

  useEffect(() => {
    if (initialValue !== otpDigit) {
      setOtpDigit(initialValue);
    }
  }, [initialValue]);

  return (
    <OutlinedInput
      classes={{
        root: classes.otpRoot,
        input: clsx(classes.otpInput, isError && classes.otpInputError),
        notchedOutline: classes.notchedOutline,
      }}
      value={otpDigit}
      {...otherProps}
    />
  );
};

OTPInput.propTypes = {
  onChange: PropTypes.func,

  isError: PropTypes.bool,
  isReset: PropTypes.bool,
};

OTPInput.defaultProps = {
  onChange: () => {},

  isError: false,
  isReset: false,
};

export default OTPInput;

const useStyles = makeStyles(theme => ({
  otpRoot: {
    width: 42,
    height: 42,
    borderRadius: 8,
    "&::hover": {
      "&>fieldset": {
        border: "unset",
      },
    },
  },

  otpInput: {
    padding: "9px 0",
    textAlign: "center",
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },

  otpInputError: {
    border: "1px solid #EF5845",
    caretColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
}));
