import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { formatSentTime } from "utils/date.utils";
import { LangConstant } from "const";
import { AvatarGroup } from "components";
import { getInteractor } from "services/local.service";
import { useStyles } from "./NotificationInviteItem";

const NotificationOwnerItem = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const [notification, setNotification] = useState(data);

  useEffect(() => {
    getInteractor()
      .LocalBranchService.get(data.branchId)
      .then(tmpBranchDetail => {
        if (tmpBranchDetail) setNotification({ ...notification, avatarId: tmpBranchDetail.attachment_id });
      });
  }, [data]);

  return (
    <Box className={classes.mentionNotificationItemRoot} {...otherProps}>
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <AvatarGroup avatarId={notification.avatarId} />
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography
            className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
            dangerouslySetInnerHTML={{
              __html: getLabel(LangConstant.FM_OWNER_NOTIFICATION, {
                message: notification.content,
              }),
            }}
          />
          <Typography className={classes.createdTimeNotification}>{formatSentTime(notification.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default NotificationOwnerItem;
