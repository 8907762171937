import { Box, Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import AvatarInfo from "components/AvatarInfo";
import { getInteractor } from "services/local.service";
import { createGroupService } from "pubsub/services/conversation.service";
import { StorageUtil, uuid } from "utils";
import { GroupInfoActions } from "redux-store";
import { useDispatch } from "react-redux";

const ForwardCategory = ({ type, data, totalItems, onSend, isSearching }) => {
  const classes = useStyles();

  let title = getNSLang(LangConstant.NS_HOME, "FM_GROUP_TAG", { number: isSearching ? data.length : totalItems });
  switch (type) {
    case SystemConstant.GROUP_CHAT_TYPE.channel:
      title = getNSLang(LangConstant.NS_HOME, "FM_CHANNEL_TAG", { number: isSearching ? data.length : totalItems });
      break;

    case SystemConstant.GROUP_CHAT_TYPE.personal:
      title = getNSLang(LangConstant.NS_HOME, "FM_PERSONAL_TAG", { number: isSearching ? data.length : totalItems });
      break;

    default:
      break;
  }

  return (
    <>
      <Box className={classes.heading}>
        <Typography className={clsx(classes.title, "semiBold-sm-txt")}>{title}</Typography>
      </Box>
      {data.map(item => (
        <ForwardItem key={item.id} data={item} type={type} onSend={onSend} />
      ))}
    </>
  );
};

export default ForwardCategory;

const ForwardItem = ({ data = {}, type, onSend }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const createNewPersonalChat = async contact => {
    const groupName = data.contactName || data.accountName;
    const memberIdList = [accountId, contact.contactId || contact.id];

    const addingGroupData = {
      groupId: uuid(),
      groupType: type,
      groupName: groupName,
      name: JSON.stringify({ name: groupName }),
      members: memberIdList,
      privateF: 0,
      encryptionF: SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION,
    };

    const newGroup = await createGroupService(prefixKey, addingGroupData);
    if (newGroup) {
      dispatch(
        GroupInfoActions.groupInfoSet({
          newGroupId: newGroup.id,
        }),
      );
      return newGroup.id;
    }

    return null;
  };

  const handleForward = async () => {
    let groupId = "";
    if (type === SystemConstant.GROUP_CHAT_TYPE.personal) {
      const group = await getInteractor(prefixKey).LocalGroupService.findPersonalGroup(
        data.contactId || data.id,
        branchId,
      );
      if (group) groupId = group.id;
      else {
        groupId = await createNewPersonalChat(data);
      }
    } else {
      groupId = data.id;
    }

    onSend(groupId);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <AvatarInfo
        title={data.groupName || data.contactName || data.accountName}
        avatar={{ avatarId: data.avatarId, name: data.groupName, type: type }}
        sx={{ padding: "6px 0px", width: "70%" }}
        privateF={data.privateF}
        sendToMe={data.contactId === accountId || data.id === accountId}
      />
      <Button variant="contained" className={classes.sendBtn} onClick={handleForward}>
        {getCommonLang("TXT_SEND")}
      </Button>
    </Stack>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: 13,
    color: "gray",
  },

  sendBtn: {
    borderRadius: 50,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 5,
    textTransform: "none",
    color: "#000000",
    backgroundColor: "#EEEEEE",
    "&:hover": {
      background: "#9e9e9e",
    },
  },
});
