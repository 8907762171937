export default {
  TXT_ALL_TIME: "Toàn bộ thời gian",
  TXT_TODAY: "Hôm nay",
  TXT_YESTERDAY: "Hôm qua",
  TXT_LAST_7DAY: "7 ngày gần đây",
  TXT_LAST_30DAY: "30 ngày gần đây",
  TXT_PDF: "PDF",
  TXT_IMAGE: "Ảnh",
  TXT_VIDEO: "Video",
  TXT_AUDIO: "Audio",
  TXT_OTHER_FILE: "Tệp khác",
  TXT_TIME: "Thời gian",
  TXT_FILE_TYPE: "Loại tệp",

  FM_MORE_FILE_TYPE: `{{quantity}} loại tệp`,
  FM_SHARE_BY_ME: `{{fileSize}} - Chia sẻ bởi tôi`,
  FM_SHARE_BY_OTHER: `{{fileSize}} - Chia sẻ bởi {{name}}`,
};
