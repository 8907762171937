import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyConstant, LangConstant, PathConstant } from "const";
import { ConfirmDialog } from "components";
import { useDispatch } from "react-redux";
import { DeviceActions } from "redux-store";
import { getCommonLang } from "utils/lang.utils";
import { useDeviceList } from "hooks";
import ChooseDeviceDrawer from "./DeviceManagement/ChooseDeviceDrawer";
import { StorageUtil } from "utils";
import AccountItem from "./AccountItem";
import { useHistory } from "react-router-dom";
import { handlingLogoutBranch, hasMoreLoggedInBranch } from "utils/auth.utils";
import { useAlertContext } from "components/context/AlertContext";

const LOGOUT_STEP = {
  none: "NOT_START",
  selectDevice: "SELECT_DEVICE",
  confirmLogout: "CONFIRM_LOGOUT",
};
const AccountLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { isMaster, slaveDeviceList } = useDeviceList();
  const { showAlert } = useAlertContext();

  const [logoutStep, setLogoutStep] = useState(LOGOUT_STEP.none);

  const handleShowConfirm = () => setLogoutStep(LOGOUT_STEP.confirmLogout);

  const handleChooseDevice = () => setLogoutStep(LOGOUT_STEP.selectDevice);

  const handleCancelLogout = () => setLogoutStep(LOGOUT_STEP.none);

  const handleLogout = async () => {
    const isLogoutSuccess = await handlingLogoutBranch(prefixKey);

    if (isLogoutSuccess) {
      dispatch(
        DeviceActions.deviceLogout({
          slaveDeviceId: [deviceId],
        }),
      );
      const hasMoreBranch = await hasMoreLoggedInBranch();
      if (hasMoreBranch) {
        history.replace(PathConstant.CHOOSE_BRANCH_PAGE);
      } else {
        history.replace(PathConstant.LOGIN);
      }
    } else {
      showAlert({ content: getCommonLang("TXT_SERVER_ERROR"), alertProps: { severity: "error" } });
    }
  };

  const hasSlave = slaveDeviceList.length > 0;
  const title = isMaster && hasSlave ? getCommonLang(LangConstant.TXT_CONFIRM_LOGOUT_MASTER) : accountObjContent.logout;
  const content = getCommonLang(
    isMaster
      ? hasSlave
        ? LangConstant.TXT_CONTENT_LOGOUT_MASTER
        : LangConstant.TXT_LOGOUT_MASTER_WITHOUT_SLAVE
      : LangConstant.TXT_CONTENT_LOGOUT_SLAVE,
  );

  const submitProps =
    isMaster && hasSlave
      ? {
          submitText: getCommonLang(LangConstant.TXT_CHOOSE_DEVICE),
          onClick: handleChooseDevice,
        }
      : {
          submitText: accountObjContent.logout,
          onClick: handleLogout,
        };

  return (
    <>
      <AccountItem text={accountObjContent.logout} onClick={handleShowConfirm} />

      {logoutStep === LOGOUT_STEP.confirmLogout && (
        <ConfirmDialog
          open
          title={title}
          content={content}
          submitProps={submitProps}
          cancelProps={{ onClick: handleCancelLogout }}
        />
      )}

      {logoutStep === LOGOUT_STEP.selectDevice && (
        <ChooseDeviceDrawer open onClose={handleCancelLogout} onLogout={handleLogout} />
      )}
    </>
  );
};

AccountLogout.propTypes = {};

export default AccountLogout;
