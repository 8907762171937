import { Box, Paper, Stack } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { AppConstant } from "const";

export const MainLayout = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  position: "relative",
});

export const MainSidebar = styled(Paper)(props => ({
  width: AppConstant.SIDEBAR_WIDTH.medium,
  maxHeight: "calc(100vh)",
  position: "relative",
  flexShrink: 0,
  boxSizing: "border-box",
  overflow: "hidden",

  [props.theme.breakpoints.up(1500)]: {
    width: AppConstant.SIDEBAR_WIDTH.large,
  },
}));

export const MainContent = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Box component="main" className={classes.main}>
      <Stack component={Paper} sx={{ height: "100%", borderRadius: 5 }} elevation={0} {...props}>
        {children}
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  main: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "#F7F7F8",
    padding: 20,
    maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.medium})`,
    maxHeight: "100vh",

    [theme.breakpoints.up(1500)]: {
      maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.large}px)`,
    },
  },
}));
