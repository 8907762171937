import PubSubFactory from "pubsub/PubSubFactory";

export function* initPubsub() {
  yield PubSubFactory.initFromDB();
}

export function* startCheckingNews(action) {
  const { prefixKey, domain } = action;

  yield PubSubFactory.getEvent(prefixKey, domain).startCheckingNews();
}

export function* destroyParallelThread(action) {
  const { prefixKey } = action;
  yield PubSubFactory.getEvent(prefixKey).destroyParallelThread();
}

export function* destroyLogoutBranchThread() {
  yield PubSubFactory.destroyLogoutBranchThread();
}
