import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { isNotEqual, toCamel } from "utils";
import { InfiniteScroll } from "components";
import { FormatConstant, LangConstant, SystemConstant } from "const";
import LineChat from "../LineChat";
import { useTranslation } from "react-i18next";
import { getInteractor } from "services/local.service";
import store, { ConversationActions, ConversationSelectors } from "redux-store";
import StringFormat from "string-format";
import { handleNewMessageList, refactorMessage, refactorMessageList } from "../ViewMode/ViewMode.helper";
import isEqual from "lodash/isEqual";
import MessageItem from "../MessageItem";
import { useCleanUpEffect } from "hooks";
import { useConversationContext } from "../ConversationContext";
import { createSelector } from "reselect";
import ScrollTooltip from "components/ScrollTooltip";

const memoizedReduxState = createSelector(
  [ConversationSelectors.getThreadingId, state => state.conversationRedux.scrollToChildId],
  (selectedThreadId, scrollToChildId) => {
    return {
      selectedThreadId,
      scrollToChildId,
    };
  },
);

const memoizedIncomingState = createSelector(
  [ConversationSelectors.getThreadingId, state => state.conversationRedux.incomingMessages],

  (selectedThreadId, incomingMessages) => {
    const messageInThread = incomingMessages[selectedThreadId];
    const messageList = messageInThread ? Object.values(messageInThread) : null;
    return Array.isArray(messageList) && messageList.length > 0 ? messageList : null;
  },
);

const ThreadViewMode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMounted } = useCleanUpEffect();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { groupDetail } = useConversationContext();

  const { selectedThreadId, scrollToChildId } = useSelector(memoizedReduxState);
  const incomingMessages = useSelector(memoizedIncomingState);

  const [threadList, setThreadList] = useState([]);
  const [parentMessage, setParentMessage] = useState({});

  const handleGetMessages = async () => {
    const parentMsg = await getInteractor().LocalMessageService.findOne({ source_id: selectedThreadId });
    if (!(selectedThreadId && parentMsg?.id)) return;

    // Set parent message
    let displayParentMsg = await refactorMessage(toCamel(parentMsg));
    displayParentMsg = { ...displayParentMsg, isAvatar: true };
    if (!isEqual(displayParentMsg, parentMessage) && isMounted()) setParentMessage(displayParentMsg);

    // Query reply list
    const showList = await getAllReplyMessage(selectedThreadId, []);
    if (Array.isArray(showList) && isMounted()) setThreadList(showList);
  };

  const handleIncomingMessage = useCallback(async (incomingMessages, threadList, groupDetail) => {
    const newMessageList = await handleNewMessageList(incomingMessages, threadList, groupDetail);
    if (isMounted() && Array.isArray(newMessageList)) {
      setThreadList(newMessageList);
    }
  }, []);

  useEffect(() => {
    if (incomingMessages) handleIncomingMessage(incomingMessages, threadList, groupDetail);
  }, [incomingMessages]);

  useEffect(() => {
    setThreadList([]);

    if (selectedThreadId) {
      handleGetMessages();
    }
  }, [selectedThreadId]);

  useEffect(() => {
    if (selectedThreadId && scrollToChildId && threadList.length > 0) {
      const isExistMsg = threadList.find(item => item.id === scrollToChildId);
      if (!Boolean(isExistMsg)) return;
      const elementId = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, scrollToChildId);

      let scrollToEl = null;
      let retries = 0;
      while (scrollToEl === null && retries < 5) {
        retries++;
        scrollToEl = document.getElementById(elementId);
        if (scrollToEl) {
          scrollToEl.scrollIntoView({ block: "center" });
          dispatch(
            ConversationActions.conversationSet({
              scrollToChildId: null,
            }),
          );
        } else {
          const mainScroll = document.getElementById(THREAD_WRAPPER_ID);
          const scrollHeight = 0 - parseInt(mainScroll.scrollHeight);
          if (mainScroll) {
            mainScroll.scrollTo({
              top: scrollHeight,
              behavior: "smooth",
            });
          }
        }
      }

      dispatch(
        ConversationActions.conversationSet({
          scrollToChildId: null,
        }),
      );
    }
  }, [scrollToChildId, threadList]);

  return (
    <InfiniteScroll
      className={classes.wrapChatItem}
      id={THREAD_WRAPPER_ID}
      hasMore={false}
      isReverse={true}
      onClickScrollButton={scrollToLastMessage}
      ScrollTooltip={ScrollTooltip}
    >
      {/* Reply message */}
      {threadList.map(item => (
        <MessageItem key={item.id} message={item} isThreadMode={true} />
      ))}

      {threadList.length > 0 && (
        <LineChat data={getLabel(LangConstant.FM_NUMBER_THREAD_REPLY, { number: threadList.length })} />
      )}

      {/* Parent message */}
      <MessageItem message={parentMessage} isThreadMode={true} />
    </InfiniteScroll>
  );
};

ThreadViewMode.propTypes = {
  messages: PropTypes.array,
  groupMembers: PropTypes.array,
  groupDetail: PropTypes.object,

  onSendMessage: PropTypes.func,
};

ThreadViewMode.defaultProps = {};

export default memo(ThreadViewMode);

const scrollToLastMessage = () => {
  const wrapper = document.getElementById(THREAD_WRAPPER_ID);
  if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;
};

const useStyles = makeStyles({
  wrapChatItem: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    padding: "0 22px",
    paddingBottom: 15,
  },
});

const THREAD_WRAPPER_ID = "thread-wrapper-id";

const updateMessageStatus = async threadingId => {
  const unreadMessageList = await getInteractor().LocalMessageService.getUnreadInThread(threadingId);
  const unreadMessageIds = unreadMessageList.map(item => item.id);

  if (unreadMessageIds.length > 0) {
    store.dispatch(
      ConversationActions.updateMessageStatus({
        messageIds: unreadMessageIds,
        status: SystemConstant.MESSAGE_STATUS.read,
      }),
    );

    await getInteractor().LocalThreadService.update({ total_unread: 0 }, { thread_id: threadingId });
  }
};

const getAllReplyMessage = async (threadingId, threadList) => {
  // Query reply list
  const messages = await getInteractor().LocalMessageService.getThreadMessage(threadingId, {
    isShowDeletingMessage: true,
  });

  if (messages.length >= threadList.length) {
    const showingList = await refactorMessageList(toCamel(messages));

    getInteractor().LocalThreadService.update({ total_reply: showingList.length }, { thread_id: threadingId });
    // Update unread message
    updateMessageStatus(threadingId);

    return isNotEqual(threadList, showingList) ? showingList : null;
  }

  return null;
};
