import { Button as MuiButton, Stack, Box, Skeleton, Avatar } from "@mui/material";
import { Reply } from "@mui/icons-material";
import styled from "@emotion/styled";

export const LimitContainer = styled(Stack)({
  width: "80%",
  maxWidth: 900,
});

export const Container = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 4,
  paddingTop: 24,
  flexGrow: 1,
});

export const Button = styled(MuiButton)({
  justifyContent: "flex-start",
  paddingLeft: 16,
  paddingRight: 16,
  textTransform: "none",
  fontSize: 15,
  fontWeight: 600,
  height: 48,
});

export const ReplyIcon = styled(Reply)({
  "&.MuiSvgIcon-root": { fontSize: 24 },
});

const TabButton = styled(Button)(props => ({
  "&.MuiButton-text:not(:hover)": {
    color: props.theme.palette.grey[700],
  },
}));

export const FileTabButton = ({ isSelected, label, ...props }) => {
  return (
    <TabButton variant={isSelected ? "contained" : "text"} {...props}>
      {label}
    </TabButton>
  );
};

export const FileListLoading = () => {
  return (
    <LimitContainer gap={1} margin="auto">
      {[...Array(10).keys()].map(key => (
        <Box key={key} sx={{ display: "flex", alignItems: "center" }} gap={1}>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>

          <Box width="100%">
            <Skeleton width="30%" />
            <Skeleton width="40%" />
            <Skeleton width="50%" />
          </Box>
        </Box>
      ))}
    </LimitContainer>
  );
};
