import { AppConstant, KeyConstant, SystemConstant } from "const";
import { uuid } from "utils";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";
import { PRIVATE_FLAG_ID } from "pages/HomePage/components/AddingChat/AddingChannel/AddingChannelDrawer";
import { ConversationActions, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { useCallback } from "react";
import { debounce } from "lodash";

const useCreateConversation = () => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const createConversation = async (memberList = [], groupName, groupType, option = {}) => {
    if (isNil(groupType)) {
      console.log("createConversation - Need passing groupType");
      return;
    }

    const privateFlagInput = document.getElementById(PRIVATE_FLAG_ID);
    const memberIdList = memberList.map(item => item.contactId || item.id);

    // Adding current account_id if not exist
    if (false === memberIdList.includes(accountId)) {
      const currentAccount = await getInteractor(prefixKey).LocalAccountService.get(accountId, branchId);
      memberIdList.push(accountId);
      memberList.push(currentAccount);
    }
    if (!groupName) {
      groupName = await getInteractor(prefixKey).LocalGroupService.getGroupName({ group_type: groupType }, memberList);
    }

    const addingGroupData = {
      groupId: uuid(),
      groupType: groupType,
      groupName: groupName,
      name: JSON.stringify({ name: groupName }),
      members: memberIdList,
      privateF: privateFlagInput?.checked ? 1 : 0,
      encryptionF: SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION,

      ...option,
    };

    dispatch(GroupInfoActions.createGroup(addingGroupData));
  };

  const createGroup = (contactList = [], strGroupName, option = {}) =>
    createConversation(contactList, strGroupName, SystemConstant.GROUP_CHAT_TYPE.group, option);

  const createChannel = (contactList = [], strGroupName, option = {}) =>
    createConversation(contactList, strGroupName, SystemConstant.GROUP_CHAT_TYPE.channel, option);

  const createWithPersonal = useCallback(
    debounce(async (contact, option = {}) => {
      const group = await getInteractor(prefixKey).LocalGroupService.findPersonalGroup(
        contact.contactId || contact.id,
        branchId,
      );
      if (group && group.id) {
        dispatch(
          ConversationActions.setSelectGroupId({
            selectedGroupId: group.id,
          }),
        );

        if (option.isDemandVoiceCall || option.isDemandVideoCall) {
          dispatch(
            ConversationActions.conversationSet({
              demandingVoiceCall: option.isDemandVoiceCall ? group.id : null,
              demandingVideoCall: option.isDemandVideoCall ? group.id : null,
            }),
          );
        }
      } else {
        // Create a conversation if the user never chat before
        createConversation([contact], contact.contactName, SystemConstant.GROUP_CHAT_TYPE.personal, option);
      }
    }, AppConstant.DEBOUNCE_TIME),
    [prefixKey],
  );

  return { createGroup, createChannel, createWithPersonal };
};

export default useCreateConversation;
