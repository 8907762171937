import { useEffect, useRef } from "react";

const useCleanUpEffect = () => {
  const cleanUp = useRef(false);

  useEffect(() => {
    return () => {
      cleanUp.current = true;
    };
  }, []);

  return {
    isCleanUp: () => Boolean(cleanUp.current),
    isMounted: () => !Boolean(cleanUp.current),
  };
};

export default useCleanUpEffect;
