import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const GroupRemove = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <circle cx="15" cy="15" r="15" fill="#C4C4C4" />
      <path
        d="M22 12.625V13.875H18.25V12.625H22ZM12 9.5C10.6188 9.5 9.5 10.6187 9.5 12C9.5 13.3812 10.6188 14.5 12 14.5C13.3812 14.5 14.5 13.3812 14.5 12C14.5 10.6187 13.3812 9.5 12 9.5ZM12 15.125C10.3312 15.125 7 15.9625 7 17.625V19.5H17V17.625C17 15.9625 13.6687 15.125 12 15.125ZM14.8188 9.53125C15.3938 10.1938 15.75 11.0562 15.75 12C15.75 12.9437 15.3938 13.8063 14.8188 14.4688C16.0437 14.3125 17 13.275 17 12C17 10.725 16.0437 9.6875 14.8188 9.53125ZM17.3313 15.6438C17.8875 16.1625 18.25 16.8125 18.25 17.625V19.5H19.5V17.625C19.5 16.7188 18.5063 16.0563 17.3313 15.6438Z"
        fill="#010101"
      />
    </SvgIcon>
  );
};
GroupRemove.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
GroupRemove.defaultProps = {
  width: 30,
  height: 30,
};
export default memo(GroupRemove);
