import { select } from "redux-saga/effects";
import store, { CallingActions, ConversationSelectors, getReduxState } from "redux-store";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { StorageUtil, convertString2JSON, toCamel } from "utils";
import { getMessage } from "./message/getMessage";
import { getCallHistory } from "sagas/calling.saga";

export const autoEndCall = (groupId, roomId) => {
  store.dispatch(
    CallingActions.callingSet({
      isNotifyEnded: {
        groupId,
        roomId,
      },
    }),
  );
  return true;
};

export const manualEndCall = (groupId, roomId) => {};

export const leaveCall = (groupId, roomId, deviceId, accountId) => {
  const createdMessage = select(ConversationSelectors.getSelectedGroupId);
  if (Boolean(createdMessage) && createdMessage.groupId === groupId) {
    store.dispatch(
      CallingActions.callingSet({
        memberChange: {
          accountId: accountId,
        },
      }),
    );
  }

  return true;
};

export const checkCallMessage = async (prefixKey, groupId, messageSourceId) => {
  try {
    await getCallHistory({ prefixKey });
    const callMessage = await getInteractor(prefixKey).LocalMessageService.findOne({
      source_id: messageSourceId,
    });
    if (callMessage && callMessage.id) {
      return true;
    } else {
      const time2FetchMessageLocal = StorageUtil.getItem(KeyConstant.KEY_TIME_2_FETCH_MESSAGE, prefixKey);
      await getMessage(prefixKey, time2FetchMessageLocal);
    }
  } catch (error) {
    console.log("check call message fail: ", error);
  }

  return false;
};
// Init Call
export const inviteCallService = async (prefixKey, groupId, messageSourceId) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  const group = await getInteractor(prefixKey).LocalGroupService.get(groupId);
  const message = toCamel(
    (await getInteractor(prefixKey).LocalMessageService.findOne({ source_id: messageSourceId })) || {},
  );

  console.log({ message, groupId, messageSourceId });

  if (false === Boolean(message.id)) return false;

  const messageContent = convertString2JSON(message.content);
  store.dispatch(
    CallingActions.callingSet({
      isVideoCall: ARR_VIDEO_CALLING.includes(message.sendType),
      isReceiver: true,
    }),
  );

  if (message.senderId !== accountId) {
    const isOpenCallingDialog = store.getState().callingRedux.isOpenCallingDialog;
    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.notStart) {
      const createdMessage = store.getState().callingRedux.createdMessage;
      const isShowCallDialog =
        message.senderId !== accountId && Date.now() - message.created < AppConstant.CALL_WAITING_TIME;
      if (isShowCallDialog) {
        store.dispatch(
          CallingActions.callingSet({
            isOpenCallingDialog: AppConstant.CALLING_STATUS.notStart,
            createdMessage: {
              ...createdMessage,
              [message.groupId]: message,
            },
            callingGroupDetail: { ...group, prefixKey, roomId: messageContent.room_id },
            message: message,
            personalRequestMessage: message,
          }),
        );
      }
    }
  }
};

// ACK: callStatus = accept || waiting
export const ackCallService = async (prefixKey, groupId, messageSourceId) => {
  try {
    const group = await getInteractor(prefixKey).LocalGroupService.get(groupId);
    if (false === Boolean(group && group.id)) return false;

    const message = toCamel(
      (await getInteractor(prefixKey).LocalMessageService.findOne({ source_id: messageSourceId })) || {},
    );
    if (false === Boolean(message.id && message.parentId)) return false;

    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

    const isGroupCall = ARR_CALLING_GROUP.includes(message.sendType);
    const isPersonalCall = ARR_CALLING_PERSONAL.includes(message.sendType);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const isMsgFromAnotherDevice = message.senderId === accountId && message.senderDeviceId !== deviceId;
    const messageContent = convertString2JSON(message.content, {});
    const oldOffers = getReduxState(state => state.callingRedux.offerMessage);
    const acceptWithId = getReduxState(state => state.callingRedux.acceptWithId);
    const missingConnection = getReduxState(state => state.callingRedux.missingConnection);

    if (isGroupCall) {
      // accept group call in another device
      if (message.callStatus === SystemConstant.MESSAGE_CALL_STATUS.accept && isMsgFromAnotherDevice) {
        store.dispatch(
          CallingActions.callingSet({
            isInAnotherCall: true,
          }),
        );
      }

      if (
        message.callStatus === SystemConstant.MESSAGE_CALL_STATUS.waiting &&
        !Boolean(messageContent.send_to_account_id)
      ) {
        console.log("Another user end the call");
        if (isMsgFromAnotherDevice) {
          store.dispatch(CallingActions.onCallCheck({ accountId: accountId, isEndCall: true }, prefixKey));
        }

        store.dispatch(
          CallingActions.callingSet({
            memberChange: {
              time: Date.now(),
              accountId: message.senderId,
            },
          }),
        );
      } else if (messageContent.send_to_account_id === accountId) {
        if (Boolean(messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.offer)) {
          console.log("offer");
          store.dispatch(
            CallingActions.callingSet({
              offerMes: message,
              offerMessage: {
                ...oldOffers,
                [message.senderId]: message,
              },
            }),
          );
        } else if (Boolean(messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.answer)) {
          console.log("answer");
          store.dispatch(
            CallingActions.callingSet({
              acceptTimeStamp: [message.created],
              acceptWithId: {
                ...acceptWithId,
                [message.senderId]: {
                  ...message,
                },
              },
            }),
          );
        }
      } else if (
        messageContent.send_to_account_id !== accountId &&
        Boolean(messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.answer)
      ) {
        console.log("another user answer");
        store.dispatch(
          CallingActions.callingSet({
            missingConnection: {
              ...missingConnection,
              [message.senderId]: {
                ...message,
              },
            },
          }),
        );
      }
    } else if (isPersonalCall) {
      // Personal call using jitsi: receiver accept call => set isCallAccepted = true (don't need to check peer connection)
      store.dispatch(
        CallingActions.callingSet({
          acceptMessage: toCamel(message),
          isCallAccepted: true,
        }),
      );
    }
  } catch (error) {
    console.log("ack calling service fail: ", error);
  }

  return false;
};

export const byeCallService = async (prefixKey, messageSourceId) => {
  try {
    const message = toCamel(
      (await getInteractor(prefixKey).LocalMessageService.findOne({ source_id: messageSourceId })) || {},
    );
    if (false === Boolean(message && message.id)) return false;
    store.dispatch(CallingActions.callingSet({ isCallEnded: message.group_id }));
    return true;
  } catch (error) {
    console.log("bye call service fail: ", error);
  }
};

export const cancelCallService = () => {};

export const cleanCallService = () => {};

const ARR_CALLING_GROUP = [
  SystemConstant.SEND_TYPE.groupVideoCall,
  SystemConstant.SEND_TYPE.conference,
  SystemConstant.SEND_TYPE.groupCall,
];

const ARR_CALLING_PERSONAL = [SystemConstant.SEND_TYPE.personalCall, SystemConstant.SEND_TYPE.personalVideoCall];

const ARR_VIDEO_CALLING = [
  SystemConstant.SEND_TYPE.groupVideoCall,
  SystemConstant.SEND_TYPE.conference,
  SystemConstant.SEND_TYPE.personalVideoCall,
];
