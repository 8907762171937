import React, { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Call, CallEnd, Close, HorizontalRule } from "@mui/icons-material";
import ConversationAction, { ConversationSelectors } from "redux-store/conversation.redux";
import { CallingActions } from "redux-store/calling.redux";
import { AppConstant, KeyConstant, LangConstant, SoundConstant, SystemConstant } from "const";
import { convertString2JSON, getDataFromPrefixKey, toCamel } from "utils";
import { getNSLang } from "utils/lang.utils";
import { isGroupOrChannelType } from "pages/Call";
import { useCallInfo, useContactList } from "hooks";
import { StorageUtil } from "utils";
import { ElectronService, getInteractor } from "services/local.service";
import { AvatarGroup } from "components";
import { changeBranchServer } from "utils/branch.utils";

let callingTimeout = null;
const clearCallingTimeout = () => {
  if (callingTimeout) {
    clearTimeout(callingTimeout);
    callingTimeout = null;
  }
};

const CALLING_DIALOG_MODE = {
  none: "not_calling", // Not show calling dialog
  incomingFullscreen: "incoming_fullscreen", // There are a new calling and show dialog
  incomingBackground: "incoming_background", // There are a new calling and show background
};

const CallingNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);

  const { callInfo } = useCallInfo();
  const { contactList } = useContactList();

  const isChatWithPersonal = callInfo.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
  const isCallEnded = useSelector(state => state.callingRedux.isCallEnded);
  const isCallMissed = useSelector(state => state.callingRedux.isCallMissed);
  const isVideoCall = useSelector(state => state.callingRedux.isVideoCall);
  const isConnectSuccess = useSelector(state => state.callingRedux.isConnectSuccess);
  const isCallAccepted = useSelector(state => state.callingRedux.isCallAccepted);
  const isNotifyEnded = useSelector(state => state.callingRedux.isNotifyEnded);
  const message = useSelector(state => state.callingRedux.message);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isCallRejected = useSelector(state => state.callingRedux.isCallRejected);
  const callingGroupDetail = useSelector(state => state.callingRedux.callingGroupDetail);

  const [deviceList, setDeviceList] = useState([]);
  const [data, setData] = useState({
    senderName: "",
    groupName: "",
    title: "",
    avatarId: null,
  });
  const [callingDialogStatus, setCallingDialogStatus] = useState(CALLING_DIALOG_MODE.none);

  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const isShowCalling = callingDialogStatus !== CALLING_DIALOG_MODE.none;
  const isCurrentBranch = prefixKey === callInfo.prefixKey;

  const onDeny = event => {
    event?.stopPropagation();
    clearCallingTimeout();
    setCallingDialogStatus(CALLING_DIALOG_MODE.none);
    dispatch(CallingActions.callingReset());

    if (message && message.branchId) {
      const branchId = message.branchId;
      const jsonContent = toCamel(convertString2JSON(message.content));
      const sendType = isGroupOrChannelType(callInfo.groupType)
        ? SystemConstant.SEND_TYPE.conference
        : isVideoCall
        ? SystemConstant.SEND_TYPE.personalVideoCall
        : SystemConstant.SEND_TYPE.personalCall;

      dispatch(
        ConversationAction.sendMessage(
          {
            groupId: callInfo.id,
            sendType: sendType,
            roomId: jsonContent.roomId,
            content: message.content,
            parentId: message.sourceId,
            deviceList: deviceList,
            branchId: branchId,
            mentionIdsArr: [],
            threadId: null,
            callStatus: SystemConstant.MESSAGE_CALL_STATUS.reject,
          },
          callInfo.prefixKey,
        ),
      );
    }

    console.log("Deny calling");
  };

  const onAccept = event => {
    event?.stopPropagation();
    clearCallingTimeout();

    const dispatchEvent2Accept = () => {
      dispatch(
        CallingActions.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
          isReceiver: true,
          isReceiving: true,
        }),
      );
      dispatch(CallingActions.onCheckReceiversStatus(callingGroupDetail));
    };

    if (isCurrentBranch) {
      dispatchEvent2Accept();
      setCallingDialogStatus(CALLING_DIALOG_MODE.none);
    } else {
      // Switch to branch
      const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, callInfo.prefixKey) || {};
      changeBranchServer(branchInfo);

      new Promise(resolve => {
        let retryNum = 1;
        const changingBranchInterval = setInterval(() => {
          const currentPrefixKey = StorageUtil.getCurrentPrefixKey();
          if (retryNum > 10 || currentPrefixKey === callInfo.prefixKey) {
            clearInterval(changingBranchInterval);
            resolve(retryNum < 10);
          }

          retryNum++;
        });
      }).then(result => {
        if (result) {
          dispatchEvent2Accept();
          setTimeout(() => {
            setCallingDialogStatus(CALLING_DIALOG_MODE.none);
          }, 2000);
        }
      });
    }
  };

  const handleCallingBackgroundFunc = () => {
    if (callingDialogStatus === CALLING_DIALOG_MODE.incomingBackground) {
      setCallingDialogStatus(CALLING_DIALOG_MODE.incomingFullscreen);
    } else {
      setCallingDialogStatus(CALLING_DIALOG_MODE.incomingBackground);
    }
  };

  const handleStartCalling = async () => {
    const [accountId, branchId] = getDataFromPrefixKey(callInfo.prefixKey);
    const senderId = message?.senderId;
    const isWaiting =
      message?.callStatus === SystemConstant.MESSAGE_CALL_STATUS.waiting && senderId && senderId !== accountId;

    if (callingDialogStatus === CALLING_DIALOG_MODE.none && callInfo.id && isWaiting) {
      const groupMembers = callInfo.groupMembers || [];
      if (groupMembers.length > 0) {
        const memberIdArray = groupMembers.map(member => member.id) || [];

        if (isChatWithPersonal && false === memberIdArray.includes(accountId)) {
          memberIdArray.push(accountId);
        }
        const deviceList = await getInteractor(callInfo.prefixKey).LocalDeviceService.find({
          account_id: memberIdArray,
        });
        setDeviceList(deviceList);
      }

      const contact = contactList.find(item => item.contactId === senderId);
      const account = toCamel(await getInteractor(callInfo.prefixKey).LocalAccountService.get(senderId, branchId));
      const callingData = {
        groupName: callInfo.groupName,
        senderName: contact?.name || account?.name || "",
        avatarId: account?.avatarId,
      };
      const callingTitle = isChatWithPersonal
        ? getLabel(
            isVideoCall ? LangConstant.FM_INCOMING_VIDEO_CALL_TITLE : LangConstant.FM_INCOMING_CALL_TITLE,
            callingData,
          )
        : getLabel(
            isVideoCall ? LangConstant.FM_INCOMING_GROUP_VIDEO_CALL_TITLE : LangConstant.FM_INCOMING_GROUP_CALL_TITLE,
            callingData,
          );

      setData(preState => ({
        ...preState,
        ...callingData,
        title: callingTitle,
      }));
      setCallingDialogStatus(CALLING_DIALOG_MODE.incomingFullscreen);
    }
  };

  useEffect(() => {
    handleStartCalling();
  }, [callInfo, message, contactList]);

  useEffect(() => {
    if (!isShowCalling) return;

    const hasEndingNotice =
      isNotifyEnded &&
      isNotifyEnded.groupId === callInfo.id &&
      isNotifyEnded.roomId === callInfo.roomId &&
      false === isChatWithPersonal;

    if (isCallEnded === callInfo.id || isCallMissed || hasEndingNotice) {
      clearCallingTimeout();
      dispatch(CallingActions.callingReset());
      setCallingDialogStatus(CALLING_DIALOG_MODE.none);
    }
  }, [isCallEnded, isCallMissed, isNotifyEnded]);

  useEffect(() => {
    // Ringing for about one minute, then if user doesn't pick up
    // Send message with call status "missed" and close the dialog automatically
    if (isShowCalling && deviceList.length > 0 && !isCallAccepted && !isConnectSuccess) {
      ElectronService.showApplication();
      callingTimeout = setTimeout(() => {
        console.log("Out of ringing time");
        // await onSendMessage(message?.content, message?.sourceId, null, SystemConstant.MESSAGE_CALL_STATUS.missed);
        dispatch(CallingActions.callingReset());
        setCallingDialogStatus(CALLING_DIALOG_MODE.none);
      }, AppConstant.CALL_WAITING_TIME + 15000);
    }

    return () => clearCallingTimeout();
  }, [isShowCalling, deviceList, isCallAccepted, isConnectSuccess]);

  useEffect(() => {
    if ((isInAnotherCall || isCallRejected) && isShowCalling) {
      dispatch(CallingActions.callingReset());
      setCallingDialogStatus(CALLING_DIALOG_MODE.none);
    }
  }, [isInAnotherCall, isCallRejected]);

  const componentProps = { open: true, data, classes, onClick: handleCallingBackgroundFunc, onAccept, onDeny };

  if (!callingGroupDetail) return <Fragment />;

  return (
    <>
      {callingDialogStatus === CALLING_DIALOG_MODE.incomingFullscreen && (
        <CallingNotificationDialog {...componentProps} />
      )}
      {callingDialogStatus === CALLING_DIALOG_MODE.incomingBackground && <CallingBackground {...componentProps} />}
    </>
  );
};

export default memo(CallingNotification);

const CallingBackground = ({ data, onClick, classes, onAccept, onDeny, ...otherProps }) => {
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);

  return (
    <Dialog
      hideBackdrop={true}
      disableEnforceFocus={true}
      classes={{
        root: clsx(classes.smallWrapperRoot, {
          [classes.smallWrapperRootInChat]: Boolean(selectedGroupId),
        }),
        paper: classes.smallWrapper,
      }}
      {...otherProps}
    >
      <Box onClick={onClick} className={classes.callDetailWrapper}>
        <Box className={classes.callDetail}>
          <Call />
          <Typography className={clsx(classes.title, classes.hiddenTitle)}>{data.title}</Typography>
        </Box>
        <Box className={classes.buttonWrapper}>
          <IconButton className={clsx(classes.smallButton, classes.smallAcceptButton)} onClick={onAccept}>
            <Call />
          </IconButton>
          <IconButton className={clsx(classes.smallButton, classes.smallDenyButton)} onClick={onDeny}>
            <CallEnd />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const CallingNotificationDialog = ({ data, isHidden, onClick, classes, onAccept, onDeny, ...otherProps }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);

  return (
    <Dialog
      classes={{
        paper: classes.wrapper,
      }}
      {...otherProps}
    >
      <Box className={classes.smallButtonWrapper}>
        <IconButton className={classes.minimizeButton} onClick={onClick}>
          <HorizontalRule />
        </IconButton>
        <IconButton className={classes.closeButton} onClick={onDeny}>
          <Close />
        </IconButton>
      </Box>
      <Typography className={clsx(classes.modalTitle, "bold-xl-txt")}>
        {getLabel(LangConstant.TXT_INCOMING_CALL)}
      </Typography>
      <Box className={classes.modalDetailWrapper}>
        <AvatarGroup
          avatarId={data.avatarId}
          group={{
            groupName: data?.senderName,
          }}
        />
        <Box className={classes.textWrapper}>
          <Typography className={classes.title}>{data.title}</Typography>
          <Typography className={clsx(classes.description, "regular-sm-txt")}>
            {getLabel(LangConstant.TXT_INCOMING_CALL_DESC)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button className={classes.denyButton} onClick={onDeny}>
          {getNSLang(LangConstant.NS_COMMON, LangConstant.TXT_DENIED)}
        </Button>
        <Button className={classes.acceptButton} variant="contained" onClick={onAccept}>
          {getNSLang(LangConstant.NS_COMMON, LangConstant.TXT_CALL_ACCEPT)}
        </Button>
      </Box>
      <audio className="hidden" src={SoundConstant.CallingRingtoneSound} autoPlay loop />
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 532,
    padding: "32px 24px",
    borderRadius: 20,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },

  modalTitle: {
    textAlign: "center",
    lineHeight: "27px",
    color: theme.palette.black,
    marginBottom: 8,
  },

  modalDetailWrapper: {
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  avatar: {
    width: 50,
    height: 50,
  },

  textWrapper: {
    marginLeft: 12,
  },

  title: {
    marginBottom: 2,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: "20px",
    color: theme.palette.black,
  },

  description: {
    lineHeight: "20px",
    color: theme.palette.grey[500],
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },

  acceptButton: {
    width: 188,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
  },

  denyButton: {
    width: 188,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    marginRight: 4,
  },

  smallWrapperRoot: {
    top: 32,
    right: 12,
    left: "unset",
    bottom: "unset",
    width: "100%",
    transition: "all .1s ease-in-out",
    maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.medium})`,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up(1500)]: {
      maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.large}px)`,
    },
  },

  smallWrapper: {
    width: "100%",
    maxWidth: "unset",
    borderRadius: 10,
    backgroundColor: theme.palette.grey[800],
    cursor: "pointer",
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: 58,
    marginTop: 0,
    marginBottom: 0,
    boxShadow: "none",
  },

  callDetailWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
  },

  callDetail: {
    display: "flex",
    color: theme.palette.white,
  },

  smallButtonWrapper: {
    position: "absolute",
    top: 12,
    right: 24,
  },

  minimizeButton: {
    marginRight: 4,
    color: theme.palette.black,
  },

  closeButton: {
    color: theme.palette.black,
  },

  hiddenTitle: {
    color: theme.palette.white,
    marginLeft: 15,
  },

  smallButton: {
    color: theme.palette.white,
  },

  smallAcceptButton: {
    backgroundColor: "#30CC45",
    marginRight: 12,
    "&:hover": {
      backgroundColor: "#5AB767",
    },
  },

  smallDenyButton: {
    backgroundColor: "#FF2C28",
    "&:hover": {
      backgroundColor: "#D6605C",
    },
  },

  smallWrapperRootInChat: {
    top: 122,
  },
}));
