import { useEffect, useRef, useState } from "react";
import { StorageUtil, isArrayNotEquals } from "utils";
import { AppConstant, KeyConstant } from "const";

const EVENT_UPDATE_EMOJI = "update-emoji";
const useFavoriteEmoji = () => {
  const storageEmoji = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
  const emojiRef = useRef();

  const [favoriteEmoji, setStaticEmoji] = useState(storageEmoji || AppConstant.DEFAULT_EMOJI);

  const updateFavoriteEmoji = emojiList => {
    if (isArrayNotEquals(storageEmoji, emojiList)) {
      StorageUtil.setItem(KeyConstant.KEY_EMOJI_STATIC_LIST, emojiList);
      emojiRef.current = true;
      window.dispatchEvent(new Event(EVENT_UPDATE_EMOJI));
    }
  };

  useEffect(() => {
    const changeEmojiInStorage = () => {
      // Do nothing if hook is updated by itself
      if (emojiRef.current) {
        emojiRef.current = false;
        return;
      }

      const newEmojiList = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
      setStaticEmoji(newEmojiList);
    };

    window.addEventListener(EVENT_UPDATE_EMOJI, changeEmojiInStorage);

    return () => {
      window.removeEventListener(EVENT_UPDATE_EMOJI, changeEmojiInStorage);
    };
  }, []);

  return { favoriteEmoji, updateFavoriteEmoji };
};

export default useFavoriteEmoji;
