export default {
  TXT_APP_NAME: "Trios",
  TXT_PHONE_NUMBER: "Nhập số điện thoại",
  TXT_CONTINUE: "Tiếp tục",
  TXT_ACTIVE: "Đang hoạt động",
  TXT_INACTIVE: "Vắng mặt",
  TXT_OTHER: "Other",
  TXT_EATING: "Eating",
  TXT_BEAUTY: "Beauty",
  TXT_SPORT: "Sport",
  TXT_HEALTH: "Heath",
  TXT_PET: "Pet",
  TXT_REPAIR: "Repair",
  TXT_OK: "OK",
  TXT_RETRY: "Thử lại",
  TXT_CONTACT_PHONE: "+84-24-3204-5456",
  TXT_CONTACT_EMAIL: "contact@bglobalcorp.com",
  TXT_COMPANY_NAME: "bGlobal",
  TXT_DELETE: "Xóa",
  TXT_REPLY: "Trả lời",
  TXT_SEARCH: "Tìm kiếm",
  TXT_ACCEPT: "Chấp nhận",
  TXT_DENIED: "Từ chối",
  TXT_CANCEL: "Hủy",
  TXT_CONFIRM: "Xác nhận",
  TXT_POPUP_DOCUMENT: "Tài liệu",
  TXT_CALL_ACCEPT: "Trả lời",
  TXT_POPUP_CAMERA: "Camera",
  TXT_POPUP_GALLERY: "Bộ sưu tập",
  TXT_POPUP_AUDIO: "Âm thanh",
  TXT_PLACEHOLDER: "Nhập tin nhắn...",
  TXT_DEFAULT_USER_NAME: "No name",
  TXT_HOUR: "giờ",
  TXT_MINUTE: "phút",
  TXT_SECOND: "giây",
  TXT_MONTH: "tháng",
  TXT_DAY: "ngày",
  TXT_YEAR: "năm",
  TXT_COPY: "Sao chép",
  TXT_EDIT: "Chỉnh sửa",
  TXT_KEYWORDS: "Từ khoá",
  TXT_CLEAR_ALL: "Xoá toàn bộ",
  TXT_NOT_FOUND: "Không tìm thấy kết quả phù hợp",
  TXT_NO_SEARCH_KEYWORDS: "Không có tìm kiếm",
  TXT_CONTACT_FOUND: "Liên hệ tìm thấy",
  TXT_GROUP_FOUND: "Nhóm tìm thấy",
  TXT_CHANNEL_FOUND: "Kênh tìm thấy",
  TXT_MESSAGE_FOUND: "Tin nhắn tìm thấy",
  TXT_CLOSE: "Đóng",
  TXT_ABOUT_US:
    " Sáng tạo - Cộng tác - Chất lượng là những giá trị cốt lõi trong hoạt động kinh doanh. Tư duy của chúng tôi là không ngừng đổi mới và luôn  luôn sáng tạo. Trong môi trường làm việc sáng tạo của chúng tôi, mỗi cá nhân đều có thể chia sẻ ý tưởng và được truyền cảm hứng. Các ý tưởng sẽ được nuôi dưỡng, mài giũa và được đưa vào cuộc sống. Bằng cách đó, chúng tôi xây dựng đội ngũ ưu tú của mình. Chúng tôi tin tưởng mạnh mẽ rằng đội ngũ ưu tú và tinh thần đồng đội sẽ là động lực giúp công ty tiến về phía trước. Mỗi thành viên trong nhóm đều hiểu tầm quan trọng của Chất lượng ảnh hưởng thế nào đến các mục tiêu thành công của công ty. Chính vì vậy, chúng tôi dành thời gian để xây dựng văn hóa chất lượng bằng cách thống nhất các tiêu chuẩn và các kỳ vọng để tạo lập và không ngừng cải thiện chất lượng trong hoạt động kinh doanh của mình.",
  TXT_CUSTOMIZE: "Tùy chỉnh",
  TXT_RESET: "Đặt lại",
  TXT_SAVE: "Lưu",
  TXT_SESSION_EXPIRED: "Hết hạn phiên đăng nhập",
  TXT_DOWNLOAD: "Tải về",
  TXT_YES: "CÓ",
  TXT_NO: "KHÔNG",
  TXT_HIDE: "Ẩn",
  TXT_SERVER_ERROR: "Có lỗi trong quá trình thực hiện. Vui lòng thử lại sau",
  TXT_PASSCODE_TITLE: "Nhập PassCode",
  TXT_PASSCODE_SUBTITLE:
    "Passcode được sử dụng để mã hóa file Backup. Vui lòng ghi nhớ để thực hiện Đồng bộ dữ liệu khi cần thiết.",
  TXT_RESTORE_PASSCODE_SUBTITLE: "Vui lòng nhập passcode để thực hiện đồng bộ dữ liệu",
  TXT_RESTORE_CONFIRM: "Xác nhận đồng bộ dữ liệu",
  TXT_BACKUP: "Sao lưu dữ liệu",
  TXT_RESTORE: "Khôi phục dữ liệu",
  TXT_BACKUP_INSTRUCTION:
    "Để bảo vệ dữ liệu sao lưu của bạn, hãy tạo mã sao lưu dữ liệu. Mã sao lưu dữ liệu được sử dụng để phục hồi dữ liệu trên các thiết bị",
  TXT_BACKUP_WARNING:
    "Lưu ý: Mã sao lưu dữ liệu chỉ có hiệu lực với bản sao lưu hiện tại. Mã sao lưu dữ liệu sẽ không được cung cấp lại. Vui lòng ghi nhớ để phục hồi dữ liệu",
  TXT_PASSCODE_INSTRUCTION: "Vui lòng nhập mã 6 chữ số để sử dụng cho việc phục hồi dữ liệu",
  TXT_CREATE_PASSCODE: "Tạo mã sao lưu",
  TXT_BACKUP_SUCCESS: "Tạo mã sao lưu dữ liệu thành công",
  TXT_NO_BACKUP: "Chưa có bản sao lưu dữ liệu. Vui lòng tạo bản sao lưu dữ liệu trên thiết bị chính của bạn.",
  TXT_RESTORE_ACTION: "Khôi phục",
  TXT_RESTORE_INSTRUCTION: "Vui lòng nhập mã 6 chữ số để thực hiện quá trình khôi phục dữ liệu",
  TXT_BLOCKED_RESTORE: "Khôi phục dữ liệu bị tạm khóa",
  TXT_BLOCKED_RESTORE_CONTENT:
    "Chúng tôi tạm khóa việc khôi phục dữ liệu của bạn vì 3 lần nhập sai mã. Vui lòng thử lại sau 5 phút",
  TXT_GOT_IT: "Đã hiểu",
  TXT_KEEP_CONNECT_INTERNET: "Hãy đảm bảo thiết bị của bạn có kết nối với internet",
  TXT_NOT_CONNECT: "Không thể kết nối",
  TXT_NOT_CONNECT_INFO: "Hãy đảm bảo thiết bị của bạn kết nối internet",
  TXT_RESTORE_SUCCESS: "Phục hồi dữ liệu thành công",
  TXT_CONFIRM_LOGOUT_MASTER: "Cấp quyền thiết bị",
  TXT_CONTENT_LOGOUT_MASTER:
    "Để tránh mất dữ liệu, hãy tạo bản sao lưu dữ liệu trước đó. Sau đó chọn thiết bị cấp quyền chính khác",
  TXT_CHOOSE_DEVICE: "Chọn thiết bị cấp quyền",
  TXT_CHOOSE_DEVICE_CONFIRM: "Chọn thiết bị",
  TXT_CHOOSE_DEVICE_SUCCESS: "Chuyển quyền thiết bị thành công",
  TXT_LOGOUT_LATER: "Hệ thống sẽ thực hiện đăng xuất trong giây lát",
  TXT_CONTENT_LOGOUT_SLAVE: "Bạn có muốn đăng xuất trên thiết bị hiện tại không?",
  TXT_LOSE_MASTER_PERMISSION: "Thiết bị của bạn đã mất quyền sao lưu dữ liệu do không còn là thiết bị chính",
  TXT_BLOCK_CHANGE_DEVICE_ROLE:
    "Mã OTP sai. Việc thực hiện chuyển quyền tạm thời bị khoá. Vui lòng thực hiện lại sau 5 phút.",
  TXT_LOGOUT_MASTER_WITHOUT_SLAVE:
    "Chúng tôi khuyến khích bạn tạo bản sao lưu dữ liệu để tránh mất dữ liệu sau khi đăng xuất. Bạn có muốn tiếp tục? ",
  TXT_CHECKING_RESTORE_DATA: "Đang lấy thông tin ...",
  TXT_CREATE_THIRD_TOKEN: "Đang thực hiện đăng nhập vào hệ thống",
  TXT_WAIT_MINUTE: "Vui lòng chờ...",
  TXT_NO_DATA: "Không có dữ liệu",
  TXT_LOGGED_OUT_BRANCH: "Chưa đăng nhập",
  TXT_CHOOSE_BRANCH: "Chuyển đến tổ chức",
  TXT_LOGOUT_ALL: "Đăng xuất tất cả",
  TXT_LOGOUT_WARNING: "Vui lòng chuyển quyền thiết bị chủ đối với branch còn lại",
  TXT_KICKED_USER: "Tài khoản của bạn đã bị vô hiệu hóa. Chúng tôi sẽ thực hiện đăng xuất sau 5s",
  TXT_NOTIFICATION: "Thông báo",
  TXT_SEE_INFORMATION: "Xem thông tin",
  TXT_DETAIL_MESSAGE: "Chi tiết tin nhắn",
  TXT_KICKED_OUT_GROUP: "Bạn không là thành viên của {{ groupName }}",
  TXT_FORWARD: "Chuyển tiếp",
  TXT_SEND: "Gửi",
  TXT_FORWARD_SUCCESS: "Chuyển tiếp thành công!",
  TXT_FORWARD_FAILED: "Chuyển tiếp thất bại!",
  TXT_ALL: "Tất cả",
  TXT_TRY_OTHER_KEYWORD: "Hãy thử từ khóa khác",
  TXT_FINDING_FILE: "Tìm kiếm tệp",
  TXT_IMAGE_ERROR: "Lỗi ảnh gốc",
  TXT_OPEN_FOLDER: "Mở thư mục",
  TXT_SELECT_ALL: "Chọn tất cả",
  TXT_ME_LABEL: "(Tôi)",
  TXT_DRAFT_LABEL: "Chưa gửi:",

  FM_PASSCODE_BLOCKED: "Bạn đã nhập sai Passcode 3 lần. Vui lòng thử lại sau {{ countDown }}",
  FM_RESTORE_SUBTITLE: "Bạn có muốn đồng độ dữ liệu với bản backup ngày {{ backupTime }} không?",
  FM_PASSCODE_WRONG: "Sai mã sao lưu. Bạn còn {{ retries }} lần thử lại",
  FM_TOTAL: "Tổng số: <span>{{count}}</span>/{{limit}}",
  FM_DOWNLOAD_FILES: "Tải xuống thành công: {{count}}/{{total}}",
  FM_DELETE: "Xóa ({{ number }})",

  OBJ_ACC_STATUS: {
    away: "Vắng mặt",
    active: "Hoạt động",
  },

  OBJ_BACKUP_INFO: {
    title: "Thông tin bản sao lưu dữ liệu",
    deviceBackupName: "Tên thiết bị sao lưu",
    backupTime: "Thời gian tạo",
    size: "Dung lượng",
  },

  ARR_DAY_OF_WEEK: ["T2", "T3", "T4", "T5", "T6", "T7", "CN"],

  OBJ_SEND_MES_TYPE: {
    3: "Hình Ảnh",
    4: "Tài liệu",
    5: "Âm thanh",
    6: "Video",
    11: "Biểu tượng cảm xúc",
  },
  CONFIRM_DELETE_MESSAGE: "Xác nhận xóa tin nhắn",
  CONFIRM_DELETE_MESSAGE_CONTENT: "Bạn có chắc muốn xóa tin nhắn?",

  ERR_TITLE: "Lỗi",
  ERR_UNSUPPORTED_EXTENSION: "Chỉ hỗ trợ định dạng: MP4, WebM, Ogg/Ogv",
  ERR_NOT_ACCEPTABLE: "Tệp tải lên không đúng định dạng",
  ERR_LIMIT_SIZE: "Tệp tải lên lớn hơn {{size}}MB",
  ERR_IMAGE_FAILED_LOADING: "Lỗi ảnh gốc, hãy kiểm tra lại",

  WARNING_TITLE: "Cảnh báo",
  FM_DIFFERENT_TIME_SERVER: `Giờ trên thiết bị <b>{{deviceName}}</b> đang bị lệch <b>{{second}} giây</b> so với giờ hệ thống. Vui lòng điều chỉnh lại chính xác sau đó khởi động lại ứng dụng để đảm bảo hệ thống hoạt động ổn định`,
  FM_SWITCH_BRANCH: `Bạn đã đăng nhập vào {{branchName}} bằng tài khoản {{ phone }}. Bạn có muốn đăng xuất không?`,
};
