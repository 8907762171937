import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { LangConstant } from "const";
import { Circle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const AccountStatus = ({ isActive }) => {
  const { t: getLabel } = useTranslation();
  const statusObjContent = getLabel(LangConstant.OBJ_ACC_STATUS, { returnObjects: true });
  const classes = useStyles();
  let statusText = isActive ? statusObjContent.active : statusObjContent.away;

  return (
    <Box className={classes.root}>
      {isActive ? <Circle className={classes.active} /> : <Circle className={classes.away} />}
      <Typography className={classes.text}>{statusText}</Typography>
    </Box>
  );
};

AccountStatus.propTypes = {
  isActive: PropTypes.bool,
};

export default memo(AccountStatus);

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
    marginBottom: 12,

    "& > svg": {
      width: 12,
      height: 12,
      marginRight: 8,
    },
  },
  active: {
    color: "#35CC3F",
  },
  away: {
    color: "red",
  },
  text: {
    fontSize: 15,
    fontWeight: 400,
  },
});
