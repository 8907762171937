import Account from "../Account";
import SelectServer from "./SelectServer";
import ViewModeTabs from "./ViewModeTabs";
const Sidebar = () => {
  return (
    <>
      <Account />
      <SelectServer />
      <ViewModeTabs />
    </>
  );
};

export default Sidebar;
