import React, { useEffect, useState } from "react";
import StringFormat from "string-format";
import { Box, Dialog, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { FormatConstant, KeyConstant, LangConstant } from "const";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { AvatarInfo, TabPanel } from "components";
import { formatEmoij, StorageUtil } from "utils";
import { getInteractor } from "services/local.service";

const ReactionDetailDialog = props => {
  const { data, open, onClose } = props;
  const classes = useStyles();

  const [tab, setTab] = useState(0);
  const [reactionTabs, setReactionTabs] = useState([]);

  const onChangeTab = (e, tab) => {
    setTab(tab);
  };

  useEffect(() => {
    let handleReactionObj = data.reduce((newObj, react) => {
      let emoji = formatEmoij(react.content);
      newObj[emoji] = (newObj[emoji] || []).concat(react);

      return newObj;
    }, {});

    let handleReactionArr = Object.entries(handleReactionObj).map(([emoji, data]) => {
      return { emoji: emoji, data: data };
    });

    setReactionTabs(handleReactionArr);
  }, [data]);

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <Box className={classes.closeButton} onClick={onClose}>
        <Close fontSize="small" />
      </Box>
      <Typography className={classes.heading}>
        {getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_REACTIONS)}
      </Typography>
      <Tabs value={tab} onChange={onChangeTab} className={classes.tabControls}>
        <Tab label={getCommonLang(LangConstant.TXT_ALL)} />
        {reactionTabs.map(reaction => (
          <Tab
            key={reaction.emoji}
            icon={
              <Box
                dangerouslySetInnerHTML={{
                  __html: StringFormat(
                    FormatConstant.FM_EMOJI_TAB_TITLE,
                    formatEmoij(reaction.emoji),
                    reaction.data.length,
                  ),
                }}
              />
            }
          />
        ))}
      </Tabs>
      <TabPanel index={0} value={tab}>
        {data.map(reaction => (
          <ReactionItem data={reaction} key={reaction.id} />
        ))}
      </TabPanel>
      {reactionTabs.map((reaction, index) => (
        <TabPanel key={reaction.emoji} index={index + 1} value={tab}>
          {reaction.data.map(react => (
            <ReactionItem data={react} key={react.id} />
          ))}
        </TabPanel>
      ))}
    </Dialog>
  );
};

export default ReactionDetailDialog;

const ReactionItem = props => {
  const { data } = props;
  const classes = useStyles();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const [senderInfo, setSenderInfo] = useState({ name: "" });

  useEffect(() => {
    if (data) {
      getInteractor()
        .LocalAccountService.get(data.senderId, branchId)
        .then(sender => setSenderInfo(sender || {}));
    }
  }, [data]);

  return (
    <Box key={data.id} className={classes.reactionItem}>
      <Box className={classes.accountDetail}>
        <AvatarInfo avatar={{ avatarId: data.accountAvatarId, name: senderInfo.name }} title={senderInfo.name} />
      </Box>
      <Box className={classes.reaction} dangerouslySetInnerHTML={{ __html: formatEmoij(data.content) }} />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    backgroundColor: theme.palette.white,
    borderRadius: 20,
    boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.1)",
    padding: 16,
    width: 680,
  },

  heading: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "27px",
    color: theme.palette.black,
    textAlign: "center",
    marginTop: 8,
    marginBottom: 26,
  },

  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
    width: 36,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(127, 127, 128, 0.05)",
    borderRadius: "50%",
    cursor: "pointer",
  },

  tabControls: {
    marginBottom: 20,
  },

  reactionItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },

  accountDetail: {
    display: "flex",
    alignItems: "center",
  },

  accountAvatar: {
    marginRight: 12,
  },

  accountName: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.black,
  },

  reaction: {
    fontSize: 24,
  },
}));
