"use strict";
import * as ImageConstant from "./image.const";
import * as SoundConstant from "./sound.const";
import LangConstant from "./lang.const";

const deepCloneJsonObject = json => {
  if (!json) return null;
  return JSON.parse(JSON.stringify(json));
};
const SystemConstant = deepCloneJsonObject(window.TriosConstant.system);
const ApiConstant = deepCloneJsonObject(window.TriosConstant.api);
const PathConstant = deepCloneJsonObject(window.TriosConstant.path);
const KeyConstant = deepCloneJsonObject(window.TriosConstant.key);
const FormatConstant = deepCloneJsonObject(window.TriosConstant.format);
export const AppConstant = {
  VIEW_MODE: {
    fetching: 1,
    empty: 2,
    list: 3,
  },
  ...window.TriosConstant.app,
};

export {
  SystemConstant,
  LangConstant,
  ApiConstant,
  PathConstant,
  KeyConstant,
  FormatConstant,
  ImageConstant,
  SoundConstant,
};
