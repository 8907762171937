import { useEffect, useState } from "react";
import { ApiConstant, KeyConstant } from "const";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services";
import { getInteractor } from "services/local.service";

const LIMIT_RETRY = 3;
const MigrateVersion48 = () => {
  const selectedBranchId = useSelector(BranchSelectors.getSelectedBranchId);
  const currentPrefixKey = StorageUtil.getCurrentPrefixKey();

  const [retryNum, setRetryNum] = useState({});

  const handleMigrateBranchAccount = async prefixKey => {
    try {
      const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
      const payload = {
        branch_ids: JSON.stringify([
          {
            branch_id: currentBranchId,
            last_time: 0,
          },
        ]),
      };

      const response = await remoteApiFactory.getBranchApi(prefixKey).getBranchAccount(payload);
      if (response.status === ApiConstant.STT_OK) {
        const remoteBranchAccount = response.data;

        // TODO: Update same logic in save function of generic.table.js
        let lastIndex = 100;
        let offset = 0;
        let saveItems = [];
        do {
          saveItems = remoteBranchAccount.slice(offset, lastIndex);
          await getInteractor(prefixKey).LocalBranchAccountService.save(saveItems);

          offset = lastIndex;
          lastIndex += 100;
        } while (saveItems.length === 100);

        StorageUtil.setItem(KeyConstant.KEY_MIGRATE_BRANCH_ACCOUNT, 1, prefixKey);
      } else if (retryNum[prefixKey] >= LIMIT_RETRY) {
        // Reset
        setRetryNum(preState => ({ ...preState, [prefixKey]: 0 }));
      } else if (retryNum[prefixKey] < LIMIT_RETRY) {
        // Retry
        setRetryNum(retryState => ({ ...retryState, [prefixKey]: retryState[prefixKey] + 1 }));
        await handleMigrateBranchAccount(prefixKey);
      }
    } catch (error) {
      console.error(error);
      // Retry
      setRetryNum(retryState => ({ ...retryState, [prefixKey]: retryState[prefixKey] + 1 }));
      await handleMigrateBranchAccount(prefixKey);
    }
  };

  useEffect(() => {
    const migrateTimeout = setTimeout(() => {
      const isNeedMigrate =
        false === Boolean(StorageUtil.getItem(KeyConstant.KEY_MIGRATE_BRANCH_ACCOUNT, currentPrefixKey));
      if (isNeedMigrate) {
        setRetryNum(preState => ({ ...preState, [currentPrefixKey]: 0 }));
        handleMigrateBranchAccount(currentPrefixKey);
      }
    }, 100);

    return () => clearTimeout(migrateTimeout);
  }, [selectedBranchId, currentPrefixKey]);

  return null;
};

export default MigrateVersion48;
