import React, { useState, memo, useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Button, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ExpandLess, ExpandMore, AddOutlined } from "@mui/icons-material";
import AddingChannelDrawer from "../../AddingChat/AddingChannel/AddingChannelDrawer";
import AddingPersonalDrawer from "../../AddingChat/AddingPersonalDrawer";
import AddingGroupDrawer from "../../AddingChat/AddingGroup/AddingGroupDrawer";
import useConversation, { LIMIT_DISPLAY } from "hooks/useConversationList";
import ChatGroupItem from "./ChatGroupItem";
import { MarkReadMsgIcon } from "components/icons";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";

const ChatAllTab = ({ type }) => {
  const classes = useStyles();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const dispatch = useDispatch();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { groupList, totalItems, getConversation } = useConversation(type);

  const [isExpand, setIsExpand] = useState(true);
  const [isShowDrawer, setIsShow] = useState(false);

  const [isAddingChannelDrawer, isAddingGroupDrawer, isAddingPersonalDrawer] = useMemo(
    () => [
      isShowDrawer && type === AppConstant.CONVERSATION_CATEGORY.channel,
      isShowDrawer && type === AppConstant.CONVERSATION_CATEGORY.group,
      isShowDrawer && type === AppConstant.CONVERSATION_CATEGORY.personal,
    ],
    [isShowDrawer],
  );

  const handleChangeList = () => {
    const newLength = groupList.length + LIMIT_DISPLAY;
    getConversation(newLength);
  };

  const handleExpand = () => {
    setIsExpand(!isExpand);
    getConversation(LIMIT_DISPLAY);
  };

  const handleControlDrawer = open => () => setIsShow(open);

  const handleReadAll = async () => {
    const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
    const allUnreadMessages = await getInteractor(prefixKey).LocalMessageService.getUnreadMessageInGroup({
      branch_id: branchId,
    });

    const updateMessageIds = allUnreadMessages.map(item => item.id);
    dispatch(
      ConversationActions.updateMessageStatus({
        isReadAll: true,
        messageIds: updateMessageIds,
        status: SystemConstant.MESSAGE_STATUS.read,
      }),
    );
  };

  let content = getLabel("FM_GROUP_TAG", { number: totalItems });
  switch (type) {
    case AppConstant.CONVERSATION_CATEGORY.unread:
      content = getLabel("FM_UNREAD_CHAT", { number: totalItems });
      break;

    case AppConstant.CONVERSATION_CATEGORY.favorite:
      content = getLabel("FM_FAVORITE_CHAT", { number: totalItems });
      break;

    case AppConstant.CONVERSATION_CATEGORY.channel:
      content = getLabel("FM_CHANNEL_TAG", { number: totalItems });
      break;

    case AppConstant.CONVERSATION_CATEGORY.personal:
      content = getLabel("FM_PERSONAL_TAG", { number: totalItems });
      break;

    default:
      break;
  }
  const hasMore = Boolean(groupList.length > 0 && totalItems > groupList.length);

  return (
    <Box className={totalItems === 0 && !CONVERSATION_GROUP_TYPE.includes(type) && "hidden"}>
      <Box className={classes.category}>
        <Box className={classes.categoryName}>
          <Typography className={classes.title}>{content}</Typography>
          <IconButton onClick={handleExpand}>{isExpand ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </Box>

        {type === AppConstant.CONVERSATION_CATEGORY.unread && (
          <IconButton
            color="primary"
            title={getLabel("TXT_READ_ALL_MSG_TITLE")}
            disabled={totalItems === 0}
            onClick={handleReadAll}
          >
            <MarkReadMsgIcon />
          </IconButton>
        )}

        {CONVERSATION_GROUP_TYPE.includes(type) && (
          <IconButton color="primary" onClick={handleControlDrawer(true)}>
            <AddOutlined />
          </IconButton>
        )}
      </Box>

      <Collapse in={isExpand}>
        {groupList.map(item => (
          <ChatGroupItem key={item.id} data={item} />
        ))}

        {hasMore && (
          <Divider className={classes.viewMore}>
            <Button variant="text" className={classes.viewMoreButton} onClick={handleChangeList}>
              {getLabel(LangConstant.TXT_VIEW_MORE)}
            </Button>
          </Divider>
        )}
      </Collapse>

      {isAddingChannelDrawer && (
        <AddingChannelDrawer open onComplete={handleControlDrawer(false)} onClose={handleControlDrawer(false)} />
      )}

      {isAddingGroupDrawer && (
        <AddingGroupDrawer open onComplete={handleControlDrawer(false)} onClose={handleControlDrawer(false)} />
      )}

      {isAddingPersonalDrawer && <AddingPersonalDrawer open onClose={handleControlDrawer(false)} />}
    </Box>
  );
};

ChatAllTab.propTypes = {
  type: PropTypes.number,
};

ChatAllTab.defaultProps = {
  type: AppConstant.CONVERSATION_CATEGORY.personal,
};

export default memo(ChatAllTab);

const CONVERSATION_GROUP_TYPE = [
  AppConstant.CONVERSATION_CATEGORY.channel,
  AppConstant.CONVERSATION_CATEGORY.group,
  AppConstant.CONVERSATION_CATEGORY.personal,
];

const useStyles = makeStyles(() => ({
  chatList: {
    height: "100%",
    overflow: "auto",
    maxHeight: "calc(100vh - 336px)",
  },

  viewMore: {
    margin: "10px 14px",
  },

  viewMoreButton: {
    textTransform: "none",
  },

  category: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  categoryName: {
    display: "flex",
    alignItems: "center",
  },

  title: {
    fontSize: 13,
    color: "gray",
  },
}));
