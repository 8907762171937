import React from "react";
import PropTypes from "prop-types";
import { Drawer, IconButton, ListItem, ListItemText, Typography, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {AppConstant} from "const";


const AddingLayout = ({ title, children, onClose, ...drawerProps }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ root: classes.drawer, paper: classes.paper }}
      hideBackdrop={true}
      onClose={onClose}
      {...drawerProps}
    >
      <ListItem>
        <IconButton onClick={onClose} edge="start" className={classes.btnBack}>
          <Close />
        </IconButton>
        <ListItemText primary={<Typography className={classes.title}>{title}</Typography>} />
      </ListItem>
      <Box overflow="auto">{children}</Box>
    </Drawer>
  );
};

AddingLayout.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,

  onClose: PropTypes.func,
};

export default AddingLayout;

const useStyles = makeStyles(theme => ({
  drawer: {
    "&, & $paper": {
      width: AppConstant.SIDEBAR_WIDTH.medium,
      flexShrink: 0,
      boxShadow: "unset",

      [theme.breakpoints.up(1500)]: {
        width: AppConstant.SIDEBAR_WIDTH.large,
      },
    },
  },
  paper: {
    paddingTop: 8,
  },
  btnBack: { position: "absolute", color: "#424242" },
  title: {
    minHeight: 24,
    fontWeight: 700,
    fontSize: 15,
    color: "#1E272E",
    textAlign: "center",
  },
}));
