import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { CallEnd, Call as CallIcon, MicOff, Mic, Videocam, VideocamOff } from "@mui/icons-material";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import ConversationAction, { ConversationSelectors } from "redux-store/conversation.redux";
import CallingAction from "redux-store/calling.redux";
import { convertString2JSON, toCamel, uuid } from "utils";
import { onEndPhone, onClosePeer } from "utils/call.utils";
import { convertMillisecondsToHMS } from "utils/date.utils";
import { AppConstant, FormatConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import StringFormat from "string-format";
import Conference from "./components/Conference";
import useCallInfo from "hooks/useCallInfo";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";

export const CallingContext = createContext({});

const Call = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const jitsiApiRef = useRef();

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const callingGroupDetail = useSelector(state => state.callingRedux.callingGroupDetail || {});
  const isCallRejected = useSelector(state => state.callingRedux.isCallRejected);
  const isCallEnded = useSelector(state => state.callingRedux.isCallEnded);
  const isCallMissed = useSelector(state => state.callingRedux.isCallMissed);
  const isRequestDeviceFail = useSelector(state => state.callingRedux.isRequestDeviceFail);
  const isNoInternetConnection = useSelector(state => state.callingRedux.isNoInternetConnection);
  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);
  const isHideCallingDialog = useSelector(state => state.callingRedux.isHideCallingDialog);
  const selectedId = useSelector(ConversationSelectors.getSelectedGroupId);
  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const isConnectSuccess = useSelector(state => state.callingRedux.isConnectSuccess);
  const isCallAccepted = useSelector(state => state.callingRedux.isCallAccepted);
  const isReceiver = useSelector(state => state.callingRedux.isReceiver);
  const isVideoCall = useSelector(state => state.callingRedux.isVideoCall);
  const isRinging = useSelector(state => state.callingRedux.isRinging);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isCalleeInAnotherCall = useSelector(state => state.callingRedux.isCalleeInAnotherCall);
  const isDisconnected = useSelector(state => state.callingRedux.isDisconnected);
  const isPingToCallee = useSelector(state => state.callingRedux.isPingToCallee);
  const removedFromGroup = useSelector(state => state.callingRedux.removedFromGroup);
  const lastCallingCall = useSelector(state => state.callingRedux.lastCallingCall);
  const isNotifyEnded = useSelector(state => state.callingRedux.isNotifyEnded);
  const isSendMeetInfoSuccess = useSelector(state => state.callingRedux.isSendMeetInfoSuccess);

  const [deviceList, setDeviceList] = useState([]);
  const [isMute, setIsMute] = useState();
  const [isVideoing, setIsVideoing] = useState();
  const [callingTime, setCallingTime] = useState(1000);
  const [connections, setConnections] = useState([]);
  const [isSaveOutputSettingSuccess, setIsSaveOutputSettingSuccess] = useState(false);
  const [isSaveInputSettingSuccess, setIsSaveInputSettingSuccess] = useState(false);
  const [connectionState, setConnectionState] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [hiddenCallingStatus, setHiddenCallingStatus] = useState("");
  const [selectedScreenStream, setSelectedScreenStream] = useState(null);
  const [sharingList, setSharingList] = useState([]);
  const [handUp, setHandUp] = useState(false);
  const [handUpListId, setHandUpListId] = useState([]);
  const [videoFlagArr, setVideoFlagArr] = useState([]);
  const [mutedArray, setMutedArray] = useState([]);
  const [pinnedList, setPinnedList] = useState([]);
  const { callInfo } = useCallInfo();
  const [remoteStreamArr, setRemoteStreamArr] = useState([
    {
      accountId: accountId,
      stream: null,
    },
  ]);
  const [isAudioAvailable, setIsAudioAvailable] = useState(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  let isCallDisconnected = connectionState === AppConstant.CALL_CONNECTION_STATE.failed;

  const onSendMessage = data => {
    const {
      content,
      parentId,
      threadId,
      callStatus,
      roomId,
      deviceList: customDeviceList = [],
      isReceiver,
      isReconnect = false,
      isRemovedFromGroup = false,
      sourceId = uuid(),
      msgId = uuid(),
      options,
    } = data;
    console.trace(data);
    if (!Boolean(roomId)) return;

    let savedServer = convertString2JSON(StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER));

    let sendType =
      isCallDisconnected || isReconnect || isDisconnected
        ? SystemConstant.SEND_TYPE.reconnect
        : isGroupOrChannelType(callingGroupDetail.groupType)
        ? SystemConstant.SEND_TYPE.conference
        : isVideoCall
        ? SystemConstant.SEND_TYPE.personalVideoCall
        : SystemConstant.SEND_TYPE.personalCall;

    let tmpOptions = "";
    if (sendType === SystemConstant.SEND_TYPE.conference && Boolean(options)) {
      tmpOptions = tmpOptions.concat(JSON.stringify(options));
    }

    if (!isGroupOrChannelType(callingGroupDetail.groupType)) {
      tmpOptions = tmpOptions.concat(
        JSON.stringify({
          is_video_call: Number(isVideoCall),
        }),
      );
    }

    if (isRemovedFromGroup) {
      tmpOptions = tmpOptions.concat(
        JSON.stringify({
          ignore_in_group_f: 1,
        }),
      );
    }

    if (!tmpOptions) {
      tmpOptions = null;
    }

    let saveMessage = {
      id: msgId,
      source_id: sourceId,
      state: 1,
      status: 1,
      thread_id: null,
      call_status: callStatus,
      group_detail: callingGroupDetail,
      send_type: sendType,
      content: content,
      parent_id: parentId,
      deviceList: [...deviceList, ...customDeviceList],
      branch_id: savedServer?.id,
      room_id: roomId,
      is_receiver: Boolean(isReceiver),
      option: tmpOptions,
      created: new Date().getTime(),
    };

    if (!Boolean(parentId)) {
      dispatch(
        ConversationAction.conversationSet({
          createdMessage: {
            ...createdMessage,
            [callingGroupDetail?.id]: toCamel(saveMessage),
          },
        }),
      );
    }

    dispatch(
      ConversationAction.sendMessage({
        groupId: callingGroupDetail.id,
        sendType: sendType,
        content: content,
        parentId: parentId,
        deviceList: [...deviceList, ...customDeviceList],
        branchId: savedServer?.id,
        mentionIdsArr: [],
        threadId: threadId,
        callStatus: callStatus,
        roomId: roomId,
        isReceiver: Boolean(isReceiver),
        option: tmpOptions,
        currentMessage: saveMessage,
      }),
    );
  };

  const onEndGroupCall = () => {
    connections.forEach(con => {
      onClosePeer(con.connection);
    });
  };

  const onFreeResources = () => {
    onEndGroupCall();
    localStream?.getVideoTracks()?.forEach(track => track.stop());
    localStream?.getAudioTracks()?.forEach(track => track.stop());
  };

  const onShowDialog = () => {
    dispatch(CallingAction.callingSet({ isHideCallingDialog: false }));
  };

  const onClickVoiceAction = e => {
    e.stopPropagation();
    setIsMute(!isMute);
  };

  const onStopSharing = () => {
    let video = document.getElementById(
      callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal
        ? KeyConstant.KEY_USER_VIDEO_TAG
        : `${accountId}-video-call`,
    );

    let tracks = localStream.getVideoTracks();
    localStream.removeTrack(
      localStream
        .getVideoTracks()
        .find(track => track.kind === "video" && (track.label === "screen" || track.label === "Screen")),
    );

    let currentTrack = tracks.find(item => item.kind === "video" && item.label !== "screen" && item.label !== "Screen");

    connections.forEach(obj => {
      let con = obj.connection;
      let senders = con?.getSenders();

      if (currentTrack) {
        senders.forEach(sender => {
          if (
            (sender.track?.kind === "video" &&
              (sender.track?.label === "screen" || sender.track?.label === "Screen")) ||
            !sender.track
          ) {
            sender.replaceTrack(currentTrack);
          }
        });
      }
    });

    setIsVideoing(false);

    if (video) {
      video.srcObject = localStream;
    }

    setSelectedScreenStream(null);
  };

  const onChangeSharingList = list => {
    setSharingList(list);
  };

  const onEnd = async (e, isSendForce = false) => {
    e?.stopPropagation();
    if (Boolean(jitsiApiRef) && Boolean(jitsiApiRef.current)) jitsiApiRef.current.dispose();

    console.trace("On end from hidden UI");

    const lastCall = toCamel(await getInteractor().LocalCallHistoryService.getLastByGroupId(callingGroupDetail.id));
    const callMessage = toCamel(
      (await getInteractor().LocalMessageService.findOne({
        source_id: lastCall.sourceId,
      })) || {},
    );
    const isPersonal = callingGroupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    const currentCallingMessage = createdMessage[callingGroupDetail.id];
    StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, null);

    if (
      currentCallingMessage ||
      (lastCall &&
        lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
        JSON.parse(lastCall.callingMembers).includes(deviceId))
    ) {
      const endMessageParent = currentCallingMessage ? currentCallingMessage : callMessage;

      const endContent = {
        room_id: convertString2JSON(endMessageParent?.content)?.room_id,
      };

      if (isPersonal) {
        if (isReceiver) {
          console.log("Send message end to caller");

          onSendMessage({
            content: JSON.stringify(endContent),
            parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
            threadId: null,
            callStatus: SystemConstant.MESSAGE_CALL_STATUS.end,
            roomId: endContent.room_id,
          });

          dispatch(
            CallingAction.callingSet({
              createdMessage: {
                ...createdMessage,
                [callingGroupDetail?.id]: null,
              },
            }),
          );
        } else {
          if (isCallAccepted) {
            console.log("Send message end to receiver");

            onSendMessage({
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.end,
              roomId: endContent.room_id,
            });

            dispatch(
              CallingAction.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingGroupDetail?.id]: null,
                },
              }),
            );
          } else {
            console.log("Send message missed to receiver");
            onSendMessage({
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.missed,
              roomId: endContent.room_id,
            });

            dispatch(
              CallingAction.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingGroupDetail?.id]: null,
                },
              }),
            );
          }
        }
      } else if (isGroupOrChannelType(callingGroupDetail?.groupType)) {
        onSendMessage({
          sendType: SystemConstant.SEND_TYPE.conference,
          content: JSON.stringify(endContent),
          parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
          threadId: null,
          callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
          roomId: endContent?.room_id,
          isRemovedFromGroup: isSendForce,
        });

        dispatch(
          CallingAction.callingSet({
            createdMessage: {
              ...createdMessage,
              [callingGroupDetail?.id]: null,
            },
          }),
        );
      }
    }
    isVideoCall && localStream?.getVideoTracks()?.forEach(track => track.stop());
    localStream?.getAudioTracks()?.forEach(track => track.stop());
    connections.forEach(obj => {
      obj.connection.close();
    });

    dispatch(CallingAction.callingReset());
  };

  useEffect(() => {
    return () => {
      if (Boolean(jitsiApiRef) && Boolean(jitsiApiRef.current)) jitsiApiRef.current.dispose();
      dispatch(CallingAction.callingReset());
    };
  }, []);

  useEffect(() => {
    if (Boolean(removedFromGroup) && Boolean(callingGroupDetail) && removedFromGroup === callingGroupDetail?.id) {
      onEnd(null, true);
    }
  }, [removedFromGroup]);

  useEffect(() => {
    const currentLastCall = lastCallingCall.find(item => item.groupId === callingGroupDetail?.id) || {};
    const callingMessageContent = currentLastCall.callingDetail || {};
    const callingRoomId = convertString2JSON(callingMessageContent.content, {}).room_id;
    if (
      isNotifyEnded &&
      isNotifyEnded.groupId === callingGroupDetail?.id &&
      callingGroupDetail?.groupType !== SystemConstant.GROUP_CHAT_TYPE.personal &&
      callingRoomId === isNotifyEnded.roomId
    ) {
      onEnd(null, true);
    }
  }, [isNotifyEnded]);

  useEffect(() => {
    // Close dialog and reset state when the call ended
    if (
      isCallEnded === callingGroupDetail?.id ||
      (isCallRejected && callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) ||
      isCallMissed
    ) {
      if (callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        onEndPhone();
      }

      if (Boolean(jitsiApiRef) && Boolean(jitsiApiRef.current)) jitsiApiRef.current.dispose();
      dispatch(CallingAction.callingReset());
    }
  }, [isCallEnded, isCallRejected, isCallMissed]);

  useEffect(() => {
    if (
      isRequestDeviceFail ||
      isNoInternetConnection ||
      (isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking &&
        (isCalleeInAnotherCall || isInAnotherCall || !isSendMeetInfoSuccess))
    ) {
      if (callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        onEndPhone();
      }
      setTimeout(() => {
        dispatch(CallingAction.callingReset());
      }, 2000);
    }
  }, [
    isRequestDeviceFail,
    isNoInternetConnection,
    isInAnotherCall,
    isCalleeInAnotherCall,
    isOpenCallingDialog,
    isSendMeetInfoSuccess,
  ]);

  useEffect(() => {
    if (callingGroupDetail && hasInternet && !callingGroupDetail.inCall) {
      let groupMembers = callingGroupDetail?.groupMembers;
      // Get device list of members in group
      if (groupMembers?.length > 0 && !isCallDisconnected) {
        let memberIdArray = groupMembers.map(member => member.id) || [];
        if (callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
          memberIdArray.push(accountId);
        }

        getInteractor()
          .LocalDeviceService.find({ account_id: memberIdArray })
          .then(deviceList => setDeviceList(deviceList || []));
      }
    }
  }, [callingGroupDetail?.groupMembers]);

  useEffect(() => {
    const isInSingleCall = isCallAccepted && callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    const isInConferenceCall = isConnectSuccess && isGroupOrChannelType(callingGroupDetail?.groupType);
    if (!isCallEnded && (isInSingleCall || isInConferenceCall)) {
      let timeObj = convertMillisecondsToHMS(callingTime, true);
      let newtime =
        parseInt(timeObj.hours) > 0
          ? StringFormat(FormatConstant.FM_CALL_HMS, timeObj)
          : StringFormat(FormatConstant.FM_CALL_MS, timeObj);

      setHiddenCallingStatus(newtime);
    }
  }, [callingTime, isCallAccepted, isConnectSuccess]);

  useEffect(() => {
    let reconnectTimeout = null;

    if (isPingToCallee) {
      if (isInAnotherCall) {
        setHiddenCallingStatus(getLabel(LangConstant.TXT_IN_ANOTHER_CALL));
      } else if (isCalleeInAnotherCall) {
        setHiddenCallingStatus(getLabel(LangConstant.TXT_RECEIVER_IN_ANOTHER_CALL));
      }
    }

    if (!isConnectSuccess && !isCallAccepted) {
      setHiddenCallingStatus(getLabel(LangConstant.TXT_CALLING));
    } else if (isRinging) {
      setHiddenCallingStatus(getLabel(LangConstant.TXT_RINGING));
    } else if (isCallEnded === callingGroupDetail?.id) {
      setHiddenCallingStatus(getLabel(LangConstant.TXT_CALL_FINISH));
    } else if (isCallRejected) {
      setHiddenCallingStatus(getLabel(LangConstant.TXT_CALL_REJECTED));
    } else if (isCallMissed && !isDisconnected) {
      setHiddenCallingStatus(getLabel(LangConstant.TXT_MISSED_CALL));
    } else if (isDisconnected && !isCallMissed) {
      setHiddenCallingStatus(getLabel(LangConstant.TXT_RECONNECTING));
    } else if (isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking && !isSendMeetInfoSuccess) {
      setHiddenCallingStatus(getLabel(LangConstant.TXT_MEET_OBJ));
    }

    if (isDisconnected) {
      reconnectTimeout = setTimeout(() => {
        setHiddenCallingStatus(LangConstant.TXT_FAILED_TO_RECONNECT);
      }, AppConstant.CALL_WAITING_TIME + 15000);
    }

    return () => {
      clearTimeout(reconnectTimeout);
    };
  }, [
    isCallEnded,
    isCallRejected,
    isCallMissed,
    isDisconnected,
    isRinging,
    isConnectSuccess,
    isPingToCallee,
    isInAnotherCall,
    isCalleeInAnotherCall,
    isSendMeetInfoSuccess,
  ]);

  useEffect(() => {
    if (
      callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
      (isCallEnded === callingGroupDetail?.id ||
        isCallMissed ||
        isInAnotherCall ||
        isCalleeInAnotherCall ||
        isCallRejected ||
        !isSendMeetInfoSuccess)
    ) {
      onFreeResources();
    }
  }, [isCallEnded, isCallRejected, isCallMissed, isCalleeInAnotherCall, isInAnotherCall, isSendMeetInfoSuccess]);

  const contextValue = {
    // Properties
    callingGroupDetail: callingGroupDetail,
    isMute: isMute,
    isVideoing: isVideoing,
    callingTime: callingTime,
    deviceList: deviceList,
    connections: connections,
    isSaveOutputSettingSuccess: isSaveOutputSettingSuccess,
    isSaveInputSettingSuccess: isSaveInputSettingSuccess,
    connectionState: connectionState,
    localStream: localStream,
    selectedScreenStream: selectedScreenStream,
    sharingList: sharingList,
    handUp: handUp,
    handUpListId: handUpListId,
    videoFlagArr: videoFlagArr,
    mutedArray: mutedArray,
    pinnedList: pinnedList,
    remoteStreamArr: remoteStreamArr,

    // Methods
    onSendMessage: onSendMessage,
    setIsMute: setIsMute,
    setIsVideoing: setIsVideoing,
    setCallingTime: setCallingTime,
    setConnections: setConnections,
    onEndPhone: onEndPhone,
    onEnd: onEndGroupCall,
    setIsSaveInputSettingSuccess: setIsSaveInputSettingSuccess,
    setIsSaveOutputSettingSuccess: setIsSaveOutputSettingSuccess,
    setConnectionState: setConnectionState,
    setLocalStream: setLocalStream,
    setSelectedScreenStream: setSelectedScreenStream,
    onStopSharing: onStopSharing,
    onChangeSharingList: onChangeSharingList,
    setHandUp: setHandUp,
    setHandUpListId: setHandUpListId,
    setVideoFlagArr: setVideoFlagArr,
    setMutedArray: setMutedArray,
    setPinnedList: setPinnedList,
    setSharingList: setSharingList,
    setRemoteStreamArr: setRemoteStreamArr,
    setIsAudioAvailable: setIsAudioAvailable,
    setIsVideoAvailable: setIsVideoAvailable,
  };

  const isShow = isOpenCallingDialog !== AppConstant.CALLING_STATUS.notStart;
  return (
    <CallingContext.Provider value={contextValue}>
      <Dialog open={isShow} classes={{ root: clsx(isHideCallingDialog && "hidden"), paper: classes.paper }}>
        <Conference ref={jitsiApiRef} />
      </Dialog>
      <Dialog
        open={isHideCallingDialog}
        hideBackdrop={true}
        disableEnforceFocus={true}
        classes={{
          root: clsx(classes.smallWrapperRoot, {
            [classes.smallWrapperRootInChat]: Boolean(selectedId),
          }),
          paper: classes.smallWrapper,
        }}
      >
        <Box className={classes.callDetailWrapper} onClick={onShowDialog}>
          <Box className={classes.callDetail}>
            <CallIcon />
            <Typography className={clsx(classes.title, classes.hiddenTitle, "ellipsis")}>
              {callInfo?.callName || callingGroupDetail?.groupName || "No Name"}
            </Typography>
            <Typography className={classes.hiddenCallingStatus}>({hiddenCallingStatus})</Typography>
          </Box>
          <Box className={classes.buttonWrapper}>
            {!(isCallEnded === callingGroupDetail?.id && isCallRejected) && (
              <>
                {isVideoing ? (
                  <IconButton
                    className={clsx(classes.smallButton, classes.smallActionButton)}
                    onClick={e => {
                      e.stopPropagation();
                      setIsVideoing(!isVideoing);
                    }}
                    disabled={!isVideoAvailable}
                  >
                    <Videocam />
                  </IconButton>
                ) : (
                  <IconButton
                    className={clsx(classes.smallButton, classes.smallActionButtonDisabled)}
                    onClick={e => {
                      e.stopPropagation();
                      setIsVideoing(!isVideoing);
                    }}
                    disabled={!isVideoAvailable}
                  >
                    <VideocamOff />
                  </IconButton>
                )}
              </>
            )}
            {isMute ? (
              <IconButton
                className={clsx(classes.smallButton, classes.smallActionButtonDisabled)}
                onClick={onClickVoiceAction}
                disabled={!isAudioAvailable}
              >
                <MicOff />
              </IconButton>
            ) : (
              <IconButton
                className={clsx(classes.smallButton, classes.smallActionButton)}
                onClick={onClickVoiceAction}
                disabled={!isAudioAvailable}
              >
                <Mic />
              </IconButton>
            )}
            <IconButton className={clsx(classes.smallButton, classes.smallDenyButton)} onClick={onEnd}>
              <CallEnd />
            </IconButton>
          </Box>
        </Box>
      </Dialog>
    </CallingContext.Provider>
  );
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const isGroupOrChannelType = type => {
  return Boolean(
    type && (type === SystemConstant.GROUP_CHAT_TYPE.group || type === SystemConstant.GROUP_CHAT_TYPE.channel),
  );
};

export default Call;

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100vw",
    height: "100Vh",
    maxWidth: "unset",
    maxHeight: "unset",
    borderRadius: 0,
    margin: 0,
    overflowY: "hidden",
  },

  smallWrapperRoot: {
    top: 32,
    right: 12,
    left: "unset",
    bottom: "unset",
    width: "100%",
    transition: "all .1s ease-in-out",
    maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.medium})`,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up(1500)]: {
      maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.large}px)`,
    },
  },

  smallWrapper: {
    width: "100%",
    maxWidth: "unset",
    borderRadius: 10,
    backgroundColor: theme.palette.grey[800],
    cursor: "pointer",
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: 58,
    marginTop: 0,
    marginBottom: 0,
    boxShadow: "none",
  },

  callDetailWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
  },

  callDetail: {
    display: "flex",
    color: theme.palette.white,
    width: "70%",
  },

  hiddenTitle: {
    color: theme.palette.white,
    marginLeft: 15,
    marginRight: 4,
    fontWeight: 700,
  },

  smallButton: {
    color: theme.palette.white,
  },

  smallActionButton: {
    backgroundColor: theme.palette.grey[700],
    marginRight: 12,
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },

  smallActionButtonDisabled: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    marginRight: 12,
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.white,
    },
  },

  smallDenyButton: {
    backgroundColor: "#FF2C28",
    "&:hover": {
      backgroundColor: "#D6605C",
    },
  },

  smallWrapperRootInChat: {
    top: 122,
  },

  hiddenCallingStatus: {
    color: theme.palette.white,
    fontWeight: 400,
  },
}));
