import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const BlockIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H7V11H17V13Z"
        fill="#E0E0E0"
      />
    </SvgIcon>
  );
};

BlockIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
BlockIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(BlockIcon);
