import React, { useState, memo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, FormatConstant } from "const";
import { InfiniteScroll } from "components";
import { getInteractor } from "services/local.service";
import { ConversationActions, ConversationSelectors } from "redux-store";
import { getDisplayMessage } from "../ViewMode/ViewMode.helper";
import MessageItem from "../MessageItem";
import { createSelector } from "reselect";
import { useLazyEffect } from "hooks";
import { useConversationContext } from "../ConversationContext";

const PAGING_SIZE = 15;
const SCROLL_DATA_DEFAULT = {
  isFetching: false,
  isNewest: false,
  isOldest: false,
};
const memoizedReduxState = createSelector(
  [ConversationSelectors.getSelectedGroupId, ConversationSelectors.getSearchingMessage],
  (selectedGroupId, searchingMessage) => {
    return {
      selectedGroupId,
      selectedMessageId: searchingMessage.selectedMessageId,
      messageList: searchingMessage.messageList,
    };
  },
);
const SearchMode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const scrollDataRef = useRef({ ...SCROLL_DATA_DEFAULT });
  const { groupDetail } = useConversationContext();

  const { selectedGroupId, selectedMessageId, messageList } = useSelector(memoizedReduxState);

  const [showMessage, setShowMessage] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const handleSetMessage = async newMessageList => {
    scrollDataRef.current.isFetching = true;

    const refactorMsgList = await getDisplayMessage(newMessageList, groupDetail);

    setShowMessage(refactorMsgList);
    setIsEmpty(newMessageList.length === 0);

    scrollDataRef.current.isFetching = false;
  };

  const handleGetOlderMessage = async () => {
    if (showMessage.length === 0) return;
    // Skip query if during get data from db
    if (scrollDataRef.current.isFetching || scrollDataRef.current.isOldest) return;

    scrollDataRef.current.isFetching = true;

    const olderMessage = showMessage[showMessage.length - 1];
    const records = await getInteractor().LocalMessageService.getGroupMessage(selectedGroupId, {
      limit: PAGING_SIZE,
      compareTime: olderMessage.created,
    });
    if (records.length > 0) {
      await handleSetMessage(showMessage.concat(records));
    }

    scrollDataRef.current.isOldest = records.length < PAGING_SIZE;
    scrollDataRef.current.isFetching = false;
  };

  const handleGetNewerMessage = async () => {
    if (showMessage.length === 0) return;
    // Skip query if during get data from db
    if (scrollDataRef.current.isFetching || scrollDataRef.current.isNewest) return;
    scrollDataRef.current.isFetching = true;

    const newerMessage = showMessage[0];
    const records = await getInteractor().LocalMessageService.getGroupMessage(selectedGroupId, {
      limit: PAGING_SIZE,
      compareTime: newerMessage.created,
      isNewer: true,
    });
    if (records.length > 0) {
      await handleSetMessage(showMessage.concat(records));
    }

    scrollDataRef.current.isNewest = records.length < PAGING_SIZE;
    scrollDataRef.current.isFetching = false;
  };

  const handleCloseSearching = () => {
    dispatch(
      ConversationActions.conversationSet({
        isSearchMode: false,
        searchingMessage: {
          selectedMessageId: null,
          messageList: [],
          searchValue: "",
        },
      }),
    );
  };

  const handleScrollToMessage = selectedMessageId => {
    const scroll2Message = (retry = 1) => {
      if (retry > 10) {
        console.log("========== Maximum retry =============");
        return;
      }

      const elementId = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, selectedMessageId);
      const scrollToEl = document.getElementById(elementId);

      if (scrollToEl) scrollToEl.scrollIntoView({ block: "center" });
      else {
        scroll2Message(retry++);
      }
    };

    return setTimeout(scroll2Message, 500);
  };

  useEffect(() => {
    // Auto reset when changing selected message
    setShowMessage([]);
    scrollDataRef.current = { ...SCROLL_DATA_DEFAULT };

    // Update state when changing selected message
    handleSetMessage(messageList);
  }, [selectedMessageId, messageList]);

  useLazyEffect(() => {
    // Scroll to selected message
    if (selectedMessageId && messageList.length > 0) {
      const scrollTimeout = handleScrollToMessage(selectedMessageId);

      return () => clearTimeout(scrollTimeout);
    }
  }, [selectedMessageId, showMessage]);

  const hasMoreData = showMessage.length > 0 && !(scrollDataRef.current.isNewest && scrollDataRef.current.isOldest);

  return (
    <>
      <InfiniteScroll
        hasMore={hasMoreData}
        className={classes.wrapChatItem}
        id={CHAT_WRAPPER_ID}
        onScrollToTop={handleGetNewerMessage}
        onScrollToBottom={handleGetOlderMessage}
        onClickScrollButton={handleCloseSearching}
        isReverse={true}
      >
        {showMessage.map(messageItem => (
          <MessageItem key={messageItem.id} message={messageItem} />
        ))}
      </InfiniteScroll>

      <Typography className={isEmpty ? classes.notFoundText : "hidden"}>
        {getLabel(LangConstant.TXT_NOT_FOUND)}
      </Typography>
    </>
  );
};

SearchMode.propTypes = {};

SearchMode.defaultProps = {};

export default memo(SearchMode);

const CHAT_WRAPPER_ID = "chat-wrapper-id";

const useStyles = makeStyles(theme => ({
  wrapChatItem: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    padding: "0 22px",
    paddingBottom: 15,
    paddingTop: 70,
  },

  notFoundText: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[200],
  },
}));
