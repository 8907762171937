import store, {
  CallingActions,
  ConversationActions,
  ConversationSelectors,
  GroupInfoActions,
  getReduxState,
} from "redux-store";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { checkCurrentBranchByPrefix, formatArray2Key, formatPagingParams } from "sagas/saga.helper";
import { getInteractor } from "services/local.service";
import { convertString2JSON, StorageUtil, toCamel, toSnake } from "utils";
import { remoteApiFactory } from "services";
import { saveKeysByAccountIds } from "sagas/account-key.saga";

export const getConversation = async (prefixKey, groupId) => {
  try {
    // Synch group
    const params = formatPagingParams();
    if (groupId) {
      params.group_id = groupId;
      params.paging = 0;
    }

    let limit = params.limit;
    let dataLength;
    let paging = 1;
    do {
      const groupResponse = await remoteApiFactory.getBranchApi(prefixKey).getConversationList(params);
      if (groupResponse.status === ApiConstant.STT_OK) {
        const groupList = groupResponse.data.data;

        await synchGroupMember(
          prefixKey,
          groupList.map(item => item.id),
        );
        await getInteractor(prefixKey).LocalGroupService.save(groupList);

        dataLength = groupList.length;
        params.offset = paging * params.limit;
        paging++;
        params.paging = paging;
      } else {
        return false;
      }
    } while (dataLength === limit);

    return true;
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const handleNewConversation = async (prefixKey, groupId) => {
  const isSynchGroup = await getConversation(prefixKey, groupId);
  if (isSynchGroup) {
    const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);

    if (groupId && isCurrentBranch) {
      store.dispatch(
        GroupInfoActions.groupInfoSet({
          newGroupId: groupId,
        }),
      );
    }
  }

  return isSynchGroup;
};

export const updateConversationService = async (prefixKey, groupId) => {
  try {
    const params = formatPagingParams({ groupId });
    const groupResponse = await remoteApiFactory.getBranchApi(prefixKey).getConversationList(params);
    if (groupResponse.status === ApiConstant.STT_OK) {
      const groupList = groupResponse.data.data;
      await getInteractor(prefixKey).LocalGroupService.save(groupList);
      const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
      if (isCurrentBranch) {
        const newGroup = await getInteractor(prefixKey).LocalGroupService.get(groupId);
        store.dispatch(
          GroupInfoActions.groupInfoSet({
            updatingGroupData: newGroup,
          }),
        );
      }

      return true;
    }
  } catch (error) {
    console.log("conversation update service fail: ", error);
  }

  return false;
};

export const deleteConversationService = async (prefixKey, groupId) => {
  const localGroup = await getInteractor(prefixKey).LocalGroupService.get(groupId);
  if (!localGroup) return true;

  const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
  try {
    const paramsObject = {
      ...formatPagingParams(formatArray2Key({ groupIds: [groupId] })),
    };

    const response = await remoteApiFactory.getBranchApi(prefixKey).getConversationMember(paramsObject);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data.data;
      const accountGroupArr = [];

      Object.entries(responseData).forEach(pair =>
        pair[1].forEach(item2 => {
          accountGroupArr.push({
            ...item2,
            id: pair[0] + item2.id,
            account_id: item2.id,
            group_id: pair[0],
          });
        }),
      );

      await getInteractor(prefixKey).LocalAccountGroupService.save(
        accountGroupArr.map(account => ({
          id: account.id,
          account_id: account.account_id,
          group_id: account.group_id,
          state: account.group_state,
          options: account.options,
          created: account.created,
          modified: account.modified,
          invite_by: account.invite_by,
          type: account.type,
        })),
      );

      // Check current account group state
      const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const currentAccountGroup = accountGroupArr.find(account => account.account_id === currentAccountId);
      if (currentAccountGroup.group_state === SystemConstant.STATE.inactive) {
        if (selectedGroupId === groupId) {
          store.dispatch(
            ConversationActions.conversationSet({
              threadingId: null,
              selectedGroupId: null,
            }),
          );
        }

        store.dispatch(
          GroupInfoActions.groupInfoSuccess({
            deleteGroup: { groupId, modified: Date.now() },
          }),
        );
      }

      return true;
    }
  } catch (error) {
    console.log("delete group service fail: ", error);
  }

  return false;
};

export const addMembers2ConversationService = async (prefixKey, groupId, addMembersList) => {
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    if (addMembersList.includes(accountId)) {
      const groupDetail = await getInteractor(prefixKey).LocalGroupService.get(groupId);
      if (groupDetail && groupDetail.id) {
        if (groupDetail.state === SystemConstant.STATE.inactive) {
          await getInteractor(prefixKey).LocalGroupService.update(
            { state: SystemConstant.STATE.active, modified: Date.now() },
            { id: groupId },
          );
        }
        await synchGroupMember(prefixKey, [groupId]);
        store.dispatch(
          GroupInfoActions.groupInfoSet({
            groupHasNewMember: {
              ...groupDetail,
              modified: Date.now(),
            },
          }),
        );
      } else {
        await getConversation(prefixKey, groupId);
      }
    } else {
      await synchGroupMember(prefixKey, [groupId]);
    }

    return true;
  } catch (error) {
    console.log(error);
  }

  return false;
};

export const removeMemberConversationService = async (prefixKey, groupId, memberId) => {
  try {
    prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const currentGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
    const accountGroupLocal = await getInteractor(prefixKey).LocalAccountGroupService.findOne({
      account_id: memberId,
      group_id: groupId,
    });

    if (accountGroupLocal && accountGroupLocal.state === SystemConstant.STATE.active) {
      await getInteractor(prefixKey).LocalAccountGroupService.update(
        {
          state: SystemConstant.STATE.inactive,
          modified: Date.now(),
        },
        { id: accountGroupLocal.id },
      );
      if (memberId === accountId) {
        await getInteractor(prefixKey).LocalGroupService.update(
          { state: SystemConstant.STATE.inactive, modified: Date.now() },
          { id: groupId },
        );

        if (groupId === currentGroupId) {
          store.dispatch(
            ConversationActions.setSelectGroupId({
              threadingId: null,
              selectedGroupId: null,
            }),
          );
        }

        store.dispatch(
          GroupInfoActions.groupInfoSet({
            deleteGroup: { groupId, modified: Date.now() },
          }),
        );
      }
      const createdMessage = getReduxState(state => state.callingRedux.createdMessage);
      if (Boolean(createdMessage) && createdMessage.groupId === groupId && memberId === accountId) {
        store.dispatch(
          CallingActions.callingSet({
            removedFromGroup: groupId,
          }),
        );
      }
    }

    return true;
  } catch (error) {
    console.log(error);
  }

  return false;
};

export const addBlockService = async (prefixKey, groupId, accountIds, blockedAccountId) => {
  try {
    const checkBlockResult = await checkBlockedContact(prefixKey, groupId, accountIds, blockedAccountId);
    return checkBlockResult;
  } catch (error) {
    console.log("add block service fail: ", error);
  }
  return false;
};

export const deleteBlockService = async (prefixKey, blockedAccountId, accountIds) => {
  try {
    const blockByAccountId = accountIds.find(item => item !== blockedAccountId);

    const localContact = await getInteractor(prefixKey).LocalContactService.getContact(
      blockByAccountId,
      blockedAccountId,
    );
    if (localContact && localContact.status === SystemConstant.CONTACT_STATUS.block) {
      await getInteractor(prefixKey).LocalContactService.update(
        {
          status: SystemConstant.CONTACT_STATUS.normal,
          modified: Date.now(),
        },
        { id: localContact.id },
      );

      const currentPrefixKey = StorageUtil.getCurrentPrefixKey();
      if (currentPrefixKey === prefixKey) {
        store.dispatch(
          ConversationActions.conversationSet({
            blockedAccount: toCamel({
              ...localContact,
              status: SystemConstant.CONTACT_STATUS.normal,
              modified: Date.now(),
            }),
          }),
        );
      }
    }
    return true;
  } catch (error) {
    console.log("delete block service fail: ", error);
  }

  return false;
};

export const synchGroupMember = async (prefixKey, groupIds) => {
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
  const isValid = groupIds && Array.isArray(groupIds);
  if (!isValid) return;

  try {
    const paramsObject = {
      ...formatPagingParams(formatArray2Key({ groupIds: groupIds })),
    };

    const response = await remoteApiFactory.getBranchApi(prefixKey).getConversationMember(paramsObject);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data.data;
      const accountGroupArr = [];
      const accountArr = [];

      Object.entries(responseData).forEach(pair =>
        pair[1].forEach(item2 => {
          accountArr.push(item2);
          accountGroupArr.push({
            ...item2,
            id: pair[0] + item2.id,
            account_id: item2.id,
            group_id: pair[0],
          });
        }),
      );

      // Change this to get key by group members
      const memberIds = accountGroupArr.map(item => item.id);
      await saveKeysByAccountIds(prefixKey, memberIds);

      await getInteractor(prefixKey).LocalAccountService.save(accountArr);
      await getInteractor(prefixKey).LocalAccountGroupService.save(
        accountGroupArr.map(account => ({
          id: account.id,
          account_id: account.account_id,
          group_id: account.group_id,
          state: account.group_state,
          options: account.options,
          created: account.created,
          modified: account.modified,
          invite_by: account.invite_by,
          type: account.type,
        })),
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkBlockedContact = async (prefixKey, groupId, accountListCheck = [], blockedAccountId) => {
  try {
    prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
    const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    if (false === Boolean(accountListCheck.includes(currentAccountId))) return true;
    const otherAccountId = accountListCheck.find(item => item !== currentAccountId);
    if (false === Boolean(otherAccountId)) return true;

    let blockedAccount = null;
    if (blockedAccountId === currentAccountId) {
      blockedAccount = await checkBlockByAnother(prefixKey, otherAccountId, currentAccountId);
    } else if (blockedAccountId) {
      blockedAccount = await checkBlockAnother(prefixKey, otherAccountId, currentAccountId);
    } else {
      blockedAccount = await checkBlockByAnother(prefixKey, otherAccountId, currentAccountId);

      if (false === Boolean(blockedAccount)) {
        blockedAccount = await checkBlockAnother(prefixKey, otherAccountId, currentAccountId);
      }
    }

    const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
    if (selectedGroupId === groupId) {
      store.dispatch(
        ConversationActions.conversationSet({
          blockedAccount: blockedAccount ? toCamel(blockedAccount) : {},
        }),
      );
    }

    return true;
  } catch (error) {
    console.log("check block fail: ", error);
  }

  return false;
};

const checkBlockByAnother = async (prefixKey, otherAccountId, currentAccountId) => {
  let blockedAccount = await getInteractor(prefixKey).LocalContactService.getContact(otherAccountId, currentAccountId);

  if (false === Boolean(blockedAccount) || blockedAccount.status !== SystemConstant.CONTACT_STATUS.block) {
    blockedAccount = await getBlockedContactOnServer(prefixKey, otherAccountId);
  }

  return blockedAccount;
};

const checkBlockAnother = async (prefixKey, otherAccountId, currentAccountId) => {
  const contactInDB = await getInteractor(prefixKey).LocalContactService.getContact(currentAccountId, otherAccountId);
  if (contactInDB && contactInDB.status === SystemConstant.CONTACT_STATUS.block) return contactInDB;
  return null;
};

// Return data if checkingAccount block login user (current account)
const getBlockedContactOnServer = async (prefixKey, checkingAccountId) => {
  if (false === Boolean(checkingAccountId)) return null;
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  const response = await remoteApiFactory.getBranchApi(prefixKey).getContact(checkingAccountId, currentAccountId);
  const contactOfReceiverServer = response.data;

  if (response.status === ApiConstant.STT_OK && contactOfReceiverServer?.id) {
    const contactOfReceiverLocal = await getInteractor(prefixKey).LocalContactService.getContact(
      checkingAccountId,
      currentAccountId,
    );

    if (contactOfReceiverLocal?.id && contactOfReceiverLocal.status !== contactOfReceiverServer.status) {
      await getInteractor(prefixKey).LocalContactService.updateBeingBlockContact(
        contactOfReceiverServer.id,
        contactOfReceiverServer.status,
      );
    }

    if (contactOfReceiverServer.status === SystemConstant.CONTACT_STATUS.block) {
      // If receiver block login user, save data to DB
      await getInteractor(prefixKey).LocalContactService.save([contactOfReceiverServer]);

      return contactOfReceiverServer;
    }
  }

  return null;
};

export const createGroupService = async (prefixKey, data) => {
  const { name, ...payload } = data || {};
  payload.groupName = name;
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();

  const response = await remoteApiFactory.getBranchApi(prefixKey).addConversation(toSnake(payload));
  if (response.status === ApiConstant.STT_OK) {
    let responseData = response.data;
    const newGroup = {
      ...data,
      ...responseData,
    };
    await getInteractor(prefixKey).LocalGroupService.save([newGroup]);
    await synchGroupMember(prefixKey, [responseData.id]);

    return getInteractor(prefixKey).LocalGroupService.getGroupInfo(newGroup);
  }

  return null;
};

export const deleteConversation = async (prefixKey, groupId) => {
  try {
    await deleteLocalConversationData(prefixKey, groupId);

    store.dispatch(
      GroupInfoActions.groupInfoSuccess({
        deleteGroup: { groupId, modified: Date.now() },
      }),
    );

    const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
    if (selectedGroupId === groupId) {
      store.dispatch(
        ConversationActions.setSelectGroupId({
          threadingId: null,
          selectedGroupId: null,
        }),
      );
    }

    return true;
  } catch (error) {
    console.log("delete conversation forever fail: ", error);
  }

  return false;
};

/**
 * update state = inactive for message
 * update state = inactive for group
 * update state = inactive for account_group
 */
export const deleteLocalConversationData = async (prefixKey, groupId) => {
  const localGroup = await getInteractor(prefixKey).LocalGroupService.findById(groupId);
  if (!localGroup) return true;

  // Remove group in local database
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

    const savingGroup = { state: SystemConstant.STATE.inactive, modified: Date.now() };
    const isPersonalConversation = localGroup.group_type === SystemConstant.GROUP_CHAT_TYPE.personal;
    if (isPersonalConversation) {
      const options = convertString2JSON(localGroup.options, {}, true);
      if (options.hidden && Array.isArray(options.hidden) && !options.hidden.includes(accountId)) {
        options.hidden.push(accountId);
      } else {
        options.hidden = [accountId];
      }
      savingGroup.options = JSON.stringify(options);
    } else {
      await getInteractor(prefixKey).LocalAccountGroupService.updateListByCondition([
        {
          updateValues: {
            state: SystemConstant.STATE.inactive,
            modified: Date.now(),
          },
          condition: { group_id: groupId },
        },
      ]);
    }
    await getInteractor(prefixKey).LocalGroupService.update(savingGroup, { id: groupId });
    // await getInteractor(prefixKey).LocalMessageService.updateDeleteStateForMsg(groupId);

    return true;
  } catch (error) {
    console.log("delete conversation fail: ", error);
  }

  return false;
};
