import { AppConstant, KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { StorageUtil, toSnake, uuid } from "utils";
import { validateInvolved, validateMentions } from "utils/view.utils";
import { IGNORE_MESSAGE } from "./saga.helper";
import max from "lodash/max";

const NORMAL_MESSAGE = [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage];

export const updateThread = async (prefixKey, newMessage) => {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const message = await getInteractor(prefixKey).LocalMessageService.get(newMessage.id);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const isMyMessage = message.sender_id === accountId;
  const isChildOfOriginalMess =
    message.parent_id && getInteractor(prefixKey).LocalThreadService.count({ thread_id: message.parent_id }) > 0;
  const isDeleteParentMessage = isChildOfOriginalMess && message.send_type === SystemConstant.SEND_TYPE.deleteMessage;
  if (false === Boolean(message.thread_id) && false === isDeleteParentMessage) return false;

  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const threadId = isDeleteParentMessage ? message.parent_id : message.thread_id;
    const threadInfo = await getInteractor(prefixKey).LocalThreadService.getByThreadId(threadId);
    const parentMessage =
      (await getInteractor(prefixKey).LocalMessageService.findOne({
        source_id: threadId,
      })) || {};
    if (false === Boolean(parentMessage.id)) return false;

    const isDeleteMessage = message.send_type === SystemConstant.SEND_TYPE.deleteMessage;
    const isMessageEdit = NORMAL_MESSAGE.includes(message.send_type) && Boolean(message.parent_id);
    const isIgnoreMessage = IGNORE_MESSAGE.includes(message.send_type);
    const isNewUnread =
      message.status !== SystemConstant.MESSAGE_STATUS.read &&
      message.sender_id !== accountId &&
      !Boolean(message.parent_id) &&
      !isIgnoreMessage;

    const threadMentions = validateMentions(threadInfo, message.mentions, parentMessage.mentions);
    const isInvolved = (await validateInvolved(message, threadMentions)) ? 1 : 0;
    const modifiedThread = max([threadInfo?.modified, message.created, 0]);

    // update thread info
    if (isDeleteParentMessage && Boolean(threadInfo)) {
      const updateThreadInfo = {
        state: AppConstant.THREAD_STATE.inactive,
        modified: modifiedThread,
      };

      await getInteractor(prefixKey).LocalThreadService.update(toSnake(updateThreadInfo), { thread_id: threadId });
    } else if (Boolean(threadInfo)) {
      const updateTotalReply =
        isMessageEdit || isIgnoreMessage
          ? threadInfo.total_reply
          : isDeleteMessage
          ? threadInfo.total_reply - 1
          : threadInfo.total_reply + 1;

      const updateThreadInfo = {
        totalReply: updateTotalReply,
        totalUnread: isMyMessage ? 0 : isNewUnread ? threadInfo.total_unread + 1 : threadInfo.total_unread,
        involvedF: threadInfo.involved_f || isInvolved,
        state: updateTotalReply > 0 ? AppConstant.THREAD_STATE.active : AppConstant.THREAD_STATE.inactive,
        modified: modifiedThread,
        threadMentions: JSON.stringify(threadMentions),
        settingType: threadInfo.setting_type,
      };

      await getInteractor(prefixKey).LocalThreadService.update(toSnake(updateThreadInfo), { thread_id: threadId });
    } else if (!Boolean(threadInfo) && !isIgnoreMessage && !isDeleteParentMessage) {
      const createdTime = Date.now();

      const newThreadInfo = {
        id: uuid(),
        threadId: threadId,
        groupId: message.group_id,
        totalReply: 1,
        totalUnread: isMyMessage ? 0 : isNewUnread ? 1 : 0,
        involvedF: isInvolved,
        state: AppConstant.THREAD_STATE.active,
        created: createdTime,
        modified: createdTime,
        threadAccountId: parentMessage.sender_id,
        threadCreated: parentMessage.created,
        threadContent: parentMessage.content,
        threadMentions: JSON.stringify(threadMentions),
        branchId: message.branch_id,
        groupType: null,
        settingType: null,
      };

      await getInteractor(prefixKey).LocalThreadService.save([toSnake(newThreadInfo)]);
    }

    return Boolean(isInvolved || threadInfo?.involved_f);
  } catch (error) {
    console.log(error);
  }

  return false;
};
