import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AddingChannelDrawer from "./AddingChannelDrawer";

const AddingChannelLayout = ({ onComplete, children, ...otherProps }) => {
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const onCloseDrawer = () => setIsShowDrawer(false);
  const onShowDrawer = () => setIsShowDrawer(true);

  return (
    <>
      <Box onClick={onShowDrawer} {...otherProps}>
        {children}
      </Box>
      {isShowDrawer && <AddingChannelDrawer onComplete={onComplete} open onClose={onCloseDrawer} />}
    </>
  );
};

AddingChannelLayout.propTypes = {
  onComplete: PropTypes.func,

  ...Box.propTypes,
};

AddingChannelLayout.defaultProps = {};

export default AddingChannelLayout;
