import { AppConstant, SystemConstant } from "const";
import { useEffect, useRef, useState } from "react";
import { getFileLocalDetails, getThumbnailUrlCache } from "services/attachment.service";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import useCleanUpEffect from "./useCleanUpEffect";
import { useSelector } from "react-redux";
import useLazyEffect from "./useLazyEffect";

// file: {metaData, groupId}
const useMediaUrl = (
  file,
  options = {
    isThumbnail: false,
  }, // {isThumbnail, width, height}
) => {
  const { isThumbnail, isCurrentDevice } = options || {};
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { isMounted } = useCleanUpEffect();
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const fetchingRef = useRef(false);

  const [mediaUrl, setMediaUrl] = useState("");
  const [status, setStatus] = useState(SystemConstant.UPLOAD_STATUS.inProgress);

  const handleSetMediaSource = async () => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;

    const mediaData = await getFileLocalDetails(prefixKey, file, {
      groupId: file.groupId,
      isCurrentDevice: Boolean(isCurrentDevice),
    });
    if (!mediaData.mediaPath) {
      fetchingRef.current = false;
      setStatus(SystemConstant.UPLOAD_STATUS.failed);
      return;
    }

    // Using origin file
    let mediaUrl = "";
    if (false === Boolean(isThumbnail)) {
      mediaUrl = mediaData.mediaPath;
    } else {
      // Thumbnail: compress file before display
      try {
        const thumbnail = await getThumbnailUrlCache(file);
        mediaUrl = thumbnail.mediaPath;
      } catch (error) {
        console.error(error, mediaData);
        if (isMounted()) setStatus(SystemConstant.UPLOAD_STATUS.failed);
      }
    }

    if (isMounted()) {
      setMediaUrl(mediaUrl);
      if (false === Boolean(mediaUrl)) {
        setStatus(SystemConstant.UPLOAD_STATUS.failed);
      } else if (isCurrentDevice) {
        const fileRecord = await getInteractor(prefixKey).LocalFileService.get(file.attachmentId);
        setStatus(fileRecord.status);
      } else if (Boolean(mediaUrl)) {
        setStatus(SystemConstant.UPLOAD_STATUS.success);
      }
    }
    fetchingRef.current = false;
  };

  useLazyEffect(() => {
    if (mediaUrl) return;

    const isValidData = file && file.attachmentId && file.metaData;
    if (isValidData) handleSetMediaSource();
  }, [file]);

  useEffect(() => {
    const isValidData = file && file.attachmentId && file.metaData;
    if (false === Boolean(mediaUrl) && isValidData && hasInternet) handleSetMediaSource();
  }, [hasInternet]);

  useEffect(() => {
    const checkingLoadingTimeout = setTimeout(() => {
      setStatus(mediaUrl ? SystemConstant.UPLOAD_STATUS.success : SystemConstant.UPLOAD_STATUS.failed);
      if (!mediaUrl) getInteractor(prefixKey).LocalFileService.updateFileFail(file.attachmentId);
    }, AppConstant.CHECK_UPLOAD_TIMEOUT);

    return () => {
      if (checkingLoadingTimeout) clearTimeout(checkingLoadingTimeout);
    };
  }, [mediaUrl]);

  return {
    mediaUrl,
    isFailedLoading: status === SystemConstant.UPLOAD_STATUS.failed,
    isLoading: status === SystemConstant.UPLOAD_STATUS.inProgress,
  };
};

export default useMediaUrl;
