import React, { useState } from "react";
import { Box } from "@mui/material";
import BackUpDrawer from "./BackUpDrawer";
import { useDeviceList } from "hooks";
import { RestoreActions } from "redux-store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import RestoreDrawer from "./RestoreDrawer";
import AccountItem from "../AccountItem";

export const BackupData = () => {
  const { isMaster } = useDeviceList();

  return (
    <Box className={!isMaster && "hidden"}>
      <BackUpDrawer />
    </Box>
  );
};

export const RestoreData = () => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();

  const [isShowRestore, setIsShowRestore] = useState(false);

  const handleRestore = () => {
    dispatch(RestoreActions.restore({}));
    setIsShowRestore(true);
  };

  return (
    <>
      <AccountItem text={getLabel(LangConstant.TXT_RESTORE)} onClick={handleRestore} />

      <RestoreDrawer open={isShowRestore} onClose={() => setIsShowRestore(false)} />
    </>
  );
};
