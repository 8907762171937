import { EventState, EventSubType, EventType, ExecuteEvent } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { EventDto, IEventDto } from "pubsub/dto";
import { getInteractor } from "services/local.service";
import { refactorSavingEvent } from "pubsub/PubSub.helper";
import {
  addMembers2ConversationService,
  addBlockService,
  updateConversationService,
  deleteBlockService,
  deleteConversationService,
  removeMemberConversationService,
  handleNewConversation,
  deleteConversation,
} from "pubsub/services/conversation.service";
import { autoEndCall, leaveCall } from "pubsub/services/call.service";

export default class GroupEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.GROUP);
  }

  handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];
    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      const groupId = eventItem.topic;
      const eventContent = eventItem.contentObj;
      let executeEventValue: ExecuteEvent = ExecuteEvent.FAIL;

      if (groupId) {
        const changeAccountId = eventContent.account_id;
        const accountIds = eventContent.account_ids;
        const roomId = eventContent.room_id;
        const deviceId = eventContent.device_id;

        switch (eventItem.subtype) {
          case EventSubType.NEW:
            executeEventValue = (await handleNewConversation(this.prefixKey, groupId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.UPDATE:
            executeEventValue = (await updateConversationService(this.prefixKey, groupId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.DELETE:
            executeEventValue = (await deleteConversationService(this.prefixKey, groupId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.ADD_ACCOUNT:
            executeEventValue = (await addMembers2ConversationService(this.prefixKey, groupId, accountIds))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.DEL_ACCOUNT:
            executeEventValue = (await removeMemberConversationService(this.prefixKey, groupId, changeAccountId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.ADD_BLOCK:
            executeEventValue = (await addBlockService(this.prefixKey, groupId, accountIds, changeAccountId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.DELETE_BLOCK:
            executeEventValue = (await deleteBlockService(this.prefixKey, changeAccountId, accountIds))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.AUTO_END_CALL:
            executeEventValue = autoEndCall(groupId, roomId) ? ExecuteEvent.SUCCESSES : ExecuteEvent.RETRY;
            break;

          case EventSubType.MANUAL_END_CALL:
            executeEventValue = ExecuteEvent.SUCCESSES;
            break;

          case EventSubType.LEAVE_CALL:
            executeEventValue = leaveCall(groupId, roomId, deviceId, changeAccountId)
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.DELETE_CHANNEL:
            executeEventValue = (await deleteConversation(this.prefixKey, groupId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          default:
            break;
        }
      }

      // Update event before saving in local db
      const refactorEvent = refactorSavingEvent(eventItem, executeEventValue, limitRetry);
      if (refactorEvent) savingEvents.push(refactorEvent);
    }
    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}
