import { useEffect, useState } from "react";
import { KeyConstant, SystemConstant } from "const";
import { useServerList, useServerMode } from "hooks";
import { StorageUtil, getPrefixKey } from "utils";
import NoticeDialog from "./dialog/NoticeDialog";
import { useDispatch, useSelector } from "react-redux";
import { BranchActions, BranchSelectors, SystemSelectors } from "redux-store";
import { LocalDbManagement } from "services/local.service";
import { useTranslation } from "react-i18next";
import { getSavedServer } from "utils/branch.utils";
import { handlingLogoutBranch } from "utils/auth.utils";

const InactiveDialog = () => {
  const currentBranchId = getSavedServer().id;
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();
  const { branchList, globalServer } = useServerList();
  const { switchServer } = useServerMode();

  const inactiveBranchArray = useSelector(BranchSelectors.getInactiveBranchArray);
  const isSynchronizing = useSelector(SystemSelectors.isSystemSynchronizing);

  const [isShowKickedOutPopup, setIsShowKickedOutPopup] = useState(false);

  const handleCloseKickedPopup = async () => {
    setIsShowKickedOutPopup(false);
    const currentPrefix = StorageUtil.getCommonKey(KeyConstant.KEY_PREFIX);
    const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID);
    await handlingLogoutBranch(currentPrefix);

    const selectBranch = [...branchList, globalServer].find(
      item => item.id !== currentBranchId && item.loginState === SystemConstant.STATE.active,
    );

    if (selectBranch) {
      switchServer(selectBranch);
    } // case else đã được check trong hàm handlingLogoutBranch
  };

  useEffect(() => {
    let kickedUserTimeout = null;
    if (isShowKickedOutPopup) {
      kickedUserTimeout = setTimeout(() => {
        handleCloseKickedPopup();
      }, 5000);

      return () => clearTimeout(kickedUserTimeout);
    }
  }, [isShowKickedOutPopup]);

  useEffect(() => {
    if (inactiveBranchArray.length > 0 && false === isSynchronizing) {
      if (inactiveBranchArray.find(item => item === currentBranchId)) {
        setIsShowKickedOutPopup(true);
      } else {
        setIsShowKickedOutPopup(false);
        LocalDbManagement.find({ state: SystemConstant.STATE.active }).then(activeBranchList => {
          inactiveBranchArray.forEach(item => {
            const branchDB = activeBranchList.find(branch => branch.branch_id === item);
            if (branchDB) {
              const prefixKey = getPrefixKey(branchDB.account_id, branchDB.branch_id);
              handlingLogoutBranch(prefixKey);
            }
          });
        });
      }

      dispatch(
        BranchActions.branchSet({
          inactiveBranchArray: [],
        }),
      );
    }
  }, [inactiveBranchArray, currentBranchId, isSynchronizing]);

  return (
    <NoticeDialog
      open={isShowKickedOutPopup}
      title={getLabel("TXT_NOTIFICATION")}
      content={getLabel("TXT_KICKED_USER")}
      submitProps={{
        submitText: getLabel("TXT_CLOSE"),
        onClick: handleCloseKickedPopup,
      }}
    />
  );
};

export default InactiveDialog;
