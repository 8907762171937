import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
  resetReducerFunc,
} from "./redux-structure";
import { AppConstant, KeyConstant } from "const";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestLogin: ["data"],
  requestVerify: ["data"],

  authSuccess: ["data"],
  authFailure: ["error", "data"],
  authSet: ["data"],
  authReset: ["data"],
});

export const AuthTypes = Types;
export const AuthActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  ...REDUX_STATE,
  isLogin: Boolean(window.electronUtils.storage.getItem(KeyConstant.KEY_TOKEN)),
  phoneNumber: null,
  isNew: false,
  masterExisted: false,
  authLoginStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
  authVerifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
};

/* ------------- Selector ------------- */
export const AuthSelectors = {
  isLogin: state => state.authRedux.isLogin,
  authLoginStatus: state => state.authRedux.authLoginStatus,
  authVerifyStatus: state => state.authRedux.authVerifyStatus,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc(state);

const requestLogin = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
    isLogin: false,
    authLoginStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
    authVerifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
  });

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

const reset = () =>
  resetReducerFunc({
    ...INITIAL_STATE,
    isLogin: false,
    authLoginStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
    authVerifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
  });
/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_LOGIN]: requestLogin,
  [Types.REQUEST_VERIFY]: request,

  [Types.AUTH_SET]: set,
  [Types.AUTH_SUCCESS]: success,
  [Types.AUTH_FAILURE]: failure,
  [Types.AUTH_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const AuthReducer = createReducer(INITIAL_STATE, HANDLERS);
