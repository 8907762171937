import React, { memo, useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { isAudio, StorageUtil } from "utils";
import { KeyConstant, SystemConstant } from "const";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem.helper";
import FileBox from "./FileBox";
import withForwardMessage from "components/hoc/withForwardMessage";
import { AppAvatar } from "components";
import AudioBox from "./AudioBox";
import { getFileLocalPath } from "services/attachment.service";
import { useCleanUpEffect, useFileStatus } from "hooks";
import { getMessageFileList } from "utils/message.utils";
import { useSelector } from "react-redux";

const FilePreview = ({ message, onForward }) => {
  const messengerClasses = useMessageStyle();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const isMine = message.senderId === accountId;
  const msgCreatedTime = getCreatedTimeText(message);
  const metaDataList = getMessageFileList(message);
  const isCurrentDevice = message.senderDeviceId === deviceId;

  return (
    <Stack direction="row" spacing={2}>
      {!isMine && (
        <AppAvatar
          avatarId={message.avatarId}
          size={40}
          className={clsx(!message.isAvatar && messengerClasses.hiddenAvatar)}
        />
      )}

      <Box sx={{ width: "100%" }}>
        {msgCreatedTime && (
          <Typography className={clsx("ellipsis", messengerClasses.createdTimeText)}>{msgCreatedTime}</Typography>
        )}

        <Stack spacing={1} sx={{ width: 450 }}>
          {metaDataList.map(mediaData => (
            <FileItem
              key={mediaData.attachmentId}
              fileInfo={mediaData}
              isMine={isMine}
              groupId={message.groupId}
              sourceMessageId={message.sourceId}
              onForward={() => onForward({ attachmentId: mediaData.attachmentId, message })}
              isCurrentDevice={isCurrentDevice}
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default memo(withForwardMessage(FilePreview));

export const FileItem = ({ fileInfo, isMine, groupId, onForward, isCurrentDevice }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { isMounted } = useCleanUpEffect();
  const { fileStatus } = useFileStatus(StorageUtil.getCurrentPrefixKey(), fileInfo.attachmentId, groupId);
  const fetchingRef = useRef(false);

  const hasInternet = useSelector(state => state.profileRedux.hasInternet);

  const [fileData, setFileData] = useState({
    attachmentId: "",
    metaData: {},
    status: SystemConstant.UPLOAD_STATUS.inProgress,
    groupId,
  });

  const getFile = async () => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;
    const filePath = await getFileLocalPath(prefixKey, fileInfo, { groupId });

    if (isMounted()) {
      setFileData({
        ...fileInfo,
        groupId,
        status: Boolean(filePath) ? SystemConstant.UPLOAD_STATUS.success : SystemConstant.UPLOAD_STATUS.failed,
      });
    }

    fetchingRef.current = false;
  };

  useEffect(() => {
    if (fileStatus === SystemConstant.UPLOAD_STATUS.success) {
      // upload success => get file to local
      getFile();
    } else {
      setFileData({
        ...fileInfo,
        groupId,
        status: fileStatus,
      });
    }
  }, [fileStatus]);

  useEffect(() => {
    if (hasInternet && fileStatus === SystemConstant.UPLOAD_STATUS.success) {
      getFile();
    }
  }, [hasInternet]);

  return isAudio(fileData.metaData.mimeType) ? (
    <AudioBox mediaData={fileData} isCurrentDevice={isCurrentDevice} groupId={groupId} />
  ) : (
    <FileBox mediaData={fileData} isMine={isMine} isShowOptMenu={true} groupId={groupId} onForward={onForward} />
  );
};
