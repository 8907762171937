import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const TabPanel = props => {
  const { value, index, ...other } = props;

  return (
    value === index && (
      <Box role="tabpanel" id={`detail-tabpanel-${index}`} aria-labelledby={`detail-tab-${index}`} {...other} />
    )
  );
};

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
