import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const Message = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <circle cx="18" cy="18" r="18" fill="#F5F5F5" />
      <path
        d="M26 8H10C8.9 8 8.01 8.9 8.01 10L8 28L12 24H26C27.1 24 28 23.1 28 22V10C28 8.9 27.1 8 26 8ZM12 15H24V17H12V15ZM20 20H12V18H20V20ZM24 14H12V12H24V14Z"
        fill="#7F7F80" />
    </SvgIcon>
  );
};
Message.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
Message.defaultProps = {
  width: 36,
  height: 36,
};
export default memo(Message);
