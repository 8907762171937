import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import {
  deepCloneJsonObject,
  formatStringWithKeyword,
  getExternalLinkFromString,
  highlightString,
  isExternalLink,
  StorageUtil,
} from "utils";
import { PREFIX_MENTION, SUFFIX_MENTION, replaceId2Name } from "utils/message.utils";
import { useSelector } from "react-redux";
import { getMentionMembers } from "./ChatItem.helper";
import { memo, useEffect, useState } from "react";
import { useCleanUpEffect } from "hooks";
import { useConversationContext } from "../ConversationContext";
import { createSelector } from "reselect";
import { SystemConstant } from "const";

const memoizedSearchingState = createSelector(
  [state => state.conversationRedux.isSearchMode, state => state.conversationRedux.searchingMessage?.searchValue],
  (isSearchMode, searchValue) => {
    return isSearchMode && searchValue ? searchValue.toLowerCase() : "";
  },
);

const ChatTypography = ({
  messageContent,
  mentions,
  mentionList,
  messageId,
  isEnableSearching,
  preText,
  ...otherProps
}) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const searchValue = useSelector(memoizedSearchingState);
  const { isMounted } = useCleanUpEffect();
  const { groupDetail } = useConversationContext();
  const cssSelectorId = `chat-typography-${messageId}`;

  const [contentList, setContentList] = useState([]);

  const handleChatContent = async () => {
    // Using group member to cover all case. If groupMembers not exist, using mentionList
    const groupMembers = deepCloneJsonObject(groupDetail.groupMembers);
    const memberList =
      groupMembers.length > 0
        ? groupMembers
        : Array.isArray(mentionList)
        ? mentionList
        : await getMentionMembers(prefixKey, mentions);

    let chatContent = await separateMessageContent(messageContent, memberList);
    if (isEnableSearching && searchValue) chatContent = separateSearchValue(chatContent, searchValue);

    if (isMounted()) setContentList(chatContent);
  };

  useEffect(() => {
    if (messageContent) {
      handleChatContent();
    }
  }, [messageContent, mentions, mentionList, groupDetail, prefixKey, searchValue]);

  return (
    <Typography
      id={cssSelectorId}
      sx={{ whiteSpace: "pre-wrap", lineBreak: "normal", wordBreak: "break-word", color: "inherit" }}
      {...otherProps}
    >
      {<span>{preText}</span>}
      {contentList.map((item, index) =>
        item.isHtml ? (
          <span key={index} dangerouslySetInnerHTML={{ __html: item.content }} color="inherit" />
        ) : (
          <span key={index} color="inherit">
            {item.isMention ? <b>{item.content}</b> : item.content}
          </span>
        ),
      )}
    </Typography>
  );
};

ChatTypography.propTypes = {
  mentionList: PropTypes.array,
  mentions: PropTypes.string,
  isEnableSearching: PropTypes.bool,
  preText: PropTypes.element,
};
ChatTypography.defaultProps = {
  isEnableSearching: true,
};

export default memo(ChatTypography);

const separateMentionContent = async (msgContent, mentions) => {
  if (!msgContent || false === msgContent.includes(PREFIX_MENTION)) {
    return {
      rawText: msgContent,
      startMentionIndex: 0,
      endMentionIndex: 0,
      isValidMention: false,
    };
  }

  const startMentionIndex = msgContent.indexOf(PREFIX_MENTION);
  const rawText = msgContent.slice(0, startMentionIndex);
  const newMsgContent = msgContent.slice(startMentionIndex);
  const endMentionIndex = newMsgContent.indexOf(SUFFIX_MENTION) + 1;

  const mentionContent = newMsgContent.slice(0, endMentionIndex).trim();
  const convertMentionContent = await replaceId2Name(mentionContent, mentions, false);
  const isValidMention = convertMentionContent !== mentionContent;

  return {
    rawText,
    startMentionIndex,
    endMentionIndex,
    mentionContent: convertMentionContent, // Content matching rule @{}
    otherContent: newMsgContent.slice(endMentionIndex),
    isValidMention,
  };
};

const separateLinkContent = (msgContent, resultArr = []) => {
  const hasLinkContent = msgContent && isExternalLink(msgContent);

  if (!hasLinkContent) {
    resultArr = [...resultArr, { content: msgContent, isHtml: false }];
    return resultArr;
  }

  try {
    const url = getExternalLinkFromString(msgContent)[0];
    if (url) {
      const startUrlIndex = msgContent.indexOf(url);
      resultArr = [
        ...resultArr,
        { content: msgContent.slice(0, startUrlIndex), isHtml: false },
        {
          content: `<a href="${url}" onclick="clickExternalLink(event)">${url}</a>`,
          isHtml: true,
          isLink: true,
        },
      ];

      return separateLinkContent(msgContent.slice(startUrlIndex + url.length, msgContent.length), resultArr);
    } else {
      return resultArr;
    }
  } catch (error) {
    console.error(error);
    console.warn({ msgContent });
    return resultArr;
  }
};

const separateMessageContent = async (msgContent = "", mentions) => {
  const isMention =
    msgContent.includes(`${PREFIX_MENTION}${SystemConstant.MENTION_ALL.id}${SUFFIX_MENTION}`) ||
    msgContent.indexOf(SUFFIX_MENTION) - msgContent.indexOf(PREFIX_MENTION) >= 32; // Valid id has 32 characters
  const isSpecialContent = Boolean(msgContent) && (isExternalLink(msgContent) || isMention);

  if (!msgContent || !isSpecialContent) {
    return [
      {
        content: msgContent,
        isHtml: false,
      },
    ];
  }

  /**
   * Array object that structure like:
   * {
   *  content: <message content>
   *  isHtml: <true: display html text - false: raw text>
   * }
   */
  let result = [];
  const { rawText, mentionContent, otherContent, isValidMention } = await separateMentionContent(msgContent, mentions);
  if (rawText) result = result.concat(separateLinkContent(rawText));

  if (mentionContent) {
    result.push({
      content: mentionContent, // Content matching rule @{}
      isMention: isValidMention,
    });
  }

  if (otherContent) result = result.concat(await separateMessageContent(otherContent, mentions));

  return result.filter(item => Boolean(item.content));
};

const separateSearchValue = (contentList, searchValue) => {
  let convertContentList = contentList;
  let results = [];
  let replaceIndex = 0;

  contentList.forEach(item => {
    const itemContent = item.content || "";
    const startSearchIndex = itemContent.toLowerCase().indexOf(searchValue);

    if (startSearchIndex >= 0 && false === Boolean(item.isLink)) {
      let result = [];
      result[0] = {
        content: highlightString(searchValue, formatStringWithKeyword(searchValue, itemContent), "black", "yellow"),
        isHtml: true,
      };
      // result[1] = {
      //   content: `<strong style="color: black; background: yellow">${searchValue}</strong>`,
      //   isHtml: true,
      // };
      // result[2] = {
      //   content: itemContent.slice(startSearchIndex + searchValue.length, itemContent.length),
      //   isHtml: item.isHtml,
      // };

      let startArr = convertContentList.splice(replaceIndex);
      startArr.shift();
      results = convertContentList.concat(result).concat(startArr);

      convertContentList = results;
      replaceIndex = replaceIndex + 3;
    } else {
      results.push(convertContentList[replaceIndex]);
      replaceIndex = replaceIndex + 1;
    }
  });

  return results;
};
