import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAccountList: ["data", "prefixKey"],
  blockUser: ["data", "prefixKey"], // data = { groupId, blockedAccountId }
  unblockUser: ["data", "prefixKey"], // data = { groupId, blockedAccountId }

  accountSuccess: ["data"],
  accountFailure: ["error", "data"],
  accountSet: ["data"],
});

export const AccountTypes = Types;
export const AccountActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  ...REDUX_STATE,
  blockAccountId: null,
  updateChattingMember: {},

  accountList: [],
};

/* ------------- Selector ------------- */
export const AccountSelectors = {
  getAccountList: state => state.accountRedux.accountList,
  getBlockAccountId: state => state.accountRedux.blockAccountId,
  getUpdateChattingMember: state => state.accountRedux.updateChattingMember,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
    blockAccountId: null,
  });

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_ACCOUNT_LIST]: request,
  [Types.BLOCK_USER]: request,
  [Types.UNBLOCK_USER]: request,

  [Types.ACCOUNT_SET]: set,
  [Types.ACCOUNT_SUCCESS]: success,
  [Types.ACCOUNT_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const AccountReducer = createReducer(INITIAL_STATE, HANDLERS);
