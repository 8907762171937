import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import { AuthActions, AuthSelectors, AuthTypes, AuthReducer } from "./auth.redux";
import {
  ConversationActions,
  ConversationSelectors,
  ConversationTypes,
  ConversationReducer,
} from "./conversation.redux";
import { CallingActions, CallingSelectors, CallingTypes, CallingReducer } from "./calling.redux";
import { ContactActions, ContactSelectors, ContactTypes, ContactReducer } from "./contact.redux";
import { DeviceActions, DeviceSelectors, DeviceTypes, DeviceReducer } from "./device.redux";
import { GroupInfoActions, GroupInfoSelectors, GroupInfoTypes, GroupInfoReducer } from "./group-info.redux";
import { BranchActions, BranchSelectors, BranchTypes, BranchReducer } from "./branch.redux";
import { RestoreActions, RestoreSelectors, RestoreTypes, RestoreReducer } from "./restore.redux";
import { ProfileActions, ProfileSelectors, ProfileTypes, ProfileReducer } from "./profile.redux";
import { AccountActions, AccountSelectors, AccountTypes, AccountReducer } from "./account.redux";
import { SystemActions, SystemSelectors, SystemTypes, SystemReducer } from "./system.redux";
import { PubSubActions, PubSubTypes, PubSubReducer } from "./pubsub.redux";

/* ------------- Assemble The Reducers ------------- */
export const appReducer = combineReducers({
  authRedux: AuthReducer,
  conversationRedux: ConversationReducer,
  groupInfoRedux: GroupInfoReducer,
  contactRedux: ContactReducer,
  callingRedux: CallingReducer,
  deviceRedux: DeviceReducer,
  branchRedux: BranchReducer,
  restoreRedux: RestoreReducer,
  profileRedux: ProfileReducer,
  accountRedux: AccountReducer,
  systemRedux: SystemReducer,
  pubsubRedux: PubSubReducer,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};

/* ------------- Redux Configuration ------------- */

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();

// Create store
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// kick off root saga
sagaMiddleware.run(rootSaga);

export const getReduxState = reducerFunc => reducerFunc(store.getState());

/* ------------- Export ------------- */
export {
  AuthActions,
  AuthTypes,
  AuthSelectors,
  ConversationActions,
  ConversationSelectors,
  ConversationTypes,
  GroupInfoActions,
  GroupInfoSelectors,
  GroupInfoTypes,
  CallingActions,
  CallingSelectors,
  CallingTypes,
  ContactActions,
  ContactSelectors,
  ContactTypes,
  DeviceActions,
  DeviceSelectors,
  DeviceTypes,
  BranchActions,
  BranchSelectors,
  BranchTypes,
  RestoreActions,
  RestoreSelectors,
  RestoreTypes,
  ProfileActions,
  ProfileSelectors,
  ProfileTypes,
  AccountActions,
  AccountSelectors,
  AccountTypes,
  SystemActions,
  SystemSelectors,
  SystemTypes,
  PubSubActions,
  PubSubTypes,
};

export default store;
