import { call, put } from "redux-saga/effects";
import { remoteApiFactory } from "services";
import { AttachmentUtil, StorageUtil, toSnake, uuid } from "utils";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import store, { ProfileActions } from "redux-store";
import { getInteractor } from "services/local.service";
import { tusUpload } from "services/multiple-file/tus-upload";

export function* updateAccountStatus(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateAccount, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ProfileActions.profileSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

// Update avatar/ cover account
export const changeProfileImage = action => {
  try {
    const { data, isAvatar } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const attachmentId = uuid();

    const onError = error => {
      console.log("change profile image fail: ", error);
    };

    const onSuccess = () => {
      const updateAccountParams = toSnake(isAvatar ? { avatarId: attachmentId } : { backgroundId: attachmentId });
      remoteApiFactory.getBranchApi(prefixKey).updateAccount(updateAccountParams);
      getInteractor(prefixKey).LocalAccountService.updateImage(accountId, attachmentId, isAvatar);

      store.dispatch(
        ProfileActions.profileSet({
          updatedProfile: new Date().getTime(),
        }),
      );
    };

    // Save file to local
    const fileName = `${attachmentId}.png`;
    AttachmentUtil.copyToUpload(data.upload.path, attachmentId, fileName, accountId, false);

    tusUpload(prefixKey, attachmentId, data.upload.path, SystemConstant.UPLOAD_TYPE.account, null, {
      onError,
      onSuccess,
    });
  } catch (error) {
    console.log("change profile image fail: ", error);
  }
};

export function* updateProfileInfo(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateAccount, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ProfileActions.profileSuccess({
          isUpdateUserInfoSuccess: true,
        }),
      );
    } else {
      yield put(
        ProfileActions.profileFailure(response, {
          isUpdateUserInfoFailed: true,
        }),
      );
    }
  } catch (error) {
    yield put(ProfileActions.profileFailure(error));
  }
}
