import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import TitleChat from "./TitleChat";
import SearchMode from "./SearchMode";
import Thread from "./ThreadMode";
import InitGroupCallPopup from "./InitGroupCallPopup";
import { ConversationActions, ConversationSelectors } from "redux-store";
import { useBlockedAccountStatus } from "hooks";
import { ConversationProvider, useConversationContext } from "./ConversationContext";
import MessengerInputLayout from "./MessengerInputLayout";
import { isGroupOrChannelType } from "pages/Call";
import ViewMode from "./ViewMode";

const MessengerChat = () => {
  return (
    <ConversationProvider>
      <MessengerListView />
      <MessengerInputLayout />

      <StartCallingGroupDialog />
    </ConversationProvider>
  );
};

export default memo(MessengerChat);

const StartCallingGroupDialog = () => {
  const dispatch = useDispatch();
  const { groupDetail } = useConversationContext();
  const isAddConference = useSelector(state => state.conversationRedux.isAddConference);

  const [isOpenInit, setIsOpenInit] = useState(false);

  useEffect(() => {
    if (isAddConference) {
      setIsOpenInit(true);
      dispatch(
        ConversationActions.conversationSet({
          isAddConference: false,
        }),
      );
    }
  }, [isAddConference]);

  if (!isOpenInit) return null;

  return <InitGroupCallPopup open={isOpenInit} onClose={() => setIsOpenInit(false)} data={groupDetail} />;
};

const MessengerListView = () => {
  const classes = useStyles();

  const { groupDetail } = useConversationContext();
  const { isBlockedAccount } = useBlockedAccountStatus();

  const isSearchMode = useSelector(state => state.conversationRedux.isSearchMode);
  const threadingId = useSelector(ConversationSelectors.getThreadingId);

  return threadingId ? (
    <Thread groupDetail={groupDetail} threadingId={threadingId} />
  ) : (
    <>
      <TitleChat />

      <Box
        className={clsx(
          classes.wrapFlex,
          isBlockedAccount && false === isGroupOrChannelType(groupDetail.groupType) && classes.blockedContainer,
        )}
      >
        {isSearchMode ? <SearchMode /> : <ViewMode />}
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  wrapFlex: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.white,
  },

  blockedContainer: {
    height: "calc(100% - 220px)",
  },
}));
