import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const NotificationOffIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M12 22.0298C13.1 22.0298 14 21.1298 14 20.0298H10C10 21.1298 10.89 22.0298 12 22.0298ZM18 16.0298V11.0298C18 7.95979 16.36 5.38978 13.5 4.70978V4.02979C13.5 3.19979 12.83 2.52979 12 2.52979C11.17 2.52979 10.5 3.19979 10.5 4.02979V4.70978C7.63 5.38978 6 7.94979 6 11.0298V16.0298L4 18.0298V19.0298H20V18.0298L18 16.0298Z"
        fill="black"
      />
      <rect
        x="17.7966"
        y="1.30977"
        width="2.55437"
        height="24.4121"
        rx="1.27719"
        transform="rotate(32.4562 17.7966 1.30977)"
        fill="white"
        stroke="black"
      />
    </SvgIcon>
  );
};

NotificationOffIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
NotificationOffIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(NotificationOffIcon);
