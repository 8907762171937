import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const Call = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <circle cx="18" cy="18" r="18" fill="#F5F5F5" />
      <path
        d="M26.01 21.38C24.78 21.38 23.59 21.18 22.48 20.82C22.13 20.7 21.74 20.79 21.47 21.06L19.9 23.03C17.07 21.68 14.42 19.13 13.01 16.2L14.96 14.54C15.23 14.26 15.31 13.87 15.2 13.52C14.83 12.41 14.64 11.22 14.64 9.99C14.64 9.45 14.19 9 13.65 9H10.19C9.65 9 9 9.24 9 9.99C9 19.28 16.73 27 26.01 27C26.72 27 27 26.37 27 25.82V22.37C27 21.83 26.55 21.38 26.01 21.38Z"
        fill="#6C7078" />
    </SvgIcon>
  );
};
Call.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
Call.defaultProps = {
  width: 36,
  height: 36,
};
export default memo(Call);
