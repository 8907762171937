import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/styles";

const Scrollable = ({ children, onBottom, ...otherProps }) => {
  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        if (onBottom) onBottom();
      }
    }
  };

  useEffect(() => {
    const listInnerElement = listInnerRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [onScroll]);

  return (
    <FileContainer ref={listInnerRef} {...otherProps}>
      {children}
    </FileContainer>
  );
};

export default Scrollable;

const FileContainer = styled(Box)({
  width: "100%",
  maxHeight: "100%",
  position: "relative",
  overflow: "auto",
});
