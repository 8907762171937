import { SystemConstant } from "const";
import { ExecuteEvent } from "pubsub/PubSub.const";
import store, { CallingActions, SystemActions } from "redux-store";
import { getInteractor } from "services/local.service";

export const deleteCallHistory = async (prefixKey, roomIds) => {
  try {
    if (roomIds.includes("select_all")) {
      await getInteractor(prefixKey).LocalCallHistoryService.update(
        {
          state: SystemConstant.STATE.inactive,
          modified: Date.now(),
        },
        {
          state: SystemConstant.STATE.active,
        },
      );
    } else {
      const updateCallList = roomIds.map(item => ({
        updateValues: {
          state: SystemConstant.STATE.inactive,
          modified: Date.now(),
        },
        condition: {
          room_id: item,
        },
      }));
      await getInteractor(prefixKey).LocalCallHistoryService.updateListByCondition(updateCallList);
    }

    store.dispatch(
      CallingActions.callingSet({
        deleteCallRoomIds: roomIds,
      }),
    );

    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("event delete call history fail: ", error);
  }

  return ExecuteEvent.FAIL;
};

export const seenCallHistory = async (prefixKey, callHistoryIds) => {
  try {
    const updateValue = callHistoryIds.map(item => ({
      updateValues: {
        action_status: SystemConstant.CALL_HISTORY_ACTION_STATUS.seen,
        modified: Date.now(),
      },
      condition: { id: item },
    }));
    await getInteractor(prefixKey).LocalCallHistoryService.updateListByCondition(updateValue);

    // Dispatch to update view
    store.dispatch(
      SystemActions.systemSet({
        seenCallHistoryTimestamp: Date.now(),
      }),
    );

    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("seen call history event fail: ", error);
  }

  return ExecuteEvent.FAIL;
};
