import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { AvatarInfo } from "components";
import { LangConstant, SystemConstant } from "const";
import { getNSLang } from "utils/lang.utils";

const PeopleTagList = ({ isShow, mentionMembers, onClick }) => {
  const classes = useStyles();

  const isShowAll = mentionMembers.findIndex(item => item.id === SystemConstant.MENTION_ALL.id) >= 0;

  return (
    <Box className={classes.tagListContainer}>
      <Box className={isShow ? classes.popover : "hidden"}>
        <Box className={classes.memberListBox}>
          {isShowAll && (
            <AvatarInfo
              className={classes.itemPeople}
              title={SystemConstant.MENTION_ALL.name}
              secondTitle={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_MENTION_ALL_SUBTITLE")}
              avatar={{ type: SystemConstant.GROUP_CHAT_TYPE.group }}
              button={true}
              onClick={() => onClick(SystemConstant.MENTION_ALL)}
            />
          )}

          {mentionMembers.map(
            item =>
              item.id !== SystemConstant.MENTION_ALL.id && (
                <AvatarInfo
                  key={item.id}
                  className={classes.itemPeople}
                  secondTitle={item.phone}
                  title={item.name}
                  avatar={{ avatarId: item.avatarId, name: item.name }}
                  button={true}
                  onClick={() => onClick(item)}
                />
              ),
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PeopleTagList);

const useStyles = makeStyles(theme => ({
  tagListContainer: {
    width: "100%",
    position: "absolute",
    bottom: "calc(100% - 4px)",
    left: "50%",
    transform: "translate(-50%)",
    backgroundColor: "transparent",
    zIndex: 1,
  },

  popover: {
    width: "auto",
    overflow: "hidden",
    boxShadow: "0px -3px 9px rgba(0, 0, 0, 0.15)",
    borderRadius: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 8,
    backgroundColor: theme.palette.white,
  },

  memberListBox: {
    borderRadius: 4,
    overflow: "auto",
    maxHeight: 350,
  },

  memberAvatar: {},

  memberName: {
    marginLeft: 12,
  },

  itemPeople: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: 12,
    paddingBottom: 8,
    paddingTop: 8,
    borderRadius: 20,
  },
}));
