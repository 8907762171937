import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { formatEmoij } from "utils";
import ReactionDetailDialog from "./ReactionDetailDialog";
import { Box, Typography } from "@mui/material";

const ReactionFlow = ({ isShow, reactionList }) => {
  const classes = useStyles();

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [displayReaction, setDisplayReaction] = useState([]);

  const handleShowReactionDialog = () => setIsShowDialog(preState => !preState);

  useEffect(() => {
    const handleReactionArr = reactionList.map(react => react.content);
    const finalReactionArr = handleReactionArr
      .filter((react, index) => handleReactionArr.indexOf(react) === index)
      .slice(0, 2);

    setDisplayReaction(finalReactionArr);
  }, [reactionList]);

  return (
    isShow && (
      <>
        <Box className={classes.reactCounterWrapper} onClick={handleShowReactionDialog}>
          {displayReaction.map(react => (
            <Box component="span" key={react} dangerouslySetInnerHTML={{ __html: formatEmoij(react) }} />
          ))}
          {reactionList.length > 1 && (
            <Typography component="span" className={classes.reactCounterNumber}>
              {reactionList.length}
            </Typography>
          )}
        </Box>
        <ReactionDetailDialog open={isShowDialog} onClose={handleShowReactionDialog} data={reactionList} />
      </>
    )
  );
};

export default memo(ReactionFlow);

const useStyles = makeStyles(theme => ({
  reactCounterWrapper: {
    display: "flex",
    backgroundColor: theme.palette.white,
    borderRadius: 13,
    padding: "0 4px",
    cursor: "pointer",
  },

  reactCounterNumber: {
    marginLeft: 3,
    fontWeight: 400,
    color: theme.palette.black,
  },
}));
