import React, { memo } from "react";
import { SvgIcon } from "@mui/material";

const IncomingAudioCall = props => {
  return (
    <SvgIcon {...props} viewBox={`0 0 14 14`} fill="none">
      <g clipPath="url(#clip0_50_927)">
        <path
          d="M2.41479 0.00209422C2.81589 0.00209422 3.21655 0.00209422 3.61722 0.00209422C4.32231 0.00646908 4.78902 0.449643 4.81177 1.14918C4.83845 1.96159 4.94299 2.76001 5.19056 3.53698C5.33666 3.99416 5.2553 4.39927 4.94037 4.77944C4.56857 5.22787 4.22958 5.70341 3.88797 6.1759C3.8651 6.2153 3.85131 6.25931 3.8476 6.30472C3.84389 6.35013 3.85036 6.3958 3.86654 6.43839C4.71029 8.05709 5.94946 9.28511 7.56348 10.1321C7.60647 10.1476 7.65241 10.1532 7.69787 10.1484C7.74332 10.1436 7.7871 10.1286 7.82593 10.1045C8.32165 9.74404 8.81038 9.37567 9.2921 8.99943C9.6031 8.75751 9.94821 8.67351 10.3196 8.78288C11.1649 9.03456 12.0396 9.174 12.9212 9.19762C13.5336 9.21424 13.9841 9.69635 13.9911 10.3088C14.0016 11.1616 14.0016 12.0144 13.9911 12.8673C13.9841 13.5265 13.4921 14.0047 12.8259 13.9986C10.4315 13.9833 8.20034 13.3682 6.1651 12.1065C3.43978 10.4166 1.58168 8.04134 0.590815 4.98069C0.19333 3.74407 -0.00668782 2.4525 -0.00186806 1.15356C-0.00186806 0.492517 0.479276 0.0130312 1.14501 0.00296904C1.56973 -0.00315576 1.99226 0.00209422 2.41479 0.00209422Z"
          fill="currentColor"
        />
        <path
          d="M12.0834 1.9165L7.91675 6.08317M7.91675 6.08317H12.0834M7.91675 6.08317V1.9165"
          stroke="#757575"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_50_927">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default memo(IncomingAudioCall);
