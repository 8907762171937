import React from "react";
import { Box, Container, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BtnAppleStoreImage, BtnPlayStoreImage, DownloadImage, LogoImage } from "const/image.const";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { CopyrightCompany } from "components";

const PLAY_STORE = "https://play.google.com/store";
const APPLE_STORE = "https://www.apple.com/vn/app-store/";
const WarningDevicePage = () => {
  const classes = useStyles();
  const styles = createStyleObj();
  const { t: getLabel } = useTranslation();

  return (
    <Container className={classes.root} sx={styles.root}>
      <Box className={classes.main}>
        <Box className={classes.centerLine}>
          <img src={LogoImage} alt="logo app" loading="lazy" />
          <Typography className={classes.appName} sx={styles.appName}>
            {getLabel(LangConstant.TXT_APP_NAME)}
          </Typography>
        </Box>
        <img src={DownloadImage} alt="downloading app" loading="lazy" />
        <Box className={classes.centerLine}>
          <Link href={PLAY_STORE} className={classes.btnDownload}>
            <img src={BtnPlayStoreImage} alt="play store" loading="lazy" />
          </Link>
          <Link href={APPLE_STORE} className={classes.btnDownload}>
            <img src={BtnAppleStoreImage} alt="apple" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <CopyrightCompany />
    </Container>
  );
};

export default WarningDevicePage;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  appName: {
    color: theme.palette.primary.main,
  },
  centerLine: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 60,
    marginBottom: 60,
    "& > *:first-child": {
      marginRight: 12,
    },
  },
  btnDownload: {
    width: "calc(50% - 6px)",
    "&, & > img": { height: "100%" },
  },
}));

const createStyleObj = () => ({
  root: { display: "flex" },
  appName: { fontFamily: "Roboto", fontWeight: 600, fontSize: 36 },
});
