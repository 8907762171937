import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { convertString2JSON, getPrefixKey, toCamel, toSnake } from "utils";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { LocalDbManagement, LocalKeyActionService, getInteractor } from "services/local.service";
import { AuthActions } from "redux-store";
import { StorageUtil } from "utils";
import { AuthService, CommonBranchInfoService, remoteApiFactory } from "services";
import { BGlobalServerImage } from "const/image.const";
import { ConfirmDialog } from "components";
import { changeBranchServer } from "utils/branch.utils";
import { handlingLogoutBranch } from "utils/auth.utils";

const NotificationInviteItem = ({ data, onAccept, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dispatch = useDispatch();

  const [branchDetail, setBranchDetail] = useState({});
  const [notification, setNotification] = useState(data);
  const [currentLoginBranch, setCurrentLoginBranch] = useState({});
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const currentPrefixKey = StorageUtil.getCommonKey(KeyConstant.KEY_PREFIX);

  const handleAccept = async () => {
    // Go to branch if user login branch before
    let branchItem = await LocalDbManagement.findOne({
      state: SystemConstant.STATE.active,
      branch_id: branchDetail.id,
    });

    branchItem = branchItem ? toCamel(branchItem) : {};
    if (branchItem.id && branchItem.ownerName === notification.invitePhone) {
      const prefixKey = getPrefixKey(branchItem.accountId, branchItem.branchId);
      const selectedBranch = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey);
      setCurrentLoginBranch({});
      updateInvitation(data, true, currentPrefixKey);
      changeBranchServer(selectedBranch);
      onAccept();
      return;
    } else if (branchItem.id) {
      setCurrentLoginBranch(branchItem);
      setIsShowConfirm(true);
    } else {
      setCurrentLoginBranch({});
      await handleLoginBranch();
    }
  };

  const handleLoginBranch = async () => {
    updateInvitation(data, true, currentPrefixKey);
    StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, branchDetail);
    const [loginParams, dataLogin] = await requestLogin(branchDetail.domain, notification);
    if (dataLogin) {
      const verifyParams = {
        device_name: StorageUtil.getCommonKey(KeyConstant.KEY_DEVICE_NAME),
        phone: loginParams.phone,
        token: loginParams.token,
        account_uuid: loginParams.accountUuid,
        device_uuid: loginParams.deviceUuid,
        verify_token: notification.content,
      };

      setNotification(preState => ({ ...preState, state: SystemConstant.INVITATION_STATE.accepted }));
      dispatch(AuthActions.requestVerify(toSnake(verifyParams)));
    }

    if (currentLoginBranch && currentLoginBranch.id) {
      const prefixKey = getPrefixKey(currentLoginBranch.accountId, currentLoginBranch.branchId);
      await handlingLogoutBranch(prefixKey);
    }
    onAccept();
  };

  const handleDecline = () => {
    setNotification(preState => ({ ...preState, state: SystemConstant.INVITATION_STATE.rejected }));

    // Decline invitation
    updateInvitation(data, false, currentPrefixKey);
  };

  const handleUpdateBranch = async data => {
    const branchInfo = await CommonBranchInfoService.getBranchWithoutToken(data.branchDomain, data.branchId);
    const branchAvatarUrl = CommonBranchInfoService.getBranchAvatarUrl(data.branchDomain, data.branchId);

    setBranchDetail({ ...branchInfo, branchAvatarUrl: branchAvatarUrl || BGlobalServerImage });
  };

  useEffect(() => {
    if (!data) return;

    const inviteOptions = toCamel(convertString2JSON(data.options, {}));
    handleUpdateBranch(inviteOptions);

    setNotification({ ...data, invitePhone: inviteOptions.ownerName });
  }, [data]);

  let notifyContent = "";
  switch (notification.state) {
    case SystemConstant.INVITATION_STATE.accepted:
      notifyContent = getLabel(LangConstant.TXT_INVITE_ACCEPTED);
      break;

    case SystemConstant.INVITATION_STATE.deleted:
      notifyContent = getLabel(LangConstant.TXT_INVITE_DISMISSED);
      break;

    case SystemConstant.INVITATION_STATE.rejected:
      notifyContent = getLabel(LangConstant.TXT_INVITE_REJECTED);
      break;

    default:
      notifyContent = getLabel(LangConstant.TXT_INVITE_TO_SERVER);
      break;
  }

  return (
    <>
      <Box className={classes.notificationItemRoot} {...otherProps}>
        <Box className={classes.notificationRequestItemRoot}>
          <Box className={classes.detailContainer}>
            <Box className={classes.avatarBox}>
              <Avatar alt="Branch Avatar" src={branchDetail.branchAvatarUrl} />
            </Box>
            <Box className={classes.notificationContentBox}>
              <Typography variant="subtitle2" className={classes.inviteName}>
                {branchDetail.name}
              </Typography>
              <Typography className={clsx(classes.inviteContent, "regular-sm-txt")}>{notifyContent}</Typography>
            </Box>
          </Box>
          {notification.state === SystemConstant.INVITATION_STATE.pending && (
            <Box className={classes.actionContainer}>
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.actionButton, "semiBold-sm-txt")}
                onClick={handleAccept}
              >
                {getCommonLang(LangConstant.TXT_ACCEPT)}
              </Button>
              <Button
                className={clsx(classes.actionButton, classes.deniedButton, "semiBold-sm-txt")}
                onClick={handleDecline}
              >
                {getCommonLang(LangConstant.TXT_DENIED)}
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {isShowConfirm && (
        <ConfirmDialog
          open
          content={getNSLang(LangConstant.NS_COMMON, "FM_SWITCH_BRANCH", {
            branchName: currentLoginBranch.branchName,
            phone: currentLoginBranch.ownerName,
          })}
          submitProps={{
            submitText: getNSLang(LangConstant.NS_COMMON, "TXT_CONFIRM"),
            onClick: handleLoginBranch,
          }}
          cancelProps={{ onClick: () => setIsShowConfirm(false) }}
        />
      )}
    </>
  );
};

export default NotificationInviteItem;

const updateInvitation = async (notification, isAccepted, currentPrefixKey) => {
  const noticeState = isAccepted ? SystemConstant.INVITATION_STATE.accepted : SystemConstant.INVITATION_STATE.rejected;

  // Update invitation on global server
  const invitationResponse = await remoteApiFactory.getBranchApi().updateMessageStatus(
    toSnake({
      notifyIds: [notification.id],
      status: SystemConstant.NOTIFICATION_STATUS.read,
      state: noticeState,
    }),
  );
  if (invitationResponse.status !== ApiConstant.STT_OK) return;

  // Update invitation on global database
  await getInteractor(currentPrefixKey).LocalNotificationService.update(
    {
      status: SystemConstant.NOTIFICATION_STATUS.read,
      state: noticeState,
      modified: Date.now(),
    },
    { id: notification.id },
  );
};

const requestLogin = async (domain, notification) => {
  const initLoginData = await LocalKeyActionService.getInitLoginData();
  const loginParams = {
    ...initLoginData,
    regionCode: "+84",
    phone: notification.invitePhone,
    deviceUuid:
      StorageUtil.getCommonKey(KeyConstant.KEY_MAC) || StorageUtil.getCommonKey(KeyConstant.KEY_DEFAULT_DEVICE_ID),
    verify_token: notification.content,
    version: window.env?.APP_VERSION || initLoginData.version,
  };

  const loginResponse = await AuthService.login(toSnake(loginParams), domain);
  const dataLogin = toCamel(loginResponse.data);
  if (loginResponse.status === ApiConstant.STT_OK && dataLogin) {
    return [loginParams, dataLogin];
  }

  return [];
};

export const useStyles = makeStyles(theme => ({
  notificationItemRoot: {
    padding: "12px 14px",
    borderTop: "0.5px solid " + theme.palette.divider,
  },

  notificationRequestItemRoot: {
    display: "flex",
    flexDirection: "column",
  },

  detailContainer: {
    display: "flex",
    alignItems: "center",
  },

  notificationContentBox: {
    maxWidth: "calc(100% - 50px)",
    height: "100%",
    paddingLeft: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  avatarBox: {
    width: 50,
    height: 50,
    flexShrink: 0,
  },

  inviteName: {
    lineHeight: "16px",
    marginBottom: 5,
  },

  inviteContent: {
    lineHeight: "16px",
  },

  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 14,
  },

  actionButton: {
    borderRadius: 10,
    height: 35,
    minHeight: "unset",
    marginRight: 6,
    width: "50%",
  },

  deniedButton: {
    backgroundColor: theme.palette.grey[100],
    marginRight: 0,
    marginLeft: 6,
    width: "50%",
    color: theme.palette.black,
  },

  createdTimeNotification: {
    lineHeight: "16px",
    marginTop: 5,
    fontSize: 10,
    fontWeight: 400,
  },

  messageContent: {
    lineHeight: "16px",
    color: theme.palette.black,
    WebkitLineClamp: 3,
    lineBreak: "auto",
  },

  mentionNotificationItemRoot: {
    padding: "12px 14px",
    borderTop: "0.5px solid " + theme.palette.divider,
    cursor: "pointer",
  },

  iconImage: {
    background: "#CBCACA",
  },

  smartKeyIcon: {
    width: 20,
    height: 20,
  },
}));
