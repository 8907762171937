import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { isJSONString } from "utils";
import { ContentCopy, Delete, Edit, Reply, Download, Info } from "@mui/icons-material";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { ActionMenu } from "components";
import ConfirmDeleteMessagePopup from "./ConfirmDeteleMessagePopup";
import { BranchSelectors, ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { saveMessageInQueue } from "../ConversationContext";
import MessageDetailDrawer from "./MessageDetailDrawer";
import { getInteractor } from "services/local.service";
import { useCleanUpEffect, useDownloadFile } from "hooks";
import { getMessageFileList, replaceId2Name } from "utils/message.utils";
import { getBranchOptions } from "utils/branch.utils";

const ChatActionMenu = ({ message, menuAnchor, isThreadMode, onEditMessage, onClose }) => {
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isMine = message.senderId === accountId;
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { downloadFile } = useDownloadFile();
  const { isMounted } = useCleanUpEffect();

  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const editMessageTime = getBranchOptions(selectedBranch?.options).editMessageTime;
  const deleteMessageTime = getBranchOptions(selectedBranch?.options).deleteMessageTime;
  const isAllowEdit = Date.now() - message.created < editMessageTime;
  const isAllowDelete = isMine && Date.now() - message.created < deleteMessageTime;

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [isDetailMessage, setIsDetailMessage] = useState(false);

  const handleReplyMessage = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        selectedGroupId: message.groupId,
        threadingId: message.sourceId,
      }),
    );
    onClose();
  };

  const handleDownLoadFile = async () => {
    if (MEDIA_TYPES.includes(message.sendType) && isJSONString(message.content)) {
      const msgFileList = getMessageFileList(message);
      return downloadFile(msgFileList, message.groupId);
    }

    // Show alert if app can not download file
    alert(getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_FILE_NOT_FOUND));
  };

  const handleCopyContent = async () => {
    const groupDetail = (await getInteractor(prefixKey).LocalGroupService.get(message.groupId)) || {};
    const groupMembers = groupDetail.groupMembers || [];
    navigator?.clipboard.writeText(await replaceId2Name(message.content, groupMembers, false));
    onClose();
  };

  const handleShowConfirmDialog = () => {
    setIsOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    if (isMounted()) setIsOpenConfirmDialog(false);
    onClose();
  };

  const handleDeleteMessage = async () => {
    if (isMine) {
      await saveMessageInQueue({
        prefixKey,
        groupId: message.groupId,
        threadId: message.threadId,
        sendType: SystemConstant.SEND_TYPE.deleteMessage,
        content: message.id,
        parentId: message.sourceId,
      });
    } else {
      dispatch(ConversationActions.deleteMessageLocal(message, StorageUtil.getCurrentPrefixKey()));
    }
    handleCloseConfirmDialog();
  };

  const handleShowDetail = () => {
    setIsDetailMessage(true);
    onClose();
  };

  const isMediaMessage = MEDIA_TYPES.includes(message.sendType);
  const isEditable =
    isAllowEdit && isMine && ![...MEDIA_TYPES, SystemConstant.SEND_TYPE.file].includes(message.sendType);
  const isCopyable = [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage].includes(
    message.sendType,
  );
  const menuArray = [
    isThreadMode
      ? null
      : {
          onClick: handleReplyMessage,
          icon: <Reply />,
          content: getCommonLang(LangConstant.TXT_REPLY),
        },
    {
      onClick: handleShowDetail,
      icon: <Info />,
      content: getCommonLang("TXT_SEE_INFORMATION"),
    },
    isEditable
      ? {
          onClick: onEditMessage,
          icon: <Edit />,
          content: getCommonLang(LangConstant.TXT_EDIT),
        }
      : null,

    isCopyable
      ? {
          onClick: handleCopyContent,
          icon: <ContentCopy />,
          content: getCommonLang(LangConstant.TXT_COPY),
        }
      : null,

    isMediaMessage
      ? {
          onClick: handleDownLoadFile,
          icon: <Download />,
          content: getCommonLang(LangConstant.TXT_DOWNLOAD),
        }
      : null,

    isAllowDelete
      ? {
          onClick: handleShowConfirmDialog,
          icon: <Delete />,
          content: getCommonLang(LangConstant.TXT_DELETE),
        }
      : null,
  ].filter(item => Boolean(item && item.content));

  return (
    <>
      <ActionMenu contentArray={menuArray} onClose={onClose} anchorEl={menuAnchor} placement="top" />

      {isOpenConfirmDialog && (
        <ConfirmDeleteMessagePopup
          open={isOpenConfirmDialog}
          onCloseConfirmDialog={handleCloseConfirmDialog}
          onConfirmDelete={handleDeleteMessage}
        />
      )}

      {isDetailMessage && (
        <MessageDetailDrawer message={message} open={isDetailMessage} onClose={() => setIsDetailMessage(false)} />
      )}
    </>
  );
};

export default memo(ChatActionMenu);

const MEDIA_TYPES = [SystemConstant.SEND_TYPE.audio, SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video];
