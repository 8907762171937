import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { AppDrawer } from "components";
import SelectingListContact from "../SelectingListContact";
import { useAccountList } from "hooks";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";

const AddingChannelMember = ({ isConferenceFlow, onCreateChannel, ...otherProps }) => {
  const { accountList } = useAccountList();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });
  const serverOptions = useSelector(BranchSelectors.getServerOptions);

  const [isShow, setIsShow] = useState(false);

  const onCreateControlFuc = useCallback(open => () => setIsShow(open), []);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const selectList = accountList.filter(item => item.id !== accountId);

  return (
    <>
      <Button variant="contained" fullWidth size="large" onClick={onCreateControlFuc(true)} {...otherProps}>
        {chatObjContent.btnContinue}
      </Button>

      <AppDrawer open={isShow} onClose={onCreateControlFuc(false)} title={chatObjContent.titleChanel}>
        <SelectingListContact
          data={selectList}
          onComplete={onCreateChannel}
          minimumLimit={SystemConstant.MINIMUM_SELECT_CONTACT}
          maximumLimit={isConferenceFlow && serverOptions.meetMaxCallPerson}
        />
        {/*creating a channel need at lease 3 member including you */}
      </AppDrawer>
    </>
  );
};

AddingChannelMember.propTypes = {
  onCreateChannel: PropTypes.func.isRequired,
};

AddingChannelMember.defaultProps = {};

export default AddingChannelMember;
