import { StorageUtil } from "utils";
import { getInteractor } from "./local.service";
import { KeyConstant } from "const";
import store, { GroupInfoActions } from "redux-store";
import { checkCurrentBranchByPrefix } from "sagas/saga.helper";

// accountId is id of chatting account, not current account
export const updateGroupName = async (prefixKey, accountId, contactName) => {
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const updatingGroup = await getInteractor(prefixKey).LocalGroupService.findOnePersonalGroup(accountId, branchId);
  if (updatingGroup && updatingGroup.group_name !== contactName) {
    // Update related group
    await getInteractor(prefixKey).LocalGroupService.update(
      {
        group_name: contactName,
        name: JSON.stringify({ name: contactName }),
      },
      { id: updatingGroup.id },
    );

    const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
    if (isCurrentBranch) {
      store.dispatch(
        GroupInfoActions.groupInfoSet({
          updatingGroupData: { id: updatingGroup.id, groupName: contactName },
        }),
      );
    }
  }
};
