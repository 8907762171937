import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Box, Button, List, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatStringWithKeyword, highlightString, StorageUtil } from "utils";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { SearchBar } from "components";
import SearchPopup from "./SearchPopup";
import FlowMessageActive from "components/icons/FlowMessageActive";
import ThreadListDrawer from "./ThreadListDrawer";
import { FlowMessage } from "components/icons";
import { useAccountList, useContactList, useUnreadThread } from "hooks";
import { getInteractor } from "services/local.service";
import { getSearchResult } from "utils/view.utils";
import { BranchSelectors } from "redux-store";
import ChatAllTab from "./ChatAllTab";
import { replaceId2Name } from "utils/message.utils";
import orderBy from "lodash/orderBy";
import { getNSLang } from "utils/lang.utils";

const ChatTab = () => {
  const classes = useStyles();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);
  return (
    <Box className={classes.chatTabRoot}>
      <SearchConversation isBranchServer={isBranchServer} />
      <Box className={classes.viewFullChat}>
        <ThreadList />

        <List>
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.unread} />
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.favorite} />

          {isBranchServer && <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.channel} />}
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.group} />
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.personal} />
        </List>
      </Box>
    </Box>
  );
};

export default ChatTab;
const SEARCH_INPUT_ANCHOR_ID = "search-input-anchor-id";

const SearchConversation = ({ isBranchServer }) => {
  const searchRef = useRef();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const classes = useStyles();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const { accountList } = useAccountList();
  const { contactList } = useContactList();

  const [searchInputEl, setSearchInputEl] = useState(null);
  const [searchingResult, setSearchingResult] = useState({
    contacts: [],
    messages: [],
    groups: [],
    channels: [],
  });

  const handleClosePopup = () => setSearchInputEl(null);

  const handleShowPopup = () => {
    const inputEl = document.getElementById(SEARCH_INPUT_ANCHOR_ID);
    setSearchInputEl(inputEl);
  };

  const handleChangeInput = value => {
    searchRef.current = value;
    Promise.all([
      handleSearchMessage(value),
      getSearchResult(value, isBranchServer ? accountList : contactList, ["name", "contactName", "phone"]),
      getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
        keyword: value,
        group_type: SystemConstant.GROUP_CHAT_TYPE.group,
        account_id: accountId,
        branch_id: branchId,
      }),
      getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
        keyword: value,
        group_type: SystemConstant.GROUP_CHAT_TYPE.channel,
        account_id: accountId,
        branch_id: branchId,
      }),
    ]).then(([messages, contacts, groups, channels]) => {
      setSearchingResult({
        ...searchingResult,
        messages: messages.slice(0, 5),
        contacts: contacts.slice(0, 5),
        groups: groups.slice(0, 5),
        channels: channels.slice(0, 5),
      });
    });
  };

  const handleSearchMessage = async searchValue => {
    if (searchValue) {
      const messageSearchResult = await getInteractor(prefixKey).LocalMessageService.searchMessage({
        keyword: searchValue,
        limit: 5,
      });
      const handleData = await Promise.all(
        messageSearchResult.map(async message => {
          if (Object.values(SystemConstant.MEDIA_SEND_TYPE).includes(message.sendType)) {
            message.content = JSON.parse(message.content).content_message;
          }
          const group = await getInteractor(prefixKey).LocalGroupService.get(message.groupId);
          if (group && group.id) {
            message.content = await replaceId2Name(message.content, group.groupMembers);
          }
          message.content = formatStringWithKeyword(searchValue, message.content);
          message.content = highlightString(searchValue, message.content);

          return message;
        }),
      );

      return orderBy(handleData, ["created"], ["desc"]);
    }

    return [];
  };

  return (
    <>
      <SearchBar
        placeholder={getLabel(LangConstant.TXT_CHAT_SEARCH_PLACE_HOLDER)}
        onFocus={handleShowPopup}
        onChange={handleChangeInput}
        classes={{
          root: clsx(classes.searchBarRoot, {
            [classes.searchBarRootActive]: Boolean(searchInputEl),
          }),
        }}
        componentsProps={{
          root: { id: SEARCH_INPUT_ANCHOR_ID },
        }}
      />

      {Boolean(searchInputEl) && (
        <SearchPopup
          anchorEL={searchInputEl}
          onClose={handleClosePopup}
          keyword={searchRef.current}
          onKeywordClick={handleChangeInput}
          data={searchingResult}
        />
      )}
    </>
  );
};

const ThreadList = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const classes = useStyles();
  const { unreadThreadNum } = useUnreadThread();

  const [isShowThreadList, setIsThreadList] = useState(false);

  const handleShowThreadFlow = () => setIsThreadList(true);

  return (
    <>
      <Tooltip title={getLabel(LangConstant.TXT_VIEW_MESSAGE_THREAD)}>
        <Button
          className={classes.flowMessage}
          classes={{ root: unreadThreadNum === 0 && classes.buttonRoot }}
          variant="text"
          startIcon={
            unreadThreadNum > 0 ? (
              <FlowMessageActive className={classes.flowMessageBtn} />
            ) : (
              <FlowMessage className={classes.flowMessageBtn} />
            )
          }
          onClick={handleShowThreadFlow}
        >
          {unreadThreadNum > 0
            ? getLabel(LangConstant.FM_NUMBER_FLOW_MESSAGE, { number: unreadThreadNum })
            : getNSLang(LangConstant.NS_HOME, "TXT_FLOW_MESSAGE")}
        </Button>
      </Tooltip>

      {isShowThreadList && <ThreadListDrawer isOpen={isShowThreadList} onClose={() => setIsThreadList(false)} />}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  chatTabRoot: {
    height: "100%",
    paddingTop: 12,
  },

  chatList: {
    height: "100%",
  },

  searchBarRoot: {
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  searchBarRootActive: {
    zIndex: theme.zIndex.snackbar,
  },

  viewFullChat: {
    height: "95%",
    overflowY: "auto",
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  flowMessage: {
    marginTop: 10,
    textTransform: "none",
  },

  flowMessageBtn: {},

  buttonRoot: {
    color: "#8E8E8E",
  },
}));
