import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const VideoCall = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <circle cx="18" cy="18" r="18" fill="#F5F5F5" />
      <path
        d="M24.1111 16.25V12.1667C24.1111 11.525 23.5611 11 22.8889 11H8.22222C7.55 11 7 11.525 7 12.1667V23.8333C7 24.475 7.55 25 8.22222 25H22.8889C23.5611 25 24.1111 24.475 24.1111 23.8333V19.75L29 24.4167V11.5833L24.1111 16.25ZM20.4444 19.1667H16.7778V22.6667H14.3333V19.1667H10.6667V16.8333H14.3333V13.3333H16.7778V16.8333H20.4444V19.1667Z"
        fill="#6C7078" />
    </SvgIcon>
  );
};
VideoCall.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
VideoCall.defaultProps = {
  width: 36,
  height: 36,
};
export default memo(VideoCall);
