import { EventState, EventSubType, EventType, ExecuteEvent } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { getInteractor } from "services/local.service";
import { EventDto, IEventDto } from "pubsub/dto";
import { refactorSavingEvent } from "pubsub/PubSub.helper";
import {
  branchInviteService,
  branchRemoveService,
  mentionsNotification,
  handlePersonalNotification,
} from "pubsub/services/personal.service";

export default class PersonalEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.PERSONAL);
  }

  handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];
    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      const eventContent = eventItem.contentObj;
      const accountId = eventContent.account_id;
      const branchId = eventContent.branch_id;
      const notificationId = eventContent.notification_id;
      let executeEventValue: ExecuteEvent = ExecuteEvent.FAIL;

      if (accountId) {
        switch (eventItem.subtype) {
          case EventSubType.REMOVE:
            executeEventValue = branchRemoveService(branchId) ? ExecuteEvent.SUCCESSES : ExecuteEvent.RETRY;
            break;

          case EventSubType.MENTIONS:
            const messageSourceId = eventContent.message_source_id;
            executeEventValue = (await mentionsNotification(this.prefixKey, messageSourceId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.INVITE:
            executeEventValue = (await branchInviteService(this.prefixKey, accountId, branchId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.SMART_OTP:
          case EventSubType.OWNER_NOTIFICATION:
            executeEventValue = (await handlePersonalNotification(this.prefixKey, notificationId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.FAIL;
            break;

          default:
            executeEventValue = ExecuteEvent.SUCCESSES;
            break;
        }
      }

      // Update event before saving in local db
      const refactorEvent = refactorSavingEvent(eventItem, executeEventValue, limitRetry);
      if (refactorEvent) savingEvents.push(refactorEvent);
    }

    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}
