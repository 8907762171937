import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { ConversationActions, ConversationSelectors, GroupInfoActions, SystemSelectors } from "redux-store";
import useInputEvent from "./useInputEvent";
import useLazyEffect from "./useLazyEffect";
import { createSelector } from "reselect";
import { getInteractor } from "services/local.service";
import { replaceId2Name } from "utils/message.utils";
import { toCamel } from "utils";

const memoizedReduxState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    ConversationSelectors.getThreadingId,
    state => state.conversationRedux.scrollToChildId,
  ],
  (selectedGroupId, selectedThreadId) => {
    return {
      selectedThreadId,
      selectedGroupId,
      categoryId: selectedThreadId || selectedGroupId,
    };
  },
);

const useDraftMessage = isAllowAttach => {
  const dispatch = useDispatch();
  const isFocused = useSelector(SystemSelectors.isFocused);
  const draftMessages = useSelector(ConversationSelectors.getDraftMessages);
  const { categoryId, selectedThreadId, selectedGroupId } = useSelector(memoizedReduxState);
  const { inputRef, inputValue, insertContentInput, updateContentInput } = useInputEvent();
  const reduxRef = useRef({ selectedThreadId, selectedGroupId });

  const [isInit, setIsInit] = useState(false);
  const [isShowUploadIcon, setIsShowUploadIcon] = useState(isAllowAttach);

  const isHandlingDraft = isAllowAttach && isInit;

  const updateMessageContent = useCallback(
    content => {
      updateContentInput(content);
      setIsShowUploadIcon(Boolean(isAllowAttach && false === Boolean(content?.trim())));
    },
    [isAllowAttach],
  );

  const resetDraftMessage = useCallback(() => {
    if (isAllowAttach) {
      saveDraftMessage(reduxRef.current.groupId, reduxRef.current.threadId, "");
    }
  }, [isAllowAttach]);

  const initDraftMessage = async () => {
    const isValid = selectedGroupId || selectedThreadId;
    if (!isValid) return;

    setIsInit(false);
    let draftMsgContent = draftMessages[categoryId];

    if (!draftMsgContent) {
      const group = await getInteractor().LocalGroupService.get(selectedGroupId);
      const thread = toCamel(await getInteractor().LocalThreadService.getByThreadId(selectedThreadId));

      const draftMessageDB = selectedThreadId ? thread.draftMessage : group.draftMessage;
      draftMsgContent = draftMessageDB ? await replaceId2Name(draftMessageDB, group.groupMembers, false) : null;
    }

    updateMessageContent(draftMsgContent || "");
    setIsInit(true);
  };

  // Save draft after typing about <delay_time>
  useLazyEffect(
    () => {
      if (!isHandlingDraft) return;
      saveDraftMessage(selectedGroupId, selectedThreadId, inputValue);
    },
    [isHandlingDraft, inputValue],
    DELAY_TYPING_TO_SAVE_DB,
  );

  // Save draft after unfocused app
  useLazyEffect(() => {
    if (isHandlingDraft && !isFocused) {
      saveDraftMessage(selectedGroupId, selectedThreadId, inputValue);
    }
  }, [isHandlingDraft, isFocused]);

  // Save draft in redux when user typing
  useLazyEffect(() => {
    if (isHandlingDraft) {
      // Save draft message in redux when user typing
      draftMessages[categoryId] = inputValue;
      dispatch(
        ConversationActions.conversationSet({
          draftMessages: draftMessages,
        }),
      );
    }
  }, [isHandlingDraft, inputValue]);

  // Init data
  useLazyEffect(() => {
    if (isAllowAttach && categoryId && !isInit) {
      initDraftMessage();
    }
  }, [categoryId, draftMessages, isInit]);

  // Effect to handle when changing group/ thread
  useEffect(() => {
    if (isAllowAttach && isInit) {
      // Save draft message
      saveDraftMessage(reduxRef.current.groupId, reduxRef.current.threadId, inputValue);
    }

    // Reset data
    reduxRef.current = { groupId: selectedGroupId, threadId: selectedThreadId };
    setIsInit(false);
  }, [categoryId]);

  return {
    inputRef,
    isShowUploadIcon,
    insertContentInput,
    updateMessageContent,
    resetDraftMessage,
  };
};

export default useDraftMessage;

const DELAY_TYPING_TO_SAVE_DB = 10000; // 10 seconds

const saveDraftMessage = async (selectedGroupId, selectedThreadId, inputValue) => {
  const isValid = selectedGroupId || selectedThreadId;
  if (!isValid) return;

  selectedThreadId
    ? await getInteractor().LocalThreadService.update({ draft_message: inputValue }, { id: selectedThreadId })
    : await getInteractor().LocalGroupService.update({ draft_message: inputValue }, { id: selectedGroupId });

  if (selectedGroupId && !selectedThreadId) {
    store.dispatch(
      GroupInfoActions.groupInfoSet({
        updatingGroupData: {
          id: selectedGroupId,
          draftMessage: inputValue,
        },
      }),
    );
  }
};
