import React, { useState, useCallback, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Divider, Typography, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageConstant, LangConstant, KeyConstant, AppConstant } from "const";
import { AppDrawer, LazyLoader } from "components";
import DeviceDetailDrawer from "./DeviceDetailDrawer";
import useDeviceList from "hooks/useDeviceList";
import DeviceItem from "./DeviceItem";
import { StorageUtil } from "utils";
import AccountItem from "../AccountItem";

const YourDevice = () => {
  const classes = useStyles();
  const { masterDeviceList, slaveDeviceList, getDeviceList, fetchDeviceList } = useDeviceList();
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const deviceObjContent = getLabel(LangConstant.OBJ_ACCOUNT_DEVICE, { returnObjects: true });
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });

  const loggedOutDevice = useSelector(state => state.deviceRedux.loggedOutDevice);

  const [isShow, setIsShow] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const onShow = useCallback(open => () => setIsShow(open), []);

  useEffect(() => {
    if (loggedOutDevice && loggedOutDevice.length > 0) {
      setSelectedDevice(null);
    }
  }, [loggedOutDevice]);

  useEffect(() => {
    if (!isShow) {
      getDeviceList();
      fetchDeviceList();
    }
  }, [isShow]);

  const appVersion = getLabel(
    Boolean(window.env?.APP_VERSION) ? deviceObjContent.buildVersion : deviceObjContent.version,
    {
      version: window.env?.npm_package_version,
      buildVersion: window.env?.APP_VERSION,
    },
  );

  return (
    <>
      <AccountItem text={accountObjContent.device} onClick={onShow(true)} />
      <AppDrawer open={isShow} onClose={onShow(false)} title={deviceObjContent.title}>
        <CardMedia
          component="img"
          alt="your device"
          src={ImageConstant.YourDeviceImage}
          className={classes.yourDeviceImg}
        />
        <Typography className={classes.versionText} id="appVersion">
          {appVersion}
        </Typography>

        <Typography variant="h5" className={classes.description}>
          {deviceObjContent.description}
        </Typography>

        <Divider />

        <Typography variant="body2" className={classes.deviceMode}>
          {deviceObjContent.masterDevice}
        </Typography>

        {masterDeviceList.map((device, index) => (
          <DeviceItem
            key={index}
            data={device}
            hasDivider={Boolean(index + 1 < masterDeviceList.length)}
            onClick={setSelectedDevice}
          />
        ))}

        <Typography variant="body2" className={classes.deviceMode}>
          {deviceObjContent.otherDevice}
        </Typography>

        {slaveDeviceList.map((device, index) => (
          <LazyLoader key={index}>
            <DeviceItem
              data={device}
              hasDivider={Boolean(index + 1 < slaveDeviceList.length)}
              onClick={setSelectedDevice}
            />
          </LazyLoader>
        ))}

        {selectedDevice && (
          <DeviceDetailDrawer
            selectedDevice={selectedDevice}
            onClose={() => setSelectedDevice(null)}
            isSlaveDevice={Boolean(slaveDeviceList.find(item => item.id === selectedDevice.id))}
            isAllowLogout={
              selectedDevice.id !== deviceId && Boolean(masterDeviceList.find(item => item.id === deviceId))
            }
          />
        )}
      </AppDrawer>
    </>
  );
};

YourDevice.propTypes = {};

export default memo(YourDevice);

const useStyles = makeStyles(theme => ({
  yourDeviceImg: {
    height: 140,
    objectFit: "contain",
    marginTop: 30,
  },

  description: {
    fontWeight: 700,
    textAlign: "center",
    padding: 20,
  },

  deviceMode: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2),
  },

  center: {
    textAlign: "center",
  },

  drawer: {
    "&, & $paper": {
      width: AppConstant.SIDEBAR_WIDTH.medium,
      flexShrink: 0,
      boxShadow: "unset",

      [theme.breakpoints.up(1500)]: {
        width: AppConstant.SIDEBAR_WIDTH.large,
      },
    },
  },

  versionText: {
    color: "#2CC84A",
    textAlign: "center",
    marginTop: 10,
  },
}));
