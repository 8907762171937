import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

const SettingTitle = ({ children, ...otherProps }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        color: theme.palette.grey[300],
        fontSize: 14,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};
export default SettingTitle;
