import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Stack } from "@mui/system";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Modal } from "@mui/material";
import { convertString2JSON, toCamel } from "utils";
import AvatarInfo from "../AvatarInfo";
import AppCarousel from "../AppCarousel";
import { formatSentTime } from "utils/date.utils";
import withForwardMessage from "../hoc/withForwardMessage";
import {
  CarouseAction,
  CarouselMediaItem,
  THUMBNAIL_CLASS_TRIGGER,
  THUMBNAIL_LIST_CLASS_TRIGGER,
  ThumbnailItem,
} from "./CarouselMedia";
import { useDownloadFile, useDragScroll, useScrollEvent } from "hooks";

const CarouselMedia = ({
  data,
  open,
  onClose,
  initialIndex,
  onForward,
  onScrollRightEnd,
  onScrollLeftEnd,
  ...otherProps
}) => {
  const classes = useStyles();
  const [thumbnailRef, thumbnailNode] = useDragScroll();
  useScrollEvent({
    ref: thumbnailNode,
    onScrollRightEnd,
    onScrollLeftEnd,
  });
  const { downloadFile, openFolder } = useDownloadFile();

  const [slideIndex, setSlideIndex] = useState(0);

  const onScrollAfterChanging = (_, { currentSlide }) => {
    setSlideIndex(currentSlide);
    scrollToThumbnail();
  };

  const handleDownload = fileInfo =>
    fileInfo?.metaData ? () => downloadFile([fileInfo.metaData], fileInfo.groupId) : null;

  const handleForwardFunc = fileInfo =>
    fileInfo?.metaData
      ? () => {
          const forwardData = { attachmentId: fileInfo.fileId, sourceMessageId: fileInfo.sourceMessageId };
          onForward(forwardData);
        }
      : null;

  const handleOpenFileFolder = selectFile => async () => {
    const fileData = toCamel(convertString2JSON(selectFile.fileData));
    await openFolder(fileData);
  };

  const handleEsc = event => {
    event.preventDefault();
    // Press Esc
    if (event.keyCode === 27) onClose();
  };

  useEffect(() => {
    setSlideIndex(initialIndex >= 0 && initialIndex < data.length ? initialIndex : 0);
  }, [open, initialIndex]);

  const isShowCarousel = data && data.length > 0 && open;
  if (!isShowCarousel) return null;
  return (
    <Modal
      open={true}
      aria-labelledby="carouse-media-title"
      aria-describedby="carouse media"
      hideBackdrop
      onKeyDown={handleEsc}
      {...otherProps}
    >
      <Box className={classes.root}>
        <AvatarInfo
          avatar={{
            name: data[slideIndex]?.senderName,
            avatarId: data[slideIndex]?.senderAvatarId,
          }}
          title={data[slideIndex]?.senderName}
          secondTitle={formatSentTime(data[slideIndex]?.created)}
          endComponent={
            <CarouseAction
              onOpenFolder={handleOpenFileFolder(data[slideIndex])}
              onForward={handleForwardFunc(data[slideIndex])}
              onDownload={handleDownload(data[slideIndex])}
              onClose={onClose}
            />
          }
          titleProps={{ className: classes.avatarTitle }}
        />
        <Box className={classes.main}>
          <AppCarousel
            swipeable={false}
            draggable={false}
            showDots={false}
            nextSlide={slideIndex}
            afterChange={onScrollAfterChanging}
          >
            {data.map((file, index) => (
              <Box key={index} className={classes.item}>
                <CarouselMediaItem file={file.metaData} />
              </Box>
            ))}
          </AppCarousel>
        </Box>
        <Stack
          ref={thumbnailRef}
          direction="row"
          flexWrap="nowrap"
          className={clsx("hidden-scrollbar", classes.thumbnailRoot, THUMBNAIL_LIST_CLASS_TRIGGER)}
        >
          {data.map((item, index) => (
            <ThumbnailItem
              key={index}
              data={item.metaData}
              active={index === slideIndex}
              onClick={() => setSlideIndex(index)}
            />
          ))}
        </Stack>
      </Box>
    </Modal>
  );
};

CarouselMedia.propTypes = {
  data: PropTypes.array,

  onClose: PropTypes.func,
  initialIndex: PropTypes.number,
  open: PropTypes.bool,
};
CarouselMedia.defaultProps = {
  data: [],
  onClose: () => {},
  initialIndex: 0,
  open: false,
};

export default withForwardMessage(CarouselMedia);

const scrollToThumbnail = () => {
  const thumbnailListEl = document.getElementsByClassName(THUMBNAIL_LIST_CLASS_TRIGGER)[0];
  const thumbnailActiveEl = document.getElementsByClassName(THUMBNAIL_CLASS_TRIGGER)[0];
  if (!thumbnailListEl || !thumbnailActiveEl) return; // Return if variable is null

  const halfWidthEl = thumbnailListEl.clientWidth / 2;
  if (thumbnailActiveEl.offsetLeft + 200 < thumbnailListEl.clientWidth) {
    thumbnailListEl.scrollLeft = 0;
  } else if (thumbnailListEl.scrollWidth - thumbnailActiveEl.offsetLeft < 200) {
    thumbnailListEl.scrollLeft = thumbnailListEl.scrollWidth;
  } else if (thumbnailActiveEl.offsetLeft < halfWidthEl) {
    thumbnailListEl.scrollLeft = thumbnailActiveEl.offsetLeft + halfWidthEl;
  } else thumbnailListEl.scrollLeft = thumbnailActiveEl.offsetLeft - halfWidthEl;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16,

    "& *": { color: "white" },
    "&:focus-visible": {
      outline: "none",
    },
  },

  avatarTitle: {
    fontSize: 15,

    "& + *": {
      fontWeight: 400,
      fontSize: 15,
    },
  },

  main: {
    flexGrow: 1,
    minHeight: 400,
    paddingLeft: 16,
    paddingRight: 16,
  },

  item: {
    width: "100%",
    height: "70vh",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",

    "& img, & video": {
      width: "95%",
      maxWidth: "95%",
      borderRadius: 10,
    },
  },

  thumbnailRoot: {
    width: 800,
    display: "inline-block",
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    transition: "all 0.3s",
    willChange: "transform",
    cursor: "pointer",
    bottom: 16,
    textAlign: "center",
    margin: "auto",

    [theme.breakpoints.up(1500)]: {
      bottom: 30,
    },
  },

  thumbnailItem: {
    display: "inline-block",
    width: 95,
    height: 95,
    margin: 6,
    borderRadius: 10,
    overflow: "hidden",

    [theme.breakpoints.up(1500)]: {
      width: 108,
      height: 108,
    },

    "& img, & video": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
  },

  thumbnailItemActive: {
    border: "3px solid #008FE8",
    boxSizing: "border-box",
  },

  thumbnailVideoPlay: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    width: 50,
    height: 50,
    background: "rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(10px)",
  },
}));
