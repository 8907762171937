import { FormatConstant, KeyConstant, LangConstant } from "const";
import { getInteractor } from "services/local.service";
import { convertString2JSON, FileUtil, StorageUtil, toCamel } from "utils";
import { convertTimeStampToDate } from "utils/date.utils";
import { getNSLang } from "utils/lang.utils";
import { getMetaDataFile } from "utils/message.utils";

// fileList: record in file-group.table
export const refactorFiles = async (prefixKey, fileList) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const jsonAccounts = {};
  const displayFileList = [];

  // Refactor fileRecord to data that is displayed
  for (let index = 0; index < fileList.length; index++) {
    const recordItem = fileList[index];
    const fileData = convertString2JSON(recordItem.fileData, {}, true);
    const fileMetaData = toCamel(fileData.meta_data);

    if (recordItem.createdByAccountId && !jsonAccounts[recordItem.createdByAccountId]) {
      const createdByAccount = await getInteractor(prefixKey).LocalAccountService.get(
        recordItem.createdByAccountId,
        branchId,
      );

      jsonAccounts[recordItem.createdByAccountId] = toCamel(createdByAccount);
    }

    const createdByAccount = jsonAccounts[recordItem.createdByAccountId] || {};
    const senderName = createdByAccount.contactName || createdByAccount.name || createdByAccount.phone || "";

    displayFileList.push({
      ...recordItem,
      attachmentId: recordItem.fileId,
      metaData: {
        ...getMetaDataFile(recordItem.sourceMessageId, toCamel(fileData)),
        ...fileMetaData,
      },
      senderName: senderName,
      senderAvatarId: createdByAccount.avatarId,
      shareBy: getNSLang(
        LangConstant.NS_MANAGE_FILE,
        accountId === recordItem.createdByAccountId ? "FM_SHARE_BY_ME" : "FM_SHARE_BY_OTHER",
        {
          fileSize: FileUtil.convertFileSizeUnit(fileMetaData.size),
          name: senderName,
        },
      ),
      createdDate: convertTimeStampToDate(recordItem.created / 1000, FormatConstant.FM_DD_MM_YYYY),
    });
  }

  return displayFileList;
};

export const getDisplayData = async (prefixKey, fileList, groupByDate = {}) => {
  const displayFileList = await refactorFiles(prefixKey, fileList);

  // Group file list by date
  displayFileList.forEach(fileItem => {
    const createdDate = fileItem.createdDate;
    if (false === Array.isArray(groupByDate[createdDate])) {
      groupByDate[createdDate] = [fileItem];
    } else {
      groupByDate[createdDate].push(fileItem);
    }
  });

  return groupByDate;
};
