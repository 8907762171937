import palette from "../palette";

export default {
  styleOverrides: {
    root: {
      boxShadow: "none",
      backgroundColor: palette.white,
    },
  },
};
