import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AddingGroupDrawer from "./AddingGroupDrawer";

const AddingGroupLayout = ({ children, Drawer, DrawerProps, onComplete }) => {
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const onCloseDrawer = () => setIsShowDrawer(false);
  const onShowDrawer = () => setIsShowDrawer(true);

  return (
    <>
      <Box onClick={onShowDrawer}>{children}</Box>
      {isShowDrawer && <Drawer onComplete={onComplete} open onClose={onCloseDrawer} {...DrawerProps} />}
    </>
  );
};

AddingGroupLayout.propTypes = {
  onComplete: PropTypes.func,
  DrawerProps: PropTypes.shape(AddingGroupDrawer.propTypes), // Props of AddingGroupDrawer
};

AddingGroupLayout.defaultProps = {};

export default AddingGroupLayout;
