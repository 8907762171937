import { createSelector } from "reselect";
import { useAlertContext } from "./context/AlertContext";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const memoizedReduxState = createSelector([state => state.systemRedux.toast], toast => ({
  toast,
}));

const Toast = () => {
  const { showAlert } = useAlertContext();
  const { toast } = useSelector(memoizedReduxState);

  useEffect(() => {
    if (toast && toast.content) {
      showAlert({ content: toast.content, alertProps: { severity: toast.severity } });
    }
  }, [toast]);

  return <></>;
};

export default Toast;
