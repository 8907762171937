import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MoreHoriz } from "@mui/icons-material";
import clsx from "clsx";
import { AvatarGroup } from "components";
import ContactGroupActionMenu from "../Sidebar/ViewModeTabs/ContactGroupActionMenu";

const GroupItem = ({ data, onClick }) => {
  const classes = useStyles();
  const chatItemRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const onShowContactItemMenu = event => {
    event.preventDefault();
    event.stopPropagation();

    setMenuAnchorEl(chatItemRef.current);
  };

  const onCloseActionMenu = () => {
    setMenuAnchorEl(null);
    setIsHovered(false);
  };

  return (
    <Box
      className={clsx(classes.conversationRoot, isHovered && classes.hoveredBox, "no-select-text")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={chatItemRef}
    >
      <Box className={classes.contactItemRoot} onClick={onClick}>
        <Box className={clsx(classes.chatItemAvatar, classes.rootSpacing)}>
          <AvatarGroup
            group={{
              groupType: data.groupType,
              groupName: data.groupName,
              privateF: data.privateF,
            }}
            avatarId={data.avatarId}
          />
        </Box>
        <Box className={clsx(classes.groupContent, classes.rootSpacing)}>
          <Typography className={clsx(classes.contactName, "ellipsis", "semiBold-md-txt")}>{data.groupName}</Typography>

          <IconButton className={classes.timeAndAction} onClick={onShowContactItemMenu}>
            <MoreHoriz />
          </IconButton>
        </Box>
      </Box>
      <ContactGroupActionMenu anchorEl={menuAnchorEl} onClose={onCloseActionMenu} data={data} />
    </Box>
  );
};
GroupItem.propTypes = {
  data: PropTypes.object,

  onClick: PropTypes.func,
};

GroupItem.defaultProps = {
  data: {},

  onClick: () => {},
};

export default GroupItem;

const useStyles = makeStyles(theme => ({
  rootSpacing: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  contactItemRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  contactName: {
    lineHeight: "18px",
    height: "fit-content",
    flex: 1,
  },

  chatItemAvatar: {
    width: 50,
    height: 70,
  },

  groupContent: {
    display: "flex",
    marginLeft: 16,
    flex: 1,
    borderBottom: "0.5px solid " + theme.palette.divider,
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  timeAndAction: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },

  conversationRoot: {
    display: "flex",
    cursor: "pointer",
    width: "100%",
  },

  hoveredBox: {
    backgroundColor: "#F1F3F6",
  },
}));
