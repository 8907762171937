import { memo, useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { InsertEmoticon } from "@mui/icons-material";
import { FullEmojiMenu } from "components";
import { convertHexToEmoji } from "utils";

const FullEmojiLayout = ({ onSelect }) => {
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);

  const handleShowEmoji = event => {
    setEmojiAnchorEl(event.currentTarget);
  };

  const handleSelectEmoji = value => {
    setEmojiAnchorEl(null);
    onSelect(convertHexToEmoji(value));
  };

  const handleCloseEmoji = () => {
    setEmojiAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleShowEmoji}>
        <InsertEmoticon color="action" />
      </IconButton>

      {Boolean(emojiAnchorEl) && (
        <FullEmojiMenu
          open={true}
          onClose={handleCloseEmoji}
          anchorEl={emojiAnchorEl}
          onSelectEmoji={handleSelectEmoji}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        />
      )}
    </>
  );
};
FullEmojiLayout.propTypes = {
  onSelect: PropTypes.func,
};

FullEmojiLayout.defaultProps = {
  onSelect: () => {},
};

export default memo(FullEmojiLayout);
