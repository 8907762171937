import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isString } from "utils";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const DialogConfirmLeave = ({
  title,
  content,
  submitProps: { submitText, ...otherSubmitProps },
  cancelProps: { cancelText, ...otherCancelProps },
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t: getLabel } = useTranslation();
  const primaryContent = isString(content) ? (
    <Typography variant="body2" className={classes.contentText}>
      {content}
    </Typography>
  ) : (
    content
  );
  return (
    <Dialog fullScreen={isFullScreen} classes={{ paper: classes.root }} {...otherProps}>
      <DialogTitle className={classes.title}>{title || ""}</DialogTitle>
      <DialogContent className={classes.contentRoot}>{primaryContent}</DialogContent>
      <Box className={classes.action}>
        <Button className={classes.submitBox} variant="contained" size="large" fullWidth {...otherSubmitProps}>
          {submitText || getLabel(LangConstant.TXT_OK)}
        </Button>
        <Button fullWidth size="large" {...otherCancelProps} className={classes.cancelBox}>
          {cancelText || getLabel(LangConstant.TXT_CANCEL)}
        </Button>
      </Box>
    </Dialog>
  );
};
DialogConfirmLeave.propTypes = {
  open: PropTypes.bool,
  submitProps: PropTypes.object,
  cancelProps: PropTypes.object,
};
DialogConfirmLeave.defaultProps = {
  submitProps: {},
  cancelProps: {},
};
export default DialogConfirmLeave;
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    padding: theme.spacing(1.5, 3),
    textAlign: "center",
    width: 540,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  contentRoot: {
    padding: 0,
    whiteSpace: "pre-line",
  },
  contentText: {
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  action: {
    marginTop: 12,
    marginBottom: 0,
    "& > *": {
      marginTop: 4,
    },
  },
  submitBox: {
    background: theme.palette.secondary.main,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    "&:hover": {
      background: theme.palette.secondary.dark,
      boxShadow: "none"
    }
  },
  cancelBox: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.grey[200],
    "&:hover": {
      color: theme.palette.grey[500],
      background: theme.palette.white,
    }
  }
}));
