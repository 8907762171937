export default {
  TXT_LOGIN: "Đăng nhập",
  TXT_LOGOUT: "Logout",
  TXT_RESTORE_DATA: "Restore Data",
  TXT_RESEND_OTP: "Gửi lại OTP",
  TXT_RESEND_SMS_OTP: "Gửi lại SMS OTP",
  TXT_CHANGE_PHONE_NUMBER: "Thay đổi số điện thoại",
  TXT_NAME_TITLE: "Nhập tên để bắt đầu",
  TXT_CHANGE_SMS_OTP: "Thay đổi  SMS OTP",
  TXT_CHANGE_SMART_OTP: "Thay đổi SMART OTP",
  TXT_SENT_OTP_TITLE: "Nhập mã OTP",
  TXT_SENT_SMART_OTP_TITLE: "Nhập mã SMART OTP",
  TXT_SENT_SMS_OTP_TITLE: "Nhập mã SMS OTP",
  TXT_REMEMBER_ME: "Ghi nhớ tôi",
  TXT_OTP_ERROR_MESSAGE: "Mã OTP không đúng. Bạn còn {{ tryTimes }} lần thử",
  TXT_OTP_LOCKED: "Tài khoản tạm thời bị khóa",
  TXT_OTP_LOCKED_FULL_MESSAGE:
    "Chúng tôi đã khóa tài khoản vì bạn đã nhập sai mã OTP 3 lần.\nHãy thử lại sau 5 phút nữa",
  TXT_UNREGISTERED_MESSAGE:
    "Không tìm thấy số điện thoại trên hệ thống. Vui lòng đăng ký trên ứng dụng Trios Mobile trước",
  TXT_TRY_AGAIN_LATTER: "Vui lòng thử lại sau ít phút",
  TXT_SENT_OTP_MESSAGE: "Nhập Smart OTP được gửi tới thiết bị di động của bạn theo tài khoản: {{ phone }}",
  TXT_SENT_SMS_OTP_MESSAGE: "Nhập SMS OTP được gửi tới: {{ phone }}",
  TXT_RESTORE_DATA_WARNING: "Bạn có muốn khôi phục dữ liệu từ thiết bị chủ ?",
  TXT_RESTORE_DATA_INPROGRESS: "Hãy đảm bảo thiết bị chủ của bạn kết nối với Internet",
  TXT_RESTORE_DATA_ERROR: "Không thể kết nối tới thiết bị chủ",
  TXT_OTP_EXPIRED: "Mã OTP đã hết hạn",
  TXT_LIMIT_DEVICE: "Số lượng thiết bị đăng nhập vượt quá 10. Vui lòng đăng xuất trên thiết bị cũ",
  TXT_LOGIN_WELCOME_TITLE: "Chào mừng bạn",
  TXT_LOGIN_WELCOME_SUBTITLE: "tham gia",
  TXT_INACTIVE_USER: "Bạn đã bị vô hiệu hóa.\nVui lòng liên hệ quản trị để tiếp tục sử dụng",

  FM_COUNTDOWN_OTP: "(00:{{ time }})",
  FM_PHONE_CODE: "+{{ code }}",
};
