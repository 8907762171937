import { useEffect, useState } from "react";
import { getFileLocalPath } from "services/attachment.service";
import { StorageUtil } from "utils";
import useCleanUpEffect from "./useCleanUpEffect";

// Get url of public file
const useFileUrl = attachmentId => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { isMounted } = useCleanUpEffect();

  const [fileUrl, setFileUrl] = useState("");

  const handleSetFileUrl = async () => {
    setFileUrl("");

    const fileName = `${attachmentId}.png`;
    const fileInfo = { attachmentId: attachmentId, metaData: { fileName } };

    if (false === Boolean(attachmentId)) return;

    const fileUrl = await getFileLocalPath(prefixKey, fileInfo);

    if (isMounted()) setFileUrl(fileUrl);
  };

  useEffect(() => {
    if (attachmentId) {
      handleSetFileUrl();
    }
  }, [attachmentId]);

  return { fileUrl, handleSetFileUrl };
};

export default useFileUrl;
