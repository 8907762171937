import { KeyConstant } from "const";
import { getFileList } from "pubsub/services/file.service";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { AttachmentUtil, StorageUtil } from "utils";

const MigrateVersion51 = () => {
  const selectedBranchReduxId = useSelector(BranchSelectors.getSelectedBranchId);
  const currentPrefixKey = StorageUtil.getCurrentPrefixKey();

  const handlingMigrateFile = async prefixKey => {
    try {
      const attachmentIdList = AttachmentUtil.getLocalAttachmentIds();
      if (Array.isArray(attachmentIdList) && attachmentIdList.length > 0) {
        let reqAttachment = [];
        let offset = 0;
        let lastIndex = 100;
        do {
          reqAttachment = attachmentIdList.slice(offset, lastIndex);
          const remoteData = await getFileList(prefixKey, reqAttachment);
          if (false === Boolean(remoteData)) {
            throw Error("get remote file info error: ", reqAttachment);
          }
          offset = lastIndex;
          lastIndex += 100;
        } while (reqAttachment === 100);
      }

      StorageUtil.setItem(KeyConstant.KEY_MIGRATE_VERSION_51, 1, prefixKey);
    } catch (error) {
      console.log("migrate version 51 error: ", error);
    }
  };

  useEffect(() => {
    const migrateTimeout = setTimeout(() => {
      const isMigrateVersion51 = StorageUtil.getItem(KeyConstant.KEY_MIGRATE_VERSION_51, currentPrefixKey);
      if (false === Boolean(isMigrateVersion51)) {
        handlingMigrateFile(currentPrefixKey);
      }
    }, 100);

    return () => clearTimeout(migrateTimeout);
  }, [selectedBranchReduxId, currentPrefixKey]);

  return null;
};

export default MigrateVersion51;
