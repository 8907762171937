import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Phone, VideoCall, VideocamOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CallingActions, ConversationActions, BranchSelectors } from "redux-store";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import { toCamel, uuid } from "utils";
import { getNSLang } from "utils/lang.utils";
import InitGroupCallPopup from "../InitGroupCallPopup";
import { isGroupOrChannelType } from "pages/Call";
import { useBlockedAccountStatus } from "hooks";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { getCallHistory } from "sagas/calling.saga";
import { getSavedServer } from "utils/branch.utils";

const TitleChatAction = ({ groupDetail }) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isBlockedAccount, isBlockedByAccount } = useBlockedAccountStatus();

  const isGroupOrChannel = isGroupOrChannelType(groupDetail.groupType);
  const isFetchHistoryTimestamp = useSelector(state => state.callingRedux.isFetchHistoryTimestamp);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const demandingVoiceCall = useSelector(state => state.conversationRedux.demandingVoiceCall);
  const demandingVideoCall = useSelector(state => state.conversationRedux.demandingVideoCall);
  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);

  const [callingDetail, setCallingDetail] = useState(null);
  const [openInitGroupCall, setOpenInitGroupCall] = useState(false);

  // Start conference call
  const onStartConference = () => {
    setOpenInitGroupCall(true);
  };

  // Start audio call
  // Call api to check receiver is in another call or not
  const onOpenAudioCallWindow = async () => {
    await getCallHistory();
    await onSendEndLastCall();
    dispatch(
      CallingActions.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: toCamel({ ...groupDetail, room_id: uuid() }),
        isReceiver: false,
      }),
    );
    dispatch(
      CallingActions.onCallCheck({ accountId: groupDetail.groupMembers[0].id }, StorageUtil.getCurrentPrefixKey()),
    );
  };

  // start video call
  // Call api to check receiver is in another call or not
  const onOpenVideoCallWindow = async () => {
    await getCallHistory();
    await onSendEndLastCall();
    dispatch(
      CallingActions.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: toCamel({ ...groupDetail, room_id: uuid() }),
        isVideoCall: true,
        isReceiver: false,
      }),
    );

    dispatch(
      CallingActions.onCallCheck({ accountId: groupDetail.groupMembers[0].id }, StorageUtil.getCurrentPrefixKey()),
    );
  };

  // TODO: check condition to end last call
  const onJoinCurrentCall = async () => {
    await getCallHistory();
    let lastCall = toCamel(await getInteractor().LocalCallHistoryService.getLastByGroupId(groupDetail.id));
    const isAnotherCall =
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId);

    if (isAnotherCall) {
      await onSendEndLastCall();
    } else {
      if (lastCall && lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling) {
        dispatch(CallingActions.getCallHistory(StorageUtil.getCurrentPrefixKey()));
        let roomId = lastCall.roomId;
        // join current conference call
        dispatch(
          CallingActions.callingSet({
            isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
            callingGroupDetail: toCamel({ ...groupDetail, roomId: roomId }),
            isReceiver: true,
            createdMessage: {
              ...createdMessage,
              [groupDetail.id]: callingDetail,
            },
            isVideoCall: callingDetail.sendType === SystemConstant.SEND_TYPE.groupVideoCall,
          }),
        );

        dispatch(CallingActions.onCallCheck({ accountId: accountId }, StorageUtil.getCurrentPrefixKey()));
      } else {
        dispatch(
          CallingActions.callingSet({
            isCallEnded: groupDetail.id,
          }),
        );
      }
    }
  };

  const onSendEndLastCall = async () => {
    // If current user with current device is in another call, send end message to that group
    let lastCall = toCamel(await getInteractor().LocalCallHistoryService.getLastByGroupId(groupDetail.id));

    let savedServer = getSavedServer();

    const callMessage = toCamel(
      (await getInteractor().LocalMessageService.findOne({ source_id: lastCall.sourceId })) || {},
    );

    if (
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId)
    ) {
      let endContent = JSON.stringify({
        room_id: lastCall.roomId,
      });

      let groupMembers = groupDetail.groupMembers;
      let memberIdArray = groupMembers.map(member => member.id) || [];

      let deviceList = await getInteractor().LocalDeviceService.find({ account_id: memberIdArray });
      console.log("Sending end message from chat title", { callMessage });

      dispatch(
        ConversationActions.sendMessage({
          groupId: groupDetail.id,
          sendType: callMessage.sendType,
          content: endContent,
          parentId: callMessage.sourceId,
          deviceList: [...deviceList],
          branchId: savedServer?.id,
          mentionIdsArr: [],
          threadId: null,
          callStatus:
            groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal
              ? SystemConstant.MESSAGE_CALL_STATUS.end
              : SystemConstant.MESSAGE_CALL_STATUS.waiting,
          roomId: lastCall.roomId,
          isReceiver: true,
        }),
      );
    }
  };

  const onClosePopupInit = () => {
    setOpenInitGroupCall(false);
  };

  const getCallingDetails = async groupId => {
    let lastCall = toCamel(await getInteractor().LocalCallHistoryService.getLastByGroupId(groupId));
    if (lastCall && lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling && !isInAnotherCall) {
      const parentMessage = toCamel(
        await getInteractor().LocalMessageService.findOne({
          source_id: lastCall.sourceId,
        }),
      );
      if (
        parentMessage &&
        Object.keys(parentMessage).length > 0 &&
        (!Boolean(callingDetail) || parentMessage.sourceId !== callingDetail.sourceId)
      ) {
        setCallingDetail(parentMessage);
      }
    } else {
      setCallingDetail(null);
    }
  };

  useEffect(() => {
    if (groupDetail && groupDetail.id) {
      getCallingDetails(groupDetail.id);
    }
  }, [groupDetail, isFetchHistoryTimestamp, isInAnotherCall]);

  useEffect(() => {
    if (demandingVideoCall && demandingVideoCall === groupDetail.id) {
      dispatch(
        ConversationActions.conversationSet({
          demandingVideoCall: null,
        }),
      );
      onOpenVideoCallWindow();
    }
  }, [demandingVideoCall, groupDetail]);

  useEffect(() => {
    if (demandingVoiceCall && demandingVoiceCall === groupDetail.id) {
      dispatch(
        ConversationActions.conversationSet({
          demandingVoiceCall: null,
        }),
      );
      onOpenAudioCallWindow();
    }
  }, [demandingVoiceCall, groupDetail]);

  return (
    <>
      <Box className={classes.titleActionContainer}>
        {hasInternet && isGroupOrChannel ? (
          <GroupCallingButton
            groupDetail={groupDetail}
            callingDetail={callingDetail}
            isInCall={isInCall}
            onJoinCurrentCall={onJoinCurrentCall}
            onStartConference={onStartConference}
          />
        ) : hasInternet && !(isBlockedAccount || isBlockedByAccount) ? (
          <PersonalCallingButton
            disabled={isInCall || groupDetail.groupMembers?.length === 0}
            onOpenAudioCallWindow={onOpenAudioCallWindow}
            onOpenVideoCallWindow={onOpenVideoCallWindow}
            sendToMe={groupDetail.sendToMe}
          />
        ) : (
          // No internet connection or user is blocked
          <LostConnection isGroupOrChannel={isGroupOrChannel} sendToMe={groupDetail.sendToMe} />
        )}
      </Box>

      {openInitGroupCall && (
        <InitGroupCallPopup open={openInitGroupCall} onClose={onClosePopupInit} data={groupDetail} />
      )}
    </>
  );
};

export default memo(TitleChatAction);

const GroupCallingButton = ({ groupDetail, callingDetail, isInCall, onJoinCurrentCall, onStartConference }) => {
  const classes = useStyles();
  const serverOptions = useSelector(BranchSelectors.getServerOptions);

  return callingDetail ? (
    <Button
      className={clsx(classes.joinCallBtn, "semiBold-sm-txt")}
      variant="contained"
      onClick={onJoinCurrentCall}
      disabled={isInCall}
    >
      <Phone className={classes.phoneIcon} />
      {getNSLang(LangConstant.NS_CALLING, LangConstant.TXT_JOIN_CALL)}
    </Button>
  ) : (
    <IconButton
      onClick={onStartConference}
      disabled={groupDetail.groupMembers.length > serverOptions.meetMaxCallPerson || isInCall}
      className={classes.infoButton}
    >
      <VideocamOutlined color="inherit" className={classes.iconBtn} />
    </IconButton>
  );
};

const PersonalCallingButton = ({ disabled, onOpenAudioCallWindow, onOpenVideoCallWindow, sendToMe }) => {
  const classes = useStyles();

  if (sendToMe) return null;

  return (
    <>
      <IconButton onClick={onOpenAudioCallWindow} className={classes.infoButton} disabled={disabled}>
        <Phone color="inherit" />
      </IconButton>

      <IconButton onClick={onOpenVideoCallWindow} className={classes.infoButton} disabled={disabled}>
        <VideoCall color="inherit" className={classes.videoCallIcon} />
      </IconButton>
    </>
  );
};

const LostConnection = ({ isGroupOrChannel, sendToMe }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  if (sendToMe) return null;

  return (
    <Tooltip title={getLabel(LangConstant.TXT_NO_INTERNET)}>
      <Box>
        {isGroupOrChannel ? (
          <IconButton className={classes.infoButton} disabled={true}>
            <VideocamOutlined color="inherit" className={classes.iconBtn} />
          </IconButton>
        ) : (
          <>
            <IconButton className={classes.infoButton} disabled={true}>
              <Phone color="inherit" />
            </IconButton>
            <IconButton className={classes.infoButton} disabled={true}>
              <VideoCall color="inherit" className={classes.videoCallIcon} />
            </IconButton>
          </>
        )}
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  infoButton: {
    width: "fit-content",
    height: "fit-content",
  },

  videoCallIcon: {
    fontSize: 24,
  },

  joinCallBtn: {
    backgroundColor: "#2CC84A",
    textTransform: "none",
    color: "white",
    borderRadius: 26,
    padding: "6px 8px",
    height: 28,
    minHeight: "unset",
    marginLeft: 10,
    width: 95,
  },

  phoneIcon: {
    fontSize: 20,
    marginRight: 5,
  },

  titleActionContainer: {
    display: "flex",
    alignItems: "center",
  },

  iconBtn: {
    fontSize: 26,
  },
});
