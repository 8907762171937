import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const PrimaryTab = ({ tabArray, onChange, classes, selectedTabIndex, ...otherProps }) => {
  const defaultClasses = useStyles();

  const onChangeTab = (_, tabIndex) => {
    onChange(tabIndex);
  };

  return (
    <Box className={clsx(defaultClasses.root, classes.root)}>
      <Tabs
        value={selectedTabIndex}
        onChange={onChangeTab}
        className={clsx(defaultClasses.tabBox, classes.tabBox)}
        classes={{
          flexContainer: clsx(defaultClasses.flexContainer, classes.flexContainer),
          indicator: clsx(defaultClasses.indicator, classes.indicator),
        }}
        {...otherProps}
      >
        {tabArray.map((tab, index) => (
          <Tab
            key={index}
            tabIndex={index}
            value={index}
            className={clsx(
              defaultClasses.tabButton,
              classes.tabButton,
              selectedTabIndex === index && classes.tabButtonSelected,
            )}
            label={
              <Box
                className={clsx(
                  defaultClasses.tabLabelBox,
                  classes.tabLabelBox,
                  selectedTabIndex === index && classes.tabLabelBoxSelected,
                )}
              >
                {tab.icon}
                <Typography
                  className={clsx(
                    defaultClasses.tabLabel,
                    classes.tabLabel,
                    selectedTabIndex === index && classes.tabLabelSelected,
                  )}
                >
                  {tab.label}
                </Typography>
              </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

PrimaryTab.propTypes = {
  tabArray: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),

  classes: PropTypes.shape({
    root: PropTypes.string,
    tabBox: PropTypes.string,
    tabButton: PropTypes.string,
    tabLabelBox: PropTypes.string,
    tabLabel: PropTypes.string,
    flexContainer: PropTypes.string,
    indicator: PropTypes.string,
    tabButtonSelected: PropTypes.string,
    tabLabelBoxSelected: PropTypes.string,
    tabLabelSelected: PropTypes.string,
  }),

  selectedTabIndex: PropTypes.number,

  onChange: PropTypes.func,
};

PrimaryTab.defaultProps = {
  tabArray: [],
  classes: {
    root: "",
    tabBox: "",
    tabButton: "",
    tabLabelBox: "",
    tabLabel: "",
    flexContainer: "",
    indicator: "",
    tabButtonSelected: "",
    tabLabelBoxSelected: "",
    tabLabelSelected: "",
  },

  selectedTabIndex: 0,

  onChange: () => {},
};

export default PrimaryTab;

const useStyles = makeStyles(theme => ({
  tabBox: {},

  tabButton: {
    padding: "8px 10px",
    textTransform: "none",
    width: "auto",
    minWidth: "unset",
    flex: 1,
  },

  tabLabelBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    color: "inherit",
    height: "100%",
  },

  tabLabel: {
    fontSize: 14,
    fontWeight: 700,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "inherit",
  },

  indicator: {
    borderRadius: 16,
    height: 4,
  },
}));
