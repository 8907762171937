import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const AddAdmin = ({className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <circle cx="15" cy="15" r="15" fill="#C4C4C4"/>
      <path d="M15 15C17.21 15 19 13.21 19 11C19 8.79 17.21 7 15 7C12.79 7 11 8.79 11
        11C11 13.21 12.79 15 15 15ZM15 17C12.33 17 7 18.34 7 21V23H23V21C23 18.34 17.67 17 15 17Z" fill="#010101"/>
    </SvgIcon>
  );
};
AddAdmin.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
AddAdmin.defaultProps = {
  width: 30,
  height: 30,
};
export default memo(AddAdmin);
