import { EventState, EventSubType, EventType } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { getInteractor } from "services/local.service";
import { updateRetryEvent } from "pubsub/PubSub.helper";
import { EventDto, IEventDto, IReceivedMember, ISeenMember } from "pubsub/dto";
import { IMessage } from "pubsub/dto/message.tb";
import { KeyConstant, SystemConstant } from "const";
import store, { ConversationActions } from "redux-store";
import { removeDuplicateInArray, StorageUtil } from "utils";
import { checkCurrentBranchByPrefix, checkTriggerMessageUI } from "sagas/saga.helper";
import { setUnreadMessageInAppLogo } from "pubsub/services/message/getMessage";

// Update message status
export default class MessageStatusEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.MESSAGE_STATUS);
  }

  handleEvent = async (limitRetry: number) => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, this.prefixKey);

    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT, EventState.TIMEOUT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];
    const savingSeenMembers: ISeenMember[] = [];
    const savingReceivedMembers: IReceivedMember[] = [];
    const savingMessages: { [x: string]: IMessage } = {};
    const updateMessageList: IMessage[] = []; // TODO: Need to separate to updateMessages/ updateThreadMessage logic in 1.2.1
    const updateReadIds: string[] = [];

    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      const eventContent = eventItem.contentObj || {}; // {source_id, account_id}

      const isValid = eventContent.source_id && eventContent.account_id;
      const existedMessage =
        (await getInteractor(this.prefixKey).LocalMessageService.findOne({
          source_id: eventContent.source_id,
        })) || {};
      if (!isValid || !existedMessage.id) {
        const retryEvent = updateRetryEvent(eventItem, limitRetry);
        if (retryEvent) savingEvents.push(retryEvent);
        continue;
      }

      const isMine = eventContent.account_id === accountId;
      updateMessageList.push(existedMessage);

      switch (eventItem.subtype) {
        case EventSubType.SEEN:
          savingSeenMembers.push({
            source_id: eventContent.source_id as string,
            member_account_id: eventContent.account_id as string,
            seen_at: eventItem.created,
          });

          if (isMine && eventContent.source_id) {
            savingMessages[eventContent.source_id] = {
              source_id: eventContent.source_id,
              status: SystemConstant.MESSAGE_STATUS.read as number,
              modified: Date.now(),
            };
          }

          updateReadIds.push(existedMessage.id);
          savingEvents.push({ ...eventItem, state: EventState.SUCCESSES, modified: Date.now() });

          break;

        case EventSubType.RECEIVED:
          savingReceivedMembers.push({
            source_id: eventContent.source_id as string,
            member_account_id: eventContent.account_id as string,
            received_at: eventItem.created,
          });

          if (
            isMine &&
            eventContent.source_id &&
            !savingMessages[eventContent.source_id] &&
            NOT_RECEIVED_STATUS.includes(existedMessage.status)
          ) {
            savingMessages[eventContent.source_id] = {
              source_id: eventContent.source_id as string,
              status: SystemConstant.MESSAGE_STATUS.received as number,
              modified: Date.now(),
            };
          }

          savingEvents.push({ ...eventItem, state: EventState.SUCCESSES, modified: Date.now() });

          break;

        default:
          savingEvents.push({ ...eventItem, state: EventState.FAIL, modified: Date.now() });
          break;
      }
    }

    let updateSourceIdList = updateMessageList
      .filter((item: IMessage) => item.thread_id)
      .map((item: IMessage) => item.thread_id);
    updateSourceIdList = removeDuplicateInArray(updateSourceIdList);

    const updateParentMessageList = await getInteractor(this.prefixKey).LocalMessageService.find({
      source_id: updateSourceIdList,
    });

    await Promise.all([
      getInteractor(this.prefixKey).LocalSeenMemberService.save(savingSeenMembers),
      getInteractor(this.prefixKey).LocalReceivedMemberService.save(savingReceivedMembers),
      getInteractor(this.prefixKey).LocalEventService.save(savingEvents),
      getInteractor(this.prefixKey).LocalMessageService.updateList(Object.values(savingMessages)),
    ]);

    // Update unread in group
    if (updateReadIds.length > 0) {
      await getInteractor(this.prefixKey).LocalGroupService.updateUnread(removeDuplicateInArray(updateReadIds));
      await setUnreadMessageInAppLogo();
      const isCurrentBranch = checkCurrentBranchByPrefix(this.prefixKey);
      if (isCurrentBranch) {
        store.dispatch(
          ConversationActions.conversationSet({
            isUpdateViewMode: Date.now(),
          }),
        );
      }
    }

    const updateThreadList = await Promise.all(
      updateSourceIdList.map(async (threadId: string) => {
        const totalUnread = await getInteractor(this.prefixKey).LocalMessageService.countUnreadMessageInThread(
          accountId,
          threadId,
        );
        return {
          total_unread: totalUnread,
          thread_id: threadId,
        };
      }),
    );
    await getInteractor(this.prefixKey).LocalThreadService.updateList(updateThreadList);
    if (updateThreadList.length > 0) {
      store.dispatch(
        ConversationActions.conversationSet({
          updateThreadStatus: {
            threadUpdate: updateThreadList,
            modified: Date.now(),
          },
        }),
      );
    }

    // Trigger to UI
    const messageList = [...updateMessageList, ...updateParentMessageList];
    const isNeedTrigger = messageList.find(message => checkTriggerMessageUI(this.prefixKey, message));
    if (isNeedTrigger) {
      store.dispatch(
        ConversationActions.conversationSet({
          updateMessageStatus: {
            updateMessageIds: messageList.map((message: IMessage) => message.id),
            modified: Date.now(),
          },
        }),
      );
    }

    return true;
  };
}

const NOT_RECEIVED_STATUS = [SystemConstant.MESSAGE_STATUS.ready, SystemConstant.MESSAGE_STATUS.send];
