import { RemoteApi, remoteApiFactory } from "services";
import { EventType } from "pubsub/PubSub.const";

export default class IEvent {
  prefixKey: string;
  api: RemoteApi;
  protected eventType: EventType;
  protected isExecuting: boolean = false;

  constructor(prefixKey: string, eventType: EventType) {
    this.prefixKey = prefixKey;
    this.api = remoteApiFactory.getBranchApi(prefixKey);
    this.eventType = eventType;
  }

  execute = async (limitRetry: number) => {
    if (this.isExecuting) return false;

    this.isExecuting = true;
    let isResult = false;
    try {
      await this.handleEvent(limitRetry);
      isResult = true;
    } catch (error) {
      console.error(error);
    }

    this.isExecuting = false;
    return isResult;
  };

  protected handleEvent = async (limitRetry: number): Promise<boolean> => {
    return true;
  };
}
