import { Box, Skeleton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const OTPLoading = () => {
  const classes = useStyles();
  return (
    <Stack spacing={1} className={classes.root}>
      <Skeleton variant="rounded" className={clsx(classes.elementMargin, classes.loadingInput)} />
      <Skeleton variant="rounded" className={clsx(classes.elementMargin, classes.loadingInput)} />
      <Box className={clsx(classes.elementMargin, classes.loadingActionsBox)}>
        <Skeleton variant="rounded" className={classes.loadingAction} />
        <Skeleton variant="rounded" className={classes.loadingAction} />
      </Box>
    </Stack>
  );
};

export default OTPLoading;

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: 10,
  },

  loadingInput: {
    width: "100%",
    height: 46,
  },

  loadingActionsBox: {
    display: "flex",
    justifyContent: "space-between",
  },

  loadingAction: {
    width: "30%",
    height: 32,
  },
});
