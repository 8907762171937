import React, { memo } from "react";
import PropTypes from "prop-types";
import { AppConstant, ImageConstant } from "const";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

function EmptyContact({ displayText }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={ImageConstant.EmptyContactImage} alt="empty contact" loading="lazy" />
      <Typography className={classes.description} variant="subtitle2">
        {displayText}
      </Typography>
    </Box>
  );
}

EmptyContact.propTypes = {
  mode: PropTypes.number,
};
EmptyContact.defaultProps = {
  mode: AppConstant.CHATTING_TAB_MODE.message,
};

export default memo(EmptyContact);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "calc(50vh - 250px)",
    textAlign: "center",

    "@media (min-height:780px)": {
      marginTop: 120,
    },

    "& img": {
      width: "80%",
    },
  },
  description: {
    color: "#1E272E",
    marginTop: 16,
  },
}));
