import { useState, memo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { AppConstant } from "const";
import { isVideo } from "utils";
import NoticeDialog from "./dialog/NoticeDialog";
import { useTranslation } from "react-i18next";
import { BranchSelectors } from "redux-store";
import { useSelector } from "react-redux";
import { useAlertContext } from "./context/AlertContext";
import { DEFAULT_SEVER_OPTIONS } from "utils/branch.utils";

const ERR_UPLOADING_TYPE = {
  none: 0,
  notAccept: 1,
  notSupport: 2,
};

const HandleUploadingInput = ({ accept, onChange, ...otherProps }) => {
  const { t: getLabel } = useTranslation();
  const serverOptions = useSelector(BranchSelectors.getServerOptions);
  const { showAlert } = useAlertContext();
  const inputRef = useRef(null);

  const [errorType, setErrorType] = useState(ERR_UPLOADING_TYPE.none);
  const [limitFileSize, setLimitFileSize] = useState(DEFAULT_SEVER_OPTIONS.maxFileSize); // MB

  const handleChangingFile = event => {
    const getFileType = file => (file?.type || "").split("/")[0];
    const uploadFiles = Array.from(event.target.files);
    const validFiles = uploadFiles.filter(file => Math.floor(file.size / 1024 / 1024) <= limitFileSize);

    const isAccepted =
      validFiles.filter(file => {
        const fileType = getFileType(file);
        return Boolean(accept === "*" || (accept.includes(fileType) && fileType));
      }).length === validFiles.length;

    const isNotSupportVideo =
      validFiles.filter(file => {
        const fileType = getFileType(file);
        return fileType && isVideo(fileType) && false === AppConstant.SUPPORT_VIDEO_TYPES.includes(file.type);
      }).length > 0;

    const isLargeFile = validFiles.length < uploadFiles.length;
    if (isLargeFile) {
      showAlert({ content: getLabel("ERR_LIMIT_SIZE", { size: limitFileSize }), alertProps: { severity: "error" } });
    }

    if (!isAccepted) {
      setErrorType(ERR_UPLOADING_TYPE.notAccept);
    } else if (isNotSupportVideo) {
      setErrorType(ERR_UPLOADING_TYPE.notSupport);
    } else if (!isLargeFile && validFiles.length > 0 && onChange) {
      onChange(validFiles);
    }

    inputRef.current.value = null;
  };

  const isError = errorType !== ERR_UPLOADING_TYPE.none;
  const errorContent = isError
    ? getLabel(errorType === ERR_UPLOADING_TYPE.notSupport ? "ERR_UNSUPPORTED_EXTENSION" : "ERR_NOT_ACCEPTABLE")
    : "";

  useEffect(() => {
    setLimitFileSize(serverOptions.maxFileSize ? serverOptions.maxFileSize : DEFAULT_SEVER_OPTIONS.maxFileSize);
  }, [serverOptions]);

  return (
    <>
      <input ref={inputRef} accept={accept} onChange={handleChangingFile} {...otherProps} />
      <NoticeDialog
        open={isError}
        title={getLabel("ERR_TITLE")}
        content={errorContent}
        submitProps={{ onClick: () => setErrorType(ERR_UPLOADING_TYPE.none) }}
      />
    </>
  );
};

HandleUploadingInput.propTypes = {
  id: PropTypes.string,
  accept: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,

  onChange: PropTypes.func,
};

HandleUploadingInput.defaultProps = {};

export default memo(HandleUploadingInput);
