import React, { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";

const ThreadFlow = ({ isMine, isShow, parentMessageId, messageThreadTotal, unreadMsgThreadTotal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const handleReplyMessage = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        threadingId: parentMessageId,
      }),
    );
  };

  const hasUnreadMessage = unreadMsgThreadTotal > 0;

  if (!parentMessageId) return <Fragment />;

  return (
    isShow && (
      <Box
        className={clsx(
          isMine ? classes.threadCountBoxMine : classes.threadCountBox,
          hasUnreadMessage && classes.unreadBox,
        )}
        onClick={handleReplyMessage}
      >
        {hasUnreadMessage ? (
          <>
            <Box className={classes.newReplyCount}>+{unreadMsgThreadTotal}</Box>
            <Typography className={classes.threadCount}>{getLabel(LangConstant.FM_NEW_REPLY_COUNT)}</Typography>
          </>
        ) : (
          <Typography className={classes.threadCount}>
            {getLabel(LangConstant.FM_REPLY_COUNT, {
              count: messageThreadTotal,
            })}
          </Typography>
        )}
      </Box>
    )
  );
};

export default memo(ThreadFlow);

const useStyles = makeStyles(theme => ({
  threadCountBox: {
    borderRadius: 12,
    padding: "0 4px",
    border: "1px solid white",
    backgroundColor: "#7F7F80",
    cursor: "pointer",
  },

  threadCountBoxMine: {
    borderRadius: 12,
    padding: "0 4px",
    border: "1px solid white",
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },

  threadCount: {
    width: "max-content",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    color: "#fff",
  },

  newReplyCount: {
    background: "red",
    borderRadius: "50%",
    width: 20,
    display: "flex",
    justifyContent: " center",
    marginRight: 5,
    color: "#fff",
  },

  unreadBox: {
    paddingLeft: 0,
    display: "flex",
    alignItems: "center",
  },
}));
