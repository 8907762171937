import { SystemConstant } from "const";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GroupInfoActions } from "redux-store";
import { getInteractor } from "services/local.service";

const useMuteGroup = groupId => {
  const dispatch = useDispatch();

  const [isMuted, setIsMuted] = useState(false);

  const muteGroup = useCallback(isMuted => {
    setIsMuted(isMuted);
    dispatch(
      GroupInfoActions.muteGroupNotification({
        groupId: groupId,
        status: isMuted ? SystemConstant.STATE.inactive : SystemConstant.STATE.active,
      }),
    );
  }, []);

  const checkGroupNoticeStt = useCallback(() => {
    getInteractor()
      .LocalGroupSettingService.isMutedNotify(groupId)
      .then(isMutedGroup => setIsMuted(Boolean(isMutedGroup)));
  }, [groupId]);

  useEffect(() => {
    checkGroupNoticeStt();
  }, [groupId]);

  return { isMuted, muteGroup, checkGroupNoticeStt };
};

export default useMuteGroup;
