import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const NoticeDialog = ({ title, content, submitProps: { submitText, ...otherSubmitProps }, onClose, ...otherProps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t: getLabel } = useTranslation();

  return (
    <Dialog fullScreen={isFullScreen} classes={{ paper: classes.root }} onClose={onClose} {...otherProps}>
      <DialogTitle className={classes.title}>{title || getLabel(LangConstant.TXT_NOTIFICATION)}</DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <Typography component="div" variant="body2" className={classes.contentText}>
          {content}
        </Typography>
      </DialogContent>
      <Box className={classes.action}>
        <Button color="primary" variant="contained" size="large" fullWidth onClick={onClose} {...otherSubmitProps}>
          {submitText || getLabel(LangConstant.TXT_OK)}
        </Button>
      </Box>
    </Dialog>
  );
};

NoticeDialog.propTypes = {
  open: PropTypes.bool,
  submitProps: PropTypes.object,
};
NoticeDialog.defaultProps = {
  submitProps: {},
};

export default NoticeDialog;

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 300,
    borderRadius: 20,
    padding: theme.spacing(1.5, 3),
    textAlign: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  contentRoot: {
    padding: 0,
  },
  contentText: {
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  action: {
    marginTop: 16,
    marginBottom: 8,

    "& > *": {
      marginTop: 4,
      marginBottom: 4,
    },
  },
}));
