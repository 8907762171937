import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";

/**
 * Copy component from IOSSwitch material v5
 * https://mui.com/components/switches/#customization
 */
const IOSSwitch = props => {
  const classes = useStyles();
  return <Switch classes={classes} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />;
};

IOSSwitch.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,

  onChange: PropTypes.func,
};

export default IOSSwitch;

const useStyles = makeStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#1B7AFC",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#606165",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));
