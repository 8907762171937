import { EventState, EventSubType, EventType } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { getInteractor } from "services/local.service";
import { updateRetryEvent } from "pubsub/PubSub.helper";
import { EventDto, IEventDto } from "pubsub/dto";

export default class MessageEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.MESSAGE);
  }

  handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = await getInteractor(this.prefixKey).LocalEventService.find({
      type: this.eventType,
      state: [EventState.NEW, EventState.RESENT],
    });
    const savingEvents: IEventDto[] = [];

    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      switch (eventItem.subtype) {
        case EventSubType.NEW:
        case EventSubType.UPDATE:
        case EventSubType.DELETE:
          let message;
          if (eventItem.state === EventState.RESENT) {
            message = await getInteractor(this.prefixKey).LocalMessageService.get(eventItem.source_event_id);
          } else if (eventItem.state === EventState.NEW) {
            message = await getInteractor(this.prefixKey).LocalMessageService.findOne({
              source_id: eventItem.source_event_id,
            });
          }

          if (message && message.id) {
            savingEvents.push({ ...eventItem, state: EventState.SUCCESSES, modified: Date.now() });
          } else {
            const retryEvent = updateRetryEvent(eventItem, limitRetry);
            if (retryEvent) savingEvents.push(retryEvent);
          }

          break;

        default:
          savingEvents.push({ ...eventItem, state: EventState.FAIL, modified: Date.now() });
          break;
      }
    }

    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}
