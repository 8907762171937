import { ApiConstant, KeyConstant } from "const";
import { getFileList } from "pubsub/services/file.service";
import { getInteractor } from "services/local.service";
import { remoteApiFactory } from "services/remote-api/RemoteApiFactory";
import StringFormat from "string-format";
import { convertString2JSON, StorageUtil } from "utils";

export const getUploadDomain = prefixKey => {
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
  const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
  const branchOptions = convertString2JSON(branchInfo.options, {});
  return branchOptions.tus_domain;
};

export const tusGet = async (prefixKey, attachmentId, groupId) => {
  let fileInfo = await getInteractor(prefixKey).LocalFileService.get(attachmentId);
  if (false === Boolean(fileInfo && fileInfo.id)) {
    const remoteFileList = await getFileList(prefixKey, [attachmentId]);
    fileInfo = (remoteFileList || [])[0];
  }

  if (fileInfo && fileInfo.id) {
    const tusDomain = getUploadDomain(prefixKey);
    groupId = groupId || fileInfo.group_id;

    const tusGetDomain = `https://${tusDomain}`;
    const fileUrl = StringFormat(ApiConstant.GET_FILE_TUS, fileInfo.url);
    const response = await remoteApiFactory.getBranchApi(prefixKey).getFileTus(tusGetDomain, fileUrl, groupId);

    if (response.status === ApiConstant.STT_OK) {
      const encryptedBytes = new Uint8Array(response.data);
      return { data: encryptedBytes, fileInfo: fileInfo };
    }
  } else {
    console.log("FILE NOT FOUND");
  }

  return null;
};
