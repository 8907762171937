import React, { memo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getExternalLinkFromString } from "utils";
import { usePreviewLink } from "hooks";

const LinkPreview = ({ message }) => {
  const classes = useStyles();
  const previewUrl = getExternalLinkFromString(message?.content)[0];
  const { linkMetaData, clickExternalLink } = usePreviewLink({ previewUrl, messageId: message.id });

  return (
    <Box className={clsx(classes.previewRoot)} onClick={clickExternalLink}>
      <Box>
        <CardMedia
          component="img"
          className={clsx(classes.previewImage, {
            [classes.skeletonLoading]: !Boolean(linkMetaData.image),
          })}
          image={linkMetaData.image}
        />

        {linkMetaData.title && (
          <Typography className={clsx("bold-md-txt ellipsis", classes.previewTitle)}>{linkMetaData.title}</Typography>
        )}

        {linkMetaData.description && (
          <Typography className={clsx("regular-sm-txt ellipsis", classes.previewDescribe)}>
            {linkMetaData.description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(LinkPreview);

LinkPreview.propTypes = {
  message: PropTypes.object,

  classes: PropTypes.shape({
    root: PropTypes.string,
    message: PropTypes.string,
    preview: PropTypes.string,
    image: PropTypes.string,
    skeletonLoading: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
  }),
};

LinkPreview.defaultProps = {
  message: {},

  classes: {
    root: "",
    message: "",
    preview: "",
    image: "",
    skeletonLoading: "",
    title: "",
    desc: "",
  },
};

const useStyles = makeStyles(theme => ({
  previewRoot: {
    backgroundColor: theme.palette.grey[100],
    textDecoration: "none",
    paddingBottom: 12,

    "&:hover": {
      textDecoration: "none",
    },
  },

  previewImage: {
    width: "100%",
    height: "100%",
    maxHeight: "200px",
    minHeight: "120px",
    backgroundSize: "cover",
    objectFit: "cover",
  },

  skeletonLoading: {
    background: `linear-gradient(to right, 
      rgba(255,255,255,0),
      rgba(255,255,255,0.2),
      rgba(255,255,255,0.4),
      rgba(255,255,255,0.55),
      rgba(255,255,255,0.6),
      rgba(255,255,255,0.4),
      rgba(255,255,255,0.2),
      rgba(255,255,255,0))`,
    backgroundSize: "200% 100%",
    animation: `$loading 3000ms linear infinite`,
  },

  previewTitle: {
    padding: "8px 16px 4px",
    color: theme.palette.black,
    WebkitLineClamp: 2,
  },

  previewDescribe: {
    padding: "0 16px",
    color: theme.palette.grey[600],
    WebkitLineClamp: 3,
  },

  "@keyframes loading": {
    to: {
      backgroundPositionX: "-200%",
    },
  },
}));
