import { Call, MissedVideoCall, Videocam } from "@mui/icons-material";
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CallingActions } from "redux-store";
import { StorageUtil, toCamel, uuid } from "utils";
import { getDisplayTimestamp } from "utils/date.utils";
import { isGroupOrChannelType } from "utils/view.utils";
import InitGroupCallPopup from "pages/HomePage/components/MessengerChat/InitGroupCallPopup";
import { AvatarGroup } from "components";
import clsx from "clsx";
import { AudioEnd, IncomingAudioCall, MissedCallIcon } from "components/icons";

const CallHistoryItem = ({ callHistory = {}, onSelect, checked }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const isVoiceCall = callHistory.type === SystemConstant.CALL_HISTORY_TYPE.audioCall;
  const isMissedCall =
    callHistory.status === SystemConstant.CALL_HISTORY_STATUS.missed && callHistory.senderId !== accountId;
  const callType = getCallType(callHistory);

  const [isOpenInitGroupCall, setIsOpenInitGroupCall] = useState(false);

  const handleCall = () => {
    if (isGroupOrChannelType(callHistory.groupDetail.groupType)) {
      setIsOpenInitGroupCall(true);
    } else {
      dispatch(
        CallingActions.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
          callingGroupDetail: toCamel({ ...callHistory.groupDetail, room_id: uuid() }),
          isVideoCall: callHistory.type === SystemConstant.CALL_HISTORY_TYPE.videoCall,
        }),
      );

      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const callingMember = callHistory.groupDetail.groupMembers.find(item => item.id !== accountId);

      dispatch(CallingActions.onCallCheck({ accountId: callingMember.id }));
    }
  };

  return (
    <Stack direction="row" alignItems="center" p="8px 16px">
      <Checkbox edge="start" onChange={onSelect} checked={checked} />

      <Stack direction="row" alignItems="center" width="100%">
        <AvatarGroup
          group={{
            groupType: callHistory.groupDetail.groupType,
            groupName: callHistory.groupDetail.groupName,
            privateF: callHistory.groupDetail.privateF,
          }}
          avatarId={callHistory.groupDetail.avatarId}
        />
        <Box ml={1}>
          <Typography className="ellipsis">{callHistory.groupDetail.groupName}</Typography>
          <CallHistoryIcon
            type={callType}
            content={getDisplayTimestamp(callHistory.timeStart)}
            className={isMissedCall && classes.missedCallIcon}
          />
        </Box>
      </Stack>
      <Button
        variant="contained"
        className={classes.callButton}
        classes={{ root: classes.rootButton, disabled: classes.disabledButton }}
        onClick={handleCall}
      >
        {isVoiceCall ? <Call color="white" /> : <Videocam color="white" />}
      </Button>

      {isOpenInitGroupCall && (
        <InitGroupCallPopup data={callHistory.groupDetail} open onClose={() => setIsOpenInitGroupCall(false)} />
      )}
    </Stack>
  );
};

export default CallHistoryItem;

const CALL_HISTORY_TYPE = {
  audioEnd: 1,
  incomingAudioEnd: 2,
  audioMissed: 3,
  video: 4,
};

const getCallType = callHistory => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isIncomingCall = callHistory.senderId !== accountId;
  const isAudioCall = callHistory.type === SystemConstant.CALL_HISTORY_TYPE.audioCall;

  const isMissesAudioCall = isAudioCall && callHistory.status === SystemConstant.CALL_HISTORY_STATUS.missed;
  const isIncomingAudioCall =
    isAudioCall && isIncomingCall && callHistory.status === SystemConstant.CALL_HISTORY_STATUS.end;
  const isAudioCallEnd =
    isAudioCall && !isIncomingCall && callHistory.status === SystemConstant.CALL_HISTORY_STATUS.end;

  let callType;
  switch (true) {
    case !isAudioCall:
      callType = CALL_HISTORY_TYPE.video;
      break;

    case isMissesAudioCall:
      callType = CALL_HISTORY_TYPE.audioMissed;
      break;

    case isIncomingAudioCall:
      callType = CALL_HISTORY_TYPE.incomingAudioEnd;
      break;

    case isAudioCallEnd:
      callType = CALL_HISTORY_TYPE.audioEnd;
      break;

    default:
      callType = CALL_HISTORY_TYPE.audioEnd;
      break;
  }

  return callType;
};

const CallHistoryIcon = ({ type, content, className }) => {
  const classes = useStyles();

  let Icon = null;
  switch (type) {
    case CALL_HISTORY_TYPE.audioEnd:
      Icon = <AudioEnd sx={{ fontSize: 16, margin: "4px" }} />;
      break;

    case CALL_HISTORY_TYPE.incomingAudioEnd:
      Icon = <IncomingAudioCall sx={{ fontSize: 16, margin: "4px" }} />;
      break;
    case CALL_HISTORY_TYPE.audioMissed:
      Icon = <MissedCallIcon sx={{ fontSize: 16, margin: "4px" }} />;
      break;

    case CALL_HISTORY_TYPE.video:
      Icon = <MissedVideoCall />;
      break;

    default:
      break;
  }

  return (
    <Box className={clsx(classes.iconWrapper, className)}>
      <Box className={classes.icon}>{Icon}</Box>
      <Typography className={clsx(classes.lastMessage, "ellipsis", "regular-md-txt", className)}>{content}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  rootButton: {
    padding: 0,
    minWidth: 42,
    minHeight: 30,
  },

  missedCallIcon: {
    color: "#EB544B !important",
  },

  iconWrapper: {
    color: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&>svg": {
      marginRight: 0,
    },
  },

  lastMessage: {
    lineHeight: "18px",
    whiteSpace: "break-spaces",
  },

  icon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
}));
