import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import { getFirstLetter } from "utils";
import { useFileUrl } from "hooks";

const AppAvatar = ({ name, size = 50, avatarId, children, ...otherProps }) => {
  const firstLetter = getFirstLetter(name) || "T";
  const { fileUrl } = useFileUrl(avatarId);

  return (
    <Avatar
      loading="lazy"
      alt={name}
      sx={{ width: size, height: size, bgcolor: "#BEBEBE" }}
      src={avatarId && fileUrl}
      {...otherProps}
    >
      {children || firstLetter}
    </Avatar>
  );
};

AppAvatar.propTypes = {
  name: PropTypes.string,
  avatarId: PropTypes.string,
  size: PropTypes.number,
};

export default AppAvatar;
