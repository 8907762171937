import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { toCamel, uuid } from "utils";
import { useDispatch, useSelector } from "react-redux";
import CallingAction from "redux-store/calling.redux";
import { Clear, EditOutlined, Videocam } from "@mui/icons-material";
import PropTypes from "prop-types";
import { AvatarGroup, HandleUploadingInput } from "components";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { getSavedServer } from "utils/branch.utils";

const InitGroupCallPopup = ({ open, onClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const idGroupCallAvatar = useSelector(state => state.conversationRedux.idGroupCallAvatar);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);

  const [groupCallName, setGroupCallName] = useState("");
  const [avatarId, setAvatarId] = useState("");

  const onChangeGroupCallName = event => {
    setGroupCallName(event.target.value);
  };

  const onDeleteGroupCallName = () => {
    setGroupCallName("");
  };

  const onCloseInitPopup = () => {
    dispatch(
      ConversationActions.conversationSet({
        idGroupCallAvatar: null,
      }),
    );
    onClose();
  };

  const onGroupCall = async () => {
    const dataCall = {
      ...data,
      avatarId: avatarId,
      callName: groupCallName,
      listMemberChoose: data.groupMembers?.filter(item => item.id !== accountId).map(item => item.id),
      adminId: accountId,
    };
    await onSendEndLastCall();
    dispatch(
      CallingAction.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: toCamel({ ...dataCall, room_id: uuid() }),
        isReceiver: false,
      }),
    );

    dispatch(CallingAction.onCallCheck({ accountId: accountId }));
    onClose();
  };

  const onSendEndLastCall = async () => {
    // If current user with current device is in another call, send end message to that group
    const lastCall = toCamel(await getInteractor().LocalCallHistoryService.getLastByGroupId(data.id));

    const savedServer = getSavedServer();

    const callMessage = toCamel(
      (await getInteractor().LocalMessageService.findOne({ source_id: lastCall.sourceId })) || {},
    );

    if (
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId)
    ) {
      let endContent = JSON.stringify({
        room_id: lastCall.roomId,
      });

      const memberIdArray = data.groupMembers.map(member => member.id) || [];

      const deviceList = await getInteractor().LocalDeviceService.find({ account_id: memberIdArray });

      dispatch(
        ConversationActions.sendMessage({
          groupId: data.id,
          sendType: callMessage.sendType,
          content: endContent,
          parentId: callMessage.parentId,
          deviceList: [...deviceList],
          branchId: savedServer?.id,
          mentionIdsArr: [],
          threadId: null,
          callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
          roomId: lastCall.roomId,
          isReceiver: true,
        }),
      );
    }
  };

  const handleChangeFile = files => {
    const file = files[0];
    dispatch(ConversationActions.requestUploadImageCall({ upload: file, initCall: true }));
  };

  // get call info
  useEffect(() => {
    setAvatarId(data.avatarId);
    setGroupCallName(data.groupName);
  }, []);

  useEffect(() => {
    if (Boolean(idGroupCallAvatar)) {
      setAvatarId(idGroupCallAvatar);
    }
  }, [idGroupCallAvatar]);

  return (
    <Dialog open={open} classes={{ paper: classes.wrapDialogGroup }} onClose={onCloseInitPopup}>
      <DialogTitle className={classes.popupTitle}>{getLabel(LangConstant.TXT_GROUP_CALL_INIT)}</DialogTitle>
      <DialogContent>
        <Box className={classes.avatarAndName}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton classes={{ root: classes.iconBtn }}>
                <HandleUploadingInput
                  accept={AppConstant.ACCEPTABLE_IMAGE_TYPES}
                  className="hidden"
                  id="upload_photo_id"
                  type="file"
                  onChange={handleChangeFile}
                />
                <label htmlFor="upload_photo_id" className={classes.styleButton}>
                  <EditOutlined fontSize="small" className={classes.chooseAvatar} />
                </label>
              </IconButton>
            }
          >
            {avatarId ? (
              <AvatarGroup className={classes.avatar} avatarId={avatarId} />
            ) : (
              <Box className={classes.defaultAvatar}>
                <Videocam fontSize="large" color="primary" />
              </Box>
            )}
          </Badge>
          <FormControl className={classes.groupCallName} variant="outlined">
            <Input
              type="text"
              value={groupCallName}
              inputProps={{ maxLength: SystemConstant.MAX_NAME_LENGTH }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={onDeleteGroupCallName} edge="end">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              }
              onChange={onChangeGroupCallName}
            />
          </FormControl>
        </Box>
        <Button
          className={classes.buttonCall}
          onClick={onGroupCall}
          classes={{ disabled: classes.disabledButton }}
          disabled={!Boolean(groupCallName) || isInCall || !hasInternet}
        >
          {getLabel(LangConstant.TXT_CALL)}
        </Button>
        <Button className={classes.buttonCancel} onClick={onCloseInitPopup}>
          {getLabel(LangConstant.TXT_CANCEL_CALL)}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

InitGroupCallPopup.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func,
};

InitGroupCallPopup.defaultProps = {
  open: false,
  data: {},

  onClose: () => {},
};

export default InitGroupCallPopup;

const useStyles = makeStyles(theme => ({
  wrapDialogGroup: {
    textAlign: "center",
    borderRadius: 20,
    padding: "30px 20px",
    minWidth: "500px",
  },
  popupTitle: {
    fontSize: 24,
    fontWeight: 700,
  },
  avatarAndName: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  groupCallName: {
    width: "70%",
  },

  buttonCall: {
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
    color: "#ffffff",
    backgroundColor: "#008FE8",
    "&:hover": {
      backgroundColor: "#72B9E6",
    },
  },

  buttonCancel: {
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
  },

  chooseAvatar: {
    color: "#dbd0d0",
  },

  avatar: {
    width: 80,
    height: 80,
  },

  defaultAvatar: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    border: "solid 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#008FE8",
  },

  iconBtn: {
    width: 28,
    height: 28,
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },
}));
