import { useEffect, useRef, useState } from "react";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ConversationSelectors } from "redux-store";
import { StorageUtil } from "utils";
import { getFileList } from "pubsub/services/file.service";

const memoizedIncomingState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    ConversationSelectors.getThreadingId,
    state => state.conversationRedux.incomingMessages,
  ],

  (selectedGroupId, threadingId, incomingMessages) => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

    const messageInGroup = incomingMessages[threadingId || selectedGroupId];
    const messageList = messageInGroup ? Object.values(messageInGroup) : null;

    if (Array.isArray(messageList) && messageList.length > 0) {
      const sendMessages = messageList.filter(
        item => item.senderId === accountId && item.senderDeviceId === deviceId && Boolean(item.modified),
      );

      return sendMessages;
    }

    return null;
  },
);

const useFileStatus = (prefixKey, attachmentId, groupId) => {
  const cleanUpRef = useRef(false);
  const sendLocalMessage = useSelector(memoizedIncomingState);

  const [fileStatus, setFileStatus] = useState(SystemConstant.UPLOAD_STATUS.inProgress);

  const handleSetUploadStatus = param => {
    if (cleanUpRef.current) return null;
    setFileStatus(param);
  };

  const handleFileStatus = async () => {
    let fileRecord = await getInteractor(prefixKey).LocalFileService.get(attachmentId);
    if (!Boolean(fileRecord && fileRecord.id)) {
      const remoteList = (await getFileList(prefixKey, [attachmentId], groupId)) || [];
      fileRecord = remoteList[0];
    }

    if (!Boolean(fileRecord && fileRecord.id)) return handleSetUploadStatus(SystemConstant.UPLOAD_STATUS.failed);

    // If file.status === inProgress for more than 15 minutes => update status = fail in localDB
    const uploadTime = Date.now() - fileRecord.created;
    if (
      fileRecord.status === SystemConstant.UPLOAD_STATUS.inProgress &&
      uploadTime >= AppConstant.CHECK_UPLOAD_TIMEOUT
    ) {
      await getInteractor(prefixKey).LocalFileService.update(
        { status: SystemConstant.UPLOAD_STATUS.failed, modified: Date.now() },
        { id: attachmentId },
      );

      fileRecord.status = SystemConstant.UPLOAD_STATUS.failed;
    }
    handleSetUploadStatus(fileRecord.status);
  };

  useEffect(() => {
    handleFileStatus();

    return () => {
      cleanUpRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (false === (Array.isArray(sendLocalMessage) && sendLocalMessage.length > 0)) return;
    if (sendLocalMessage.findIndex(item => item.content.includes(attachmentId) && item.groupId === groupId) >= 0) {
      handleFileStatus();
    }
  }, [sendLocalMessage]);

  return { fileStatus };
};

export default useFileStatus;
