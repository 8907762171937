import { useRef, useState } from "react";

const useInputEvent = () => {
  const inputRef = useRef({ value: "" });

  const [inputValue, setInputValue] = useState("");

  const updateContentInput = inputValue => {
    if (inputRef.current) {
      setInputValue(inputValue);
      inputRef.current.value = inputValue;
      inputRef.current.focus();
    }
  };

  // Insert content at current cursor
  const insertContentInput = (value, callback) => {
    const cursorIndex = getCursorPosition(inputRef.current);

    const content = inputRef.current.value.slice(0, cursorIndex) + value + inputRef.current.value.slice(cursorIndex);
    updateContentInput(content);

    // Add new cursor
    const newCursorIndex = cursorIndex + value.length;
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(newCursorIndex, newCursorIndex);
    }, 100);

    if (callback) callback(content);
  };

  return { inputRef, inputValue, insertContentInput, updateContentInput };
};

export default useInputEvent;

export const getCursorPosition = oField => {
  if (!oField) return 0;
  // Initialize
  var iCaretPos = 0;

  // IE Support
  if (document.selection) {
    // Set focus on the element
    oField.focus();

    // To get cursor position, get empty selection range
    var oSel = document.selection.createRange();

    // Move selection start to 0 position
    oSel.moveStart("character", -oField.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart === "0")
    iCaretPos = oField.selectionDirection === "backward" ? oField.selectionStart : oField.selectionEnd;

  // Return results
  return iCaretPos;
};
