import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, styled } from "@mui/styles";
import { Menu, MenuItem as MuiMenuItem } from "@mui/material";
import { Delete, Notifications, RemoveCircle, Logout, StarOutlineRounded, StarRounded } from "@mui/icons-material";
import { NotificationOffIcon } from "components/icons";
import { SystemConstant, LangConstant, KeyConstant } from "const";
import { showConfirmLeaveGroup } from "utils/view.utils";
import DialogGroupMember from "../../GroupInfoDrawer/DialogGroupMember";
import ChooseAdminDrawer from "../../GroupInfoDrawer/GroupMember/ChooseAdminDrawer";
import DialogConfirmLeave from "../../AddingContact/DialogConfirmLeave";
import { AccountActions, BranchSelectors, ConversationActions, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { getCommonLang } from "utils/lang.utils";

const ChatItemMenu = ({ anchorEl, group, onClose, onMutedGroup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isChannelGroup = group?.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const [isConfirmLeave, setIsConfirmLeave] = useState(false);
  const [isChooseAdmin, setIsChooseAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [menuOption, setMenuOption] = useState(null);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const onDeleteConversation = () => {
    setIsConfirmDelete(true);
  };

  const onCloseConfirmDialog = () => {
    setIsConfirmDelete(false);
    onClose();
  };

  const onConfirmDelete = () => {
    setIsConfirmDelete(false);
    dispatch(
      GroupInfoActions.deleteGroup({
        groupId: group.id,
      }),
    );
  };

  const handleNotificationSetting = () => {
    dispatch(
      GroupInfoActions.muteGroupNotification({
        groupId: group.id,
        status: group.isMuted ? SystemConstant.STATE.active : SystemConstant.STATE.inactive,
      }),
    );
    onMutedGroup(!group.isMuted);
    onClose();
  };

  const onBlockUser = () => {
    let other = group?.groupMembers?.find(s => s.id !== accountId);
    if (other) {
      if (group.isBlocked) {
        dispatch(AccountActions.unblockUser({ groupId: group.id, blockedAccountId: other.id }));
      } else {
        dispatch(AccountActions.blockUser({ groupId: group.id, blockedAccountId: other.id }));
      }
    }
    onClose();
  };
  const onLeaveGroupChannel = () => {
    showConfirmLeaveGroup(group, setIsConfirmLeave, setIsChooseAdmin);
  };

  const onClickSetAdmin = () => {
    onClose();
    setIsAdmin(true);
    setIsChooseAdmin(false);
  };

  const onLeave = () => {
    dispatch(
      ConversationActions.sendMessage({
        groupId: group.id,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: accountId,
        parentId: null,
        deviceList: [],
        branchId: selectedBranch?.id,
        mentionIdsArr: [],
        threadId: null,
        removingId: accountId,
      }),
    );

    setIsConfirmLeave(false);
    onClose();
  };

  const handleFavoriteGroup = () => {
    const newFavorite = !isFavorite;
    setIsFavorite(newFavorite);
    dispatch(
      GroupInfoActions.updateGroup({
        groupId: group.id,
        userType: Boolean(newFavorite) ? SystemConstant.GROUP_USER_TYPE.favorite : SystemConstant.GROUP_USER_TYPE.none,
      }),
    );
  };

  useEffect(() => {
    setIsFavorite(Boolean(group.userType));
  }, [group]);

  useEffect(() => {
    if (false === Boolean(isBranchServer && group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal)) {
      const result = {};
      if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        result.content = getLabel(group.isBlocked ? "TXT_UN_BLOCK" : "TXT_BLOCK");
        result.icon = <RemoveCircle />;
        result.function = onBlockUser;
      } else if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.group) {
        result.content = getLabel("TXT_LEAVE_GROUP_INFO");
        result.icon = <Logout />;
        result.function = onLeaveGroupChannel;
      } else {
        result.content = getLabel("TXT_LEAVE_CHANNEL_INFO");
        result.icon = <Logout />;
        result.function = onLeaveGroupChannel;
      }
      setMenuOption(result);
    }
  }, [group, isBranchServer]);

  return (
    <>
      <Menu
        open={Boolean(anchorEl)}
        onClose={onClose}
        classes={{
          root: classes.chatMenuRoot,
          paper: classes.chatMenuPaper,
          list: classes.chatMenuList,
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        PaperProps={{
          elevation: 0,
        }}
      >
        <MenuItem onClick={handleFavoriteGroup}>
          {isFavorite ? <StarRounded /> : <StarOutlineRounded />}
          {getLabel(isFavorite ? "TXT_REMOVE_FAVORITE" : "TXT_ADD_FAVORITE")}
        </MenuItem>
        <MenuItem onClick={onDeleteConversation}>
          <Delete /> {getCommonLang("TXT_DELETE")}
        </MenuItem>
        <MenuItem className={group.sendToMe ? "hidden" : ""} onClick={handleNotificationSetting}>
          {group.isMuted ? <Notifications /> : <NotificationOffIcon />}
          {getLabel(group.isMuted ? "TXT_UNMUTE_CONVERSATION" : "TXT_MUTE_CONVERSATION")}
        </MenuItem>
        {menuOption && menuOption.icon && (
          <MenuItem className={group.sendToMe ? "hidden" : ""} onClick={menuOption.function}>
            {menuOption.icon} {menuOption.content}
          </MenuItem>
        )}
      </Menu>

      {isConfirmLeave && (
        <DialogConfirmLeave
          open={isConfirmLeave}
          onClose={() => {
            setIsConfirmLeave(false);
            onClose();
          }}
          title={isChannelGroup ? getLabel("TXT_LEAVE_CHANNEL") : getLabel("TXT_LEAVE_GROUP")}
          content={getLabel("TXT_CONTENT_BUTTON_GROUP")}
          submitProps={{
            submitText: isChannelGroup ? getLabel("TXT_LEAVE_CHANNEL_INFO") : getLabel("TXT_LEAVE_GROUP_INFO"),
            onClick: onLeave,
          }}
          cancelProps={{
            cancelText: getCommonLang("TXT_CANCEL"),
            onClick: () => {
              setIsConfirmLeave(false);
              onClose();
            },
          }}
        />
      )}

      {isChooseAdmin && (
        <DialogGroupMember
          open={isChooseAdmin}
          onClickSetAdmin={onClickSetAdmin}
          onClose={() => {
            setIsChooseAdmin(false);
            onClose();
          }}
          groupDetail={group}
          subTitle={
            group.groupType === SystemConstant.GROUP_CHAT_TYPE.channel
              ? getLabel("TXT_SUBTITLE_LEAVE_CHANNEL")
              : getLabel("TXT_DES_BUTTON_CONFIRM")
          }
          confirmButtonText={
            group.groupType === SystemConstant.GROUP_CHAT_TYPE.channel
              ? getLabel("TXT_LEAVE_CHANNEL")
              : getLabel("TXT_LEAVE_GROUP")
          }
        />
      )}

      {isAdmin && (
        <ChooseAdminDrawer
          dataAdmin={group.groupMembers}
          onClose={() => {
            setIsAdmin(false);
            onClose();
          }}
          open={isAdmin}
          groupDetail={group}
        />
      )}

      {isConfirmDelete && (
        <DialogConfirmLeave
          open
          onClose={onCloseConfirmDialog}
          title={getLabel("TXT_DELETE_CONVERSATION_TITLE")}
          content={getLabel("TXT_DELETE_CONVERSATION_SUBTITLE")}
          submitProps={{ submitText: getCommonLang("TXT_DELETE"), onClick: onConfirmDelete }}
          cancelProps={{ onClick: onCloseConfirmDialog }}
        />
      )}
    </>
  );
};

ChatItemMenu.propTypes = {
  anchorEl: PropTypes.object,

  onClose: PropTypes.func,

  data: PropTypes.shape({
    type: PropTypes.number,
    isMuted: PropTypes.bool,
  }),
};

export default memo(
  ChatItemMenu,
  (prev, next) => next && prev?.data?.id === next.data?.id && prev?.anchorEl === next.anchorEl,
);

const useStyles = makeStyles(theme => ({
  chatMenuPaper: {
    borderRadius: 20,
    padding: "13px 10px",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "50%",
      left: -10,
      width: 20,
      height: 20,
      backgroundColor: theme.palette.white,
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
}));

const MenuItem = styled(MuiMenuItem)({
  borderRadius: 10,
  padding: "10px 14px",
  "&::hover": {
    backgroundColor: "#F2F2F2",
  },

  "&>svg": {
    marginRight: 10,
  },

  fontSize: 15,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#1E272E",
});
