import { useState } from "react";
import { ListItem, ListItemButton, ListItemText, List, ListItemIcon, Checkbox } from "@mui/material";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { SelectList } from "components";
import { LangConstant, SystemConstant } from "const";
import { getDayStartAndEnd } from "utils/date.utils";

export const FILTER_BY_TIME = {
  allTime: "allTime",
  today: "today",
  yesterday: "yesterday",
  last7Day: "last7Day",
  last30Day: "last30Day",
};

export const FILTER_FILE_TYPE = {
  pdf: SystemConstant.FILE_TYPE.PDF,
  image: SystemConstant.FILE_TYPE.IMAGE,
  video: SystemConstant.FILE_TYPE.VIDEO,
  audio: SystemConstant.FILE_TYPE.AUDIO,
  otherFile: SystemConstant.FILE_TYPE.OTHER,
};

export const TimeFilter = ({ onChangeTime }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_FILE);
  const defaultText = getLabel("TXT_TIME");

  const [selectedText, setSelectedText] = useState(defaultText);

  const handleClickItemFunc = item => () => {
    setSelectedText(item.value === FILTER_BY_TIME.allTime ? defaultText : item.label);

    const selectDate = new Date();
    const todayDate = getDayStartAndEnd(new Date());
    if (onChangeTime) {
      switch (item.value) {
        case FILTER_BY_TIME.allTime:
          onChangeTime(); // All tim
          break;

        case FILTER_BY_TIME.today:
          onChangeTime(todayDate.start.getTime(), todayDate.end.getTime());
          break;

        case FILTER_BY_TIME.yesterday:
          selectDate.setDate(selectDate.getDate() - 1);
          const yesterdayDate = getDayStartAndEnd(selectDate);
          onChangeTime(yesterdayDate.start.getTime(), yesterdayDate.end.getTime());
          break;

        case FILTER_BY_TIME.last7Day:
          selectDate.setDate(selectDate.getDate() - 7);
          const last7Date = getDayStartAndEnd(selectDate);
          onChangeTime(last7Date.start.getTime(), todayDate.end.getTime());
          break;

        case FILTER_BY_TIME.last30Day:
          selectDate.setDate(selectDate.getDate() - 30);
          const last30Date = getDayStartAndEnd(selectDate);
          onChangeTime(last30Date.start.getTime(), todayDate.end.getTime());
          break;

        default:
          break;
      }
    }
  };

  const arrayLabel = [
    {
      label: getLabel("TXT_ALL_TIME"),
      value: FILTER_BY_TIME.allTime,
    },
    {
      label: getLabel("TXT_TODAY"),
      value: FILTER_BY_TIME.today,
    },
    {
      label: getLabel("TXT_YESTERDAY"),
      value: FILTER_BY_TIME.yesterday,
    },
    {
      label: getLabel("TXT_LAST_7DAY"),
      value: FILTER_BY_TIME.last7Day,
    },
    {
      label: getLabel("TXT_LAST_30DAY"),
      value: FILTER_BY_TIME.last30Day,
    },
  ];

  return (
    <SelectList selectedText={selectedText}>
      <List>
        {arrayLabel.map(item => (
          <ListItem disablePadding key={item.value} onClick={handleClickItemFunc(item)}>
            <ItemButton>
              <ListItemText primary={item.label} />
            </ItemButton>
          </ListItem>
        ))}
      </List>
    </SelectList>
  );
};

export const FileFilter = ({ onChangeFileType }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_FILE);
  const defaultText = getLabel("TXT_FILE_TYPE");
  const arrayLabel = [
    {
      label: getLabel("TXT_PDF"),
      value: FILTER_FILE_TYPE.pdf,
    },
    {
      label: getLabel("TXT_IMAGE"),
      value: FILTER_FILE_TYPE.image,
    },
    {
      label: getLabel("TXT_VIDEO"),
      value: FILTER_FILE_TYPE.video,
    },
    {
      label: getLabel("TXT_AUDIO"),
      value: FILTER_FILE_TYPE.audio,
    },
    {
      label: getLabel("TXT_OTHER_FILE"),
      value: FILTER_FILE_TYPE.otherFile,
    },
  ];

  const [selectedText, setSelectedText] = useState(defaultText);
  const [fileTypeList, setFileTypeList] = useState([]);

  const handleClickItemFunc = item => () => {
    const currentIndex = fileTypeList.indexOf(item.value);
    const newTypeList = [...fileTypeList];

    if (currentIndex === -1) {
      newTypeList.push(item.value);
    } else {
      newTypeList.splice(currentIndex, 1);
    }

    switch (newTypeList.length) {
      case 0:
        setSelectedText(defaultText);
        break;

      case 1:
        const selectedItem = arrayLabel.find(typeItem => typeItem.value === newTypeList[0]);
        setSelectedText(selectedItem.label);
        break;

      default:
        setSelectedText(getLabel("FM_MORE_FILE_TYPE", { quantity: newTypeList.length }));
        break;
    }

    setFileTypeList(newTypeList);
    if (onChangeFileType) onChangeFileType(newTypeList);
  };

  return (
    <SelectList selectedText={selectedText} disableClickItemEvent>
      <List>
        {arrayLabel.map(item => (
          <ListItem disablePadding key={item.value} onClick={handleClickItemFunc(item)}>
            <ItemButton>
              <ItemIcon>
                <CheckboxIcon
                  edge="start"
                  checked={fileTypeList.indexOf(item.value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": item.value }}
                />
              </ItemIcon>
              <ListItemText primary={item.label} />
            </ItemButton>
          </ListItem>
        ))}
      </List>
    </SelectList>
  );
};

const ItemButton = styled(ListItemButton)(props => ({
  minWidth: 156,

  "&:hover": {
    background: props.theme.palette.primary.main,
    "&, & *": { color: "white" },
  },
}));

const ItemIcon = styled(ListItemIcon)({
  minWidth: 36,
  paddingLeft: 6,
});

const CheckboxIcon = styled(Checkbox)({
  padding: 0,
});
